import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  TextField,
  FormHelperText,
} from "@mui/material";
import {
  FormBoxContainer,
  FormDialogTitle,
  SeparatorHrBox,
} from "../../assets/styles/tables";
import Actions from "./Actions";

const FeeStructureDialog = (props) => {
  const {
    openFeeStructure,
    handleCloseFeeStructure,
    editModeFeeStructure,
    selectedFeeStructure,
    handleInputChangeFeeStructure,
    handleSaveFeeStructure,
  } = props;

  const [nameError, setNameError] = useState(false);
  const [fixedAmountError, setFixedAmountError] = useState(false);
  const [rateError, setRateError] = useState(false);
  const [ivaError, setIvaError] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (openFeeStructure) {
      setNameError(false);
      setFixedAmountError(false);
      setRateError(false);
      setIvaError(false);
      setSaveAttempted(false);
    }
  }, [openFeeStructure]);

  const handleSave = () => {
    setSaveAttempted(true);

    if (!selectedFeeStructure.name) {
      setNameError(true);
      return;
    }

    if (!selectedFeeStructure.fixed_amount) {
      setFixedAmountError(true);
      return;
    }

    if (!selectedFeeStructure.rate) {
      setRateError(true);
      return;
    }

    if (!selectedFeeStructure.iva) {
      setIvaError(true);
      return;
    }

    handleSaveFeeStructure();
  };

  const actionProps = {
    handleClose: handleCloseFeeStructure,
    handleSave: handleSave,
  };

  return (
    <Dialog
      open={openFeeStructure}
      onClose={handleCloseFeeStructure}
      maxWidth={false}
      PaperProps={{
        style: { minWidth: "600px", maxWidth: "600px" },
      }}
    >
      <FormDialogTitle>
        {editModeFeeStructure
          ? "Editar Estructura de Cuota"
          : "Nueva Estructura de Cuota"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <TextField
                margin="dense"
                name="name"
                label="Item"
                type="text"
                fullWidth
                value={selectedFeeStructure ? selectedFeeStructure.name : ""}
                onChange={handleInputChangeFeeStructure}
                error={
                  nameError || (saveAttempted && !selectedFeeStructure.name)
                }
              />
              {nameError || (saveAttempted && !selectedFeeStructure.name) ? (
                <FormHelperText>Debes ingresar un item.</FormHelperText>
              ) : null}
              <TextField
                margin="dense"
                name="fixed_amount"
                label="Monto Fijo (%)"
                type="text"
                fullWidth
                value={
                  selectedFeeStructure ? selectedFeeStructure.fixed_amount : ""
                }
                onChange={handleInputChangeFeeStructure}
                error={
                  fixedAmountError ||
                  (saveAttempted && !selectedFeeStructure.fixed_amount)
                }
              />
              {fixedAmountError ||
              (saveAttempted && !selectedFeeStructure.fixed_amount) ? (
                <FormHelperText>Debes ingresar un monto fijo.</FormHelperText>
              ) : null}
              <TextField
                margin="dense"
                name="rate"
                label="Porcentaje (%)"
                type="text"
                fullWidth
                value={selectedFeeStructure ? selectedFeeStructure.rate : ""}
                onChange={handleInputChangeFeeStructure}
                error={
                  rateError || (saveAttempted && !selectedFeeStructure.rate)
                }
              />
              {rateError || (saveAttempted && !selectedFeeStructure.rate) ? (
                <FormHelperText>Debes ingresar un porcentaje.</FormHelperText>
              ) : null}
              <TextField
                margin="dense"
                name="iva"
                label="IVA (%)"
                type="text"
                fullWidth
                value={selectedFeeStructure ? selectedFeeStructure.iva : ""}
                onChange={handleInputChangeFeeStructure}
                error={ivaError || (saveAttempted && !selectedFeeStructure.iva)}
              />
              {ivaError || (saveAttempted && !selectedFeeStructure.iva) ? (
                <FormHelperText>Debes ingresar un IVA.</FormHelperText>
              ) : null}
            </FormBoxContainer>
          </Box>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default FeeStructureDialog;
