import { createSlice } from "@reduxjs/toolkit";
import {
  getLegalEntities,
  getRecLegalEntities,
} from "../../api/endpoints/legalEntities";

export const legalEntitySlice = createSlice({
  name: "legalEntity",
  initialState: {
    legalEntities: [],
    recLegalEntities: [],
    legalEntity: null,
  },
  reducers: {
    addLegalEntities: (state, action) => {
      state.legalEntities = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    addLegalEntity: (state, action) => {
      state.legalEntity = action.payload;
    },
    clearLegalEntity: (state, action) => {
      state.legalEntity = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLegalEntities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLegalEntities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.legalEntities = action.payload;
      })
      .addCase(getLegalEntities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getRecLegalEntities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRecLegalEntities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.recLegalEntities = action.payload;
      })
      .addCase(getRecLegalEntities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { addLegalEntities, addLegalEntity, clearLegalEntity } =
  legalEntitySlice.actions;

export default legalEntitySlice.reducer;
