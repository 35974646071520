import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
} from "../../assets/styles/tables";
import Actions from "./Actions";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFee } from "../../features/fees/feeSlice";

const PaymentMethodDetailDialog = (props) => {
  const dispatch = useDispatch();
  const {
    openPaymentMethodDetail,
    handleClosePaymentMethodDetail,
    editModePaymentMethodDetail,
    selectedPaymentMethodDetail,
    handleInputChangePaymentMethodDetail,
    handleSavePaymentMethodDetail,
    handleSelectChangePaymentMethodDetail,
  } = props;

  const [feeError, setFeeError] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  const { fees, selectedFee } = useSelector((state) => state.fees);

  useEffect(() => {
    // Cuando el diálogo se abre, reseteamos el estado de error y selectedFee
    if (openPaymentMethodDetail) {
      setFeeError(false);
      setSaveAttempted(false);
      dispatch(setSelectedFee(null)); // Aquí reseteamos selectedFee
    }
  }, [openPaymentMethodDetail, dispatch]);

  const handleSelectChangeFee = (event) => {
    // Aquí, manejamos la opción especial "Seleccione una cuota"
    if (event.target.value === "") {
      dispatch(setSelectedFee(null));
      return;
    }
    const fee = fees.find((fee) => fee.name === event.target.value);
    dispatch(setSelectedFee(fee));
    setFeeError(false);
  };

  const handleBlur = (event) => {
    if (!event.target.value) {
      setFeeError(true);
    }
  };

  const handleSave = () => {
    setSaveAttempted(true);
    // No permitimos guardar si no se ha seleccionado una cuota
    if (!selectedFee) {
      setFeeError(true);
      return;
    }
    handleSavePaymentMethodDetail()
  };

  const actionProps = {
    handleClose: handleClosePaymentMethodDetail,
    handleSave: handleSave,
  };

  return (
    <Dialog
      open={openPaymentMethodDetail}
      onClose={handleClosePaymentMethodDetail}
      maxWidth={false}
    >
      <FormDialogTitle>
        {editModePaymentMethodDetail
          ? "Editar Forma de Pago"
          : "Nueva Forma de Pago"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <TextField
                margin="dense"
                name="item_order"
                label="Orden"
                type="text"
                fullWidth
                value={selectedPaymentMethodDetail.item_order}
                onChange={handleInputChangePaymentMethodDetail}
                disabled
              />
              <SelectFormControl
                error={feeError || (saveAttempted && !selectedFee)}
              >
                <InputLabel margin="dense" id="fee-label">
                  Tipo Cuota
                </InputLabel>
                <Select
                  labelId="fee-label"
                  name="fee"
                  value={selectedFee ? selectedFee.name : "1"}
                  onChange={handleSelectChangeFee}
                  onBlur={handleBlur}
                >
                  <MenuItem value="1">
                    <em>Seleccione una cuota</em>
                  </MenuItem>
                  {fees.map((fee, index) => (
                    <MenuItem key={index} value={fee.name}>
                      {fee.name}
                    </MenuItem>
                  ))}
                </Select>
                {(feeError || (saveAttempted && !selectedFee)) && (
                  <FormHelperText>Debes seleccionar una cuota.</FormHelperText>
                )}
              </SelectFormControl>
            </FormBoxContainer>
          </Box>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default PaymentMethodDetailDialog;
