import React from "react";
import { Box, Table, TableBody, Typography, IconButton } from "@mui/material";
import {
  CustomTableContainer,
  CustomTableRow,
  CustomTableCell,
  CustomButtonContainer,
  CustomButton,
} from "../../../assets/styles/tablesMobile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const SubscribersTableMobile = (props) => {
  const { subscribers, handleEdit, handleDelete, ciudadesArray } = props;

  return (
    <CustomTableContainer>
      <Table>
        <TableBody>
          {subscribers.map((subscriber) => (
            <CustomTableRow key={subscriber.id}>
              <CustomTableCell sx={{ border: "transparent" }}>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {subscriber.name}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Nombre
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {subscriber.last_name}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Apellido
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {subscriber.dni}
                  </Typography>
                  <Typography variant="caption" align="center">
                    DNI
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {subscriber.adress}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Dirección
                  </Typography>
                </CustomTableCell>
                <CustomTableCell sx={{ borderBottom: "transparent" }}>
                  <Typography variant="subtitle1" align="center">
                    {ciudadesArray.map((city) =>
                      city.id === subscriber.district
                        ? city.name
                        : "Sin ciudad"
                    )}
                  </Typography>
                  <Typography variant="caption" align="center" >
                    Ciudad
                  </Typography>
                </CustomTableCell>
              </CustomTableCell>
              <CustomButtonContainer >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CustomButton
                    variant="contained"
                    onClick={() => handleEdit(subscriber)}
                    sx={{ marginBottom: "10px" }}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    color="tertiary"
                    onClick={() => handleDelete(subscriber.id)}
                  >
                    <IconButton size="small">
                      <DeleteIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </CustomButton>
                </Box>
              </CustomButtonContainer>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default SubscribersTableMobile;