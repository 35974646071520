import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getLegalEntities = createAsyncThunk("legalEntities", async () => {
  const response = await apiClient.get("legal_entities");
  return response.data;
});

export const getLegalEntityById = createAsyncThunk("legalEntity/id", async (id) => {
  const response = await apiClient.get(`legal_entity/${id}`);
  return response.data;
});

export const getRecLegalEntities = createAsyncThunk("ReclegalEntities", async () => {
  const response = await apiClient.get("rec_legal_entities");
  return response.data;
});


export const deleteLegalEntityById = createAsyncThunk("legalEntity/delete", async (id) => {
  const response = await apiClient.delete(`legal_entity/${id}`);
  return response.data;
});

export const addLegalEntity = createAsyncThunk("legalEntities/add", async (legalEntity) => {
  const response = await apiClient.post("legal_entities", legalEntity);
  return response.data;
});

export const updateLegalEntityById = createAsyncThunk("legalEntity/id", async (legalEntity) => {
  const response = await apiClient.put(`legal_entity/${legalEntity.uuid}`, legalEntity);
  return response.data;
}
);