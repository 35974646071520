import { createSlice } from "@reduxjs/toolkit";
import { getFees } from "../../api/endpoints/fees"; // Asegúrate de usar la ruta correcta a tu archivo thunk

export const feeSlice = createSlice({
  name: "fees",
  initialState: {
    fees: [],
    selectedFee: {},
    status: "idle", // Se añade una nueva propiedad para rastrear el estado de carga
    error: null, // Se añade una nueva propiedad para manejar posibles errores
  },
  reducers: {
    clearFees: (state) => {
      state.fees = [];
    },
    setSelectedFee: (state, action) => {
      // Aquí se añade un reducer para cambiar el valor de selectedFee
      state.selectedFee = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fees = action.payload;
      })
      .addCase(getFees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { clearFees, setSelectedFee } = feeSlice.actions;

export default feeSlice.reducer;
