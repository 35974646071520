import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  HeadTableCell,
  RowTableCell,
  EditButton,
  DeleteButton,
  PaginationBox,
  HeaderBox,
  AddButton,
} from "../../assets/styles/tables";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SearchBar from "../../common/SearchBar";

const FeeTypeTab = (props) => {
  const { handleEditFee, handleDeleteFee, fees, setSelectedFee, setOpenFee } =
    props;

  const handleClickOpen = () => {
    setSelectedFee({
      name: "",
      feeType: "",
      complete: "",
      score: "",
    });
    setOpenFee(true);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <br />
      <HeaderBox sx={{ marginLeft: 0 }}>
        <AddButton variant="contained" onClick={handleClickOpen}>
          <PostAddIcon fontSize="large" />
        </AddButton>
      </HeaderBox>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <HeadTableCell sx={{ width: "25%", textAlign: "center" }}>
              Nombre
            </HeadTableCell>
            <HeadTableCell sx={{ width: "20%", textAlign: "center" }}>
              Tipo
            </HeadTableCell>
            <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>
              Completa
            </HeadTableCell>
            <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>
              Puntaje
            </HeadTableCell>
            <HeadTableCell sx={{ width: "25%", textAlign: "center" }}>
              Acciones
            </HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fees.map((fee, index) => (
            <TableRow key={index}>
              <RowTableCell sx={{ textAlign: "center" }}>
                {fee.name}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                {fee.feeType}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                {fee.complete === 1 ? "Si" : "No"}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                {fee.score ? "Si" : "No"}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                <EditButton
                  variant="contained"
                  onClick={() => handleEditFee(fee)}
                >
                  <IconButton size="small">
                    <EditIcon sx={{ color: "whitesmoke" }} />
                  </IconButton>
                </EditButton>
                <DeleteButton
                  variant="contained"
                  onClick={() => handleDeleteFee(fee.id, fee.uuid)}
                >
                  <IconButton size="small">
                    <DeleteIcon sx={{ color: "whitesmoke" }} />
                  </IconButton>
                </DeleteButton>
              </RowTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default FeeTypeTab;
