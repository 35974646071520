import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getDepartments = createAsyncThunk("departments", async () => {
  const response = await apiClient.get("departments");
  return response.data;
});

export const getDepartmentById = createAsyncThunk("department/id", async ( id ) => {
  const response = await apiClient.get(`department/${id}`);
  return response.data;
});

export const deleteDepartmentById = createAsyncThunk("department/delete", async ( id ) => {
  const response = await apiClient.delete(`department/${id}`);
  return response.data;
});

export const addDepartment = createAsyncThunk("departments/add", async (department) => {
delete department.deleted_at;
delete department.updated_at;
delete department.created_at;
delete department.is_active;
delete department.modules;
  const response = await apiClient.post("departments", department);
  return response.data;
});

export const updateDepartment = createAsyncThunk("department/update", async ( department ) => {
  delete department.deleted_at;
  delete department.updated_at;
  delete department.created_at;
  delete department.is_active;
  delete department.modules;
  
  const response = await apiClient.put(`department/${department.uuid}`, department);
  return response.data;
});