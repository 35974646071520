import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getConstTypes = createAsyncThunk("constructionTypes", async () => {
  const response = await apiClient.get("construction_types");
  return response.data;
});

export const getConstTypeById = createAsyncThunk("constructionType/id", async (id) => {
  const response = await apiClient.get(`construction_type/${id}`);
  return response.data;
});

export const deleteConstTypeById = createAsyncThunk("constructionType/delete", async (id) => {
  const response = await apiClient.delete(`construction_type/${id}`);
  return response.data;
});

export const addConstType = createAsyncThunk("constructionTypes/add", async (constructionType) => {
  const response = await apiClient.post("construction_types", constructionType);
  return response.data;
});

export const updateConstTypeById = createAsyncThunk(
  "constructionType/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`construction_type/${id}`, data);
    return response.data;
  }
);