import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularColor() {
  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress color="success" size={100} />
    </div>
  );
}