import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getFeeTypes = createAsyncThunk("feeTypes", async () => {
  const response = await apiClient.get("fee_types");
  return response.data;
});

export const getFeeTypeById = createAsyncThunk("feeType/id", async (id) => {
  const response = await apiClient.get(`fee_type/${id}`);
  return response.data;
});

export const deleteFeeTypeById = createAsyncThunk(
  "feeType/delete",
  async (id) => {
    const response = await apiClient.delete(`fee_type/${id}`);
    return response.data;
  }
);

export const addFeeType = createAsyncThunk("feeTypes/add", async (feeType) => {
  const response = await apiClient.post("fee_types", feeType);
  return response.data;
});

export const updateFeeTypeById = createAsyncThunk(
  "feeType/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`fee_type/${id}`, data);
    return response.data;
  }
);
