const originalGroups = [
  {
    id: 1,
    name: "Grupo 1",
    number_from: "1",
    number_to: "368",
    created_at: "2014/08/19",
    subgroup_of: 1
  },
  {
    id: 2,
    name: "Grupo 2",
    number_from: "369",
    number_to: "736",
    created_at: "2014/09/14",
    subgroup_of: 1
  }
];

export default originalGroups