import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
  FormTextareaAutosize,
  FormBoxLeft,
  FormBoxCenter,
  FormBoxRight,
  FormDivider
} from "../../assets/styles/tables";
import Actions from "./Actions";

const BranchDialog = (props) => {

  const { open, handleClose, editMode,
    selectedBranch, handleInputChange,
    handleSelectChange, handleSave } = props;

  const actionProps = { handleClose, handleSave };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <FormDialogTitle>
        {editMode ? "Editar Sucursal" : "Agregar Sucursal"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "flex" }}>
        <Box>
          <Box>
            <FormDialogSubtitle> Datos generales </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
              <TextField
                  margin="dense"
                  name="company"
                  label="Empresa"
                  type="text"
                  fullWidth
                  value={selectedBranch.description}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="name"
                  label="Nombre"
                  type="text"
                  fullWidth
                  value={selectedBranch.name}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="mail_id"
                  label="Email"
                  type="text"
                  fullWidth
                  value={selectedBranch.email}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="telephone"
                  label="Teléfono"
                  type="text"
                  fullWidth
                  value={selectedBranch.telephone}
                  onChange={handleInputChange}
                />
              </FormBoxLeft>
              <FormBoxCenter />
              <FormBoxRight>
                <FormDialogSubtitle sx={{ marginTop: "-17px" }}>
                  Descripción:
                </FormDialogSubtitle>
                <FormTextareaAutosize
                  rowsMin={3}
                  placeholder=""
                  aria-label="Observaciones"
                  margin="dense"
                  name="obs"
                  value={selectedBranch.description}
                  onChange={handleInputChange}
                  style={{ height: "169px" }}
                />
                <TextField
                  margin="dense"
                  name="status"
                  label="Estado"
                  type="text"
                  fullWidth
                  value={selectedBranch.status}
                  onChange={handleInputChange}
                />
              </FormBoxRight>
            </FormBoxContainer>
          </Box>
        </Box>

        <FormDivider orientation="vertical" sx={{ height: "300px" }} />
        <Box>
          <Box>
            <FormDialogSubtitle>Datos de localización</FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
                <TextField
                  margin="dense"
                  name="adress"
                  label="Dirección"
                  type="text"
                  fullWidth
                  value={selectedBranch.adress}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="postal_code"
                  label="CP"
                  type="text"
                  fullWidth
                  value={selectedBranch.postal_code}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="timezone"
                  label="Zona Horaria"
                  type="text"
                  fullWidth
                  value={selectedBranch.timezone}
                  onChange={handleInputChange}
                />
              </FormBoxLeft>
              <FormBoxCenter />
              <FormBoxRight>
                <TextField
                  margin="dense"
                  name="city"
                  label="Localidad"
                  type="text"
                  fullWidth
                  value={selectedBranch.city}
                  onChange={handleInputChange}
                  sx={{ marginTop: 1 }}
                />
                <TextField
                  margin="dense"
                  name="province"
                  label="Provincia"
                  type="text"
                  fullWidth
                  value={selectedBranch.province}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="country"
                  label="País"
                  type="text"
                  fullWidth
                  value={selectedBranch.country}
                  onChange={handleInputChange}
                />
              </FormBoxRight>
            </FormBoxContainer>
          </Box>
          <TextField
            margin="dense"
            name="gps_cords"
            label="Coordenadas geográficas"
            type="text"
            fullWidth
            value={selectedBranch.gps_cords}
            onChange={handleInputChange}
          />
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  )
}

export default BranchDialog;