import React from "react";
import CouponsMailsForm from "../common/CouponsMailsForm";
import { Box } from "@mui/material";

const CouponsMails = () => {
  return (
    <Box mt={12}>
      <CouponsMailsForm/>
    </Box>
  );
};

export default CouponsMails;
