import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../api/apiClient";

export const getUsers = createAsyncThunk("users", async () => {
  const response = await apiClient.get("users");
  return response.data;
});

export const getUserById = createAsyncThunk("user/id", async ( id ) => {
  const response = await apiClient.get(`user/${id}`);
  return response.data;
});

export const deleteUserById = createAsyncThunk("user/delete", async ( id ) => {
  const response = await apiClient.delete(`user/${id}`);
  return response.data;
});

export const addUser = createAsyncThunk("user/add", async (user) => {
  const response = await apiClient.post("users", user);
  return response.data;
});

export const updateUser = createAsyncThunk("user/update", async ({ id, user }) => {
  const response = await apiClient.put(`user/${id}`, user);
  return response.data;
});