import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  TextField,
  FormHelperText,
} from "@mui/material";
import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  FormTextareaAutosize,
  SeparatorHrBox,
} from "../../assets/styles/tables";
import Actions from "./Actions";

const ConstTypeDialog = (props) => {
  const { openConstType, handleCloseConstType,
    editModeConstType, selectedConstType,
    handleInputChangeConstType,
    handleSaveConstType,
  } = props;

  const [errors, setErrors] = useState({
    name: false,
    surface: false,
  });

  const handleInputChange = (event) => {
    handleInputChangeConstType(event);
    const { name, value } = event.target;
    setErrors({ ...errors, [name]: !value });
  };

  const handleSave = () => {
    const newErrors = {
      name: !selectedConstType.name,
      surface: !selectedConstType.surface,
    };
    setErrors(newErrors);
    if (Object.values(newErrors).includes(true)) {
      return;
    }
    handleSaveConstType();
  };

  const actionProps = {
    handleClose: handleCloseConstType,
    handleSave: handleSave
  };

  return (
    <Dialog open={openConstType} onClose={handleCloseConstType} maxWidth={false} PaperProps={{style: {minWidth: '600px', maxWidth: '600px'}}}>
      <FormDialogTitle>
        {editModeConstType ? "Editar Tipo de construcción" : "Agregar Tipo de construcción"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <FormBoxContainer sx={{ display: "block" }}>
            <TextField
              error={errors.name}
              margin="dense"
              name="name"
              label="Nombre"
              type="text"
              fullWidth
              value={selectedConstType ? selectedConstType.name : ''}
              onChange={handleInputChange}
            />
            {errors.name && <FormHelperText>El nombre es obligatorio</FormHelperText>}
            <TextField
              error={errors.surface}
              margin="dense"
              name="surface"
              label="Superficie"
              type="number"
              fullWidth
              value={selectedConstType ? selectedConstType.surface : ''}
              onChange={handleInputChange}
            />
            {errors.surface && <FormHelperText>La superficie es obligatoria</FormHelperText>}
            <FormDialogSubtitle sx={{
              marginTop: -1,
              textAlign: "left",
              marginLeft: -3
            }}>
              Descripción:
            </FormDialogSubtitle>
            <FormTextareaAutosize
              rowsMin={3}
              placeholder="Descripción"
              aria-label="Descripción"
              margin="dense"
              name="description"
              value={selectedConstType ? selectedConstType.description : ''}
              onChange={handleInputChangeConstType}
              style={{ height: "169px", marginTop: -10 }}
            />
          </FormBoxContainer>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  )
}

export default ConstTypeDialog;