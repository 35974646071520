import { createSlice } from '@reduxjs/toolkit';

export const IVAConditionSlice = createSlice({
  name: 'IVAConditions',
  initialState: [], // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addIVAConditions: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeIVACondition: (state, action) => {
      const index = state.findIndex((ivaCondition) => ivaCondition.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { addIVAConditions, removeIVACondition } = IVAConditionSlice.actions;

export default IVAConditionSlice.reducer;
