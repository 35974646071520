import React, { useState, useEffect } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { getContractsCount } from "../api/endpoints/contracts";
import { setArchiveDialogFalse, setArchiveDialogTrue, setContractsToEditByGroup } from "../features/contracts/contractSlice";
import { getLegalEntities } from "../api/endpoints/legalEntities";
import { getConstTypes } from "../api/endpoints/constructionTypes";
import { getSubscribers } from "../api/endpoints/subscribers";
import { getAdditionals } from "../api/endpoints/additionals";
import Loading from "./Loading";

import ContractsTable from "../components/table/ContractsTable";
import ContractDialog from "../components/dialog/ContractDialog";
import ArchivedContractsTable from "../components/table/ArchivedContractsTable";

const ContractsForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [save, setSave] = useState(false);

  useEffect(() => {
    const fetchContracts = async () => {
      setIsLoading(true);
      try {
        const contractsCountData = await dispatch(getContractsCount(false)).unwrap();
        const archivedContractsCountData = await dispatch(getContractsCount(true)).unwrap();
        dispatch(getLegalEntities());
        dispatch(getConstTypes());
        dispatch(getSubscribers());
        dispatch(getAdditionals());

        //const paymentMethodsData = await dispatch(getPaymentMethods()).unwrap();
        //await dispatch(addContracts(contractsData));
        //await dispatch(addPaymentMethods(paymentMethodsData));
        //await dispatch(addLegalEntities(legalEntitiesData));
        //await dispatch(addConstructionTypes(constTypesData));
        //await dispatch(addPreAdjudicationReasons(preAdjReasonsData));
        //await dispatch(addReleaseReasons(releaseReasonsData));

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchContracts();
    // }, [save]);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSave(!save);
  };

  const handleEdit = (contracts) => {
    dispatch(setContractsToEditByGroup(contracts));
    setEditMode(true);
  };

  const props = {
    handleEdit: handleEdit,
    setOpen: setOpen,
  };

  const dialogProps = {
    save,
    setSave,
    open,
    handleClose,
    editMode,
  };

  if (isLoading) {
    return <Loading />;
  }

  const customTableStyles = {
    generalTableBox: {
      display: "flex",
      boxShadow: "10px 10px 50px rgba(1,2,10,0.3)",
      borderRadius: "20px",
      margin: 0,
      // Ajuste para que siempre ocupe el espacio disponible sin desbordar
      width: "100%",
      overflowX: "auto", // Asegura que el contenido se desplace si es demasiado ancho
    },
    generalTableContainer: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: 5,
      borderRadius: "20px",
      width: "100%", // Asegura que el contenedor tome el 100% del ancho disponible
      // No es necesario establecer el color de fondo aquí, ya que debería heredarse
    },
  };

  return (
    <>
      <div style={{ margin: "20px 3%" }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          style={{
            fontWeight: 600,
            marginBottom: "1rem",
            backgroundColor: "#f5f5f5",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
          }}
        >
          Contratos
        </Typography>

        <ContractsTable style={customTableStyles} {...props} onOpen={() => dispatch(setArchiveDialogFalse())} />
      </div>

      <div style={{ margin: "40px 3%" }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          style={{
            fontWeight: 600,
            marginBottom: "1rem",
            backgroundColor: "#f5f5f5",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
          }}
        >
          Contratos Archivados
        </Typography>

        <ArchivedContractsTable style={customTableStyles} {...props} onOpen={() => dispatch(setArchiveDialogTrue())} />
      </div>

      {open && <ContractDialog {...dialogProps} />}
    </>
  );
};

export default ContractsForm;
