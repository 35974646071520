import { createSlice } from '@reduxjs/toolkit';
import { getFeeStructures } from '../../api/endpoints/feeStructures';

const feeStructuresSlice = createSlice({
  name: "feeStructures",
  initialState: { data: [], status: "idle", error: null },
  reducers: {
    setFeeStructures: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeeStructures.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      });
  },
});

export const { setFeeStructures } = feeStructuresSlice.actions;

export default feeStructuresSlice.reducer;