import { createSlice } from '@reduxjs/toolkit';

export const NationalitySlice = createSlice({
  name: 'Nationalities',
  initialState: [], // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addNationality: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeNationality: (state, action) => {
      const index = state.findIndex((nationality) => nationality.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { addNationality, removeNationality } = NationalitySlice.actions;

export default NationalitySlice.reducer;
