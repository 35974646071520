import React from "react";
import LifeInsuranceImportForm from "../common/LifeInsuranceImportForm";
import { Box } from "@mui/material";

const LifeInsuranceImport = () => {
  return (
    <Box mt={12}>
      <LifeInsuranceImportForm />
    </Box>
  );
};

export default LifeInsuranceImport;
