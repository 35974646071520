import { createSlice } from '@reduxjs/toolkit';
import { getSalePoints } from '../../api/endpoints/salePoints';

export const SalePointSlice = createSlice({
  name: 'salePoints',
  initialState: {
    salePoints: [], // Mantener el estado dentro de un objeto
  },
  reducers: {
    addSalePoints: (state, action) => {
      state.salePoints = action.payload; // Asignar el payload directamente al estado
    },
    removeSalePoint: (state, action) => {
      const index = state.salePoints.findIndex((SalePoint) => SalePoint.uuid === action.payload);
      if (index !== -1) {
        state.salePoints.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalePoints.fulfilled, (state, action) => {
      console.log(action.payload);
      state.salePoints = action.payload;
    });
  },
});

export const { addSalePoints, removeSalePoint } = SalePointSlice.actions;

export default SalePointSlice.reducer;