import React from "react";

import {
    DeleteButton,
    EditButton,
    GeneralTableBox,
    GeneralTableContainer,
    HeadTableCell,
    PaginationBox,
    RowTableCell
} from "../../assets/styles/tables";

import {
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableHead,
    TableRow
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";


const BranchesTable = (props) => {

    const { branches, handleEdit, handleDelete,
        getCurrentPageItems, ITEMS_PER_PAGE,
        page, setPage } = props;

    return (
        <GeneralTableBox>
            <GeneralTableContainer>
                <Table aria-label="branch table">
                    <TableHead>
                        <TableRow>
                            <HeadTableCell sx={{ width: "20%", minWidth: "250px" }}>Empresa</HeadTableCell>
                            <HeadTableCell sx={{ width: "20%", minWidth: "200px" }}>Direccion</HeadTableCell>
                            <HeadTableCell sx={{ width: "20%" }}>Localidad</HeadTableCell>
                            <HeadTableCell sx={{ width: "20%" }}>Provincia</HeadTableCell>
                            <HeadTableCell sx={{ textAlign: "center", width: "18%" }}>
                                Acciones
                            </HeadTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getCurrentPageItems().map((branch, key) => (
                            <TableRow key={key}>
                                <RowTableCell>{branch.description}</RowTableCell>
                                <RowTableCell>{branch.adress}</RowTableCell>
                                <RowTableCell>{branch.city}</RowTableCell>
                                <RowTableCell>{branch.province}</RowTableCell>
                                <RowTableCell sx={{ textAlign: "center" }}>
                                    <EditButton
                                        variant="contained"
                                        onClick={() => handleEdit(branch)}
                                    >
                                        <IconButton size="small">
                                            <EditIcon sx={{ color: "whitesmoke" }} />
                                        </IconButton>
                                    </EditButton>
                                    <DeleteButton
                                        variant="contained"
                                        onClick={() => handleDelete(branch.id, branch.uuid)}
                                        color="tertiary"
                                    >
                                        <IconButton size="small">
                                            <DeleteIcon sx={{ color: "whitesmoke" }} />
                                        </IconButton>
                                    </DeleteButton>
                                </RowTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <PaginationBox>
                    <Pagination
                        count={Math.ceil(branches.length / ITEMS_PER_PAGE)}
                        shape="rounded"
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />
                </PaginationBox>
            </GeneralTableContainer>
        </GeneralTableBox>
    )
}

export default BranchesTable;