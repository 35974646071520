import { createSlice } from '@reduxjs/toolkit';

export const UserSlice = createSlice({
  name: 'Users',
  initialState: [],
  reducers: {
    addUser: (state, action) => {
      state.push(action.payload);
    },
    removeUser: (state, action) => {
      const index = state.findIndex((user) => user.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { addUser, removeUser } = UserSlice.actions;

export default UserSlice.reducer;