import { TableContainer, Box, TableRow, TableCell, styled, Button } from "@mui/material";

export const CustomTableContainer = styled(TableContainer)({
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export const CustomTableRow = styled(TableRow)({
  backgroundColor: "whitesmoke",
  borderRadius: "10px",
  border: "1px solid rgba(224, 224, 224, 1)",
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  width: "90%",
  marginLeft: "5%",
});

export const CustomTableCell = styled(TableCell)({
  display: "block",
  textAlign: "center",
  alignItems: "center",
  paddingTop: "8px",
  paddingBottom: "8px",
  width: "100%",
  flex: "1 1 0",
  borderRight: "1px solid rgba(224, 224, 224, 1)",
  "& .MuiTypography-subtitle1": {
    fontSize: "18px",
  },

  "& .MuiTypography-caption": {
    fontSize: "14px",
    opacity: 0.6,
  },
});

export const CustomButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "20%",
  paddingRight: "15px",
});

export const CustomButton = styled(Button)({
  borderRadius: "15px",
  boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
});