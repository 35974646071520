import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Pagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../common/Loading";
import SearchBar from "../../common/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  AddButton,
  DeleteButton,
  NameViewBox,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  RowTableCell,
  EditButton,
  PaginationBox,
  HeaderBox,
} from "../../assets/styles/tables";
import {
  getPlans,
  deletePlanById,
  addPlan,
  updatePlanById,
} from "../../api/endpoints/plans";
import { getFeeTypes } from "../../api/endpoints/feeTypes";
import { getBranchById } from "../../api/endpoints/branches";

import PlanDialog from "../dialog/PlanDialog";

const PlansTable = () => {
  const dispatch = useDispatch();
  const { branch } = useSelector((state) => state.branch);
  const user = useSelector((state) => state.userLogged);
  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    name: "",
    effective_start_date: "",
    branch,
    installments_number: null,
    contracts_number: null,
    interest_rate: null,
    first_installment_amount: null,
    id: null,
    uuid_user: "",
    // remaining_balance: null,
    // plan_type: null,
    // payment_down: null,
    // issued_condition: null,
  });
  const [namePlan, setNamePlan] = useState("");
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  useEffect(() => {
    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const plansData = await dispatch(getPlans()).unwrap();
        const feeTypesData = await dispatch(getFeeTypes()).unwrap();
        const branchIds = plansData.map((plan) => plan.uuid_branch);
        const uniqueBranchIds = [...new Set(branchIds)]; // Elimina duplicados
        const branches = await Promise.all(
          uniqueBranchIds.map((branchId) =>
            dispatch(getBranchById(branchId)).unwrap()
          )
        );
        // Crea un mapa de roles para un acceso rápido
        const branchMap = branches.reduce((acc, role, index) => {
          acc[uniqueBranchIds[index]] = role;
          return acc;
        }, {});

        // Luego, crea la lista de usuarios con roles
        const plansWithBranches = plansData.map((plan, index) => ({
          ...plan,
          id: index,
          branch: branchMap[plan.uuid_branch],
        }));

        setPlans(plansWithBranches);
        setFilteredPlans(plansWithBranches);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchPlans();
  }, []);

  const handleSearch = (searchText) => {
    if (searchText === "") {
      setFilteredPlans(plans);
    } else {
      const filteredPlans = plans.filter((plan) =>
        plan.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredPlans(filteredPlans);
    }
    setPage(1);
  };

  const getCurrentPageItems = () => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredPlans.slice(startIndex, endIndex);
  };

  const handleClickOpen = () => {
    setSelectedPlan({
      name: "",
      effective_start_date: "",
      branch,
      installments_number: null,
      contracts_number: null,
      interest_rate: null,
      first_installment_amount: null,
      uuid_user: user.uuid,
      // remaining_balance: null,
      // plan_type: null,
      // payment_down: null,
      // issued_condition: null,
    });
    setEditMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // setSelectedPlan((prevState) => ({
    //   ...prevState,
    //   [name]:
    //     name === "cuotas" || name === "contratos"
    //       ? Number.parseFloat(value.replace(",", ""))
    //       : value,
    // }));
    setSelectedPlan({ ...selectedPlan, [name]: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedPlan({ ...selectedPlan, [name]: value });
  };

  const handleSave = async () => {
    if (editMode) {
      const updatedPlans = plans.map((plan) =>
        plan.id === selectedPlan.id ? selectedPlan : plan
      );

      const updatePlan = {
        id: selectedPlan.uuid,
        data: {
          name: selectedPlan.name,
          effective_start_date: selectedPlan.effective_start_date,
          installments_number: selectedPlan.installments_number,
          contracts_number: selectedPlan.contracts_number,
          interest_rate: selectedPlan.interest_rate,
          first_installment_amount: selectedPlan.first_installment_amount,
        },
      };
      const updatedPlan = await dispatch(updatePlanById(updatePlan)).unwrap();
      setFilteredPlans(updatedPlans);
    } else {
      try {
        const newPlan = {
          name: selectedPlan.name,
          effective_start_date: selectedPlan.effective_start_date,
          installments_number: selectedPlan.installments_number,
          contracts_number: selectedPlan.contracts_number,
          interest_rate: selectedPlan.interest_rate,
          first_installment_amount: selectedPlan.first_installment_amount,
          uuid_branch: selectedPlan.branch.uuid_branch,
          uuid_user: selectedPlan.uuid_user,
          // remaining_balance: selectedPlan.remaining_balance,
          // payment_down: selectedPlan.payment_down,
          // plan_type: selectedPlan.plan_type,
          // issued_condition: selectedPlan.issued_condition,
        };
        const addedPlan = await dispatch(addPlan(newPlan)).unwrap();
        setFilteredPlans([...plans, addedPlan]);
        setPlans([...plans, addedPlan]);
      } catch (error) {
        console.error(error);
      }
    }
    handleClose();
  };

  const handleEdit = (plan) => {
    setSelectedPlan(plan);
    setEditMode(true);
    setOpen(true);
    setNamePlan(plan.name);
  };

  const handleDelete = (id, uuid) => {
    const updatedPlans = plans.filter((plan) => plan.id !== id);
    dispatch(deletePlanById(uuid)).unwrap();
    setFilteredPlans(updatedPlans);
    setPlans(updatedPlans);
  };

  const dialogProps = {
    open,
    handleClose,
    editMode,
    selectedPlan,
    handleInputChange,
    handleSelectChange,
    handleSave,
    namePlan,
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <HeaderBox>
        <AddButton variant="contained" onClick={handleClickOpen}>
          <PersonAddIcon />
        </AddButton>
        <SearchBar onSearch={handleSearch} />
        <NameViewBox> VER PLANES </NameViewBox>
      </HeaderBox>

      <GeneralTableBox>
        <GeneralTableContainer>
          <Table aria-label="plan table">
            <TableHead>
              <TableRow>
                <HeadTableCell sx={{ width: "10%", textAlign: "center" }}>
                  Nombre
                </HeadTableCell>
                <HeadTableCell sx={{ width: "13%", textAlign: "center" }}>
                  Fecha inicio vig.
                </HeadTableCell>
                <HeadTableCell sx={{ width: "13%", textAlign: "center" }}>
                  Fecha baja
                </HeadTableCell>
                <HeadTableCell sx={{ width: "10%", textAlign: "center" }}>
                  Cant. de cuotas
                </HeadTableCell>
                <HeadTableCell sx={{ width: "10%", textAlign: "center" }}>
                  Cant. de contrat.
                </HeadTableCell>
                <HeadTableCell sx={{ width: "12%", textAlign: "center" }}>
                  Interes x Def.(%)
                </HeadTableCell>
                <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>
                  1er Valor Cuota($)
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                  Acciones
                </HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getCurrentPageItems().map((plan, key) => (
                <TableRow key={key}>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {plan.name}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {plan.effective_start_date}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {plan.finish_date}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {plan.installments_number}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {plan.contracts_number}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {plan.interest_rate.toLocaleString(navigator.language, {
                      minimumFractionDigits: 3,
                    })}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {plan.first_installment_amount.toLocaleString(
                      navigator.language,
                      { minimumFractionDigits: 3 }
                    )}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    <EditButton
                      variant="contained"
                      onClick={() => handleEdit(plan)}
                    >
                      <IconButton size="small">
                        <EditIcon sx={{ color: "whitesmoke" }} />
                      </IconButton>
                    </EditButton>
                    <DeleteButton
                      variant="contained"
                      onClick={() => handleDelete(plan.id, plan.uuid)}
                    >
                      <IconButton size="small">
                        <DeleteIcon sx={{ color: "whitesmoke" }} />
                      </IconButton>
                    </DeleteButton>
                  </RowTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <PaginationBox>
            <Pagination
              count={Math.ceil(plans.length / ITEMS_PER_PAGE)}
              shape="rounded"
              page={page}
              onChange={(event, value) => setPage(value)}
            />
          </PaginationBox>
        </GeneralTableContainer>
      </GeneralTableBox>
      <PlanDialog {...dialogProps} />
    </>
  );
};

export default PlansTable;
