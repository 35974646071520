import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../api/apiClient";

export const afip = createAsyncThunk("auth/afip", async (_, thunkAPI) => {
  const { tokenAfip, expirationTime, sign, genTime } = thunkAPI.getState().afip;
  const currentTime = new Date();
  const genTimeDate = new Date(genTime);

  // Verifica si el token existe, no ha expirado y ha sido generado hace menos de 5 minutos
  if (tokenAfip && sign && new Date(expirationTime) > currentTime && (currentTime - genTimeDate) < 5 * 60 * 1000) {
    return {
      data: tokenAfip,
      status: "existing",
    };
  }

  // Si no hay token o ha expirado, obtén uno nuevo
  const response = await apiClient.get("afip_connect");
  // console.log("AFIP", response.data);
  return {
    data: response.data.Token,
    expirationTime: response.data["Expiration Time"],
    sign: response.data.Sing,
    status: response.status,
  };
});
