import { createSlice } from "@reduxjs/toolkit";
import { getPaymentMethods } from "../../api/endpoints/paymentMethods"; // Asegúrate de usar la ruta correcta a tu archivo thunk

export const paymentMethodSlice = createSlice({
  name: "paymentMethods",
  initialState: {
    paymentMethods: [],
    selectedPaymentMethod: {},
    status: "idle", // Se añade una nueva propiedad para rastrear el estado de carga
    error: null, // Se añade una nueva propiedad para manejar posibles errores
  },
  reducers: {
    addPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    clearPaymentMethods: (state) => {
      state.paymentMethods = [];
    },
    setSelectedPaymentMethod: (state, action) => {
      // Aquí se añade un reducer para cambiar el valor de selectedPaymentMethod
      state.selectedPaymentMethod = action.payload;
    },
    setPaymentMethod: (state, action) => {
      if (action.payload.update) {
        // Si estamos actualizando, buscamos el método de pago con el mismo ID y lo reemplazamos
        const index = state.paymentMethods.findIndex(paymentMethod => paymentMethod.id === action.payload.data.id);
        if (index !== -1) {
          state.paymentMethods[index] = action.payload.data;
        }
      } else {
        // Si estamos añadiendo, simplemente lo agregamos al final
        state.paymentMethods.push(action.payload.data);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethods.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.paymentMethods = action.payload;
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  clearPaymentMethods,
  setSelectedPaymentMethod,
  setPaymentMethod,
  addPaymentMethods,
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer;
