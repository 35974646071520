import React from "react";
import { Box, Table, TableBody, Typography, IconButton } from "@mui/material";
import {
  CustomTableContainer,
  CustomTableRow,
  CustomTableCell,
  CustomButtonContainer,
  CustomButton,
} from "../../../assets/styles/tablesMobile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const ProfilesTableMobile = (props) => {
  const { profiles, handleEdit, handleDelete, handleAccessEdit } = props;

  return (
    <CustomTableContainer>
      <Table>
        <TableBody>
          {profiles.map((profile) => (
            <CustomTableRow key={profile.id}>
              <CustomTableCell sx={{ border: "transparent" }}>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {profile.name}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Nombre
                  </Typography>
                </CustomTableCell>
                <CustomTableCell sx={{ borderBottom: "transparent" }}>
                  <Typography variant="subtitle1" align="center">
                    {profile.description}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Descripción
                  </Typography>
                </CustomTableCell>
              </CustomTableCell>
              <CustomButtonContainer>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CustomButton
                    variant="contained"
                    onClick={() => handleEdit(profile)}
                    sx={{ marginBottom: "10px" }}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    color="tertiary"
                    onClick={() => handleDelete(profile.id)}
                  >
                    <IconButton size="small">
                      <DeleteIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </CustomButton>
                </Box>
              </CustomButtonContainer>
              <CustomButtonContainer>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="caption" align="center">
                  </Typography>
                  <CustomButton
                    variant="contained"
                    onClick={() => handleAccessEdit(profile)}
                    sx={{
                      marginBottom: "10px",
                      backgroundColor: "white",
                      border: "1px solid",
                      borderColor: "red",
                    }}
                  >
                    <IconButton size="small">
                      <VpnKeyIcon sx={{ color: "red" }} />
                    </IconButton>
                  </CustomButton>
                </Box>
              </CustomButtonContainer>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default ProfilesTableMobile;
