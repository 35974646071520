import React from "react";

import {
  AddButton,
  NameViewBox,
  HeaderBox
} from "../../assets/styles/tables";

import PersonAddIcon from "@mui/icons-material/PersonAdd";

import SearchBar from "../../common/SearchBar";


const TableHeader = (props) => {
  const { handleClickOpen, handleSearch, name } = props;
  return (
    <HeaderBox>
      <AddButton variant="contained" onClick={handleClickOpen} >
        <PersonAddIcon />
      </AddButton>
      <SearchBar onSearch={handleSearch} />
      <NameViewBox>{name}</NameViewBox>
    </HeaderBox>
  )
}

export default TableHeader;