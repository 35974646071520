import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  IconButton,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Collapse,
  Drawer,
} from "@mui/material";
import { ExpandLess, ExpandMore, Menu } from "@mui/icons-material";
import menuItems from "../data/menuItems";

const MobileMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [menuStates, setMenuStates] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleItemClick = (path) => () => {
    navigate(path);
    setDrawerOpen(!drawerOpen);
    setIsMenuOpen(!isMenuOpen);
    setMenuStates({});
  };

  const handleSubmenuClick = (index) => () => {
    setMenuStates({
      ...menuStates,
      [index]: !menuStates[index],
    });
  };

  const handleDrawerOpen = () => {
    setIsMenuOpen(true);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsMenuOpen(false);
    setDrawerOpen(false);
    setMenuStates({});
  };

  return (
    <Fragment>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <IconButton edge="start" color="inherit" onClick={handleDrawerOpen}>
            <Menu />
          </IconButton>
          <div />
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            width: "100%",
            mt: 7,
            marginBottom: 10,
            backgroundColor: theme.palette.background.menus,
            color: theme.palette.primary.main,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
          },
        }}
      >
        <List>
          {menuItems.map((item, index) => (
            <Fragment key={item.label}>
              <ListItem
                button
                onClick={
                  item.children
                    ? handleSubmenuClick(index)
                    : handleItemClick(item.path)
                }
              >
                <ListItemIcon sx={{ color: theme.palette.primary.main }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
                {item.children ? (
                  menuStates[index] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItem>
              {item.children ? (
                <Collapse in={menuStates[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((child, subIndex) => (
                      <Fragment key={child.label}>
                        <ListItem
                          button
                          sx={{ pl: 4 }}
                          onClick={
                            child.children
                              ? handleSubmenuClick(`${index}-${subIndex}`)
                              : handleItemClick(child.path)
                          }
                        >
                          <ListItemText primary={child.label} />
                          {child.children ? (
                            menuStates[`${index}-${subIndex}`] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : null}
                        </ListItem>
                        {child.children ? (
                          <Collapse
                            in={menuStates[`${index}-${subIndex}`]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {child.children.map((grandchild, subsubIndex) => (
                                <ListItem
                                  key={grandchild.label}
                                  button
                                  sx={{ pl: 8 }}
                                  onClick={handleItemClick(grandchild.path)}
                                >
                                  <ListItemText primary={grandchild.label} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        ) : null}
                      </Fragment>
                    ))}
                  </List>
                </Collapse>
              ) : null}
            </Fragment>
          ))}
        </List>
      </Drawer>
    </Fragment>
  );
};

export default MobileMenu;