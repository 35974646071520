import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getCoupons = createAsyncThunk(
  "coupons",
  async (filters, { getState }) => {
    // Obten los filtros del estado de Redux (opcional, si necesitas enviar filtros preestablecidos desde el componente)
    const currentFilters = getState().coupons.filters;

    // Combina los filtros actuales con los nuevos filtros proporcionados
    const mergedFilters = { ...currentFilters, ...filters };

    if (mergedFilters.quarter === "") {
      delete mergedFilters.quarter;
    }
    if (mergedFilters.group === "") {
      delete mergedFilters.group;
    }
    // Realiza la solicitud al backend con los filtros combinados
    const response = await apiClient.get("coupon_filter", {
      params: mergedFilters,
    });

    return response.data;
  }
);

export const getCouponsDeferred = createAsyncThunk(
  "coupons_deferred",
  async (filters, { getState }) => {
    // Obten los filtros del estado de Redux (opcional, si necesitas enviar filtros preestablecidos desde el componente)
    const currentFilters = getState().coupons.filters;

    // Combina los filtros actuales con los nuevos filtros proporcionados
    const mergedFilters = { ...currentFilters, ...filters };

    if (mergedFilters.quarter === "") {
      delete mergedFilters.quarter;
    }
    if (mergedFilters.group === "") {
      delete mergedFilters.group;
    }
    // Realiza la solicitud al backend con los filtros combinados
    const response = await apiClient.get("coupon_deferred_filter", {
      params: mergedFilters,
    });

    return response.data;
  }
);

export const getCouponById = createAsyncThunk("coupon/id", async (id) => {
  const response = await apiClient.get(`coupon/${id}`);
  console.log(response, "getCouponById");
  return response.data;
});

export const deleteCouponById = createAsyncThunk(
  "coupon/delete",
  async (id) => {
    const response = await apiClient.delete(`coupon/${id}`);
    return response.data;
  }
);

export const addCouponsByContract = createAsyncThunk(
  "coupon/contractNumber",
  async (coupon, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `coupon/${coupon.numberContract}`,
        coupon
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        message: error.response.data,
      });
    }
  }
);

export const addCoupons = createAsyncThunk(
  "coupons/add",
  async (coupon, thunkAPI) => {
    try {
      const response = await apiClient.post("coupons", coupon);
      return { data: response.data, status: response.status };
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        message: error.response.data,
      });
    }
  }
);

export const getCheckCuota = createAsyncThunk("checkCuota", async (coupon) => {
  const response = await apiClient.post(`check_cuota`, coupon);
  return response.data;
});

export const updateCouponById = createAsyncThunk(
  "coupon/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`coupon/${id}`, data);
    return response.data;
  }
);

export const getPdfByCoupon = createAsyncThunk(
  "print_coupon/id",
  async (id) => {
    const response = await apiClient.get(`print_coupon/${id}`);
    return response.data;
  }
);

export const getGenerationControl = createAsyncThunk(
  "coupons/generationControl",
  async (filters) => {
    // Combina los filtros con los valores por defecto si es necesario
    const defaultFilters = {
      branch: '',
      plan: '',
      group: '',
      payment_method: '',
      year: null,
      quarter: null,
      contract_number: ''
    };

    const mergedFilters = { ...defaultFilters, ...filters };

    // Realiza la solicitud al backend con los filtros combinados
    const response = await apiClient.get("generation_control", {
      params: mergedFilters,
    });

    return response.data;
  }
);