import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getBranches = createAsyncThunk("branches", async () => {
  const response = await apiClient.get("branches");
  return response.data;
});

export const getBranchById = createAsyncThunk("branch/id", async ( id ) => {
  const response = await apiClient.get(`branch/${id}`);
  return response.data;
});

export const deleteBranchById = createAsyncThunk("branch/delete", async ( id ) => {
  const response = await apiClient.delete(`branch/${id}`);
  return response.data;
});

export const addBranch = createAsyncThunk("branches/add", async (branch) => {
  const response = await apiClient.post("branches", branch);
  return response.data;
});