import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

import ABMSubscriberDialogMobile from "../components/mobile/dialog/ABMSubscriberDialogMobile";
import SubscribersTableMobile from "../components/mobile/table/SubscribersTableMobile";
import TableHeaderMobile from "../components/mobile/tableHeader/TableHeaderMobile";
import TableHeader from "../components/table/TableHeader";
import SubscribersTable from "../components/table/SubscribersTable";
import SubscriberDialog from "../components/dialog/SubscriberDialog";
import { useDispatch } from "react-redux";
import Loading from "../common/Loading";

import {  getSubscribers, 
          getSubscriberById, 
          deleteSubscriberById, 
          addSubscriber, 
          updateSubscriber 
        } from "../api/endpoints/subscribers";

import {  getNationalities } from "../api/endpoints/nationalities";
import {  getMaritalStatuses } from "../api/endpoints/maritalStatuses";
import {  getIVAConditions } from "../api/endpoints/iva_conditions";
import {  getCountries } from "../api/endpoints/countries";
import {  getProvinces } from "../api/endpoints/provinces";
import {  getDepartments } from "../api/endpoints/departments";
import {  getDistricts } from "../api/endpoints/districts";

import { addNationality } from "../features/nationalities/nationalitySlice"
import { addMaritalStatuses } from "../features/maritalStatuses/maritalStatusSlice"
import { addIVAConditions } from "../features/iva_conditions/ivaConditionSlice"
import { addCountries } from "../features/countries/countrySlice"
import { addProvinces } from "../features/provinces/provinceSlice"
import { addDepartments } from "../features/departments/departmentSlice"
import { addDistricts } from "../features/districts/districtSlice"

const ciudadesArray = [
  {
    id: "1",
    name: "Rivadavia",
  },
];
const ABMSubscriberForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [save, setSave] = useState(true);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [subscribers, setSubscribers] = useState([]);
  const [filteredSubscribers, setFilteredSubscribers] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedSubscriber, setSelectedSubscriber] = useState({
    id: null,
    user_name: "",
    name: "",
    last_name: "",
    dni: "",
    nationality: "",
    civil_status: "",
    cuit: "",
    birth_date: "",
    sexo: "",
    adress: "",
    country_code: "",
    province: "",
    department: "",
    district: "",
    gps_cords: "",
    email: "",
    telephone: "",
    postal_code: "",
    id_iva: "",
    observations: "",
    additional_interview: "",
    user_interview: "",
  });

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  useEffect(() => {
    const fetchSubscribers = async () => {
      setIsLoading(true);
      try {
        const subscribersData = await dispatch(getSubscribers()).unwrap();

        const nationalitiesData = await dispatch(getNationalities()).unwrap();
        const maritalStatusesData = await dispatch(getMaritalStatuses()).unwrap();
        const ivaConditionsData = await dispatch(getIVAConditions()).unwrap();
        const countriesData = await dispatch(getCountries()).unwrap();
        const provincesData = await dispatch(getProvinces()).unwrap();
        const departmentsData = await dispatch(getDepartments()).unwrap();
        const districtsData = await dispatch(getDistricts()).unwrap();
        
        await dispatch(addNationality(nationalitiesData));
        await dispatch(addMaritalStatuses(maritalStatusesData));
        await dispatch(addIVAConditions(ivaConditionsData));
        await dispatch(addCountries(countriesData));
        await dispatch(addProvinces(provincesData));
        await dispatch(addDepartments(departmentsData));
        await dispatch(addDistricts(districtsData));

        setSubscribers(subscribersData);
        setFilteredSubscribers(subscribersData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchSubscribers();
  }, [save]);

  const handleSearch = async(searchText) => {

    // const subscribersData = await dispatch(getSubscribers()).unwrap();

    if (searchText === "") {
      setFilteredSubscribers(subscribers);
    } else {
      const filteredSubscribers = subscribers.filter((subscriber) =>
        subscriber.first_name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredSubscribers(filteredSubscribers);
    }
    setPage(1);
  };

  const getCurrentPageItems = () => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredSubscribers.slice(startIndex, endIndex);
  };

  const handleClickOpen = () => {
    setSelectedSubscriber({
      id: null,
      name: "",
      phone: "",
      email: "",
      role: "",
    });
    setOpen(true);
    setEditMode(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedSubscriber({ ...selectedSubscriber, [name]: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedSubscriber({ ...selectedSubscriber, [name]: value });
  };

  const handleSave = async () => {
    if (editMode) {
      
      await dispatch(updateSubscriber(selectedSubscriber));
      setEditMode(false);
      setSave(!save)
    } else {

      await dispatch(addSubscriber(selectedSubscriber));
      setEditMode(false);
      setSave(!save)
    }
    handleClose();
  };

  const handleEdit = (subscriber) => {
    setSelectedSubscriber(subscriber);
    setEditMode(true);
    setOpen(true);
  };

  const handleDelete = async(uuid) => {

    dispatch(deleteSubscriberById(uuid));

    setSave(!save)

  };

  const props = {
    subscribers: filteredSubscribers,
    handleEdit,
    handleDelete,
    ciudadesArray,
    getCurrentPageItems,
    ITEMS_PER_PAGE,
    page,
    setPage
  };

  const name = 'ADMINISTRACIÓN DE SUSCRIPTORES'
  const tableHeaderProps = {
    handleClickOpen,
    handleSearch,
    name
  }

  const dialogProps = {
    open,
    handleClose,
    editMode,
    selectedSubscriber,
    handleInputChange,
    handleSelectChange,
    handleSave,
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isMobile ? (<TableHeaderMobile {...tableHeaderProps} />)
        : (<TableHeader {...tableHeaderProps} />)}

      {isMobile ? (<SubscribersTableMobile {...props} />)
        : (<SubscribersTable {...props} />)}

      {isMobile ? (<ABMSubscriberDialogMobile {...dialogProps} />)
        : (<SubscriberDialog {...dialogProps} />)}
    </>
  );
};

export default ABMSubscriberForm;
