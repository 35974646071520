import React from "react";
import UpdateDebtForm from "../common/UpdateDebtForm";
import { Box } from "@mui/material";

const UpdateDebt = () => {
  return (
    <Box mt={12}>
      <UpdateDebtForm/>
    </Box>
  );
};

export default UpdateDebt;
