import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";

import ProfilesTableMobile from "../components/mobile/table/ProfilesTableMobile";
import ABMProfileDialogMobile from "../components/mobile/dialog/ABMProfileDialogMobile";

import Loading from "./Loading";

import TableHeader from "../components/table/TableHeader";
import TableHeaderMobile from "../components/mobile/tableHeader/TableHeaderMobile";
import ProfilesTable from "../components/table/ProfilesTable";
import ProfileDialog from "../components/dialog/ProfileDialog";
import AccessDialog from "../components/dialog/AccessDialog";
import { useDispatch } from "react-redux";
import { addProfile, addRoleModule, deleteProfileById, deleteRoleModuleById, getProfileById, getProfiles, updateProfile } from "../api/endpoints/profiles";

const originalProfiles = [
  {
    id: 1,
    name: "Administrador",
    description: "Administrador total del sistema",
  },
  { id: 2, name: "Area Compras", description: "" },
  { id: 3, name: "Area Depósito", description: "" },
  { id: 4, name: "Area Ingeniería", description: "" },
  { id: 5, name: "Prueba", description: "Usuario de prueba" },
  { id: 6, name: "Vendedor", description: "" },
];

const accessData = [
  {
    id: 1, 
    idProfile: 1, 
    logSucCent: true,
    abmUsers: true,
    admin: true,
    access: true,
    empr: true,
    multiSuc: true,
    stopSession: true,
    contrato: true,
    cupon: true,
    stats: true,
    factura: true,
    personas: true,
    plan: true,
    suscriptor: true,
  },
  {
    id: 1, idProfile: 2, logSucCent: false,
    abmUsers: false,
    admin: false,
    access: false,
    empr: false,
    multiSuc: false,
    stopSession: false,

    contrato: true,

    cupon: true,

    stats: true,

    factura: true,

    personas: true,

    plan: true,

    suscriptor: true,
  }
]
const ABMProfileForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [access, setAccess] = useState(accessData);
  const [open, setOpen] = useState(false);
  const [openAccess, setOpenAccess] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({ id: null, name: '', email: '', role: '' });
  const [page, setPage] = useState(1);
  const [save, setSave] = useState(true);
  const ITEMS_PER_PAGE = 5;
  const [selectedAccess, setSelectedAccess] = useState(
    {
      id: 1, 
      idProfile: 5,
      logSucCent: false,
      abmUsers: false,
      admin: false,
      access: false,
      empr: false,
      multiSuc: false,
      stopSession: false,

      contrato: false,

      cupon: false,

      stats: false,

      factura: false,

      personas: false,

      plan: false,

      suscriptor: false,
    });

  useEffect(() => {
    const fetchProfiles = async () => {
      setIsLoading(true);
      try {
        const profilesData = await dispatch(getProfiles()).unwrap();
        
        setProfiles(profilesData);
        setFilteredProfiles(profilesData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchProfiles();
    
    
  }, [save]);

  const handleSearch = async (searchText) => {

    const profilesData = await dispatch(getProfiles()).unwrap();

    if (searchText === "") {
      setFilteredProfiles(profilesData);
    } else {
      const filteredProfiles = profilesData.filter((profile) =>
        profile.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredProfiles(filteredProfiles);
    }
    setPage(1);
  };

  const getCurrentPageItems = () => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredProfiles.slice(startIndex, endIndex);
  };

  const handleClickOpen = () => {
    setSelectedProfile({ id: null, name: "", description: "" });
    setEditMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAccess(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedProfile({ ...selectedProfile, [name]: value });
  };

  const handleCheckBoxChange = async(uuid, event) => {
    const { name, checked } = event.target;
    const variables = {uuid,name}
  
    if (checked) {
      await dispatch(addRoleModule(variables))
      const selectedProfile = await dispatch(getProfileById(uuid)).unwrap()
      setSelectedProfile(selectedProfile);
    } else {
      await dispatch(deleteRoleModuleById(variables))
      const selectedProfile = await dispatch(getProfileById(uuid)).unwrap()
      setSelectedProfile(selectedProfile);
    }
  };

  const handleSave = async () => {
    if (editMode) {
      
      await dispatch(updateProfile(selectedProfile));
      setEditMode(false);
      setSave(!save)
    } else {

      await dispatch(addProfile(selectedProfile));
      setEditMode(false);
      setSave(!save)
    }
    handleClose();
  };

  const handleSaveAccess = async(uuid) => {
    const selectedProfile = await dispatch(getProfileById(uuid)).unwrap()
    setSelectedProfile(selectedProfile);
    handleClose();
  };

  const handleEdit = (profile) => {
    setSelectedProfile(profile);
    setEditMode(true);
    setOpen(true);
  };

  const handleAccessEdit = async (profile) => {
    const selectedProfile = await dispatch(getProfileById(profile.uuid)).unwrap()
    setSelectedProfile(selectedProfile);
    setOpenAccess(true);
  };

  const handleDelete = async(uuid) => {

    dispatch(deleteProfileById(uuid));

    const profilesData = await dispatch(getProfiles()).unwrap();
    
    setFilteredProfiles(profilesData);
    setSave(!save)

  };

  const props = {
    profiles: filteredProfiles,
    handleEdit: handleEdit,
    handleDelete: handleDelete,
    handleAccessEdit: handleAccessEdit,
    getCurrentPageItems,
    ITEMS_PER_PAGE,
    page,
    setPage
  };

  const name = 'ADMINISTRACIÓN DE PERFILES';
  const tableHeaderProps = {
    handleClickOpen,
    handleSearch,
    name
  }

  const dialogProfileProps = {
    open,
    handleClose,
    editMode,
    selectedProfile,
    handleInputChange,
    handleSave,
  }

  const dialogAccessProps = {
    openAccess,
    handleClose,
    selectedProfile,
    selectedAccess,
    handleCheckBoxChange,
    handleSaveAccess,
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isMobile ? (<TableHeaderMobile {...tableHeaderProps} />)
        : (<TableHeader {...tableHeaderProps} />)}

      {isMobile ? (<ProfilesTableMobile {...props} />)
        : (<ProfilesTable {...props} />)}

      <ProfileDialog {...dialogProfileProps} />

      {isMobile ? (<ABMProfileDialogMobile {...dialogAccessProps} />)
        : (<AccessDialog {...dialogAccessProps} />)}
    </>
  );
};

export default ABMProfileForm;