import { createSlice } from '@reduxjs/toolkit';

export const DepartmentSlice = createSlice({
  name: 'Departments',
  initialState: [], // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addDepartments: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeDepartment: (state, action) => {
      const index = state.findIndex((department) => department.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { addDepartments, removeDepartment } = DepartmentSlice.actions;

export default DepartmentSlice.reducer;
