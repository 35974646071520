import { createSlice } from "@reduxjs/toolkit";
import { getReleaseReasons } from "../../api/endpoints/release_reasons";

export const ReleaseReasonSlice = createSlice({
  name: "ReleaseReasons",
  initialState: [], // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addReleaseReasons: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeReleaseReason: (state, action) => {
      const index = state.findIndex(
        (release_reason) => release_reason.uuid === action.payload
      );
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReleaseReasons.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { addReleaseReasons, removeReleaseReason } =
  ReleaseReasonSlice.actions;

export default ReleaseReasonSlice.reducer;
