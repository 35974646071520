import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
} from "../../assets/styles/tables";
import Actions from "./Actions";

const AdditionalDialog = (props) => {
  const {
    openAdditional,
    handleCloseAdditional,
    editModeAdditional,
    selectedAdditional,
    handleInputChangeAdditional,
    handleSelectChangeAdditional,
    handleSaveAdditional,
  } = props;

  const [nameError, setNameError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [prorateError, setProrateError] = useState(false);
  const [mandatoryError, setMandatoryError] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (openAdditional) {
      setNameError(false);
      setAmountError(false);
      setProrateError(false);
      setMandatoryError(false);
      setSaveAttempted(false);
    }
  }, [openAdditional]);

  const handleSave = () => {
    setSaveAttempted(true);

    if (!selectedAdditional.name) {
      setNameError(true);
      return;
    }

    if (!selectedAdditional.amount) {
      setAmountError(true);
      return;
    }

    if (selectedAdditional.prorate === undefined) {
      setProrateError(true);
      return;
    }

    if (selectedAdditional.mandatory === undefined) {
      setMandatoryError(true);
      return;
    }

    handleSaveAdditional();
  };

  const actionProps = {
    handleClose: handleCloseAdditional,
    handleSave: handleSave,
  };

  return (
    <Dialog
      open={openAdditional}
      onClose={handleCloseAdditional}
      PaperProps={{
        style: { minWidth: "600px", maxWidth: "600px" },
      }}
    >
      <FormDialogTitle>
        {editModeAdditional ? "Editar Adicional" : "Agregar Adicional"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <Box>
            <FormDialogSubtitle>
              {" "}
              {selectedAdditional.name === undefined
                ? ""
                : `Datos del adicional "${selectedAdditional.name}"`}
            </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <TextField
                margin="dense"
                name="name"
                label="Nombre"
                type="text"
                fullWidth
                value={selectedAdditional.name}
                onChange={handleInputChangeAdditional}
                error={nameError || (saveAttempted && !selectedAdditional.name)}
              />
              {nameError || (saveAttempted && !selectedAdditional.name) ? (
                <FormHelperText>Debes ingresar un nombre.</FormHelperText>
              ) : null}
              <TextField
                margin="dense"
                name="amount"
                label="Monto ($)"
                type="number"
                fullWidth
                value={selectedAdditional.amount}
                onChange={handleInputChangeAdditional}
                error={
                  amountError || (saveAttempted && !selectedAdditional.amount)
                }
              />
              {amountError || (saveAttempted && !selectedAdditional.amount) ? (
                <FormHelperText>Debes ingresar un monto.</FormHelperText>
              ) : null}
              <SelectFormControl>
                <InputLabel margin="dense" id="prorate-label">
                  Se prorratea?
                </InputLabel>
                <Select
                  labelId="prorate-label"
                  name="prorate"
                  value={selectedAdditional.prorate}
                  onChange={handleSelectChangeAdditional}
                  error={
                    prorateError ||
                    (saveAttempted && selectedAdditional.prorate === undefined)
                  }
                >
                  <MenuItem value="1">Si</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
                {prorateError ||
                (saveAttempted && selectedAdditional.prorate === undefined) ? (
                  <FormHelperText>
                    Debes seleccionar si se prorratea.
                  </FormHelperText>
                ) : null}
              </SelectFormControl>
              <SelectFormControl>
                <InputLabel margin="dense" id="mandatory-label">
                  Es obligatorio?
                </InputLabel>
                <Select
                  labelId="mandatory-label"
                  name="mandatory"
                  value={selectedAdditional.mandatory}
                  onChange={handleSelectChangeAdditional}
                  error={
                    mandatoryError ||
                    (saveAttempted &&
                      selectedAdditional.mandatory === undefined)
                  }
                >
                  <MenuItem value="1">Si</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
                {mandatoryError ||
                (saveAttempted &&
                  selectedAdditional.mandatory === undefined) ? (
                  <FormHelperText>
                    Debes seleccionar si es obligatorio.
                  </FormHelperText>
                ) : null}
              </SelectFormControl>
            </FormBoxContainer>
          </Box>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default AdditionalDialog;
