import {
  Dialog,
  DialogContent,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SelectFormControl,
  SeparatorHrBox,
} from "../../assets/styles/tables";
import Actions from "./Actions";
import { useSelector, useDispatch } from "react-redux";
import {
  getPeiValues,
  deletePeiValueById,
  addPeiValue,
  updatePeiValueById,
} from "../../api/endpoints/peiValues";

const PeiValueDialogEdit = (props) => {
  const dispatch = useDispatch();
  const { peiValueSelected: initialPeiValueSelected } = useSelector(
    (state) => state.peiValue
  );
  const [peiValueSelected, setPeiValueSelected] = useState(
    initialPeiValueSelected || {}
  );
  const { fees } = useSelector((state) => state.fees);
  const { legalEntity } = useSelector((state) => state.legalEntity);
  const {
    openPeiValueEdit,
    setOpenPeiValueEdit,
    peiValues,
    setPeiValues,
    setEditModePeiValueEdit,
    isEditPeiValue,
    selectedPlan,
  } = props;

  const [errors, setErrors] = useState({
    uuid_fee: false,
    amount: false,
    rate: false,
  });

  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    setPeiValueSelected(initialPeiValueSelected || {});
  }, [initialPeiValueSelected]);

  const handleInputChangePeiValue = (event) => {
    const { name, value } = event.target;
    setPeiValueSelected({ ...peiValueSelected, [name]: value });
    setErrors({ ...errors, [name]: !value });
  };

  const handleSelectChangePeiValue = (event) => {
    const { name, value } = event.target;
    setPeiValueSelected({ ...peiValueSelected, [name]: value });
    setErrors({ ...errors, [name]: !value });
  };

  const handleClosePeiValueEdit = () => {
    setErrors({
      uuid_fee: false,
      amount: false,
      rate: false,
    });
    setPeiValueSelected({}); // Limpia el estado peiValueSelected
    setOpenPeiValueEdit(false);
    setEditModePeiValueEdit(false);
  };

  const handleSavePeiValue = async () => {
    setSaveAttempted(true);
    const newErrors = {
      uuid_fee: !peiValueSelected.uuid_fee,
      amount: !peiValueSelected.amount,
      rate: !peiValueSelected.rate,
    };
    setErrors(newErrors);
    if (Object.values(newErrors).includes(true)) {
      return;
    }
    try {
      if (isEditPeiValue) {
        const updatedPeiValues = peiValues.map((value) =>
          value.id === peiValueSelected.id ? peiValueSelected : value
        );
        const updatePeiValue = {
          id: peiValueSelected.uuid,
          data: {
            amount: peiValueSelected.amount,
            rate: peiValueSelected.rate,
          },
        };
        await dispatch(updatePeiValueById(updatePeiValue)).unwrap();
        setPeiValues(updatedPeiValues);
      } else {
        const newPeiValue = {
          uuid_plan: selectedPlan.uuid,
          uuid_legal_entity: legalEntity.uuid,
          uuid_fee: peiValueSelected.uuid_fee,
          amount: peiValueSelected.amount,
          rate: peiValueSelected.rate,
        };
        const addedPlan = await dispatch(addPeiValue(newPeiValue)).unwrap();

        const fee = fees.filter((fee) => fee.uuid === newPeiValue.uuid_fee);
        const peiValueWithFee = {
          ...newPeiValue,
          fee: fee[0],
        };
        setPeiValues([...peiValues, peiValueWithFee]);
        setPeiValueSelected({}); // Limpia el estado peiValueSelected después de crear un nuevo elemento
      }
    } catch (error) {
      console.error(error);
    }
    setOpenPeiValueEdit(false);
    setEditModePeiValueEdit(false);
  };

  const actionProps = {
    handleClose: handleClosePeiValueEdit,
    handleSave: handleSavePeiValue,
    saveAttempted: saveAttempted,
  };

  return (
    <Dialog
      open={openPeiValueEdit}
      onClose={handleClosePeiValueEdit}
      PaperProps={{
        style: { minWidth: "600px", maxWidth: "600px" },
      }}
    >
      <FormDialogTitle>
        {isEditPeiValue ? "Editar valores PEI" : "Nuevo valor"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <FormBoxContainer sx={{ display: "block" }}>
            {isEditPeiValue ? null : (
              <SelectFormControl error={saveAttempted && errors.uuid_fee}>
                <InputLabel margin="dense" id="id_fee-label">
                  Tipo de cuota
                </InputLabel>
                <Select
                  value={peiValueSelected.uuid_fee}
                  onChange={handleSelectChangePeiValue}
                  labelId="uuid_fee"
                  name="uuid_fee"
                >
                  {fees.map((item, key) => (
                    <MenuItem key={key} value={item.uuid}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {saveAttempted && errors.uuid_fee && (
                  <FormHelperText>Debe seleccionar una cuota</FormHelperText>
                )}
              </SelectFormControl>
            )}
            <TextField
              error={saveAttempted && errors.amount}
              margin="dense"
              name="amount"
              label="Monto"
              type="number"
              fullWidth
              onChange={handleInputChangePeiValue}
              value={isEditPeiValue ? peiValueSelected.amount : null}
              helperText={
                saveAttempted && errors.amount && "El monto es obligatorio"
              }
            />
            <TextField
              error={saveAttempted && errors.rate}
              margin="dense"
              name="rate"
              label="Porcentaje"
              type="number"
              fullWidth
              onChange={handleInputChangePeiValue}
              value={isEditPeiValue ? peiValueSelected.rate : null}
              helperText={
                saveAttempted && errors.rate && "El porcentaje es obligatorio"
              }
            />
          </FormBoxContainer>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default PeiValueDialogEdit;
