import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getModules = createAsyncThunk("modules", async () => {
  const response = await apiClient.get("modules");
  return response.data;
});

export const getModuleById = createAsyncThunk("module/id", async ( id ) => {
  const response = await apiClient.get(`module/${id}`);
  return response.data;
});

export const deleteModuleById = createAsyncThunk("module/delete", async ( id ) => {
  const response = await apiClient.delete(`module/${id}`);
  return response.data;
});

export const addModule = createAsyncThunk("modules/add", async (module) => {
  const response = await apiClient.post("modules", module);
  return response.data;
});