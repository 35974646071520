import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getCouponsForMails = createAsyncThunk(
  "coupons_mails",
  async (filters, { getState }) => {
    // Obten los filtros del estado de Redux (opcional, si necesitas enviar filtros preestablecidos desde el componente)
    const currentFilters = getState().coupons.filters;

    // Combina los filtros actuales con los nuevos filtros proporcionados
    const mergedFilters = { ...currentFilters, ...filters };

    if (mergedFilters.quarter === "") {
      delete mergedFilters.quarter;
    }
    if (mergedFilters.month === "") {
      delete mergedFilters.month;
    }
    if (mergedFilters.year === "") {
      delete mergedFilters.year;
    }
    if (mergedFilters.group === "") {
      delete mergedFilters.group;
    }
    // Realiza la solicitud al backend con los filtros combinados
    const response = await apiClient.get("mails", {
      params: mergedFilters,
    });

    return response.data;
  }
);

export const getAllSubscribersForMails = createAsyncThunk(
  "all_subscribers",
  async (filters, { getState }) => {
    // Obten los filtros del estado de Redux (opcional, si necesitas enviar filtros preestablecidos desde el componente)
    const currentFilters = getState().coupons.filters;

    // Combina los filtros actuales con los nuevos filtros proporcionados
    const mergedFilters = { ...currentFilters, ...filters };

    if (mergedFilters.quarter === "") {
      delete mergedFilters.quarter;
    }
    if (mergedFilters.month === "") {
      delete mergedFilters.month;
    }
    if (mergedFilters.year === "") {
      delete mergedFilters.year;
    }
    if (mergedFilters.group === "") {
      delete mergedFilters.group;
    }
    // Realiza la solicitud al backend con los filtros combinados
    const response = await apiClient.get("mails_all_subscribers", {
      params: mergedFilters,
    });

    return response.data;
  }
);

export const sendMails = createAsyncThunk("mails", async (emails) => {
  const response = await apiClient.post("mails", emails);
  return response.data;
});
