import { createSlice } from '@reduxjs/toolkit';

export const peiValueSlice = createSlice({
  name: 'peiValue',
  initialState: {
    peiValueSelected: null
  },
  reducers: {
    addPeiValue: (state, action) => {
      state.peiValueSelected = action.payload;
    },
    clearPeiValue: (state, action) => {
      state.peiValueSelected = null;
    },
  },
});

export const { addPeiValue, clearPeiValue } = peiValueSlice.actions;

export default peiValueSlice.reducer;