import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore, createTransform } from "redux-persist";
import { combineReducers } from "redux";
import { createLogger } from "redux-logger";

import userLoggedReducer from "../features/auth/userLoggedSlice";
import authReducer from "../features/auth/authSlice";
import tableReducer from "../features/tables/tableSlice";
import branchReducer from "../features/branchs/branchSlice";
import userReducer from "../features/users/usersSlice";
import nationalityReducer from "../features/nationalities/nationalitySlice";
import maritalStatusReducer from "../features/maritalStatuses/maritalStatusSlice";
import ivaConditionReducer from "../features/iva_conditions/ivaConditionSlice";
import countryReducer from "../features/countries/countrySlice";
import provinceReducer from "../features/provinces/provinceSlice";
import departmentReducer from "../features/departments/departmentSlice";
import districtReducer from "../features/districts/districtSlice";
import legalEntityReducer from "../features/legalEntities/legalEntitySlice";
import peiValueReducer from "../features/peiValues/peiValueSlice";
import feeTypeReducer from "../features/feeTypes/feeTypeSlice";
import planReducer from "../features/plans/planSlice";
import feeReducer from "../features/fees/feeSlice";
import feeStructureReducer from "../features/feeStructures/feeStructureSlice";
import paymentMethodReducer from "../features/paymentMethods/paymentMethodSlice";
import groupReducer from "../features/groups/groupSlice";
import contractReducer from "../features/contracts/contractSlice";
import constructionTypeReducer from "../features/constructionTypes/constructionTypeSlice";
import preAdjudicationReasonReducer from "../features/preAdjudicationReasons/preAdjudicationReasonSlice";
import additionalReducer from "../features/additionals/additionalSlice";
import releaseReasonReducer from "../features/release_reasons/releaseReasonSlice";
import couponReducer from "../features/coupons/couponSlice";
import subscriberReducer from "../features/subscribers/subscriberSlice.js";
import registerPaymentReducer from "../features/registerPayments/registerPaymentSlice.js";
import updatedDebtReducer from "../features/updatedDebts/updatedDebtSlice.js";
import afipSliceReducer from "../features/auth/afipSlice.js";
import salePointSliceReducer from "../features/salePoints/salePointSlice.js";

const arrayTransform = createTransform(
  (inboundState) => {
    // Transformar el estado antes de persistirlo
    return Array.isArray(inboundState) ? [...inboundState] : inboundState;
  },
  (outboundState) => {
    // Transformar el estado después de rehidratarlo
    if (
      outboundState &&
      typeof outboundState === "object" &&
      !Array.isArray(outboundState)
    ) {
      // Verificar si el objeto tiene claves numéricas
      const keys = Object.keys(outboundState).filter(
        (key) => key !== "_persist"
      );
      const isNumericArray = keys.every((key) => !isNaN(parseInt(key, 10)));

      if (isNumericArray) {
        return keys.map((key) => outboundState[key]);
      }
    }
    return outboundState;
  },
  { whitelist: ["salePoints"] }
);

// Definición del transformer
const afipTransform = createTransform(
  (inboundState) => {
    const currentTime = new Date();
    const expirationTime = new Date(inboundState.expirationTime);
    const genTime = new Date(inboundState.genTime);

    // Solo persistir si el token es válido y no ha expirado
    if (
      inboundState.tokenAfip &&
      expirationTime > currentTime &&
      currentTime - genTime < 5 * 60 * 1000
    ) {
      return inboundState;
    }

    return {};
  },
  null,
  { whitelist: ["afip"] }
);

// Configuración de persistencia para el slice afip
const afipPersistConfig = {
  key: "afip",
  storage,
  transforms: [afipTransform],
};

const authTransform = createTransform(
  (inboundState) => {
    const currentTime = new Date();
    const expirationTime = new Date(inboundState.expirationTime);
    // Solo persistir si el token es válido y no ha expirado
    if (inboundState.token && expirationTime > currentTime) {
      return inboundState;
    }
    return {};
  },
  null,
  { whitelist: ["auth"] }
);

const authPersistConfig = {
  key: "auth",
  storage,
  transforms: [authTransform],
};

const userLoggedPersistConfig = {
  key: "userLogged",
  storage: storage,
};

const plansPersistConfig = {
  key: "plans",
  storage: storage,
};

const branchesPersistConfig = {
  key: "branch",
  storage: storage,
};

const groupsPersistConfig = {
  key: "groups",
  storage: storage,
};

const paymentMethodsPersistConfig = {
  key: "paymentMethods",
  storage,
};

const salePointsPersistConfig = {
  key: "salePoints",
  storage,
};

const logger = createLogger({
  collapsed: true, // Collapse state differences
  duration: true, // Display action duration
});

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(logger),
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    userLogged: persistReducer(userLoggedPersistConfig, userLoggedReducer),
    tables: tableReducer,
    branch: persistReducer(branchesPersistConfig, branchReducer),
    users: userReducer,
    nationalities: nationalityReducer,
    maritalStatuses: maritalStatusReducer,
    ivaConditions: ivaConditionReducer,
    countries: countryReducer,
    provinces: provinceReducer,
    departments: departmentReducer,
    districts: districtReducer,
    legalEntity: legalEntityReducer,
    peiValue: peiValueReducer,
    feeTypes: feeTypeReducer,
    plans: persistReducer(plansPersistConfig, planReducer),
    fees: feeReducer,
    feeStructures: feeStructureReducer,
    paymentMethods: persistReducer(
      paymentMethodsPersistConfig,
      paymentMethodReducer
    ),
    groups: persistReducer(groupsPersistConfig, groupReducer),
    contracts: contractReducer,
    constructionTypes: constructionTypeReducer,
    preAdjudicationReasons: preAdjudicationReasonReducer,
    additionals: additionalReducer,
    releaseReasons: releaseReasonReducer,
    coupons: couponReducer,
    subscribers: subscriberReducer,
    registerPayment: registerPaymentReducer,
    updatedDebtPayments: updatedDebtReducer,
    afip: persistReducer(afipPersistConfig, afipSliceReducer),
    salePoints: persistReducer(salePointsPersistConfig, salePointSliceReducer),
  },
});

export const persistor = persistStore(store);
