import React from "react";
import PaymentsForm from "../common/PaymentsForm";
import { Box } from "@mui/material";

const Payments = () => {
  return (
    <Box mt={12}>
      <PaymentsForm/>
    </Box>
  );
};

export default Payments;
