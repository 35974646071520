import CheckIcon from "@mui/icons-material/Check";
import CloudIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  FormDialogTitle,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  HeaderBox,
  PaginationBox,
  RowTableCell,
  SelectFormControl,
  SeparatorHrBox,
} from "../assets/styles/tables";
import Actions from "../components/dialog/Actions";
import Loading from "./Loading";
import { getGenerationControl } from "../api/endpoints/coupon";

const GenerateControlForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isScreenSizeLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const isScreenSizeMedium = useMediaQuery(
    theme.breakpoints.between("sm", "lg")
  );
  const [isLoading, setIsLoading] = useState(false);
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);
  const { branches } = useSelector((state) => state.branch);
  const { paymentMethods } = useSelector((state) => state.paymentMethods);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    branch: branches.length > 0 ? branches[0].uuid : "",
    plan: plans.length > 0 ? plans[0].uuid : "",
    group: groups.length > 0 ? groups[0].uuid : "",
    paymentMethod: paymentMethods.length > 0 ? paymentMethods[0].uuid : "",
    quarter: "",
    year: new Date().getFullYear(),
    contract_number: "",
  });

  const fetchDataWithFilters = async () => {
    console.log(branches, filter, paymentMethods);
    setIsLoading(true);
    try {
      const response = await dispatch(getGenerationControl(filter));
      console.log(response.payload);
      const transformedGenerateControls = response.payload.results.map(
        (generateControl) => {
          return {
            toGenerate: generateControl.to_generate,
            generated: generateControl.generated,
            notGenerated: generateControl.not_generated,
            ordersNotGenerated: generateControl.orders_not_generated.join(", "),
            month: generateControl.month,
            year: generateControl.year,
          };
        }
      );

      setData(transformedGenerateControls);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleFilter = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  const generateMenuItems = (start, end) => {
    let items = [];
    for (let i = start; i >= end; i--) {
      items.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    return items;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: isScreenSizeLarge ? "3%" : isScreenSizeMedium ? "5%" : 5,
          width: "100%",
        }}
      >
        <Box
          sx={{
            mt: 3,
            justifyContent: "center",
            backgroundColor: "#f2f2f2",
            borderRadius: "20px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            padding: "15px",
            boxShadow: "none",
            borderBottom: "1px solid #ccc",
            overflowX: "auto",
            maxWidth: "90%",
          }}
        >
          <Box
            sx={{
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            Cupon | Generar | Control de generación
          </Box>
          <HeaderBox
            sx={{
              display: "grid",
              gap: 2,
              mb: 3,
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            {/* primer columna */}
            <SelectFormControl sx={{ width: "auto", height: "40px" }}>
              <InputLabel margin="dense" id="branch-label">
                Sucursal
              </InputLabel>
              <Select
                labelId="branch-label"
                name="branch"
                value={filter.branch}
                onChange={handleFilter}
                sx={{ width: "100%" }}
              >
                <MenuItem value="">Todas</MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch.uuid} value={branch.uuid}>
                    {branch.description}
                  </MenuItem>
                ))}
              </Select>
            </SelectFormControl>
            <SelectFormControl sx={{ width: "auto", height: "40px" }}>
              <InputLabel margin="dense" id="plan-label">
                Plan
              </InputLabel>
              <Select
                labelId="plan-label"
                id="plan-select"
                value={filter.plan}
                onChange={handleFilter}
                name="plan"
                sx={{ width: "100%" }}
              >
                {plans.map((plan) => (
                  <MenuItem key={plan.uuid} value={plan.uuid}>
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
            </SelectFormControl>
            <SelectFormControl sx={{ width: "auto", height: "40px" }}>
              <InputLabel margin="dense" id="group-label">
                Grupo
              </InputLabel>
              <Select
                labelId="group-label"
                id="group-select"
                value={filter.group}
                onChange={handleFilter}
                name="group"
                sx={{ width: "100%" }}
              >
                <MenuItem value="">Todos</MenuItem>
                {groups.map((group) => (
                  <MenuItem key={group.uuid} value={group.uuid}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </SelectFormControl>
            <SelectFormControl sx={{ width: "auto", height: "40px" }}>
              <InputLabel margin="dense" id="quarter-label">
                Trimestre
              </InputLabel>
              <Select
                labelId="quarter-label"
                name="quarter"
                value={filter.quarter}
                onChange={handleFilter}
                sx={{ width: "100%" }}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value={1}>1 (Ene, Feb, Mar)</MenuItem>
                <MenuItem value={2}>2 (Abr, May, Jun)</MenuItem>
                <MenuItem value={3}>3 (Jul, Ago, Sep)</MenuItem>
                <MenuItem value={4}>4 (Oct, Nov, Dic)</MenuItem>
              </Select>
            </SelectFormControl>
            {/* segunda columna */}
            <SelectFormControl sx={{ width: "auto", height: "40px" }}>
              <InputLabel margin="dense" id="paymentMethod-label">
                Forma de Pago
              </InputLabel>
              <Select
                labelId="paymentMethod-label"
                id="paymentMethod-select"
                value={filter.paymentMethod}
                onChange={handleFilter}
                name="paymentMethod"
                sx={{ width: "100%" }}
              >
                <MenuItem value="">Todos</MenuItem>
                {paymentMethods.map((paymentMethod) => (
                  <MenuItem key={paymentMethod.uuid} value={paymentMethod.uuid}>
                    {paymentMethod.name}
                  </MenuItem>
                ))}
              </Select>
            </SelectFormControl>
            <SelectFormControl sx={{ width: "auto", height: "40px" }}>
              <TextField
                label="Contrato:"
                name="contract_number"
                variant="outlined"
                value={filter.contract_number}
                onChange={handleFilter}
                sx={{ width: "auto", height: "40px" }}
              />
            </SelectFormControl>
            <SelectFormControl sx={{ width: "auto", height: "40px" }}>
              <InputLabel margin="dense" id="year-label">
                Año
              </InputLabel>
              <Select
                labelId="year-label"
                id="year-select"
                value={filter.year}
                onChange={handleFilter}
                name="year"
                sx={{ width: "100%" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "300px",
                      overflow: "auto",
                    },
                  },
                }}
              >
                {generateMenuItems(2024, 1990)}
              </Select>
            </SelectFormControl>

            <SelectFormControl sx={{ width: "auto", height: "40px" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "auto", height: "40px" }}
                onClick={fetchDataWithFilters}
              >
                Ver Cupones
              </Button>
            </SelectFormControl>
          </HeaderBox>
        </Box>
        <GeneralTableBox
          sx={{
            overflowX: "auto",
            maxWidth: "90%",
            minWidth: "90%",
            mt: 0,
            ml: 0,
            mr: 0,
            mb: 0,
            borderRadius: "0px",
            "*::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "*::-webkit-scrollbar-track": {
              background: "inherit",
              boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            },
            "*::-webkit-scrollbar-thumb": {
              backgroundColor: "gray",
              borderRadius: "20px",
            },
            "*::-webkit-scrollbar-corner": {
              background: "inherit",
            },
          }}
        >
          <GeneralTableContainer
            sx={{
              minHeight: "10em",
              overflow: "auto",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                    A Generar
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                    Generados
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                    Sin Generar
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", minWidth: "30%" }}>
                    Ordenes Sin Generar
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                    Mes
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    Año
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <Loading />
                ) : (
                  data.map((row, index) => (
                    <TableRow key={index}>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.toGenerate}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.generated}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.notGenerated}
                      </RowTableCell>
                      <RowTableCell
                        sx={{
                          textAlign: "center",
                          whiteSpace: "normal", // Ajuste para permitir múltiples líneas
                          wordWrap: "break-word", // Ajuste para dividir palabras largas
                          overflow: "hidden", // Ocultar el desbordamiento
                        }}
                      >
                        {row.ordersNotGenerated}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.month}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.year}
                      </RowTableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </GeneralTableContainer>
        </GeneralTableBox>
      </Box>
    </>
  );
};

export default GenerateControlForm;
