import { createSlice } from "@reduxjs/toolkit";
import { getPlans } from "../../api/endpoints/plans"; // Importamos la acción

export const planSlice = createSlice({
  name: "plans",
  initialState: {
    plans: [],
  }, // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addPlans: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removePlan: (state, action) => {
      const index = state.findIndex(
        (maritalstatus) => maritalstatus.uuid === action.payload
      );
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.plans = action.payload;
    });
  },
});

export const { addPlans, removePlan } = planSlice.actions;

export default planSlice.reducer;
