import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    expirationTime: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload.token;
      state.expirationTime = action.payload.expirationTime;
    },
    clearToken: (state) => {
      state.token = null;
      state.expirationTime = null;
    },
  },
});


export const { setToken, clearToken } = authSlice.actions;

export default authSlice.reducer;