import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getAdditionals = createAsyncThunk("additionals", async () => {
  const response = await apiClient.get("additionals");
  return response.data;
});

export const getAdditionalById = createAsyncThunk("additional/id", async (id) => {
  const response = await apiClient.get(`additional/${id}`);
  return response.data;
});

export const deleteAdditionalById = createAsyncThunk("additional/delete", async (id) => {
  const response = await apiClient.delete(`additional/${id}`);
  return response.data;
});

export const addAdditional = createAsyncThunk("additionals/add", async (additional) => {
  const response = await apiClient.post("additionals", additional);
  return response.data;
});

export const updateAdditionalById = createAsyncThunk(
  "additional/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`additional/${id}`, data);
    return response.data;
  }
);