import {
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { HeadTableCell, RowTableCell } from "../../assets/styles/tables";

import GroupTab from "./GroupTab";
import AdditionalTab from "./AdditionalTab";
import ScoreCoefficentTab from "./ScoreCoefficentTab";
import PreAdjReasonsTab from "./PreAdjReasonsTab";

import GroupDialog from "../dialog/GroupDialog";
import AdditionalDialog from "../dialog/AdditionalDialog";
import ScoreCoefficentDialog from "../dialog/ScoreCoefficentDialog";
import PreAdjReasonsDialog from "../dialog/PreAdjReasonsDialog";
import ConstTypesTab from "./ConstTypesTab";
import ConstTypeDialog from "../dialog/ConstTypeDialog";
import PeiValueTab from "./PeiValueTab";
import PeiValueDialog from "../dialog/PeiValueDialog";
import FeeTypeTab from "./FeeTypeTab";
import FeeTypeDialog from "../dialog/FeeTypeDialog";
import FeeStructureTab from "./FeeStructureTab";
import FeeStructureDialog from "../dialog/FeeStructureDialog";
import PaymentMethodTab from "./PaymentMethodTab";
import PaymentMethodDialog from "../dialog/PaymentMethodDialog";
import PaymentMethodDetailTab from "./PaymentMethodDetailTab";
import PaymentMethodDetailDialog from "../dialog/PaymentMethodDetailDialog";

const PlanTab = (props) => {
  const {
    activeTab,
    setActiveTab,

    //Grupos
    getCurrentGroupPageItems,
    handleEditGroup,
    handleDeleteGroup,
    groupDialogProps,
    pageGroup,
    groups,
    setPageGroup,
    setSelectedGroup,
    setOpenGroup,
    setFilteredGroups,

    //Adicionales
    getCurrentAdditionalPageItems,
    handleEditAdditional,
    handleDeleteAdditional,
    additionalDialogProps,
    pageAdditional,
    additionals,
    setPageAdditional,
    setSelectedAdditional,
    setOpenAdditional,
    setFilteredAdditionals,

    //Coeficientes de puntaje
    getCurrentScoreCoefficentPageItems,
    handleEditScoreCoefficent,
    handleDeleteScoreCoefficent,
    scoreCoefficentDialogProps,
    pageScoreCoefficent,
    scoreCoefficents,
    setPageScoreCoefficent,
    setSelectedScoreCoefficent,
    setOpenScoreCoefficent,
    setFilteredScoreCoefficents,

    //Motivos de preadjudicacion
    getCurrentPreAdjRPageItems,
    handleEditPreAdjR,
    handleDeletePreAdjR,
    preAdjRDialogProps,
    pagePreAdjR,
    preAdjRs,
    setPagePreAdjR,
    setSelectedPreAdjR,
    setOpenPreAdjR,
    setFilteredPreAdjRs,

    //Tipos de construcción
    getCurrentConstTypePageItems,
    handleEditConstType,
    handleDeleteConstType,
    constTypeDialogProps,
    pageConstType,
    constTypes,
    setPageConstType,
    setSelectedConstType,
    setOpenConstType,
    setFilteredConstTypes,

    //Valores PEI
    getCurrentPeiValuePageItems,
    handleEditPeiValue,
    handleDeletePeiValue,
    peiValueDialogProps,
    pagePeiValue,
    peiValues,
    setPagePeiValue,
    setSelectedPeiValue,
    setOpenPeiValue,
    setFilteredPeiValues,
    legalEntities,

    //Tipos de Cuota
    handleEditFee,
    handleDeleteFee,
    feeDialogProps,
    pageFee,
    fees,
    setPageFee,
    setSelectedFee,
    setOpenFee,
    setFilteredFees,

    //Estructura de Cuotas
    handleEditFeeStructure,
    handleDeleteFeeStructure,
    feeStructureDialogProps,
    feeStructures,
    setSelectedFeeStructure,
    setOpenFeeStructure,
    setFilteredFeeStructures,
    selectedPlan,

    //Forma de pagos
    handleEditPaymentMethod,
    handleDeletePaymentMethod,
    paymentMethodDialogProps,
    paymentMethods,
    setSelectedPaymentMethod,
    setOpenPaymentMethod,
    setFilteredPaymentMethods,

    //Detalle de Forma de pagos
    handleEditPaymentMethodDetail,
    handleDeletePaymentMethodDetail,
    paymentMethodDetailDialogProps,
    paymentMethodDetails,
    setSelectedPaymentMethodDetail,
    setOpenPaymentMethodDetail,
    setFilteredPaymentMethodDetails,
    // selectedPlan ya lo recibo arriba
  } = props;

  const groupTabProps = {
    groupDialogProps,
    getCurrentGroupPageItems,
    handleEditGroup,
    handleDeleteGroup,
    pageGroup,
    groups,
    setPageGroup,
    setSelectedGroup,
    setOpenGroup,
    setFilteredGroups,
  };

  const additionalTabProps = {
    additionalDialogProps,
    getCurrentAdditionalPageItems,
    handleEditAdditional,
    handleDeleteAdditional,
    pageAdditional,
    additionals,
    setPageAdditional,
    setSelectedAdditional,
    setOpenAdditional,
    setFilteredAdditionals,
  };

  const scoreCoefficentTabProps = {
    scoreCoefficentDialogProps,
    getCurrentScoreCoefficentPageItems,
    handleEditScoreCoefficent,
    handleDeleteScoreCoefficent,
    pageScoreCoefficent,
    scoreCoefficents,
    setPageScoreCoefficent,
    setSelectedScoreCoefficent,
    setOpenScoreCoefficent,
    setFilteredScoreCoefficents,
  };

  const preAdjRTabProps = {
    preAdjRDialogProps,
    getCurrentPreAdjRPageItems,
    handleEditPreAdjR,
    handleDeletePreAdjR,
    pagePreAdjR,
    preAdjRs,
    setPagePreAdjR,
    setSelectedPreAdjR,
    setOpenPreAdjR,
    setFilteredPreAdjRs,
  };

  const constTypeTabProps = {
    constTypeDialogProps,
    getCurrentConstTypePageItems,
    handleEditConstType,
    handleDeleteConstType,
    pageConstType,
    constTypes,
    setPageConstType,
    setSelectedConstType,
    setOpenConstType,
    setFilteredConstTypes,
  };

  const peiValueTabProps = {
    peiValueDialogProps,
    getCurrentPeiValuePageItems,
    handleEditPeiValue,
    handleDeletePeiValue,
    pagePeiValue,
    peiValues,
    setPagePeiValue,
    setSelectedPeiValue,
    setOpenPeiValue,
    setFilteredPeiValues,
    legalEntities,
  };

  const feeTypeTabProps = {
    feeDialogProps,
    handleEditFee,
    handleDeleteFee,
    pageFee,
    fees,
    setPageFee,
    setSelectedFee,
    setOpenFee,
    setFilteredFees,
  };

  const feeStructureTabProps = {
    feeStructureDialogProps,
    handleEditFeeStructure,
    handleDeleteFeeStructure,
    feeStructures,
    setSelectedFeeStructure,
    setOpenFeeStructure,
    setFilteredFeeStructures,
    selectedPlan,
  };

  const paymentMethodTabProps = {
    handleEditPaymentMethod,
    handleDeletePaymentMethod,
    paymentMethodDialogProps,
    paymentMethods,
    setSelectedPaymentMethod,
    setOpenPaymentMethod
  };

  const paymentMethodDetailTabProps = {
    handleEditPaymentMethodDetail,
    handleDeletePaymentMethodDetail,
    paymentMethodDetailDialogProps,
    paymentMethodDetails,
    setSelectedPaymentMethodDetail,
    setOpenPaymentMethodDetail,
    setFilteredPaymentMethodDetails,
    selectedPlan
  };

  return (
    <Box
      sx={{ mt: 3 }}
      style={{
        boxShadow: "2px 2px 10px rgba(1,2,10,0.3)",
        padding: "25px 55px 25px 55px",
        borderRadius: 15,
      }}
    >
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        scrollButtons="auto"
        variant="scrollable"
      >
        <Tab label="Grupos" />
        <Tab label="Adicion." />
        <Tab label="Coef. de Puntaje" />
        <Tab label="Motivos Preadj." />
        <Tab label="Tip. Constr." />
        <Tab label="Valores PEI" />
        <Tab label="Tip. Cuotas" />
        <Tab label="Estr. Cuotas" />
        <Tab label="F. de Pagos" />
        <Tab label="Det. F. de Pagos" />
      </Tabs>
      {activeTab === 0 && <GroupTab {...groupTabProps} />}
      {activeTab === 1 && <AdditionalTab {...additionalTabProps} />}
      {activeTab === 2 && <ScoreCoefficentTab {...scoreCoefficentTabProps} />}
      {activeTab === 3 && <PreAdjReasonsTab {...preAdjRTabProps} />}
      {activeTab === 4 && <ConstTypesTab {...constTypeTabProps} />}
      {activeTab === 5 && <PeiValueTab {...peiValueTabProps} />}
      {activeTab === 6 && <FeeTypeTab {...feeTypeTabProps} />}
      {activeTab === 7 && <FeeStructureTab {...feeStructureTabProps} />}
      {activeTab === 8 && <PaymentMethodTab {...paymentMethodTabProps} />}
      {activeTab === 9 && <PaymentMethodDetailTab {...paymentMethodDetailTabProps} />}
      <GroupDialog {...groupDialogProps} />
      <AdditionalDialog {...additionalDialogProps} />
      <ScoreCoefficentDialog {...scoreCoefficentDialogProps} />
      <PreAdjReasonsDialog {...preAdjRDialogProps} />
      <ConstTypeDialog {...constTypeDialogProps} />
      <PeiValueDialog {...peiValueDialogProps} />
      <FeeTypeDialog {...feeDialogProps} />
      <FeeStructureDialog {...feeStructureDialogProps} />
      <PaymentMethodDialog {...paymentMethodDialogProps} />
      <PaymentMethodDetailDialog {...paymentMethodDetailDialogProps} />
    </Box>
  );
};

export default PlanTab;
