import React from "react";
import RegularizePlanForm from "../common/RegularizePlanForm";
import { Box } from "@mui/material";

const RegularizePlan = () => {
  return (
    <Box mt={12}>
      <RegularizePlanForm/>
    </Box>
  );
};

export default RegularizePlan;
