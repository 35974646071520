import React from "react";

import {
  DeleteButton,
  EditButton,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  PaginationBox,
  RowTableCell,
} from "../../assets/styles/tables";

import {
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";

const LegalEntitiesTable = (props) => {
  const {
    legalEntities,
    handleEdit,
    handleDelete,
    getCurrentPageItems,
    ITEMS_PER_PAGE,
    page,
    setPage,
  } = props;

  return (
    <GeneralTableBox>
      <GeneralTableContainer>
        <Table aria-label="legalEntity table">
          <TableHead>
            <TableRow>
              <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                Empresa
              </HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                Cuit
              </HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                Razón social
              </HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "5%" }}>
                Entidad intermedia
              </HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "5%" }}>
                Cuota social
              </HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                Persona contacto
              </HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                Acciones
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCurrentPageItems().map((legalEntity) => (
              <TableRow key={legalEntity.id}>
                <RowTableCell>{legalEntity.company}</RowTableCell>
                <RowTableCell>{legalEntity.cuit}</RowTableCell>
                <RowTableCell>{legalEntity.legal_business_name}</RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  {legalEntity.is_intermediate_entity === 1 ? "Si" : "No"}
                </RowTableCell>
                <RowTableCell>{legalEntity.membership_fee}</RowTableCell>
                <RowTableCell>{legalEntity.contact_information}</RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  <EditButton
                    variant="contained"
                    onClick={() => handleEdit(legalEntity)}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </EditButton>
                  <DeleteButton
                    variant="contained"
                    onClick={() => handleDelete(legalEntity.uuid)}
                    color="tertiary"
                  >
                    <IconButton size="small">
                      <DeleteIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </DeleteButton>
                </RowTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <PaginationBox>
          <Pagination
            count={Math.ceil(legalEntities.length / ITEMS_PER_PAGE)}
            shape="rounded"
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </PaginationBox>
      </GeneralTableContainer>
    </GeneralTableBox>
  );
};

export default LegalEntitiesTable;
