import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
  FormTextareaAutosize
} from "../../../assets/styles/tables";
import Actions from "../../dialog/Actions";

const ABMSubscriberDialogMobile = (props) => {

  const { open, handleClose, editMode,
    selectedSubscriber, handleInputChange,
    handleSelectChange, handleSave } = props;

  const actionProps = { handleClose, handleSave };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <FormDialogTitle>
        {editMode ? "Editar Suscriptor" : "Agregar Suscriptor"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent>
        <Box>
          <Box>
            <FormDialogSubtitle> Datos personales </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <TextField
                margin="dense"
                name="name"
                label="Nombre"
                type="text"
                fullWidth
                value={selectedSubscriber.name}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="dni"
                label="DNI"
                type="text"
                fullWidth
                value={selectedSubscriber.dni}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="birth_date"
                label="Fecha de nacimiento"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={selectedSubscriber.birth_date}
                onChange={handleInputChange}
              />
              <SelectFormControl sx={{ marginTop: 1 }}>
                <InputLabel margin="dense" id="sexo-label">
                  Sexo
                </InputLabel>
                <Select
                  labelId="sexo-label"
                  name="sexo"
                  value={selectedSubscriber.sexo}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="1">Masculino</MenuItem>
                  <MenuItem value="2">Femenino</MenuItem>
                  <MenuItem value="3">Otro</MenuItem>
                </Select>
              </SelectFormControl>
              <TextField
                margin="dense"
                name="last_name"
                label="Apellido"
                type="text"
                fullWidth
                value={selectedSubscriber.last_name}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="cuit"
                label="CUIT"
                type="text"
                fullWidth
                value={selectedSubscriber.cuit}
                onChange={handleInputChange}
              />
              <SelectFormControl sx={{ marginTop: 1 }}>
                <InputLabel margin="dense" id="nationality-label">
                  Nacionalidad
                </InputLabel>
                <Select
                  labelId="nationality-label"
                  name="nationality"
                  value={selectedSubscriber.nationality}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="1">Argentina</MenuItem>
                  <MenuItem value="2">Brasil</MenuItem>
                  <MenuItem value="3">Chile</MenuItem>
                </Select>
              </SelectFormControl>
              <SelectFormControl>
                <InputLabel margin="dense" id="civil_status-label">
                  Estado Civil
                </InputLabel>
                <Select
                  labelId="civil_status-label"
                  name="civil_status"
                  value={selectedSubscriber.civil_status}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="1">Soltero/a</MenuItem>
                  <MenuItem value="2">Casado/a</MenuItem>
                  <MenuItem value="3">Divorciado/a</MenuItem>
                </Select>
              </SelectFormControl>
            </FormBoxContainer>
          </Box>
        </Box>
        <SeparatorHrBox mt={5}>
          <hr />
        </SeparatorHrBox>
        <Box>
          <Box>
            <FormDialogSubtitle sx={{ mt: 0 }}> Datos de contacto </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <TextField
                margin="dense"
                name="adress"
                label="Dirección"
                type="text"
                fullWidth
                value={selectedSubscriber.adress}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="postal_code"
                label="CP"
                type="text"
                fullWidth
                value={selectedSubscriber.postal_code}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="telephone"
                label="Teléfono"
                type="text"
                fullWidth
                value={selectedSubscriber.telephone}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="email"
                label="Correo Electrónico"
                type="text"
                fullWidth
                value={selectedSubscriber.email}
                onChange={handleInputChange}
              />
              <SelectFormControl sx={{ marginTop: 1 }}>
                <InputLabel margin="dense" id="country_code-label">
                  Pais
                </InputLabel>
                <Select
                  labelId="country_code-label"
                  name="country_code"
                  value={selectedSubscriber.country_code}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="1">Argentina</MenuItem>
                  <MenuItem value="2">Chile</MenuItem>
                  <MenuItem value="3">Brasil</MenuItem>
                </Select>
              </SelectFormControl>
              <SelectFormControl>
                <InputLabel margin="dense" id="province-label">
                  Provincia
                </InputLabel>
                <Select
                  labelId="province-label"
                  name="province"
                  value={selectedSubscriber.province}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="1">Mendoza</MenuItem>
                  <MenuItem value="2">San Juan</MenuItem>
                  <MenuItem value="3">San Luis</MenuItem>
                </Select>
              </SelectFormControl>
              <SelectFormControl>
                <InputLabel margin="dense" id="department-label">
                  Departamento
                </InputLabel>
                <Select
                  labelId="department-label"
                  name="department"
                  value={selectedSubscriber.department}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="1">Rivadavia</MenuItem>
                  <MenuItem value="2">San Martin</MenuItem>
                  <MenuItem value="3">Junin</MenuItem>
                </Select>
              </SelectFormControl>
              <SelectFormControl>
                <InputLabel margin="dense" id="district-label">
                  Distrito
                </InputLabel>
                <Select
                  labelId="district-label"
                  name="district"
                  value={selectedSubscriber.district}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="1">Rivadavia</MenuItem>
                  <MenuItem value="2">San Martin</MenuItem>
                  <MenuItem value="3">Junin</MenuItem>
                </Select>
              </SelectFormControl>
            </FormBoxContainer>
          </Box>
        </Box>
        <SeparatorHrBox mt={5}>
          <hr />
        </SeparatorHrBox>
        <Box>
          <Box>
            <FormDialogSubtitle sx={{ mt: 0 }}> Datos adicionales: </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <SelectFormControl>
                <InputLabel margin="dense" id="id_iva-label">
                  Condicion IVA
                </InputLabel>
                <Select
                  labelId="id_iva-label"
                  name="id_iva"
                  value={1}
                  onChange={handleSelectChange}
                  disabled
                >
                  <MenuItem value="1">Sin Condicion</MenuItem>
                </Select>
              </SelectFormControl>
            </FormBoxContainer>
          </Box>

          <SeparatorHrBox mt={5}>
            <hr />
          </SeparatorHrBox>

          <FormDialogSubtitle sx={{ marginTop: 0 }}> Observaciones: </FormDialogSubtitle>

          <FormTextareaAutosize
            rowsMin={3}
            placeholder="Introduzca una observacion aqui"
            aria-label="Observaciones"
            margin="dense"
            name="observations"
            value={selectedSubscriber.observations}
            onChange={handleInputChange}
            style={{ height: "155px" }}
          />
        </Box>

      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  )
}

export default ABMSubscriberDialogMobile;