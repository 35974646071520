import { createSlice } from "@reduxjs/toolkit";

export const UpdatedDebtSlice = createSlice({
  name: "UpdatedDebt",
  initialState: {
    filters: {
      plan: "",
      group: "",
      contact_number: "",
    },
  },

  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    clearFilters: (state) => {
      state.filters = {
        plan: "",
        group: "",
        contact_number: "",
      };
    },
  },
});

export const { setFilter, clearFilters } = UpdatedDebtSlice.actions;

export default UpdatedDebtSlice.reducer;
