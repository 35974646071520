import { Box, TextField, styled } from "@mui/material";

const CustomBox = styled(Box)({
  marginLeft: "20px",
  borderRadius: "15px",
  ":hover": {
    boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
  },
  "@media (max-width: 900px)": {
    marginLeft: 0,
    marginBottom: 15,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    ":hover": {
      boxShadow: "none",
    },
  },
});

const SearchBar = ({ onSearch }) => {
  const handleSearchChange = (event) => {
    const searchText = event.target.value;
    onSearch(searchText);
  };

  return (
    <CustomBox>
      <TextField
        type="search"
        id="search"
        label="Buscar"
        color="primary"
        sx={{
          width: 300,
          backgroundColor: "whitesmoke",
          borderRadius: "10px",
          border: "1px solid rgba(224, 224, 224, 1)",
          "@media (max-width: 900px)": {
            width: "80%",
            borderRadius: "10px",
          },
        }}
        onChange={handleSearchChange}
      />
    </CustomBox>
  );
};

export default SearchBar;
