import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  branch: [],
  email: '',
  is_active: false,
  is_administrator: false,
  name_rol: '',
  names: '',
  telephone: '',
  timezone: '',
  uid_firebase: '',
  uuid: '',
  uuid_rol: '',
  uuid_subscriber: 'None'
};

const userLoggedSlice = createSlice({
  name: 'userLogged',
  initialState,
  reducers: {
    setUserLogged: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearUserLogged: () => initialState,
  },
});

export const { setUserLogged, clearUserLogged } = userLoggedSlice.actions;

export default userLoggedSlice.reducer;