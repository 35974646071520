import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  HeadTableCell,
  RowTableCell,
  EditButton,
  DeleteButton,
  PaginationBox,
  HeaderBox,
  AddButton,
} from "../../assets/styles/tables";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from '@mui/icons-material/PostAdd';
import SearchBar from "../../common/SearchBar";
import originalAdditionals from "../../data/tableContentAdditionals";

const AdditionalTab = (props) => {
  const ITEMS_PER_PAGE = 3;
  const {
    getCurrentAdditionalPageItems, handleEditAdditional, handleDeleteAdditional
    ,pageAdditional, additionals, setPageAdditional, setSelectedAdditional,
    setOpenAdditional, setFilteredAdditionals
  } = props;

  const handleClickOpen = () => {  
    setSelectedAdditional({ });
    
    setOpenAdditional(true);
  };

  const handleSearch = (searchText) => {
    if (searchText === "") {
      setFilteredAdditionals(originalAdditionals);
    } else {
      const filteredAdditionals = originalAdditionals.filter((additional) =>
        additional.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredAdditionals(filteredAdditionals);
    }
    setPageAdditional(1);
  };

  return (
<Box sx={{ mt: 2 }}>
  <br />
    <HeaderBox sx={{marginLeft:0}}>
      <AddButton variant="contained" onClick={handleClickOpen}>
        <PostAddIcon fontSize="large"/>
      </AddButton>
      <SearchBar onSearch={handleSearch} />
    </HeaderBox>
    <br />
    <Table>
        <TableHead>
        <TableRow>
            <HeadTableCell sx={{ width: "10%", textAlign: "center" }}>Nombre</HeadTableCell>
            <HeadTableCell sx={{ width: "25%", textAlign: "center"  }}>Importe ($)</HeadTableCell>
            <HeadTableCell sx={{ width: "25%", textAlign: "center"  }}>Se prorratea?</HeadTableCell>
            <HeadTableCell sx={{ width: "25%", textAlign: "center"  }}>Es obligatorio?</HeadTableCell>
            <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>Acciones</HeadTableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {getCurrentAdditionalPageItems().map((additional) => (
        <TableRow key={additional.id}>
            <RowTableCell sx={{ textAlign: "center" }}>
            {additional.name}
            </RowTableCell>
            <RowTableCell sx={{ textAlign: "center" }}>
            $ {additional.amount.toLocaleString()}
            </RowTableCell>
            <RowTableCell sx={{ textAlign: "center" }}>
            {additional.prorate === 1 ? "Si" : "No"}
            </RowTableCell>
            <RowTableCell sx={{ textAlign: "center" }}>
            {additional.mandatory === 1 ? "Si" : "No"}
            </RowTableCell>
            <RowTableCell sx={{ textAlign: "center" }}>
            <EditButton
                variant="contained"
                onClick={() => handleEditAdditional(additional)}
            >
                <IconButton size="small">
                <EditIcon sx={{ color: "whitesmoke" }} />
                </IconButton>
            </EditButton>
            <DeleteButton
                variant="contained"
                onClick={() => handleDeleteAdditional(additional.id, additional.uuid)}
            >
                <IconButton size="small">
                <DeleteIcon sx={{ color: "whitesmoke" }} />
                </IconButton>
            </DeleteButton>
            </RowTableCell>
        </TableRow>
        ))}
        </TableBody>
    </Table>
    <PaginationBox>
      <Pagination
        count={Math.ceil(additionals.length / ITEMS_PER_PAGE)}
        shape="rounded"
        page={pageAdditional}
        onChange={(event, value) => setPageAdditional(value)}
      />
    </PaginationBox>
    </Box>
)
  
};

export default AdditionalTab;
