import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getPeiValues = createAsyncThunk("peiValues", async () => {
  const response = await apiClient.get("pei_values");
  return response.data;
});

export const getPeiValueById = createAsyncThunk("peiValue/id", async (id) => {
  const response = await apiClient.get(`pei_value/${id}`);
  return response.data;
});

export const deletePeiValueById = createAsyncThunk("peiValue/delete", async (id) => {
  const response = await apiClient.delete(`pei_value/${id}`);
  return response.data;
});

export const addPeiValue = createAsyncThunk("peiValues/add", async (peiValue) => {
  const response = await apiClient.post("pei_values", peiValue);
  return response.data;
});

export const updatePeiValueById = createAsyncThunk(
  "peiValue/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`pei_value/${id}`, data);
    return response.data;
  }
);