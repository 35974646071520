import { createSlice } from '@reduxjs/toolkit';

export const CountrySlice = createSlice({
  name: 'Countries',
  initialState: [], // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addCountries: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeCountry: (state, action) => {
      const index = state.findIndex((country) => country.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { addCountries, removeCountry } = CountrySlice.actions;

export default CountrySlice.reducer;
