import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Pagination,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  HeadTableCell,
  RowTableCell,
  EditButton,
  DeleteButton,
  PaginationBox,
  HeaderBox,
  AddButton,
  SelectFormControl,
} from "../../assets/styles/tables";
import { setSelectedPaymentMethod } from "../../features/paymentMethods/paymentMethodSlice";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const PaymentMethodDetailTab = (props) => {
  const dispatch = useDispatch();
  const { paymentMethods, selectedPaymentMethod } = useSelector(
    (state) => state.paymentMethods
  );
  const {
    handleEditPaymentMethodDetail,
    handleDeletePaymentMethodDetail,
    paymentMethodDetails,
    setSelectedPaymentMethodDetail,
    setOpenPaymentMethodDetail,
    selectedPlan,
  } = props;

  const [filteredPaymentMethodDetails, setFilteredPaymentMethodDetails] =
    useState([]);

  useEffect(() => {
    if (paymentMethods.length > 0 && selectedPlan) {
      const firstPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.uuid_plan === selectedPlan.uuid
      );

      if (firstPaymentMethod) {
        dispatch(setSelectedPaymentMethod(firstPaymentMethod));
        const newFilteredDetails = paymentMethodDetails.filter(
          (paymentMethodDetail) =>
            paymentMethodDetail.uuid_payment_method === firstPaymentMethod.uuid
        );
        setFilteredPaymentMethodDetails(newFilteredDetails);
      }
    }
  }, [paymentMethods, selectedPlan, paymentMethodDetails, dispatch]);

  const handleClickOpen = () => {
    // Encuentra el valor de orden más grande en los detalles del método de pago filtrados
    const maxOrder = Math.max(
      ...filteredPaymentMethodDetails.map((detail) => detail.item_order),
      0
    );

    // Establece el detalle del método de pago seleccionado con el próximo número de orden
    setSelectedPaymentMethodDetail({
      fee: {},
      item_order: maxOrder + 1,
    });

    setOpenPaymentMethodDetail(true);
  };

  const handleSelectChange = (event) => {
    dispatch(setSelectedPaymentMethod(event.target.value));
    setFilteredPaymentMethodDetails(
      paymentMethodDetails.filter(
        (paymentMethodDetail) =>
          paymentMethodDetail.uuid_payment_method === event.target.value.uuid
      )
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      <br />
      <HeaderBox sx={{ marginLeft: 0 }}>
        <AddButton variant="contained" onClick={handleClickOpen}>
          <PostAddIcon fontSize="large" />
        </AddButton>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "30%" }}>
          <InputLabel margin="dense" id="paymentMethod-label">
            Forma Pago
          </InputLabel>
          <Select
            labelId="paymentMethod-label"
            name="paymentMethod"
            onChange={handleSelectChange}
            value={selectedPaymentMethod}
          >
            {paymentMethods
              .filter(
                (paymentMethod) => paymentMethod.uuid_plan === selectedPlan.uuid
              )
              .map((paymentMethod, index) => (
                <MenuItem key={index} value={paymentMethod}>
                  {paymentMethod.name}
                </MenuItem>
              ))}
          </Select>
        </SelectFormControl>
      </HeaderBox>
      <br />
      {filteredPaymentMethodDetails.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <HeadTableCell sx={{ width: "30%", textAlign: "center" }}>
                Orden
              </HeadTableCell>
              <HeadTableCell sx={{ width: "30%", textAlign: "center" }}>
                Tipo Cuota
              </HeadTableCell>
              <HeadTableCell sx={{ width: "20%", textAlign: "center" }}>
                Acciones
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPaymentMethodDetails
              .sort((a, b) => a.item_order - b.item_order)
              .map((paymentMethodDetail) => (
                <TableRow key={paymentMethodDetail.id}>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {paymentMethodDetail.item_order}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {paymentMethodDetail.fee.name}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    <EditButton
                      variant="contained"
                      onClick={() =>
                        handleEditPaymentMethodDetail(paymentMethodDetail)
                      }
                    >
                      <IconButton size="small">
                        <EditIcon sx={{ color: "whitesmoke" }} />
                      </IconButton>
                    </EditButton>
                    <DeleteButton
                      variant="contained"
                      onClick={() =>
                        handleDeletePaymentMethodDetail(
                          paymentMethodDetail.id,
                          paymentMethodDetail.uuid
                        )
                      }
                    >
                      <IconButton size="small">
                        <DeleteIcon sx={{ color: "whitesmoke" }} />
                      </IconButton>
                    </DeleteButton>
                  </RowTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <p>No se detalles de la forma de pago para el plan seleccionado.</p>
      )}
    </Box>
  );
};

export default PaymentMethodDetailTab;
