import { createSlice } from '@reduxjs/toolkit';

export const DistrictSlice = createSlice({
  name: 'Districts',
  initialState: [], // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addDistricts: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeDistrict: (state, action) => {
      const index = state.findIndex((district) => district.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { addDistricts, removeDistrict } = DistrictSlice.actions;

export default DistrictSlice.reducer;
