import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  TextField,
  FormHelperText,
} from "@mui/material";
import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
} from "../../assets/styles/tables";
import Actions from "./Actions";

const ScoreCoefficentDialog = (props) => {
  const {
    openScoreCoefficent,
    handleCloseScoreCoefficent,
    editModeScoreCoefficent,
    selectedScoreCoefficent,
    handleInputChangeScoreCoefficent,
    handleSaveScoreCoefficent,
  } = props;

  const [valueError, setValueError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [decimalError, setDecimalError] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (openScoreCoefficent) {
      setValueError(false);
      setDateError(false);
      setDecimalError(false);
      setSaveAttempted(false);
    }
  }, [openScoreCoefficent]);

  const isValidDecimal = (value) => {
    const number = parseFloat(value);
    return !isNaN(number) && number > 0 && number < 1;
  };

  const handleSave = () => {
    setSaveAttempted(true);
    setDecimalError(false);

    if (!selectedScoreCoefficent.value) {
      setValueError(true);
      return;
    } else if (!isValidDecimal(selectedScoreCoefficent.value)) {
      setDecimalError(true);
      return;
    }

    if (
      !selectedScoreCoefficent.start_date ||
      !selectedScoreCoefficent.due_date
    ) {
      setDateError(true);
      return;
    }

    handleSaveScoreCoefficent();
  };

  const actionProps = {
    handleClose: handleCloseScoreCoefficent,
    handleSave: handleSave,
  };

  return (
    <Dialog
      open={openScoreCoefficent}
      onClose={handleCloseScoreCoefficent}
      PaperProps={{
        style: { minWidth: "600px", maxWidth: "600px" },
      }}
    >
      <FormDialogTitle>
        {editModeScoreCoefficent
          ? "Editar Coeficiente de puntaje"
          : "Agregar Coeficiente de puntaje"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <Box>
            <FormDialogSubtitle>
              {" "}
              {selectedScoreCoefficent.value === undefined
                ? ""
                : `Datos del coeficiente con valor = ${selectedScoreCoefficent.value}`}{" "}
            </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <TextField
                margin="dense"
                name="value"
                label="Valor"
                type="number"
                fullWidth
                inputProps={{
                  step: "0.001",
                  min: "0",
                  max: "9.9999999",
                }}
                value={selectedScoreCoefficent.value}
                onChange={handleInputChangeScoreCoefficent}
                error={
                  valueError ||
                  decimalError ||
                  (saveAttempted && !selectedScoreCoefficent.value)
                }
              />
              {valueError ||
              (saveAttempted && !selectedScoreCoefficent.value) ? (
                <FormHelperText>Debes ingresar un valor.</FormHelperText>
              ) : null}
              {decimalError ? (
                <FormHelperText>
                  El valor debe ser un decimal entre 0 y 1 (excluyendo 0 y 1).
                </FormHelperText>
              ) : null}
              <TextField
                margin="dense"
                name="start_date"
                label="Fecha Inicio"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={(selectedScoreCoefficent.start_date || "").slice(0, 10)}
                onChange={handleInputChangeScoreCoefficent}
                error={
                  dateError ||
                  (saveAttempted &&
                    (!selectedScoreCoefficent.start_date ||
                      !selectedScoreCoefficent.due_date))
                }
              />
              <TextField
                margin="dense"
                name="due_date"
                label="Fecha Fin"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={(selectedScoreCoefficent.due_date || "").slice(0, 10)}
                onChange={handleInputChangeScoreCoefficent}
                error={
                  dateError ||
                  (saveAttempted &&
                    (!selectedScoreCoefficent.start_date ||
                      !selectedScoreCoefficent.due_date))
                }
              />
              {dateError ||
              (saveAttempted &&
                (!selectedScoreCoefficent.start_date ||
                  !selectedScoreCoefficent.due_date)) ? (
                <FormHelperText>
                  Debes ingresar las fechas de inicio y fin.
                </FormHelperText>
              ) : null}
            </FormBoxContainer>
          </Box>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default ScoreCoefficentDialog;
