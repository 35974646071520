import EmailIcon from "@mui/icons-material/Email";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSubscribersForMails,
  getCouponsForMails,
  sendMails,
} from "../api/endpoints/mails";
import {
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  PaginationBox,
  RowTableCell,
  SelectFormControl
} from "../assets/styles/tables";

function capitalizeWords(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
const EmailComposeView = ({
  open,
  onClose,
  onSend,
  emailData,
  plans,
  onNoteChange,
  resetNoteFlag
}) => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [note, setNote] = useState("");
  const [isNoteEnabled, setIsNoteEnabled] = useState(false);
  const formattedName = emailData
    ? capitalizeWords(`${emailData.first_name} ${emailData.last_name}`)
    : "";

  useEffect(() => {
    onNoteChange(note); // Llama al callback con la nota actual
  }, [note, onNoteChange]);

  useEffect(() => {
    if (resetNoteFlag) {
      setNote('');
      setIsNoteEnabled(false);
    }
  }, [resetNoteFlag]);

  // Restablece el estado cuando se abre el diálogo con nuevos datos
  useEffect(() => {
    if (open) {
      const planName =
        plans.find((plan) => plan.uuid === emailData.contract.uuid_plan)
          ?.name || "";
      const defaultSubject = `${planName} - Nro de Orden: ${emailData.contract_number}`;
      const defaultMessage =
        `Estimado ${formattedName}, Nro de Orden: ${emailData.contract_number}\n\n` +
        `En el presente correo le hacemos envío de los cupones que se detallan a continuación:\n` +
        emailData.coupons
          .map(
            (coupon) =>
              `- Cupón Mes: ${coupon.month} Año: ${coupon.year} Cuotas: ${coupon.fee_number1} - ${coupon.fee_number2}`
          )
          .join("\n") +
        `\n\nAtte.\n\n${planName}\n\nArchivo Adjunto: NroOrden1-Año2023-Grupo0.pdf`;

      setTo(emailData.subscriber.email || "suscriptor@SinMail.com");
      setSubject(defaultSubject);
      setMessage(defaultMessage);

      // // Calcula el nombre formateado a partir de first_name y last_name
      // const formatted = `${emailData.first_name} ${emailData.last_name}`;
      // setFormattedName(formatted);
    }
  }, [open, emailData, plans]);

  return (
    <>
      <TextField
        label="Para:"
        fullWidth
        margin="dense"
        value={to}
        onChange={(e) => setTo(e.target.value)}
        disabled
      />
      <TextField
        label="De:"
        fullWidth
        margin="dense"
        defaultValue="test.colonial@constructoracolonial.com.ar"
        disabled
      />
      <TextField
        label="Asunto:"
        fullWidth
        margin="dense"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        disabled
      />
      <TextField
        label="Mensaje:"
        fullWidth
        margin="dense"
        multiline
        rows={10}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        disabled
      />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={11}>
          <TextField
            label="Nota:"
            fullWidth
            margin="dense"
            multiline
            rows={2} // Inicia con dos filas y se expandirá según sea necesario
            value={note}
            onChange={(e) => setNote(e.target.value)}
            disabled={!isNoteEnabled} // Deshabilitar si el interruptor no está activado
            placeholder="Escriba su nota aquí..."
            variant="outlined" // Esto da un aspecto más definido al TextField
          />
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isNoteEnabled}
                onChange={(e) => {
                  setIsNoteEnabled(e.target.checked);
                  if (!e.target.checked) {
                    setNote(""); // Limpiar el campo de nota si se deshabilita
                  }
                }}
                color="primary"
              />
            }
            label=""
          />
        </Grid>
      </Grid>
    </>
  );
};

const BulkSendConfirmationDialog = ({
  open,
  selectedItems,
  onClose,
  onConfirm,
}) => {
  const [currentEmailIndex, setCurrentEmailIndex] = useState(0);
  const [confirmedEmails, setConfirmedEmails] = useState([]);
  const [notes, setNotes] = useState({});
  const { plans } = useSelector((state) => state.plans);
  const [resetNoteFlag, setResetNoteFlag] = useState(false);

  // Reset state when the dialog closes
  useEffect(() => {
    if (!open) {
      setCurrentEmailIndex(0);
      setConfirmedEmails([]);
      setNotes({}); // Limpia las notas cuando el diálogo se cierra
    }
  }, [open]);

  const handleNoteChange = (index, newNote) => {
    setNotes((prevNotes) => ({
      ...prevNotes,
      [index]: newNote,
    }));
  };

  const handleNext = () => {
    // Guarda el correo electrónico confirmado actual con su respectiva nota
    const currentEmail = {
      ...selectedItems[currentEmailIndex],
      note: notes[currentEmailIndex] || "", // Asegúrate de que la nota está siendo recogida del estado `notes`
    };
  
    setConfirmedEmails((prevConfirmed) => [...prevConfirmed, currentEmail]);
    // Pasar al siguiente correo electrónico
    setCurrentEmailIndex((prevIndex) => prevIndex + 1);
    setResetNoteFlag(prev => !prev);
  };
  
  const handleSkip = () => {
    // Skip the current email and move to the next
    setCurrentEmailIndex((prevIndex) => prevIndex + 1);
    setResetNoteFlag(prev => !prev);
  };

  const isVerificationComplete = currentEmailIndex >= selectedItems.length;
  

  const handleSendAll = () => {
    const emailsToSend = confirmedEmails.map((emailData, index) => {
      const formattedName = capitalizeWords(
        `${emailData.first_name} ${emailData.last_name}`
      );
      const mostRecentCoupon = emailData.coupons?.sort(
        (a, b) => new Date(b.year, b.month - 1) - new Date(a.year, a.month - 1)
      )[0];
      const uuidForFile = mostRecentCoupon?.uuid;
      return {
        to: emailData.subscriber.email,
        subject: `${
          plans.find((plan) => plan.uuid === emailData.contract.uuid_plan)
            ?.name || ""
        } - Nro de Orden: ${emailData.contract_number}`,
        text_message: `Estimado ${formattedName}, Nro de Orden:${emailData.contract_number}...`, // Agrega aquí el mensaje completo
        name: formattedName,
        order_number: emailData.contract_number,
        coupons: emailData.coupons,
        plan_name:
          plans.find((plan) => plan.uuid === emailData.contract.uuid_plan)
            ?.name || "",
        attachment: "NroOrden1-Año2023-Grupo0.pdf",
        note: emailData.note, // Usa la nota actual de estado
        uuidForFile: uuidForFile,
      };
    });

    onConfirm({ emails: emailsToSend });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Confirmar Envío de Emails</DialogTitle>
      <DialogContent>
        {isVerificationComplete ? (
          // Show the final confirmation message
          <DialogContentText>
            Todos los ítems han sido verificados. ¿Enviar los seleccionados?
          </DialogContentText>
        ) : (
          // Use EmailComposeView to display the current email details
          <EmailComposeView
            open={open}
            onClose={onClose}
            onSend={handleNext}
            emailData={selectedItems[currentEmailIndex]}
            plans={plans}
            onNoteChange={(newNote) =>
              handleNoteChange(currentEmailIndex, newNote)
            }
            resetNoteFlag={resetNoteFlag}
          />
        )}
      </DialogContent>
      <DialogActions>
        {isVerificationComplete ? (
          <>
            <Button onClick={onClose}>Cancelar</Button>
            <Button onClick={handleSendAll} color="primary" variant="contained">
              Enviar Todo
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleSkip}>Saltar</Button>
            <Button onClick={handleNext}>Siguiente</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const EmailDialog = ({ open, onClose, emailData }) => {
  const { plans } = useSelector((state) => state.plans);
  const dispatch = useDispatch(); // para despachar la acción
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [note, setNote] = useState("");
  const [isNoteEnabled, setIsNoteEnabled] = useState(false);
  const formattedName = capitalizeWords(
    `${emailData.first_name} ${emailData.last_name}`
  );

  let mostRecentCoupon = emailData.coupons?.sort(
    (a, b) => new Date(b.year, b.month - 1) - new Date(a.year, a.month - 1)
  )[0];
  let uuidForFile = mostRecentCoupon?.uuid; // Asumiendo que cada cupón tiene un campo 'uuid'

  // Restablece el estado cuando se abre el diálogo con nuevos datos
  useEffect(() => {
    if (open) {
      const planName =
        plans.find((plan) => plan.uuid === emailData.contract.uuid_plan)
          ?.name || "";
      const defaultSubject = `${planName} - Nro de Orden: ${emailData.contract_number}`;
      const defaultMessage =
        `Estimado ${formattedName}, Nro de Orden: ${emailData.contract_number}\n\n` +
        `En el presente correo le hacemos envío de los cupones que se detallan a continuación:\n` +
        emailData.coupons
          .map(
            (coupon) =>
              `- Cupón Mes: ${coupon.month} Año: ${coupon.year} Cuotas: ${coupon.fee_number1} - ${coupon.fee_number2}`
          )
          .join("\n") +
        `\n\nAtte.\n\n${planName}\n\nArchivo Adjunto: NroOrden1-Año2023-Grupo0.pdf`;

      setTo(emailData.subscriber.email);
      setSubject(defaultSubject);
      setMessage(defaultMessage);
      console.log("emailData", emailData);
    }
  }, [open, emailData, plans]);

  const handleSend = () => {
    const emailToSend = {
      emails: [
        {
          to: to,
          subject: subject,
          text_message: message, // Este es el mensaje en texto plano
          name: formattedName, // Nombre completo del destinatario
          order_number: emailData.contract_number, // Número de orden
          coupons: emailData.coupons, // Array de cupones
          plan_name:
            plans.find((plan) => plan.uuid === emailData.contract.uuid_plan)
              ?.name || "", // Nombre del plan
          attachment: "NroOrden1-Año2023-Grupo0.pdf", // Ejemplo de archivo adjunto
          note: note,
          uuidForFile: uuidForFile,
        },
      ],
    };
    console.log("entro aca", isNoteEnabled, note, emailToSend);
    dispatch(sendMails(emailToSend)); // Despacha el thunk con los datos del email
    onClose(); // Cierra el diálogo después de enviar
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Enviar Email</DialogTitle>
      <DialogContent>
        <EmailComposeView
          open={open}
          onClose={onClose}
          onSend={handleSend}
          emailData={emailData}
          plans={plans}
          onNoteChange={(newNote) => setNote(newNote)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSend}>Enviar</Button>
      </DialogActions>
    </Dialog>
  );
};

const CouponsMailsHeader = ({
  handleBulkSend,
  onFilterChange,
  includeAllSubscribers,
  onIncludeAllSubscribersChange,
}) => {
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);
  const [filter, setFilter] = useState({
    timePeriod: "",
    quarter: "",
    month: "",
    year: "",
    plan: "",
    group: "",
    order: "",
    notCancelled: true,
  });

  // useEffect para actualizar `filter` cuando `plans` y `groups` estén disponibles
  useEffect(() => {
    if (plans.length > 0 && groups.length > 0) {
      setFilter((f) => ({
        ...f,
        plan: plans[0].uuid,
        group: groups[0].uuid,
      }));
    }
  }, [plans, groups]);

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilter((prevFilter) => {
      let newFilter = {
        ...prevFilter,
        [name]: value,
      };

      // Resetear el otro filtro basado en el cambio de timePeriod
      if (name === "timePeriod") {
        if (value === "quarter") {
          newFilter = { ...newFilter, month: "" };
        } else if (value === "month") {
          newFilter = { ...newFilter, quarter: "" };
        }
      }

      onFilterChange(newFilter);
      return newFilter;
    });
  };

  const generateMenuItems = (start, end) => {
    let items = [];
    for (let i = start; i >= end; i--) {
      items.push(<MenuItem value={i.toString()}>{i}</MenuItem>);
    }
    return items;
  };

  const generateMonthItems = () => {
    const months = [
      { value: "1", label: "Ene" },
      { value: "2", label: "Feb" },
      { value: "3", label: "Mar" },
      { value: "4", label: "Abr" },
      { value: "5", label: "May" },
      { value: "6", label: "Jun" },
      { value: "7", label: "Jul" },
      { value: "8", label: "Ago" },
      { value: "9", label: "Sep" },
      { value: "10", label: "Oct" },
      { value: "11", label: "Nov" },
      { value: "12", label: "Dic" },
    ];
    return months.map((month) => (
      <MenuItem value={month.value}>{month.label}</MenuItem>
    ));
  };

  return (
    <Box sx={{ mt: 3, backgroundColor: "#f2f2f2", padding: "15px" }}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Cupon | Impresión | Cupones por mail
      </Typography>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={2}>
          <SelectFormControl>
            <InputLabel id="timePeriod-label">Periodo</InputLabel>
            <Select
              labelId="timePeriod-label"
              name="timePeriod"
              value={filter.timePeriod}
              onChange={handleFilter}
            >
              <MenuItem value="quarter">Trimestre</MenuItem>
              <MenuItem value="month">Mes</MenuItem>
            </Select>
          </SelectFormControl>
        </Grid>

        {/* Conditional rendering based on timePeriod */}
        {filter.timePeriod === "quarter" && (
          <Grid item xs={2}>
            <SelectFormControl>
              <InputLabel id="quarter-label">Trimestre</InputLabel>
              <Select
                labelId="quarter-label"
                name="quarter"
                value={filter.quarter}
                onChange={handleFilter}
              >
                <MenuItem value="">Todos</MenuItem>
                {/* ... other MenuItems */}
              </Select>
            </SelectFormControl>
          </Grid>
        )}

        {filter.timePeriod === "month" && (
          <Grid item xs={2}>
            <SelectFormControl>
              <InputLabel id="month-label">Mes</InputLabel>
              <Select
                labelId="month-label"
                name="month"
                value={filter.month}
                onChange={handleFilter}
              >
                {generateMonthItems()}
              </Select>
            </SelectFormControl>
          </Grid>
        )}

        <Grid item xs={1}>
          <SelectFormControl>
            <InputLabel id="year-label">Año</InputLabel>
            <Select
              labelId="year-label"
              name="year"
              value={filter.year}
              onChange={handleFilter}
            >
              {generateMenuItems(new Date().getFullYear(), 1990)}
            </Select>
          </SelectFormControl>
        </Grid>

        <Grid item xs={2}>
          <SelectFormControl>
            <InputLabel id="plan-label">Plan</InputLabel>
            <Select
              labelId="plan-label"
              name="plan"
              value={filter.plan}
              onChange={handleFilter}
            >
              {plans.map((plan) => (
                <MenuItem key={plan.uuid} value={plan.uuid}>
                  {plan.name}
                </MenuItem>
              ))}
            </Select>
          </SelectFormControl>
        </Grid>

        <Grid item xs={2}>
          <SelectFormControl>
            <InputLabel id="group-label">Grupo</InputLabel>
            <Select
              labelId="group-label"
              name="group"
              value={filter.group}
              onChange={handleFilter}
            >
              <MenuItem value="">Todos</MenuItem>
              {groups.map((group) => (
                <MenuItem key={group.uuid} value={group.uuid}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </SelectFormControl>
        </Grid>

        <Grid item xs={1}>
          <TextField
            label="N° Contrato"
            name="order"
            value={filter.order}
            onChange={handleFilter}
            sx={{ marginTop: "12px", width: "100%" }}
          />
        </Grid>

        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={filter.notCancelled}
                onChange={(event) =>
                  handleFilter({
                    target: {
                      name: "notCancelled",
                      value: event.target.checked,
                    },
                  })
                }
                name="notCancelled"
              />
            }
            label="No cancelados"
            sx={{ marginTop: "12px" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} ml={0} mt={1} alignItems="center">
        <Grid item xs="auto">
          <FormControlLabel
            control={
              <Checkbox
                checked={includeAllSubscribers}
                onChange={onIncludeAllSubscribersChange}
                name="includeAllSubscribers"
              />
            }
            label="Enviar a todos"
          />
        </Grid>
        <Grid item xs="auto">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleBulkSend}
          >
            Enviar Seleccionados
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const CouponsMailsForm = (props) => {
  const theme = useTheme();
  const [subscribersWithCoupons, setSubscribersWithCoupons] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  const [selectedItems, setSelectedItems] = useState([]);
  const [emailData, setEmailData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bulkSendDialogOpen, setBulkSendDialogOpen] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [bulkConfirmationDialogOpen, setBulkConfirmationDialogOpen] =
    useState(false);

  const handleBulkSend = () => {
    // Abrir el diálogo de confirmación masiva
    console.log("Selected items", selectedItems);
    setBulkConfirmationDialogOpen(true);
  };

  const clearSelection = () => {
    setSelectedItems([]);
    // Además, aquí podrías actualizar cualquier otro estado o realizar acciones
    // para desmarcar visualmente los ítems en la interfaz de usuario
  };

  useEffect(() => {
    if (!bulkConfirmationDialogOpen) {
      // Llama a la función clearSelection cuando el diálogo de confirmación se cierra
      clearSelection();
    }
  }, [bulkConfirmationDialogOpen]);

  const handleBulkSendConfirmation = (confirmedEmails) => {
    console.log("confirmedEmails", confirmedEmails);
    const emailsPayload = {
      emails: confirmedEmails.emails.map((emailInfo) => {
        return {
          to: emailInfo.to,
          subject: emailInfo.subject,
          text_message: emailInfo.text_message,
          name: emailInfo.name,
          order_number: emailInfo.order_number,
          coupons: emailInfo.coupons,
          plan_name: emailInfo.plan_name,
          attachment: emailInfo.attachment,
          note: emailInfo.note,
          uuidForFile: emailInfo.uuidForFile,
        };
      }),
    };
    console.log('handleBulkSendConfirmation emailsPayload',emailsPayload)
    dispatch(sendMails(emailsPayload));
  };

  // Maneja el envío individual
  const handleSendEmail = (emailData) => {
    setEmailData(emailData); // Establece los datos del ítem seleccionado
    setDialogOpen(true); // Abre el diálogo
  };

  const handleSelectItem = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (
        prevSelectedItems.some(
          (selectedItem) =>
            selectedItem.subscriber_uuid === item.subscriber_uuid
        )
      ) {
        // Si el item ya está seleccionado, lo remueve de la lista
        return prevSelectedItems.filter(
          (selectedItem) =>
            selectedItem.subscriber_uuid !== item.subscriber_uuid
        );
      } else {
        // Si el item no está seleccionado, lo agrega a la lista
        return [...prevSelectedItems, item];
      }
    });
  };

  const dispatch = useDispatch();
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);

  // Valores por defecto
  const defaultFilter = {
    timePeriod: "",
    quarter: "",
    month: "",
    year: "",
    plan: plans.length > 0 ? plans[0].uuid : "",
    group: groups.length > 0 ? groups[0].uuid : "",
    order: "",
  };

  const [filter, setFilter] = useState(defaultFilter);

  // Función para manejar el cambio en los filtros
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    dispatch(getCouponsForMails(newFilter)).then((response) => {
      setSubscribersWithCoupons(response.payload.subscribers); // Actualiza los cupones
      setTotalPages(response.payload.pages); // Actualiza el total de páginas
      setTotalCount(response.payload.total); // Actualiza el conteo total
      setPage(response.payload.page); // Actualiza la página actual
      console.log("Datos traidos del backend:", response.payload);
    });
  };

  // Llamada inicial al cargar el componente
  useEffect(() => {
    handleFilterChange(defaultFilter);
  }, [plans, groups]); // Dependencias del efecto

  const [includeAllSubscribers, setIncludeAllSubscribers] = useState(false);
  const [allSubscribersData, setAllSubscribersData] = useState({
    subscribers: [],
    total: 0,
  });

  useEffect(() => {
    if (includeAllSubscribers) {
      dispatch(getAllSubscribersForMails(filter)).then((data) => {
        console.log(data.payload);
        setAllSubscribersData(data.payload);
      });
    } else {
      // Limpiar los datos si "Enviar a todos" no está marcado
      setAllSubscribersData({ subscribers: [], total: 0 });
      setSelectedItems([]);
    }
  }, [includeAllSubscribers, filter]);

  const handleIncludeAllSubscribersChange = (event) => {
    setIncludeAllSubscribers(event.target.checked);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mr: 3,
        ml: 3,
      }}
    >
      <CouponsMailsHeader
        handleBulkSend={handleBulkSend}
        onFilterChange={handleFilterChange}
        includeAllSubscribers={includeAllSubscribers}
        onIncludeAllSubscribersChange={handleIncludeAllSubscribersChange}
      />
      <GeneralTableBox
        sx={{
          mt: 0,
          ml: 0,
          mr: 0,
          mb: 0,
          borderRadius: "0px",
          boxShadow: "none",
          display: "flex", // Añadido para flexbox
          flexDirection: "column", // Añadido para flexbox
          alignItems: "center", // Añadido para centrar horizontalmente
          justifyContent: "center", // Añadido para centrar verticalmente
          height: "100%", // Añadido para llenar el contenedor
          "*::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "*::-webkit-scrollbar-track": {
            background: "inherit",
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "20px",
          },
          "*::-webkit-scrollbar-corner": {
            background: "inherit",
          },
          "@media (min-width: 900px) and (max-width: 1536px)": {
            width: "100%",
          },
        }}
      >
        {!includeAllSubscribers ? (
          <GeneralTableContainer
            sx={{
              maxHeight: "60em",
              overflow: "auto",
            }}
          >
            <Table aria-label="plan table">
              <TableHead>
                <TableRow>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    N° Contrato
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "20%" }}>
                    Suscriptores
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "50%" }}>
                    Cupones
                  </HeadTableCell>
                  <HeadTableCell
                    sx={{ textAlign: "center", width: "5%" }}
                  ></HeadTableCell>
                  <HeadTableCell
                    sx={{ textAlign: "center", width: "5%" }}
                  ></HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscribersWithCoupons.map((subscriber, key) => (
                  <TableRow key={key}>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      {subscriber.contract_number}
                    </RowTableCell>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      {`${subscriber.first_name} ${subscriber.last_name}`}
                    </RowTableCell>
                    <RowTableCell
                      sx={{ textAlign: "center", fontSize: "1rem" }}
                    >
                      {subscriber.coupons.map((coupon, index) => (
                        <div key={index} style={{ marginBottom: "0.5rem" }}>
                          - Cupón Mes: {coupon.month} Año: {coupon.year} Cuotas:{" "}
                          {coupon.fee_number1} - {coupon.fee_number2}
                        </div>
                      ))}
                    </RowTableCell>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => handleSendEmail(subscriber)}
                      >
                        <EmailIcon />
                      </IconButton>
                    </RowTableCell>
                    <RowTableCell sx={{ textAlign: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedItems.includes(subscriber)}
                            onChange={() => handleSelectItem(subscriber)}
                          />
                        }
                        label="Seleccionar"
                      />
                    </RowTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </GeneralTableContainer>
        ) : (
          <Box
            sx={{
              m: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" align="center" gutterBottom>
              Todos los suscriptores han sido seleccionados para enviar su
              respectivo mail.
            </Typography>
            <Typography variant="h6" align="center">
              Total de suscriptores seleccionados: {allSubscribersData.total}
            </Typography>
          </Box>
        )}
      </GeneralTableBox>
      <PaginationBox
        sx={{
          backgroundColor: theme.palette.background.formTables,
          mt: 0,
          borderBottomRightRadius: "20px",
          borderBottomLeftRadius: "20px",
          width: "100%",
        }}
      >
        <Pagination
          sx={{ mr: 10 }}
          count={totalPages}
          shape="rounded"
          page={page}
          onChange={(event, value) => {
            setPage(value);
            handleFilterChange({ ...filter, page: value }); // Actualiza los filtros con la nueva página
          }}
        />
        <Box
          sx={{
            ml: 10,
            fontSize: "15px",
          }}
        >
          Registros: {totalCount}
        </Box>
      </PaginationBox>
      <EmailDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        emailData={emailData}
      />
      <BulkSendConfirmationDialog
        open={bulkConfirmationDialogOpen}
        selectedItems={
          includeAllSubscribers ? allSubscribersData.subscribers : selectedItems
        }
        onClose={() => setBulkConfirmationDialogOpen(false)}
        onConfirm={handleBulkSendConfirmation}
      />
    </Box>
  );
};

export default CouponsMailsForm;
