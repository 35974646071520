import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
} from "../../assets/styles/tables";
import Actions from "./Actions";
import { useSelector } from "react-redux";

const FeeTypeDialog = (props) => {
  const { feeTypes } = useSelector((state) => state.feeTypes);
  const {
    openFee,
    handleCloseFee,
    editModeFee,
    selectedFee,
    handleInputChangeFee,
    handleSelectChangeFee,
    handleSaveFee,
  } = props;

  const [nameError, setNameError] = useState(false);
  const [feeTypeError, setFeeTypeError] = useState(false);
  const [completeError, setCompleteError] = useState(false);
  const [scoreError, setScoreError] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (openFee) {
      setNameError(false);
      setFeeTypeError(false);
      setCompleteError(false);
      setScoreError(false);
      setSaveAttempted(false);
    }
  }, [openFee]);

  const handleSave = () => {
    setSaveAttempted(true);

    if (!selectedFee.name) {
      setNameError(true);
      return;
    }

    if (!selectedFee.feeType) {
      setFeeTypeError(true);
      return;
    }

    if (!selectedFee.complete) {
      setCompleteError(true);
      return;
    }

    if (!selectedFee.score) {
      setScoreError(true);
      return;
    }

    handleSaveFee();
  };

  const actionProps = {
    handleClose: handleCloseFee,
    handleSave: handleSave,
  };

  return (
    <Dialog
      open={openFee}
      onClose={handleCloseFee}
      maxWidth={false}
      PaperProps={{
        style: { minWidth: "600px", maxWidth: "600px" },
      }}
    >
      <FormDialogTitle>
        {editModeFee ? "Editar Adicional" : "Agregar Adicional"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <TextField
                margin="dense"
                name="name"
                label="Nombre"
                type="text"
                fullWidth
                value={selectedFee.name}
                onChange={handleInputChangeFee}
                error={nameError || (saveAttempted && !selectedFee.name)}
              />
              {nameError || (saveAttempted && !selectedFee.name) ? (
                <FormHelperText>Debes ingresar un nombre.</FormHelperText>
              ) : null}
              <SelectFormControl>
                <InputLabel margin="dense" id="feeType-label">
                  Tipo Cuota{" "}
                </InputLabel>
                <Select
                  labelId="feeType-label"
                  name="feeType"
                  value={selectedFee.feeType}
                  onChange={handleSelectChangeFee}
                  error={
                    feeTypeError || (saveAttempted && !selectedFee.feeType)
                  }
                >
                  {feeTypes.map((feeType, index) => (
                    <MenuItem key={index} value={feeType.name}>
                      {feeType.name}
                    </MenuItem>
                  ))}
                </Select>
                {feeTypeError || (saveAttempted && !selectedFee.feeType) ? (
                  <FormHelperText>
                    Debes seleccionar un tipo de cuota.
                  </FormHelperText>
                ) : null}
              </SelectFormControl>
              <SelectFormControl>
                <InputLabel margin="dense" id="complete-label">
                  Completa
                </InputLabel>
                <Select
                  labelId="complete-label"
                  name="complete"
                  value={selectedFee.complete}
                  onChange={handleSelectChangeFee}
                  error={
                    completeError || (saveAttempted && !selectedFee.complete)
                  }
                >
                  <MenuItem value="1">Si</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
                {completeError || (saveAttempted && !selectedFee.complete) ? (
                  <FormHelperText>
                    Debes seleccionar una opción para 'Completa'.
                  </FormHelperText>
                ) : null}
              </SelectFormControl>
              <SelectFormControl>
                <InputLabel margin="dense" id="score-label">
                  Puntaje
                </InputLabel>
                <Select
                  labelId="score-label"
                  name="score"
                  value={selectedFee.score}
                  onChange={handleSelectChangeFee}
                  error={scoreError || (saveAttempted && !selectedFee.score)}
                >
                  <MenuItem value="1">Si</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
                {scoreError || (saveAttempted && !selectedFee.score) ? (
                  <FormHelperText>
                    Debes seleccionar una opción para 'Puntaje'.
                  </FormHelperText>
                ) : null}
              </SelectFormControl>
            </FormBoxContainer>
          </Box>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default FeeTypeDialog;
