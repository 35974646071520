import React from "react";

import {
  DeleteButton,
  EditButton,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  PaginationBox,
  RowTableCell
} from "../../assets/styles/tables";

import {
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableHead,
  TableRow
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";


const UsersTable = (props) => {

  const { users, handleEdit, handleDelete,
    getCurrentPageItems, ITEMS_PER_PAGE,
    page, setPage } = props;

  return (
    <GeneralTableBox >
      <GeneralTableContainer>
        <Table aria-label="user table">
          <TableHead>
            <TableRow>
              <HeadTableCell sx={{ width: "15%" }}>Usuario</HeadTableCell>
              <HeadTableCell sx={{ width: "15%" }}>Nombre</HeadTableCell>
              <HeadTableCell sx={{ width: "10%" }}>Teléfono</HeadTableCell>
              <HeadTableCell sx={{ width: "20%" }}>Email</HeadTableCell>
              <HeadTableCell sx={{ width: "9%" }}>Rol</HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "18%" }}>
                Acciones
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCurrentPageItems().map((user) => (
              <TableRow key={user.id}>
                <RowTableCell>{user.names.split(' ')[0]}</RowTableCell>
                <RowTableCell>{user.names}</RowTableCell>
                <RowTableCell>{user.telephone}</RowTableCell>
                <RowTableCell>{user.email}</RowTableCell>
                <RowTableCell>{user.role.name}</RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  <EditButton
                    variant="contained"
                    onClick={() => handleEdit(user)}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </EditButton>
                  <DeleteButton
                    variant="contained"
                    color="tertiary"
                    onClick={() => handleDelete(user.id, user.uuid)}
                  >
                    <IconButton size="small">
                      <DeleteIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </DeleteButton>
                </RowTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <PaginationBox>
          <Pagination
            count={Math.ceil(users.length / ITEMS_PER_PAGE)}
            shape="rounded"
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </PaginationBox>
      </GeneralTableContainer>
    </GeneralTableBox>
  )
}

export default UsersTable;