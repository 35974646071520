import { createSlice } from "@reduxjs/toolkit";
import { getConstTypes } from "../../api/endpoints/constructionTypes"; // Importamos la acción

export const constructionTypeSlice = createSlice({
  name: "constructionTypes",
  initialState: [], // Cambiar el estado inicial a un arreglo vacío 
  reducers: {
    addConstructionTypes: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeConstructionType: (state, action) => {
      const index = state.findIndex((constructionType) => constructionType.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConstTypes.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { addConstructionTypes, removeConstructionType } = constructionTypeSlice.actions;

export default constructionTypeSlice.reducer;
