import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getRegularizesCoupons = createAsyncThunk(
    "updatedDebt",
    async (filters) => {
      console.log(filters)
      if (filters.group === "") {
        delete filters.group;
      }
      // if (filters.contract_number === "") {
      //   delete filters.contract_number;
      // }
      // Realiza la solicitud al backend con los filtros combinados
      const response = await apiClient.get("regularizes_plans", {
        params: filters,
      });
  
      return response.data;
    }
  );