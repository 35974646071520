import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import Layout from "./components/Layout";
import Login from "./components/Login";
import ABMUsers from "./components/ABMUsers";
import ABMProfiles from "./components/ABMProfiles";
import ABMBranches from "./components/ABMBranches";
import ABMSubscribers from "./components/ABMSuscribers";
import Plans from "./components/Plans";
import Contracts from "./components/Contracts";
import LifeInsuranceExport from "./components/LifeInsuranceExport";
import LifeInsuranceImport from "./components/LifeInsuranceImport";
import FireInsuranceExport from "./components/FireInsuranceExport";
import LegalEntities from "./components/LegalEntities";
import GenerateCoupons from "./components/GenerateCoupons";
import Coupons from "./components/Coupons";
import Payments from "./components/Payments";
import Home from "./components/Home";
import ProtectedRoute from "./common/ProtectedRoute";
import "./assets/styles/App.css";
import RegisterPayments from "./components/RegisterPayment";
import ExternalPayment from "./components/ExternalPayment";
import UpdateDebt from "./components/UpdateDebt";
import RegularizePlan from "./components/RegularizePlan";
import CouponsMails from "./components/CouponsMails";

import { useSelector, useDispatch } from "react-redux";
import { clearToken } from "./features/auth/authSlice";

import { getPlans } from "./api/endpoints/plans";
import { getGroups } from "./api/endpoints/groups";
import { getPaymentMethods } from "./api/endpoints/paymentMethods";
import { getPreAdjudicationReasons } from "./api/endpoints/preAdjudicationReasons";
import { getReleaseReasons } from "./api/endpoints/release_reasons";
import { getRecLegalEntities } from "./api/endpoints/legalEntities";
import { getSalePoints } from "./api/endpoints/salePoints";
import { useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { getBranches } from "./api/endpoints/branches";
import CouponsDeferred from "./components/CouponsDeferred";
import GenerationControl from "./components/GenerationControl";

function App() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => !!state.auth.token);
  const expirationTime = useSelector((state) => state.auth.expirationTime);
  const dispatch = useDispatch();
  const [showExpirationAlert, setShowExpirationAlert] = useState(false);

  // Definimos handleLogout usando useCallback
  const handleLogout = useCallback(() => {
    dispatch(clearToken());
    navigate("/");
  }, [dispatch, navigate]);

  // useEffect para manejar el cierre de sesión cuando el token expire
  useEffect(() => {
    if (isLoggedIn && expirationTime - Date.now() <= 0) {
      handleLogout();
    }
  }, [isLoggedIn, expirationTime, handleLogout]);

  // useEffect para cargar datos y establecer el temporizador para el cierre de sesión
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getPlans());
      dispatch(getGroups());
      dispatch(getBranches());
      dispatch(getPaymentMethods());
      dispatch(getSalePoints());
      dispatch(getPreAdjudicationReasons());
      dispatch(getReleaseReasons());
      dispatch(getRecLegalEntities());

      const timeToExpiration = expirationTime - Date.now();
      const alertTimer = setTimeout(() => {
        setShowExpirationAlert(true);
      }, timeToExpiration - 60000); // Se activa 1 minuto antes de expirar

      const tokenTimer = setTimeout(handleLogout, timeToExpiration);

      return () => {
        clearTimeout(alertTimer);
        clearTimeout(tokenTimer);
      };
    }
  }, [dispatch, isLoggedIn, expirationTime, handleLogout]);

  return (
    <>
      <Dialog
        open={showExpirationAlert}
        onClose={() => setShowExpirationAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tu sesión está a punto de expirar en un minuto.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowExpirationAlert(false)} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      {isLoggedIn ? (
        <Layout>
          <Routes>
            <Route
              path="/entidades/personas-juridicas"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <LegalEntities />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contratos"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Contracts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contrato/seguro-vida/exportar"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <LifeInsuranceExport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contrato/seguro-vida/importar"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <LifeInsuranceImport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contrato/seguro-incendio/exportar"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <FireInsuranceExport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/plan/ver-planes/"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Plans />
                </ProtectedRoute>
              }
            />
            <Route
              path="/entidades/suscriptores"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <ABMSubscribers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/estadisticas/cupones/cupones-diferidos"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <CouponsDeferred />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cupon/generar/generar-cupones"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <GenerateCoupons />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cupon/generar/control-generacion"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <GenerationControl />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cupon/cupones"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Coupons />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cupon/cupones/actualizar-deuda"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <UpdateDebt />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cupon/cupones/planes-regulariza"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <RegularizePlan />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cupon/registrar/registrar-cobro"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <RegisterPayments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cupon/registrar/cobros-externos"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <ExternalPayment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cupon/registrar/ver-cobros"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Payments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cupon/impresion/cupones-mail"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <CouponsMails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/administracion/empresa/sucursales"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <ABMBranches />
                </ProtectedRoute>
              }
            />
            <Route
              path="/administracion/usuarios"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <ABMUsers />
                </ProtectedRoute>
              }
            />
            <Route
              path="/administracion/usuarios/perfiles"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <ABMProfiles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/home"
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Home onLogout={handleLogout} />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<Navigate to="/home" replace />} />
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </>
  );
}

export default App;
