import {
  Dialog,
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SelectFormControl,
  SeparatorHrBox,
  HeaderBox,
  AddButton,
  HeadTableCell,
  RowTableCell,
  EditButton,
  CancelButton,
  DeleteButton,
} from "../../assets/styles/tables";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getPeiValues,
  deletePeiValueById,
} from "../../api/endpoints/peiValues";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PeiValueDialogEdit from "../dialog/PeiValueDialogEdit";
import { getFeeById, getFees } from "../../api/endpoints/fees";
import { addPeiValue, clearPeiValue } from "../../features/peiValues/peiValueSlice";
import { useEffect, useState } from "react";

const PeiValueDialog = (props) => {
  const dispatch = useDispatch();
  const { legalEntity } = useSelector((state) => state.legalEntity);
  const [peiValues, setPeiValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditPeiValue, setIsEditPeiValue] = useState(false);
  const {
    openPeiValue,
    handleClosePeiValue,
    openPeiValueEdit,
    setOpenPeiValueEdit,
    selectedPlan,
  } = props;

  useEffect(() => {
    const fetchPeiValues = async () => {
      setIsLoading(true);
      try {
        const peiValuesData = await dispatch(getPeiValues()).unwrap();
        const allFees = await dispatch(getFees()).unwrap();
   
        const peiValuesWithFees = peiValuesData.map((value, index) => {
          const fee = allFees.filter((fee) => fee.uuid === value.uuid_fee);

          return {
            ...value,
            id: index,
            fee: fee[0],
          };
        });

        const filteredPeiValues = peiValuesWithFees.filter(
          (value) => value.uuid_legal_entity === legalEntity.uuid
        );

        setPeiValues(filteredPeiValues);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchPeiValues();
  }, [openPeiValue]);

  const handleDeletePeiValue = async (id, uuid) => {
    try {
      const updatedPeiValues = peiValues.filter((value) => value.id !== id);
      await dispatch(deletePeiValueById(uuid)).unwrap();
      setPeiValues(updatedPeiValues);
    } catch (error) {
      console.error(error);
    }
  };

  const peiValueDialogEditProps = {
    ...props,
    peiValues,
    setPeiValues,
    setIsEditPeiValue,
    isEditPeiValue,
    selectedPlan,
  };

  return (
    <>
      <Dialog open={openPeiValue} onClose={handleClosePeiValue}>
        <FormDialogTitle>
          <AddButton
            variant="contained"
            sx={{ height: "36px", mb: 0.5, mr: 2 }}
          >
            <PostAddIcon
              sx={{ height: "36px", width: "25px" }}
              fontSize="large"
              onClick={() => {
                setOpenPeiValueEdit(true);
                setIsEditPeiValue(false);
                dispatch(clearPeiValue());
              }}
            />
          </AddButton>
          {legalEntity ? legalEntity.legal_business_name : "Entidad Intermedia"}
          , tipos de cuota
        </FormDialogTitle>
        <SeparatorHrBox>
          <hr />
        </SeparatorHrBox>
        <Table>
          <TableHead>
            <TableRow>
              <HeadTableCell
                sx={{ width: "40%", textAlign: "center", fontSize: "15px" }}
              >
                Tipo Cuota
              </HeadTableCell>
              <HeadTableCell
                sx={{ width: "20%", textAlign: "center", fontSize: "15px" }}
              >
                Monto
              </HeadTableCell>
              <HeadTableCell
                sx={{ width: "20%", textAlign: "center", fontSize: "15px" }}
              >
                Porcentaje
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <></>
            ) : (
              <>
                {peiValues.map((value, key) => {
                  return (
                    <TableRow key={key}>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {value.fee.name}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {value.amount}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {value.rate}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        <EditButton
                          variant="contained"
                          onClick={() => {
                            setOpenPeiValueEdit(true);
                            setIsEditPeiValue(true);
                            dispatch(addPeiValue(value));
                          }}
                        >
                          <IconButton size="small">
                            <EditIcon sx={{ color: "whitesmoke" }} />
                          </IconButton>
                        </EditButton>
                        <DeleteButton
                          variant="contained"
                          onClick={() =>
                            handleDeletePeiValue(value.id, value.uuid)
                          }
                        >
                          <IconButton size="small">
                            <DeleteIcon sx={{ color: "whitesmoke" }} />
                          </IconButton>
                        </DeleteButton>
                      </RowTableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
        <CancelButton
          onClick={handleClosePeiValue}
          variant="contained"
          color="tertiary"
          sx={{
            width: "30%",
            backgroundColor: "gray",
            mt: 1,
            mb: 1,
            ml: "35%",
          }}
        >
          Cancelar
        </CancelButton>
      </Dialog>
      <PeiValueDialogEdit {...peiValueDialogEditProps} />
    </>
  );
};

export default PeiValueDialog;
