import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import {
  FormBoxContainer,
  FormBoxLeft,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  FormBoxRightDescCheck
} from "../../../assets/styles/tables";

import LockIcon from '@mui/icons-material/Lock';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Actions from "../../dialog/Actions";


const ABMProfileDialogMobile = ({ openAccess,
  handleClose,
  selectedProfile,
  selectedAccess,
  handleCheckBoxChange,
  handleSaveAccess,
}) => {

  const actionProps = { handleClose, handleSave: handleSaveAccess };

  return (
    <Dialog open={openAccess} onClose={handleClose} fullWidth>
      <FormDialogTitle>
        Accesos para usuario {selectedProfile.name}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box>
          <Box>
            <FormDialogSubtitle sx={{ fontSize: 18 }}>
              <LockIcon sx={{ fontSize: 15 }} />
              <KeyboardDoubleArrowRightIcon sx={{ fontSize: 15 }} />
              &nbsp;Administrador
            </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.logSucCent}
                      onChange={handleCheckBoxChange}
                      name="logSucCent" />}
                    label={"Sucursal Central"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permitir loguearse con Empresa Principal</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
            <hr style={{ border: "0px solid" }} />
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.abmUsers}
                      onChange={handleCheckBoxChange}
                      name="abmUsers" />}
                    label={"ABM Usuarios"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permitir Altas Bajas y Modificaciones en Usuarios</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
            <hr style={{ border: "0px solid" }} />
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.admin}
                      onChange={handleCheckBoxChange}
                      name="admin" />}
                    label={"Administrador"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permitir ver Módulo de Administracion</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
            <hr style={{ border: "0px solid" }} />
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.access}
                      onChange={handleCheckBoxChange}
                      name="access" />}
                    label={"Accesos"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite modificar Accesos y Perfiles</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
            <hr style={{ border: "0px solid" }} />
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.empr}
                      onChange={handleCheckBoxChange}
                      name="empr" />}
                    label={"Empresas"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permitir Altas Bajas y Modificaciones de Empresas y Sucursales</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
            <hr style={{ border: "0px solid" }} />
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.multiSuc}
                      onChange={handleCheckBoxChange}
                      name="multiSuc" />}
                    label={"Multi Sucursal"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite loguearse seleccionando la sucursal en la cual va a operar</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
            <hr style={{ border: "0px solid" }} />
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.stopSession}
                      onChange={handleCheckBoxChange}
                      name="stopSession" />}
                    label={"Terminar Sesión"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite habilitar el stop de session</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
          </Box>
          <SeparatorHrBox>
            <hr />
          </SeparatorHrBox>
          <Box sx={{ marginTop: '30px' }}>
            <FormDialogSubtitle sx={{ fontSize: 18 }}>
              <LockIcon sx={{ fontSize: 15 }} />
              <KeyboardDoubleArrowRightIcon sx={{ fontSize: 15 }} />
              &nbsp;Módulos
            </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.contrato}
                      onChange={handleCheckBoxChange}
                      name="contrato" />}
                    label={"Contrato"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite ver Módulo Contratos</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.cupon}
                      onChange={handleCheckBoxChange}
                      name="cupon" />}
                    label={"Cupón"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite ver Módulo Cupón</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.stats}
                      onChange={handleCheckBoxChange}
                      name="stats" />}
                    label={"Estadísticas"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite ver Módulo Estadísticas</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>

            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.factura}
                      onChange={handleCheckBoxChange}
                      name="factura" />}
                    label={"Factura"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite ver Módulo Facturas</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>

            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.personas}
                      onChange={handleCheckBoxChange}
                      name="personas" />}
                    label={"Personas"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite ver Módulo Personas</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>

            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.plan}
                      onChange={handleCheckBoxChange}
                      name="plan" />}
                    label={"Plan"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite ver Módulo Plan</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>

            <FormBoxContainer>
              <FormBoxLeft>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAccess.suscriptor}
                      onChange={handleCheckBoxChange}
                      name="suscriptor" />}
                    label={"Suscriptor"}
                  />
                </FormGroup>
              </FormBoxLeft>
              <FormBoxRightDescCheck>
                <div>Permite ver Módulo Suscriptor</div>
              </FormBoxRightDescCheck>
            </FormBoxContainer>
          </Box>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  )
}

export default ABMProfileDialogMobile;