const originalScoreCoefficents = [
    {
      id: 1,
      id_plan: 1,
      value: 10.2,
      start_date: "2014-08-19",
      due_date: "2015-08-19",
      created_at: "2014-08-19",
    },
    {
        id: 2,
        id_plan: 1,
        value: 10.6,
        start_date: "2014-08-19",
        due_date: "2015-08-19",
        created_at: "2014-08-19",
    },
    {
        id: 3,
        id_plan: 1,
        value: 10.8,
        start_date: "2014-08-19",
        due_date: "2015-08-19",
        created_at: "2014-08-19",
    },
    {
        id: 4,
        id_plan: 1,
        value: 11.0,
        start_date: "2014-08-19",
        due_date: "2015-08-19",
        created_at: "2014-08-19",
    },
    {
        id: 5,
        id_plan: 1,
        value: 11.2,
        start_date: "2014-08-19",
        due_date: "2015-08-19",
        created_at: "2014-08-19",
    },
    {
        id: 6,
        id_plan: 1,
        value: 11.4,
        start_date: "2014-08-19",
        due_date: "2015-08-19",
        created_at: "2014-08-19",
    }
  ];
  
  export default originalScoreCoefficents