import { createSlice } from '@reduxjs/toolkit';

export const MaritalStatusSlice = createSlice({
  name: 'MaritalStatuses',
  initialState: [], // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addMaritalStatuses: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeMaritalStatus: (state, action) => {
      const index = state.findIndex((maritalstatus) => maritalstatus.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
});

export const { addMaritalStatuses, removeMaritalStatus } = MaritalStatusSlice.actions;

export default MaritalStatusSlice.reducer;
