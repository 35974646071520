import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getProfiles = createAsyncThunk("roles", async () => {
  const response = await apiClient.get("roles");
  return response.data;
});

export const getProfileById = createAsyncThunk("rol/id", async ( id ) => {
  const response = await apiClient.get(`rol/${id}`);
  return response.data;
});

export const deleteProfileById = createAsyncThunk("rol/delete", async ( id ) => {
  const response = await apiClient.delete(`rol/${id}`);
  return response.data;
});

export const addProfile = createAsyncThunk("rol/add", async (rol) => {
  const response = await apiClient.post("roles", rol);
  return response.data;
});

export const updateProfile = createAsyncThunk("rol/update", async ( rol ) => {
  delete rol.deleted_at;
  delete rol.updated_at;
  delete rol.created_at;
  delete rol.is_active;
  delete rol.modules;
  
  const response = await apiClient.put(`rol/${rol.uuid}`, rol);
  return response.data;
});

export const deleteRoleModuleById = createAsyncThunk("rol_module/delete", async ( roleModule ) => {
  const { uuid: roleId, name: moduleId } = roleModule;
  const response = await apiClient.delete(`rol_module/${roleId}/${moduleId}`);
  return response.data;
});

export const addRoleModule = createAsyncThunk("rol_module/add", async ( roleModule ) => {
  const { uuid: roleId, name: moduleId } = roleModule;
  const response = await apiClient.post(`rol_module/${roleId}/${moduleId}`);
  return response.data;
});