import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";
import { setFeeStructures } from "../../features/feeStructures/feeStructureSlice";

export const getFeeStructures = createAsyncThunk("feeStructures", async () => {
  const response = await apiClient.get("fee_structures");
  return response.data;
});

export const getFeeStructureById = createAsyncThunk(
  "feeStructure/id",
  async (id) => {
    const response = await apiClient.get(`fee_structure/${id}`);
    return response.data;
  }
);

export const deleteFeeStructureById = createAsyncThunk(
  "feeStructure/delete",
  async (id, { getState, dispatch }) => {
    const response = await apiClient.delete(`fee_structure/${id}`);
    const { data: currentFeeStructures } = getState().feeStructures;
    const updatedFeeStructures = currentFeeStructures.filter(
      (type) => type.uuid !== id
    );
    dispatch(setFeeStructures(updatedFeeStructures));
    return response.data;
  }
);

export const addFeeStructure = createAsyncThunk(
  "feeStructures/add",
  async (feeStructure, { dispatch, getState }) => {
    const response = await apiClient.post("fee_structures", feeStructure);
    const { data: currentFeeStructures } = getState().feeStructures;
    const newFeeStructure = {
      ...feeStructure,
      id: currentFeeStructures.length + 1,
      uuid: response.data.uuid,  // asegúrate de que 'response.data.uuid' sea correcto
    };
    dispatch(setFeeStructures([...currentFeeStructures, newFeeStructure]));
    return response.data;
  }
);

export const updateFeeStructureById = createAsyncThunk(
  "feeStructure/id",
  async ({ id, data }, { dispatch, getState }) => {
    const response = await apiClient.put(`fee_structure/${id}`, data);
    const { data: currentFeeStructures } = getState().feeStructures;
    const updatedFeeStructures = currentFeeStructures.map((type) =>
      type.uuid === id ? { ...type, ...data } : type
    );
    dispatch(setFeeStructures(updatedFeeStructures));
    return response.data;
  }
);
