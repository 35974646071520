import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

import TableHeaderMobile from "../components/mobile/tableHeader/TableHeaderMobile";
import TableHeader from "../components/table/TableHeader";

import LegalEntitiesTable from "../components/table/LegalEntitiesTable";
import LegalEntityDialog from "../components/dialog/LegalEntityDialog";

import { useDispatch } from "react-redux";
import Loading from "./Loading";

import {
  getLegalEntities,
  getLegalEntityById,
  deleteLegalEntityById,
  addLegalEntity,
  updateLegalEntityById
} from "../api/endpoints/legalEntities";

import { getCountries } from "../api/endpoints/countries";
import { getProvinces } from "../api/endpoints/provinces";
import { getDepartments } from "../api/endpoints/departments";
import { getDistricts } from "../api/endpoints/districts";

import { addCountries } from "../features/countries/countrySlice"
import { addProvinces } from "../features/provinces/provinceSlice"
import { addDepartments } from "../features/departments/departmentSlice"
import { addDistricts } from "../features/districts/districtSlice"

const LegalEntitiesForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [save, setSave] = useState(true);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [legalEntities, setLegalEntities] = useState([]);
  const [filteredLegalEntities, setFilteredLegalEntities] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState({});

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  useEffect(() => {
    const fetchLegalEntities = async () => {
      setIsLoading(true);
      try {
        const legalEntitiesData = await dispatch(getLegalEntities()).unwrap();
        const countriesData = await dispatch(getCountries()).unwrap();
        const provincesData = await dispatch(getProvinces()).unwrap();
        const departmentsData = await dispatch(getDepartments()).unwrap();
        const districtsData = await dispatch(getDistricts()).unwrap();

        await dispatch(addCountries(countriesData));
        await dispatch(addProvinces(provincesData));
        await dispatch(addDepartments(departmentsData));
        await dispatch(addDistricts(districtsData));

        setLegalEntities(legalEntitiesData);
        setFilteredLegalEntities(legalEntitiesData);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchLegalEntities();
  }, [save]);

  const handleSearch = async (searchText) => {

    const legalEntitiesData = await dispatch(getLegalEntities()).unwrap();

    if (searchText === "") {
      setFilteredLegalEntities(legalEntitiesData);
    } else {
      const filteredLegalEntities = legalEntitiesData.filter((legalEntity) =>
        legalEntity.company.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredLegalEntities(filteredLegalEntities);
    }
    setPage(1);
  };

  const getCurrentPageItems = () => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredLegalEntities.slice(startIndex, endIndex);
  };

  const handleClickOpen = () => {
    setSelectedLegalEntity({
      id: null,
      name: "",
      phone: "",
      email: "",
      role: "",
    });
    setOpen(true);
    setEditMode(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedLegalEntity({ ...selectedLegalEntity, [name]: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedLegalEntity({ ...selectedLegalEntity, [name]: value });
  };

  const handleSave = async () => {
    if (editMode) {

      await dispatch(updateLegalEntityById(selectedLegalEntity));
      setEditMode(false);
      setSave(!save)
    } else {

      await dispatch(addLegalEntity(selectedLegalEntity));
      setEditMode(false);
      setSave(!save)
    }
    handleClose();
  };

  const handleEdit = (legalEntity) => {
    setSelectedLegalEntity(legalEntity);
    setEditMode(true);
    setOpen(true);
  };

  const handleDelete = async (uuid) => {

    dispatch(deleteLegalEntityById(uuid));

    setSave(!save)

  };

  const props = {
    legalEntities: filteredLegalEntities,
    handleEdit,
    handleDelete,
    getCurrentPageItems,
    ITEMS_PER_PAGE,
    page,
    setPage
  };

  const name = 'ADMINISTRACIÓN DE PERSONAS JURÍDICAS'
  const tableHeaderProps = {
    handleClickOpen,
    handleSearch,
    name
  }

  const dialogProps = {
    open,
    handleClose,
    editMode,
    selectedLegalEntity,
    handleInputChange,
    handleSelectChange,
    handleSave,
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isMobile ? (<TableHeaderMobile {...tableHeaderProps} />)
        : (<TableHeader {...tableHeaderProps} />)}

      {isMobile ? (<LegalEntitiesTable {...props} />)
        : (<LegalEntitiesTable {...props} />)}

      {isMobile ? (<LegalEntityDialog {...dialogProps} />)
        : (<LegalEntityDialog {...dialogProps} />)}
    </>
  );
};

export default LegalEntitiesForm;
