import React from "react";
import RegisterPaymentForm from "../common/RegisterPaymentForm";
import { Box } from "@mui/material";

const RegisterPayments = () => {
  return (
    <Box mt={12}>
      <RegisterPaymentForm/>
    </Box>
  );
};

export default RegisterPayments;
