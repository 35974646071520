import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getNationalities = createAsyncThunk("nationalities", async () => {
  const response = await apiClient.get("nationalities");
  return response.data;
});

export const getNationalityById = createAsyncThunk("nationality/id", async ( id ) => {
  const response = await apiClient.get(`nationality/${id}`);
  return response.data;
});

export const deleteNationalityById = createAsyncThunk("nationality/delete", async ( id ) => {
  const response = await apiClient.delete(`nationality/${id}`);
  return response.data;
});

export const addNationality = createAsyncThunk("nationality/add", async (nationality) => {
  const response = await apiClient.post("nationalities", nationality);
  return response.data;
});

export const updateNationality = createAsyncThunk("nationality/update", async ( nationality ) => {
  delete nationality.deleted_at;
  delete nationality.updated_at;
  delete nationality.created_at;
  delete nationality.is_active;
  delete nationality.modules;
  
  const response = await apiClient.put(`nationality/${nationality.uuid}`, nationality);
  return response.data;
});