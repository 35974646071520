import React from "react";
import ExternalPaymentForm from "../common/ExternalPaymentForm";
import { Box } from "@mui/material";

const ExternalPayment = () => {
  return (
    <Box mt={12}>
      <ExternalPaymentForm />
    </Box>
  );
};

export default ExternalPayment;
