import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getReleaseReasons = createAsyncThunk("release_reasons", async () => {
  const response = await apiClient.get("release_reasons");
  return response.data;
});

export const getReleaseReasonById = createAsyncThunk("release_reason/id", async ( id ) => {
  const response = await apiClient.get(`release_reason/${id}`);
  return response.data;
});

export const deleteReleaseReasonById = createAsyncThunk("release_reason/delete", async ( id ) => {
  const response = await apiClient.delete(`release_reason/${id}`);
  return response.data;
});

export const addReleaseReason = createAsyncThunk("release_reason/add", async (release_reason) => {
  delete release_reason.deleted_at;
  delete release_reason.updated_at;
  delete release_reason.created_at;
  delete release_reason.is_active;

  const response = await apiClient.post("release_reasons", release_reason);
  return response.data;
});

export const updateReleaseReason = createAsyncThunk("release_reason/update", async ( release_reason ) => {
  delete release_reason.deleted_at;
  delete release_reason.updated_at;
  delete release_reason.created_at;
  delete release_reason.is_active;
  
  const response = await apiClient.put(`release_reason/${release_reason.uuid}`, release_reason);
  return response.data;
});