import { createSlice } from '@reduxjs/toolkit';
import { getFeeTypes } from '../../api/endpoints/feeTypes'; // Asegúrate de usar la ruta correcta a tu archivo thunk

export const feeTypeSlice = createSlice({
  name: 'feeTypes',
  initialState: {
    feeTypes: [],
    status: 'idle', // Se añade una nueva propiedad para rastrear el estado de carga
    error: null, // Se añade una nueva propiedad para manejar posibles errores
  },
  reducers: {
    clearFeeTypes: state => {
      state.feeTypes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeeTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFeeTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.feeTypes = action.payload;
      })
      .addCase(getFeeTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { clearFeeTypes } = feeTypeSlice.actions;

export default feeTypeSlice.reducer;
