import React from "react";
import { Box, Table, TableBody, Typography, IconButton } from "@mui/material";
import {
  CustomTableContainer,
  CustomTableRow,
  CustomTableCell,
  CustomButtonContainer,
  CustomButton,
} from "../../../assets/styles/tablesMobile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const UsersTableMobile = (props) => {
  const { users, handleEdit, handleDelete } = props;

  return (
    <CustomTableContainer>
      <Table>
        <TableBody>
          {users.map((user) => (
            <CustomTableRow key={user.id}>
              <CustomTableCell sx={{ border: "transparent" }}>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {user.names.split(' ')[0]}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Usuario
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {user.names}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Nombre
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {user.telephone}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Teléfono
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {user.email}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Email
                  </Typography>
                </CustomTableCell>
                <CustomTableCell sx={{ borderBottom: "transparent" }}>
                  <Typography variant="subtitle1" align="center">
                    {user.role.name}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Rol
                  </Typography>
                </CustomTableCell>
              </CustomTableCell>
              <CustomButtonContainer>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CustomButton
                    variant="contained"
                    onClick={() => handleEdit(user)}
                    sx={{ marginBottom: "10px" }}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    color="tertiary"
                    onClick={() => handleDelete(user.id, user.uuid)}
                  >
                    <IconButton size="small">
                      <DeleteIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </CustomButton>
                </Box>
              </CustomButtonContainer>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default UsersTableMobile;
