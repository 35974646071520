import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getIVAConditions = createAsyncThunk("ivaConditions", async () => {
  const response = await apiClient.get("iva_conditions");
  return response.data;
});

export const getIVAConditionById = createAsyncThunk("ivaCondition/id", async ( id ) => {
  const response = await apiClient.get(`iva_condition/${id}`);
  return response.data;
});

export const deleteIVAConditionById = createAsyncThunk("ivaCondition/delete", async ( id ) => {
  const response = await apiClient.delete(`iva_condition/${id}`);
  return response.data;
});

export const addIVACondition = createAsyncThunk("ivaCondition/add", async (ivaCondition) => {
  const response = await apiClient.post("iva_conditions", ivaCondition);
  return response.data;
});

export const updateIVACondition = createAsyncThunk("ivaCondition/update", async ( ivaCondition ) => {
  delete ivaCondition.deleted_at;
  delete ivaCondition.updated_at;
  delete ivaCondition.created_at;
  delete ivaCondition.is_active;
  delete ivaCondition.modules;
  
  const response = await apiClient.put(`iva_condition/${ivaCondition.uuid}`, ivaCondition);
  return response.data;
});