import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  HeadTableCell,
  RowTableCell,
  EditButton,
  DeleteButton,
  PaginationBox,
  HeaderBox,
  AddButton,
} from "../../assets/styles/tables";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from '@mui/icons-material/PostAdd';
import SearchBar from "../../common/SearchBar";
import originalScoreCoefficents from "../../data/tableContentScoreCoefficents";

const ScoreCoefficentTab = (props) => {
  const ITEMS_PER_PAGE = 3;
  const {
    getCurrentScoreCoefficentPageItems, handleEditScoreCoefficent, 
    handleDeleteScoreCoefficent, pageScoreCoefficent, scoreCoefficents, 
    setPageScoreCoefficent, setSelectedScoreCoefficent,
    setOpenScoreCoefficent, setFilteredScoreCoefficents
  } = props;

  const handleClickOpen = () => {  
    setSelectedScoreCoefficent({ });
    
    setOpenScoreCoefficent(true);
  };

  const handleSearch = (searchText) => {
    if (searchText === "") {
      setFilteredScoreCoefficents(originalScoreCoefficents);
    } else {
      const filteredScoreCoefficents = originalScoreCoefficents.filter((scoreCoefficent) =>
        scoreCoefficent.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredScoreCoefficents(filteredScoreCoefficents);
    }
    setPageScoreCoefficent(1);
  };

  return (
<Box sx={{ mt: 2 }}>
  <br />
    <HeaderBox sx={{marginLeft:0}}>
      <AddButton variant="contained" onClick={handleClickOpen}>
        <PostAddIcon fontSize="large"/>
      </AddButton>
      <SearchBar onSearch={handleSearch} />
    </HeaderBox>
    <br />
    <Table>
        <TableHead>
        <TableRow>
            <HeadTableCell sx={{ width: "10%", textAlign: "center" }}>Valor</HeadTableCell>
            <HeadTableCell sx={{ width: "25%", textAlign: "center"  }}>Fecha Inicio</HeadTableCell>
            <HeadTableCell sx={{ width: "25%", textAlign: "center"  }}>Fecha Fin</HeadTableCell>
            <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>Acciones</HeadTableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {getCurrentScoreCoefficentPageItems().map((scoreCoefficent) => (
        <TableRow key={scoreCoefficent.id}>
            <RowTableCell sx={{ textAlign: "center" }}>
            {scoreCoefficent.value}
            </RowTableCell>
            <RowTableCell sx={{ textAlign: "center" }}>
            {scoreCoefficent.start_date}
            </RowTableCell>
            <RowTableCell sx={{ textAlign: "center" }}>
            {scoreCoefficent.due_date}
            </RowTableCell>
            <RowTableCell sx={{ textAlign: "center" }}>
            <EditButton
                variant="contained"
                onClick={() => handleEditScoreCoefficent(scoreCoefficent)}
            >
                <IconButton size="small">
                <EditIcon sx={{ color: "whitesmoke" }} />
                </IconButton>
            </EditButton>
            <DeleteButton
                variant="contained"
                onClick={() => handleDeleteScoreCoefficent(scoreCoefficent.id, scoreCoefficent.uuid)}
            >
                <IconButton size="small">
                <DeleteIcon sx={{ color: "whitesmoke" }} />
                </IconButton>
            </DeleteButton>
            </RowTableCell>
        </TableRow>
        ))}
        </TableBody>
    </Table>
    <PaginationBox>
      <Pagination
        count={Math.ceil(scoreCoefficents.length / ITEMS_PER_PAGE)}
        shape="rounded"
        page={pageScoreCoefficent}
        onChange={(event, value) => setPageScoreCoefficent(value)}
      />
    </PaginationBox>
    </Box>
)
  
};

export default ScoreCoefficentTab;