import React from "react";
import ABMBranchesForm from "../common/ABMBranchesForm";
import { Box } from "@mui/material";

const ABMBranches = () => {
  return (
    <Box mt={12}>
      <ABMBranchesForm />
    </Box>
  );
};

export default ABMBranches;