import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControl
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
  FormTextareaAutosize,
  FormBoxLeft,
  FormBoxCenter,
  FormBoxRight,
  FormDivider
} from "../../assets/styles/tables";
import Actions from "./Actions";
import { useSelector } from "react-redux";


const LegalEntityDialog = (props) => {

  const countries = useSelector((state) => state.countries);
  const provinces = useSelector((state) => state.provinces);
  const departments = useSelector((state) => state.departments);
  const districts = useSelector((state) => state.districts);

  const [district, setDistrict] = useState();
  const [department, setDepartment] = useState();
  const [province, setProvince] = useState();
  const [country, setCountry] = useState();

  const { open, handleClose, editMode,
    selectedLegalEntity, handleInputChange,
    handleSelectChange, handleSave } = props;

  const actionProps = { handleClose, handleSave };

  // if (!editMode) {
  //   selectedLegalEntity.is_intermediate_entity = "0";
  // }

  useEffect(() => {

    const selectedDistrict = districts.find(dist => dist.uuid === selectedLegalEntity.uuid_district);
    const selectedDepartment = departments.find(dep => dep.uuid === selectedDistrict?.uuid_department);
    const selectedProvince = provinces.find(prov => prov.uuid === selectedDepartment?.uuid_province);
    const selectedCountry = countries.find(country => country.uuid === selectedProvince?.uuid_country);

    setDistrict(selectedDistrict);
    setDepartment(selectedDepartment);
    setProvince(selectedProvince);
    setCountry(selectedCountry);
  }, [selectedLegalEntity]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <FormDialogTitle>
        {editMode ? "Editar Persona Jurídica" : "Agregar Persona Jurídica"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "flex" }}>
        <Box>
          <Box>
            <FormDialogSubtitle> Datos generales </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
                <TextField
                  margin="dense"
                  name="legal_business_name"
                  label="Razón social"
                  type="text"
                  fullWidth
                  value={selectedLegalEntity.legal_business_name}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="libb"
                  label="IIBB"
                  type="text"
                  fullWidth
                  value={selectedLegalEntity.libb}
                  onChange={handleInputChange}
                />
              </FormBoxLeft>
              <FormBoxCenter />
              <FormBoxRight>
                <TextField
                  margin="dense"
                  name="cuit"
                  label="CUIT"
                  type="text"
                  fullWidth
                  value={selectedLegalEntity.cuit}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="company"
                  label="Empresa"
                  type="text"
                  fullWidth
                  value={selectedLegalEntity.company}
                  onChange={handleInputChange}
                />
              </FormBoxRight>
            </FormBoxContainer>
          </Box>
        </Box>

        <FormDivider orientation="vertical" sx={{ height: "300px" }} />
        <Box>
          <Box>
            <FormDialogSubtitle> Datos de contacto </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
                <TextField
                  margin="dense"
                  name="address"
                  label="Dirección"
                  type="text"
                  fullWidth
                  value={selectedLegalEntity.address}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="postal_code"
                  label="CP"
                  type="text"
                  fullWidth
                  value={selectedLegalEntity.postal_code}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="phone_number"
                  label="Teléfono"
                  type="text"
                  fullWidth
                  value={selectedLegalEntity.phone_number}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="email"
                  label="Correo Electrónico"
                  type="text"
                  fullWidth
                  value={selectedLegalEntity.email}
                  onChange={handleInputChange}
                />
              </FormBoxLeft>
              <FormBoxCenter />
              {!editMode ?
                <FormBoxRight>
                  <SelectFormControl sx={{ marginTop: 1 }}>
                    <InputLabel
                      margin="dense"
                      id="uuid_country-label"
                      sx={{ zIndex: 1, backgroundColor: '#fff', padding: '0 10px' }}
                    >
                      País
                    </InputLabel>
                    <Select
                      labelId="uuid_country-label"
                      name="uuid_country"
                      value={selectedLegalEntity.uuid_country || countries[0]?.uuid}
                      onChange={handleSelectChange}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.uuid} value={country.uuid}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel
                      margin="dense"
                      id="uuid_province-label"
                      sx={{ zIndex: 1, backgroundColor: '#fff', padding: '0 10px' }}
                    >
                      Provincia
                    </InputLabel>
                    <Select
                      labelId="uuid_province-label"
                      name="uuid_province"
                      value={selectedLegalEntity.uuid_province || provinces[0]?.uuid}
                      onChange={handleSelectChange}
                      disabled={editMode ? false : !selectedLegalEntity.uuid_country}
                    >
                      {provinces
                        .filter((prov) => prov.uuid_country === selectedLegalEntity.uuid_country)
                        .map((prov) => (
                          <MenuItem key={prov.uuid} value={prov.uuid}>
                            {prov.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel
                      margin="dense"
                      id="uuid_department-label"
                      sx={{ zIndex: 1, backgroundColor: '#fff', padding: '0 10px' }}
                    >
                      Departamento
                    </InputLabel>
                    <Select
                      labelId="uuid_department-label"
                      name="uuid_department"
                      value={selectedLegalEntity.uuid_department || departments[0]?.uuid}
                      onChange={handleSelectChange}
                      disabled={editMode ? false : !selectedLegalEntity.uuid_province}
                    >
                      {departments
                        .filter((dep) => dep.uuid_province === selectedLegalEntity.uuid_province)
                        .map((dep) => (
                          <MenuItem key={dep.uuid} value={dep.uuid}>
                            {dep.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel
                      margin="dense"
                      id="uuid_district-label"
                      sx={{ zIndex: 1, backgroundColor: '#fff', padding: '0 10px' }}
                    >
                      Distrito
                    </InputLabel>
                    <Select
                      labelId="uuid_district-label"
                      name="uuid_district"
                      value={selectedLegalEntity.uuid_district || districts[0]?.uuid}
                      onChange={handleSelectChange}
                      disabled={editMode ? false : !selectedLegalEntity.uuid_department}
                    >
                      {districts
                        .filter((dist) => dist.uuid_department === selectedLegalEntity.uuid_department)
                        .map((dist) => (
                          <MenuItem key={dist.uuid} value={dist.uuid}>
                            {dist.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl>
                </FormBoxRight>
                :
                <FormBoxRight>
                  <SelectFormControl sx={{ marginTop: 1 }}>
                    <InputLabel
                      margin="dense"
                      id="uuid_country-label"
                      sx={{ zIndex: 1, backgroundColor: '#fff', padding: '0 10px' }}
                    >
                      País
                    </InputLabel>
                    <Select
                      labelId="uuid_country-label"
                      name="uuid_country"
                      value={country ? country.uuid : ""}
                      onChange={handleSelectChange}
                      disabled={true}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.uuid} value={country.uuid}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel
                      margin="dense"
                      id="uuid_province-label"
                      sx={{ zIndex: 1, backgroundColor: '#fff', padding: '0 10px' }}
                    >
                      Provincia
                    </InputLabel>
                    <Select
                      labelId="uuid_province-label"
                      name="uuid_province"
                      value={province ? province.uuid : ""}
                      onChange={handleSelectChange}
                      disabled={true}
                    >
                      {provinces
                        .map((prov) => (
                          <MenuItem key={prov.uuid} value={prov.uuid}>
                            {prov.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel
                      margin="dense"
                      id="uuid_department-label"
                      sx={{ zIndex: 1, backgroundColor: '#fff', padding: '0 10px' }}
                    >
                      Departamento
                    </InputLabel>
                    <Select
                      labelId="uuid_department-label"
                      name="uuid_department"
                      value={department ? department.uuid : ""}
                      onChange={handleSelectChange}
                      disabled={true}
                    >
                      {departments
                        .map((dep) => (
                          <MenuItem key={dep.uuid} value={dep.uuid}>
                            {dep.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl>
                  <SelectFormControl>
                    <InputLabel
                      margin="dense"
                      id="uuid_district-label"
                      sx={{ zIndex: 1, backgroundColor: '#fff', padding: '0 10px' }}
                    >
                      Distrito
                    </InputLabel>
                    <Select
                      labelId="uuid_district-label"
                      name="uuid_district"
                      value={district ? district.uuid : ""}
                      onChange={handleSelectChange}
                    >
                      {districts
                        .map((dist) => (
                          <MenuItem key={dist.uuid} value={dist.uuid}>
                            {dist.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl>
                </FormBoxRight>
              }
            </FormBoxContainer>
          </Box>
        </Box>
        <FormDivider orientation="vertical" sx={{ height: "300px" }} />
        <Box>
          <Box>
            <FormDialogSubtitle> Datos adicionales: </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
                <SelectFormControl sx={{ mt: 1 }}>
                  <InputLabel
                    margin="dense"
                    id="is_intermediate_entity-label"
                    htmlFor="is_intermediate_entity"
                    sx={{ zIndex: 1, backgroundColor: '#fff', padding: '0 10px' }}
                  >
                    ¿Entidad intermedia?
                  </InputLabel>
                  <Select
                    labelId="is_intermediate_entity-label"
                    id="is_intermediate_entity"
                    name="is_intermediate_entity"
                    value={selectedLegalEntity.is_intermediate_entity}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Si</MenuItem>
                  </Select>
                </SelectFormControl>
                <TextField
                  margin="dense"
                  sx={{mt:"12px"}}
                  name="contact_information"
                  label="Persona de contacto"
                  type="text"
                  fullWidth
                  value={selectedLegalEntity.contact_information}
                  onChange={handleInputChange}
                />
              </FormBoxLeft>
              <FormBoxCenter />
              <FormBoxRight>
                <TextField
                  margin="dense"
                  name="membership_fee"
                  label="Cuota social"
                  type="number"
                  fullWidth
                  value={selectedLegalEntity.membership_fee}
                  onChange={handleInputChange}
                />
              </FormBoxRight>
            </FormBoxContainer>
          </Box>
          <SeparatorHrBox />

          <FormControl sx={{my:"10px"}} >
            <InputLabel
              margin="dense"
              htmlFor="observations"
              style={{
                position: 'absolute',
                top: '-35px',
                left: '3px',
                backgroundColor: '#fff',
                padding: '0 4px',
                zIndex: 1,
                fontSize: '12px',
              }}
            >
              Observaciones
            </InputLabel>
            <FormTextareaAutosize
              rowsMin={3}
              placeholder="Introduzca una observacion aquí"
              aria-label="Observaciones"
              margin="dense"
              name="observations"
              value={selectedLegalEntity.observations}
              onChange={handleInputChange}
              style={{ height: '122px', borderColor: '#A9A9A9', width:"370px" }}
            />
          </FormControl>

        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  )
}

export default LegalEntityDialog;