import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getSubscribers = createAsyncThunk("subscribers", async () => {
  const response = await apiClient.get("subscribers");
  return response.data;
});

export const getSubscriberById = createAsyncThunk("subscriber/id", async ( id ) => {
  const response = await apiClient.get(`subscriber/${id}`);
  return response.data;
});

export const deleteSubscriberById = createAsyncThunk("subscriber/delete", async ( id ) => {
  const response = await apiClient.delete(`subscriber/${id}`);
  return response.data;
});

export const addSubscriber = createAsyncThunk("subscriber/add", async (subscriber) => {
  delete subscriber.deleted_at;
  delete subscriber.updated_at;
  delete subscriber.created_at;
  delete subscriber.is_active;

  const response = await apiClient.post("subscribers", subscriber);
  return response.data;
});

export const updateSubscriber = createAsyncThunk("subscriber/update", async ( subscriber ) => {
  delete subscriber.deleted_at;
  delete subscriber.updated_at;
  delete subscriber.created_at;
  delete subscriber.is_active;
  
  const response = await apiClient.put(`subscriber/${subscriber.uuid}`, subscriber);
  return response.data;
});