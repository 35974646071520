import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getDebtCoupons,
    updateDebtCoupons,
} from "../api/endpoints/updatedDebt";
import {
    FormDialogTitle,
    GeneralTableBox,
    GeneralTableContainer,
    HeadTableCell,
    PaginationBox,
    RowTableCell,
    SelectFormControl
} from "../assets/styles/tables";

const ITEMS_PER_PAGE = 10; // Establece tu valor predeterminado para items por página
const dateNow = new Date(); // Creating a new date object with the current date and time
const year = dateNow.getFullYear(); // Getting current year from the created Date object
const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
const month = // Setting current Month number from current Date object
  monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
    ? `0${monthWithOffset}`
    : monthWithOffset;
const date =
  dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
    ? `0${dateNow.getUTCDate()}`
    : dateNow.getUTCDate();

const materialDateInput = `${year}-${month}-${date}`;

const formatDate = (dateStr) => {
  const [year, month, day] = dateStr.split(" ")[0].split("-");
  return `${day}/${month}/${year}`;
};

const CouponDetailsModal = ({ open, onClose, item }) => {
  const handlePrint = () => {
    const originalSize = document.body.style.zoom;
    document.body.style.zoom = "70%"; // Disminuye el zoom para ajustar el contenido en la impresión
    window.print();
    document.body.style.zoom = originalSize; // Restablece el zoom original después de la impresión
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          background: "white",
          padding: 3,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: 2,
          width: "90%", // Ancho automático para adaptarse al contenido
          overflow: "hidden", // Oculta el overflow para evitar barras de desplazamiento innecesarias
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">CUOTAS ADEUDADAS</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1">
            ORDEN NÚMERO: {item?.header?.contract_number}
          </Typography>
          <Typography variant="subtitle1">
            SUSCRIPTOR: {item?.header?.subscriber}
          </Typography>
        </Box>
        <GeneralTableContainer
          sx={{
            overflow: "auto",
            maxWidth: "100%",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Numero Cuota</TableCell>
                <TableCell>Fecha 1er Venc.</TableCell>
                <TableCell>Importe 1er</TableCell>
                <TableCell>Fecha 2do Venc.</TableCell>
                <TableCell>Importe 2do </TableCell>
                <TableCell>Fecha 3er Venc.</TableCell>
                <TableCell>Importe 3er</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item?.coupons?.map((coupon, index) => (
                <TableRow key={index}>
                  <TableCell>Cuota: {coupon.fee_number1}</TableCell>
                  <TableCell>{formatDate(coupon.first_due_date)}</TableCell>
                  <TableCell> $ {coupon.first_due_amount}</TableCell>
                  <TableCell>{formatDate(coupon.second_due_date)}</TableCell>
                  <TableCell> $ {coupon.second_due_amount}</TableCell>
                  <TableCell>{formatDate(coupon.third_due_date)}</TableCell>
                  <TableCell> $ {coupon.third_due_amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GeneralTableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 2,
            mr: 2,
          }}
        >
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            Total: ${item?.header?.total}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

const UpdateDebtHeader = ({ isOpen, setIsOpen, filter, setFilter }) => {
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);

  // const [filter, setFilter] = useState({
  //   plan: plans.length > 0 ? plans[0].uuid : "", // Primer elemento de los planes como valor predeterminado
  //   group: "",
  //   order: "",
  // });

  const handleFilter = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box
      sx={{
        mt: 3,
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        padding: "15px",
        boxShadow: "none",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Box
        sx={{
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Cupon | Cupones | Actualizar Deuda
      </Box>
      <Box sx={{ ml: 0, mt: 2 }}>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
          <InputLabel margin="dense" id="trimestre-label">
            Plan
          </InputLabel>
          <Select
            labelId="plan-label"
            id="plan-select"
            value={filter.plan}
            onChange={handleFilter}
            name="plan"
          >
            {plans.map((plan) => (
              <MenuItem key={plan.uuid} value={plan.uuid}>
                {plan.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
          <InputLabel margin="dense" id="group-label">
            Grupo
          </InputLabel>
          <Select
            labelId="group-label"
            id="group-select"
            value={filter.group}
            onChange={handleFilter}
            name="group"
          >
            <MenuItem value="">Todos</MenuItem>
            {groups.map((group) => (
              <MenuItem key={group.uuid} value={group.uuid}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
          <TextField
            label="Orden"
            id="contract_number-input"
            value={filter.contract_number}
            onChange={handleFilter}
            name="contract_number"
          />
        </SelectFormControl>
      </Box>
    </Box>
  );
};

const UpdateDebtForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { plans } = useSelector((state) => state.plans);

  const [filter, setFilter] = useState({
    plan: plans.length > 0 ? plans[0].uuid : "", // Primer elemento de los planes como valor predeterminado
    group: "",
    contract_number: "",
  });
  const [modifiedCoupons, setModifiedCoupons] = useState([]);

  const [secondExpirationDate, setSecondExpirationDate] =
    useState(materialDateInput);

  const handleSecondExpirationDateChange = (e) => {
    console.log(e.target.value, materialDateInput);
    setSecondExpirationDate(e.target.value);
  };

  // Esta función se encargará de llamar al endpoint con los filtros y paginación correspondientes
  const fetchDataWithFilters = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        getDebtCoupons({
          plan: filter.plan,
          group: filter.group,
          contract_number: filter.contract_number,
          page: page,
          per_page: ITEMS_PER_PAGE,
        })
      );
      // console.log(response.payload);
      setData(response.payload.grouped_coupons);
      setTotalPages(response.payload.pages);
      setTotalCount(response.payload.total);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  // Llamamos a fetchDataWithFilters cuando el componente se monta y cuando cambian los filtros o el número de página
  useEffect(() => {
    fetchDataWithFilters();
  }, [filter, page]);

  const handleOpenDialog = (item) => {
    console.log(item);
    setSelectedItem(item);
    setSecondExpirationDate(materialDateInput);
    setOpenDialog(true);
    setModifiedCoupons([]);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDetail = (item) => {
    setSelectedItem(item);
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleThirdDueAmountChange = (index, newValue) => {
    const newModifiedCoupons = [...modifiedCoupons];

    // Verificar si ya existe un objeto en esa posición.
    if (!newModifiedCoupons[index]) {
      newModifiedCoupons[index] = { ...selectedItem.coupons[index] };
    }

    newModifiedCoupons[index].third_due_amount = newValue;
    console.log(index, newValue, newModifiedCoupons);
    setModifiedCoupons(newModifiedCoupons);
  };

  const handleThirdDueDateChange = (index, newDate) => {
    const newModifiedCoupons = [...modifiedCoupons];

    // Verificar si ya existe un objeto en esa posición.
    if (!newModifiedCoupons[index]) {
      newModifiedCoupons[index] = { ...selectedItem.coupons[index] };
    }

    newModifiedCoupons[index].third_due_date = newDate;
    console.log(index, newDate, newModifiedCoupons);
    setModifiedCoupons(newModifiedCoupons);
  };

  const [payChecks, setPayChecks] = useState([]);
  const [regularizeChecks, setRegularizeChecks] = useState([]);

  // Cuando se seleccionan los cupones, inicializa los estados de los checks
  useEffect(() => {
    if (selectedItem?.coupons) {
      setPayChecks(new Array(selectedItem.coupons.length).fill(false));
      setRegularizeChecks(new Array(selectedItem.coupons.length).fill(false));
    }
  }, [selectedItem]);

  const handlePayCheckChange = (index) => {
    const updatedChecks = [...payChecks];
    updatedChecks[index] = !updatedChecks[index];
    setPayChecks(updatedChecks);

    // Si se marca un check de 'Pagar', desactiva todos los checks de 'Regularizar'
    if (updatedChecks[index]) {
      setRegularizeChecks(new Array(regularizeChecks.length).fill(false));
    }
  };

  const handleRegularizeCheckChange = (index) => {
    const updatedChecks = [...regularizeChecks];
    updatedChecks[index] = !updatedChecks[index];
    setRegularizeChecks(updatedChecks);

    // Si se marca un check de 'Regularizar', desactiva todos los checks de 'Pagar'
    if (updatedChecks[index]) {
      setPayChecks(new Array(payChecks.length).fill(false));
    }
  };

  const getModifiedCoupons = () => {
    const modifiedCoupons = [];
    payChecks.forEach((checked, index) => {
      if (checked || regularizeChecks[index]) {
        const coupon = {
          ...selectedItem.coupons[index],
          action: checked ? "Pagar" : "Regularizar",
        };
        modifiedCoupons.push(coupon);
      }
    });
    return modifiedCoupons;
  };

  const handleSave = () => {
    const modifiedCoupons = getModifiedCoupons();
    let actionType = "";

    // Determina si la acción es 'pago' o 'regulariza'
    if (payChecks.includes(true)) {
      actionType = "pago";
    } else if (regularizeChecks.includes(true)) {
      actionType = "regulariza";
    }

    // Prepara los datos para enviar
    const dataToSend = {
      coupons: modifiedCoupons.map((coupon, index) => {
        if (actionType === "regulariza" && regularizeChecks[index]) {
          return {
            uuid: coupon.uuid,
            third_due_amount: coupon.third_due_amount - coupon.first_due_amount, // Aquí ajustamos el valor del interés
            third_due_date: secondExpirationDate, // Utilizamos la fecha del campo secondExpirationDate
          };
        } else {
          return {
            uuid: coupon.uuid,
            third_due_amount: coupon.third_due_amount,
            third_due_date: coupon.third_due_date,
          };
        }
      }),
      tipo: actionType,
    };

    // Envía los datos al backend
    const response = dispatch(updateDebtCoupons(dataToSend));
    console.log("Datos enviados:", response);
    setOpenDialog(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mr: 15,
        ml: 5,
      }}
    >
      <UpdateDebtHeader filter={filter} setFilter={setFilter} />
      <GeneralTableBox
        sx={{
          m: 0,
          borderRadius: "0px",
          boxShadow: "none",
          "*::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "*::-webkit-scrollbar-track": {
            background: "inherit",
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "20px",
          },
          "@media (min-width: 900px) and (max-width: 1536px)": {
            width: "100%",
          },
        }}
      >
        <GeneralTableContainer
          sx={{
            maxHeight: "45em",
            overflow: "auto",
          }}
        >
          <Table aria-label="plan table">
            <TableHead>
              <TableRow>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Orden
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Suscriptores
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Cuotas con Deuda
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Ajustes con Deuda
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Total
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>
                  Editar
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center" }}>Ver</HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, key) => (
                <TableRow key={key}>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.contract_number}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.subscriber}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.debt_installment}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.adequacy_installment}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {item.header.total}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    <IconButton onClick={() => handleOpenDialog(item)}>
                      <EditIcon sx={{ color: "#5e894f" }} />
                    </IconButton>
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    <IconButton onClick={() => handleOpenDetail(item)}>
                      <DocumentScannerIcon sx={{ color: "#5e894f" }} />
                    </IconButton>
                  </RowTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GeneralTableContainer>
      </GeneralTableBox>
      <PaginationBox
        sx={{
          backgroundColor: theme.palette.background.formTables,
          mt: 0,
          borderBottomRightRadius: "20px",
          borderBottomLeftRadius: "20px",
        }}
      >
        <Pagination
          sx={{ mr: 10 }}
          count={totalPages} // Usamos totalPages directamente
          shape="rounded"
          page={page}
          onChange={(event, value) => setPage(value)}
        />
        <Box
          sx={{
            ml: 10,
            fontSize: "15px",
          }}
        >
          Registros: {totalCount}
        </Box>
      </PaginationBox>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <FormDialogTitle
            sx={{
              mt: 0,
              mb: 1,
            }}
          >
            DEUDAS A LA FECHA
          </FormDialogTitle>
          <Box mb={3} display="flex" justifyContent="space-around">
            <Box>
              <Typography variant="body1">
                Contrato: {selectedItem?.header.contract_number}
              </Typography>
              <Typography variant="body1">
                Plan:{" "}
                {
                  plans.find((plan) => plan.uuid === selectedItem?.header.plan)
                    ?.name
                }
              </Typography>
              <Typography variant="body1">
                Suscriptor/s: {selectedItem?.header.subscriber}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <TextField
                name="secondExpirationDate"
                type="date"
                size="small"
                value={secondExpirationDate}
                onChange={handleSecondExpirationDateChange}
                placeholder="dd/mm/aaaa"
              />
              <IconButton>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Box>

          <Divider />

          {/* Títulos para las columnas Pagar y Regularizar */}
          <Box
            display="flex"
            justifyContent="space-around"
            mt={2}
            mb={2}
            style={{ backgroundColor: "#e0e0e0" }}
          >
            <Typography style={{ width: "60%" }} align="center"></Typography>
            <Typography style={{ width: "7%" }} align="center">
              Pagar
            </Typography>
            <Typography style={{ width: "15%" }} align="center"></Typography>
            <Typography style={{ width: "12%" }} align="center"></Typography>
            <Typography style={{ width: "12%" }} align="center"></Typography>
            <Typography style={{ width: "10%" }} align="center">
              Regularizar
            </Typography>
            <Typography style={{ width: "12%" }} align="center"></Typography>
          </Box>

          {selectedItem?.coupons.map((coupon, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              mt={2}
              mb={2}
            >
              <Typography variant="body1" style={{ width: "7%" }}>
                Cuota: {coupon.fee_number1}
              </Typography>
              <TextField
                label="$ 1er Venc."
                size="small"
                InputLabelProps={{ shrink: true }}
                value={coupon.first_due_amount}
                style={{ width: "12%" }}
                disabled
              />
              <TextField
                label="Fecha 1er Venc."
                type="date"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={coupon.first_due_date.split(" ")[0]}
                style={{ width: "12%" }}
                disabled
              />
              <TextField
                label="$ 3er Venc."
                size="small"
                InputLabelProps={{ shrink: true }}
                value={
                  modifiedCoupons[index]?.third_due_amount ||
                  coupon.third_due_amount
                }
                onChange={(e) =>
                  handleThirdDueAmountChange(index, e.target.value)
                }
                style={{ width: "12%" }}
              />

              <Checkbox
                checked={payChecks[index]}
                onChange={() => handlePayCheckChange(index)}
                size="small"
              />
              <TextField
                label="Fecha 3er Venc."
                type="date"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={
                  modifiedCoupons[index]?.third_due_date ||
                  coupon.third_due_date.split(" ")[0]
                }
                onChange={(e) =>
                  handleThirdDueDateChange(index, e.target.value)
                }
                style={{ width: "12%" }}
              />
              <TextField
                label="$ Interes"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={coupon.third_due_amount - coupon.first_due_amount}
                style={{ width: "12%" }}
              />
              <Checkbox
                checked={regularizeChecks[index]}
                onChange={() => handleRegularizeCheckChange(index)}
                size="small"
              />
            </Box>
          ))}

          <Divider />

          <Box
            sx={{
              mt: 2,
            }}
            display="flex"
            justifyContent="space-around"
          >
            <Typography variant="h6">TOTALES:</Typography>
            <Box display="flex" flexDirection="column">
              <TextField
                label="$"
                size="small"
                InputLabelProps={{ shrink: true }}
                style={{ width: "70%", marginTop: "10px" }}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
      <CouponDetailsModal
        open={openDetail}
        onClose={handleCloseDetail}
        item={selectedItem ? selectedItem : []}
      />
    </Box>
  );
};
export default UpdateDebtForm;
