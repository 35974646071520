import React from "react";
import LoginForm from "../common/LoginForm";
import { Box } from "@mui/material";

const Login = (props) => {

  return (
    <Box >
      <LoginForm/>
    </Box>
  );
};

export default Login;
