import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getPreAdjudicationReasons = createAsyncThunk("preAdjudicationReasons", async () => {
  const response = await apiClient.get("pre_adjudication_reasons");
  return response.data;
});

export const getPreAdjudicationReasonById = createAsyncThunk("preAdjudicationReason/id", async (id) => {
  const response = await apiClient.get(`pre_adjudication_reason/${id}`);
  return response.data;
});

export const deletePreAdjudicationReasonById = createAsyncThunk("preAdjudicationReason/delete", async (id) => {
  const response = await apiClient.delete(`pre_adjudication_reason/${id}`);
  return response.data;
});

export const addPreAdjudicationReason = createAsyncThunk("preAdjudicationReasons/add", async (preAdjudicationReason) => {
  const response = await apiClient.post("pre_adjudication_reasons", preAdjudicationReason);
  return response.data;
});

export const updatePreAdjudicationReasonById = createAsyncThunk(
  "preAdjudicationReason/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`pre_adjudication_reason/${id}`, data);
    return response.data;
  }
);