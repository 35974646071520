import React from "react";
import ABMSubscriberForm from "../common/ABMSubscriberForm";
import { Box } from "@mui/material";

const ABMSubscribers = () => {
  return (
    <Box mt={12}>
      <ABMSubscriberForm />
    </Box>
  );
};

export default ABMSubscribers;