import { createSlice } from "@reduxjs/toolkit";
import { getGroups } from "../../api/endpoints/groups"; // Importamos la acción

export const groupSlice = createSlice({
  name: "groups",
  initialState: {
    groups: [],
  }, // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addGroups: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeGroup: (state, action) => {
      const index = state.findIndex((group) => group.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.groups = action.payload;
    });
  },
});

export const { addGroups, removeGroup } = groupSlice.actions;

export default groupSlice.reducer;
