import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getPaymentMethods = createAsyncThunk(
  "paymentMethods",
  async () => {
    const response = await apiClient.get("payment_methods");
    return response.data;
  }
);

export const getPaymentMethodById = createAsyncThunk(
  "paymentMethod/id",
  async (id) => {
    const response = await apiClient.get(`payment_method/${id}`);
    return response.data;
  }
);

export const deletePaymentMethodById = createAsyncThunk(
  "paymentMethod/delete",
  async (id) => {
    const response = await apiClient.delete(`payment_method/${id}`);
    return response.data;
  }
);

export const addPaymentMethod = createAsyncThunk(
  "paymentMethods/add",
  async (paymentMethod) => {
    console.log("llega aca", paymentMethod);
    const response = await apiClient.post("payment_methods", paymentMethod);
    return response.data;
  }
);

export const updatePaymentMethodById = createAsyncThunk(
  "paymentMethod/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`payment_method/${id}`, data);
    return response.data;
  }
);
