import { Box, Grid } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from "./Header";
import SideMenu from "./SideMenu";
import MobileMenu from "./MobileMenu";

const Layout = ({ children }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Grid container>
      {isMobile ? (
        <Grid item xs={12}>
          <MobileMenu />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item sx={{ width: 240 }}>
            <SideMenu />
          </Grid>
        </>
      )}
      <Grid xs item sx={{ width: `calc(100% - 240px)`, margin: "0 auto", marginTop: isMobile ? '-64px' : '0' }}>
        <Box>{children}</Box>
      </Grid>
    </Grid>
  );
};

export default Layout;