import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { getBranches, deleteBranchById } from "../api/endpoints/branches";
import Loading from "./Loading";
import ABMBranchDialogMobile from "../components/mobile/dialog/ABMBranchDialogMobile";
import BranchesTableMobile from "../components/mobile/table/BranchesTableMobile";
import TableHeaderMobile from "../components/mobile/tableHeader/TableHeaderMobile";
import TableHeader from "../components/table/TableHeader";
import BranchesTable from "../components/table/BranchesTable";
import BranchDialog from "../components/dialog/BranchDialog";

const ABMBranchesForm = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState({
    id: null,
    name: "",
    email: "",
    role: "",
  });

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  useEffect(() => {
    const fetchBranches = async () => {
      setIsLoading(true);
      try {
        const branchesData = await dispatch(getBranches()).unwrap();

        const branchesWithId = branchesData.map((user, index) => ({
          ...user,
          id: index,
        }));

        setBranches(branchesWithId);
        setFilteredBranches(branchesWithId);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchBranches();
  }, []);

  const handleSearch = (searchText) => {
    if (searchText === "") {
      setFilteredBranches(branches);
    } else {
      const filteredBranches = branches.filter((branch) =>
        branch.description.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredBranches(filteredBranches);
    }
    setPage(1);
  };

  const getCurrentPageItems = () => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredBranches.slice(startIndex, endIndex);
  };

  const handleClickOpen = () => {
    setSelectedBranch({ id: null, name: "", phone: "", email: "", role: "" });
    setEditMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedBranch({ ...selectedBranch, [name]: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedBranch({ ...selectedBranch, [name]: value });
  };

  const handleSave = () => {
    if (editMode) {
      const updatedBranches = branches.map((branch) =>
        branch.id === selectedBranch.id ? selectedBranch : branch
      );
      setFilteredBranches(updatedBranches);
    } else {
      setSelectedBranch({ ...selectedBranch, id: branches.length + 1 });
      setFilteredBranches([...branches, selectedBranch]);
      setBranches([...branches, selectedBranch]);
    }
    handleClose();
  };

  const handleEdit = (branch) => {
    setSelectedBranch(branch);
    setEditMode(true);
    setOpen(true);
  };

  const handleDelete = (id, uuid) => {
    const updatedBranches = branches.filter((branch) => branch.id !== id);
    dispatch(deleteBranchById(uuid)).unwrap();
    setFilteredBranches(updatedBranches);
    setBranches(updatedBranches);
  };

  const props = {
    branches: filteredBranches,
    handleEdit: handleEdit,
    handleDelete: handleDelete,
    getCurrentPageItems,
    ITEMS_PER_PAGE,
    page,
    setPage,
  };

  const name = "ADMINISTRACIÓN DE SUCURSALES";
  const tableHeaderProps = {
    handleClickOpen,
    handleSearch,
    name,
  };

  const dialogProps = {
    open,
    handleClose,
    editMode,
    selectedBranch,
    handleInputChange,
    handleSelectChange,
    handleSave,
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isMobile ? (
        <TableHeaderMobile {...tableHeaderProps} />
      ) : (
        <TableHeader {...tableHeaderProps} />
      )}

      {isMobile ? (
        <BranchesTableMobile {...props} />
      ) : (
        <BranchesTable {...props} />
      )}

      {isMobile ? (
        <ABMBranchDialogMobile {...dialogProps} />
      ) : (
        <BranchDialog {...dialogProps} />
      )}
    </>
  );
};

export default ABMBranchesForm;
