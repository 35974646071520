import React from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  FormTextareaAutosize,
  SelectFormControl,
  SeparatorHrBox
} from "../../../assets/styles/tables";
import Actions from "../../dialog/Actions";

const ABMUserDialogMobile = ({ open,
  handleClose,
  editMode,
  selectedUser,
  handleInputChange,
  handleSelectChange,
  handleSave
}) => {

  const actionProps = { handleClose, handleSave };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <FormDialogTitle>
        {editMode ? "Editar Usuario" : "Agregar Usuario"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent>
        <FormDialogSubtitle> Datos de acceso </FormDialogSubtitle>
        <FormBoxContainer sx={{ display: "block" }}>
          <TextField
            margin="dense"
            name="user"
            label="Usuario"
            type="text"
            fullWidth
            value={selectedUser.user}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="pass"
            label="Contraseña"
            type="text"
            fullWidth
            value={selectedUser.pass}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="session"
            label="Session"
            type="number"
            fullWidth
            value={selectedUser.session}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={selectedUser.email}
            onChange={handleInputChange}
          />
          <SelectFormControl sx={{ marginTop: 1 }}>
            <InputLabel margin="dense" id="role-label">
              Nivel Seguridad
            </InputLabel>
            <Select
              labelId="role-label"
              name="role"
              value={selectedUser.role}
              onChange={handleSelectChange}
            >
              <MenuItem value="admin">Administrador</MenuItem>
              <MenuItem value="user">Usuario</MenuItem>
            </Select>
          </SelectFormControl>
          <SelectFormControl>
            <InputLabel margin="dense" id="sucursal-label">
              Empresa defecto
            </InputLabel>
            <Select
              labelId="sucursal-label"
              name="sucursal"
              value={selectedUser.sucursal}
              onChange={handleSelectChange}
            >
              <MenuItem value="Casa Central">Casa Central</MenuItem>
              <MenuItem value="Sucursal 1">Sucursal 1</MenuItem>
            </Select>
          </SelectFormControl>
          <SelectFormControl>
            <InputLabel margin="dense" id="area-label">
              Area
            </InputLabel>
            <Select
              labelId="area-label"
              name="area"
              value={selectedUser.area}
              onChange={handleSelectChange}
            >
              <MenuItem value="Deposito">Depósito</MenuItem>
              <MenuItem value="Ingenieria">Ingeniería</MenuItem>
              <MenuItem value="Compras">Compras</MenuItem>
            </Select>
          </SelectFormControl>
          <SelectFormControl>
            <InputLabel margin="dense" id="deposito-label">
              Depósito
            </InputLabel>
            <Select
              labelId="deposito-label"
              name="deposito"
              value={selectedUser.deposito}
              onChange={handleSelectChange}
            >
              <MenuItem value="1">Dep. B° nnnnn2</MenuItem>
              <MenuItem value="2">Dep. B° sssss</MenuItem>
              <MenuItem value="3">Dep. B° xxxxx</MenuItem>
            </Select>
          </SelectFormControl>
        </FormBoxContainer>

        <SeparatorHrBox mt={5}>
          <hr />
        </SeparatorHrBox>

        <FormDialogSubtitle sx={{ mt: 0 }}> Datos Personales </FormDialogSubtitle>

        <FormBoxContainer sx={{ display: "block" }}>
          <TextField
            margin="dense"
            name="name"
            label="Nombre"
            type="text"
            fullWidth
            value={selectedUser.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="dir"
            label="Dirección"
            type="text"
            fullWidth
            value={selectedUser.dir}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="cp"
            label="CP"
            type="text"
            fullWidth
            value={selectedUser.cp}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="phone"
            label="Teléfono"
            type="text"
            fullWidth
            value={selectedUser.phone}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="birthdate"
            defaultValue=""
            label="Fecha de Nacimiento"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={selectedUser.birthdate}
            onChange={handleInputChange}
          />
          <SelectFormControl sx={{ marginTop: 1 }}>
            <InputLabel margin="dense" id="localidad-label">
              Localidad
            </InputLabel>
            <Select
              labelId="localidad-label"
              name="localidad"
              value={selectedUser.localidad}
              onChange={handleSelectChange}
            >
              <MenuItem value="1">Rivadavia</MenuItem>
            </Select>
          </SelectFormControl>
          <SelectFormControl>
            <InputLabel margin="dense" id="provincia-label">
              Provincia
            </InputLabel>
            <Select
              labelId="provincia-label"
              name="provincia"
              value={selectedUser.provincia}
              onChange={handleSelectChange}
            >
              <MenuItem value="1">Mendoza</MenuItem>
            </Select>
          </SelectFormControl>
          <SelectFormControl>
            <InputLabel margin="dense" id="pais-label">
              País
            </InputLabel>
            <Select
              labelId="pais-label"
              name="pais"
              value={selectedUser.pais}
              onChange={handleSelectChange}
            >
              <MenuItem value="1">Argentina</MenuItem>
            </Select>
          </SelectFormControl>
        </FormBoxContainer>

        <SeparatorHrBox mt={5}>
          <hr />
        </SeparatorHrBox>
        <FormDialogSubtitle sx={{ mt: 0 }}> Observaciones: </FormDialogSubtitle>

        <FormTextareaAutosize
          fullWidth
          rowsMin={3}
          placeholder=""
          aria-label="Observaciones"
          margin="dense"
          name="obs"
          value={selectedUser.obs}
          onChange={handleInputChange}
          style={{ height: "200px", marginTop: '8px' }}
        />

      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  )
}

export default ABMUserDialogMobile;