import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getMaritalStatuses = createAsyncThunk("marital_statuses", async () => {
  const response = await apiClient.get("marital_statuses");
  return response.data;
});

export const getMaritalStatusById = createAsyncThunk("marital_status/id", async ( id ) => {
  const response = await apiClient.get(`marital_status/${id}`);
  return response.data;
});

export const deleteMaritalStatusById = createAsyncThunk("marital_status/delete", async ( id ) => {
  const response = await apiClient.delete(`marital_status/${id}`);
  return response.data;
});

export const addMaritalStatus = createAsyncThunk("marital_statuses/add", async (marital_status) => {
  const response = await apiClient.post("marital_statuses", marital_status);
  return response.data;
});

export const updateMaritalStatus = createAsyncThunk("marital_status/update", async ( marital_status ) => {
  delete marital_status.deleted_at;
  delete marital_status.updated_at;
  delete marital_status.created_at;
  delete marital_status.is_active;
  delete marital_status.modules;
  
  const response = await apiClient.put(`marital_status/${marital_status.uuid}`, marital_status);
  return response.data;
});