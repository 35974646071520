import React, { useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { utils, read } from "xlsx";
import {
  AddButton,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  HeaderBox,
  NameViewBox,
  PaginationBox,
  RowTableCell,
  SelectFormControl,
} from "../assets/styles/tables";
import { styled } from "@mui/system";

const DialogTitleWithCloseButton = styled((props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle {...other}>
      <Typography sx={{ flex: "1" }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
})({});

const LifeInsuranceImportTable = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openTableDialog, setOpenTableDialog] = useState(false);

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const readExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: "array" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      let json = utils.sheet_to_json(worksheet, { header: 1 });
      // reorganizar las columnas
      json = json.map((row) => [row[2], row[0], row[1], row[7]]);
      setTableData(json);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleImportValues = () => {
    if (!selectedFile) {
      setOpenErrorDialog(true);
    } else {
      readExcel(selectedFile);
      setOpenTableDialog(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        ml: 3,
      }}
    >
      <Box
        sx={{
          mt: 3,
          justifyContent: "center",
          backgroundColor: "#f2f2f2",
          borderRadius: "20px",
          padding: "15px",
          boxShadow: "none",
          borderBottom: "1px solid #ccc",
        }}
      >
        <Box
          sx={{
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Contrato | Seguro de vida | Importar
        </Box>
        <Box
          sx={{
            fontSize: "20px",
            mt: 2,
            ml: 0.5,
          }}
        >
          El archivo a subir debe contener la estructura de columnas del archivo
          exportado.
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            mt: 2,
          }}
        >
          <input
            type="file"
            accept=".xlsx"
            onChange={handleFileUpload}
            style={{ display: "none" }}
            id="fileUpload"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label htmlFor="fileUpload">
              <Button
                variant="contained"
                component="span"
                sx={{
                  backgroundColor: "#5e894f",
                  borderRadius: "15px",
                  height: "50px",
                  width: "250px",
                  fontWeight: "bold",
                  fontSize: "100%",
                  boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
                }}
              >
                <FileUploadIcon />
                <Box
                  sx={{
                    fontSize: "15px",
                    ml: 1,
                  }}
                >
                  Seleccionar archivo
                </Box>
              </Button>
            </label>
            <Box
              sx={{
                fontSize: "15px",
                ml: 3,
              }}
            >
              {selectedFile ? selectedFile.name : "Sin archivos seleccionados"}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "2px",
              backgroundColor: "#ccc",
              my: 2,
            }}
          />
          <Button
            variant="outlined"
            component="span"
            onClick={handleImportValues}
            sx={{
              borderColor: "#5e894f",
              color: "#5e894f",
              borderRadius: "15px",
              height: "50px",
              width: "250px",
              fontWeight: "bold",
              fontSize: "100%",
              boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
            }}
          >
            Importar valores
          </Button>
        </Box>
        <Dialog
          open={openErrorDialog}
          onClose={() => setOpenErrorDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Es necesario seleccionar un archivo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenErrorDialog(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Dialog
        open={openTableDialog}
        onClose={() => setOpenTableDialog(false)}
        maxWidth="md" // "md" es para un tamaño medio. Puedes usar "sm", "lg", "xl" para tamaño pequeño, grande y extra grande respectivamente.
        fullWidth // Esto hace que el diálogo se expanda para ajustarse al ancho del contenedor padre.
        aria-labelledby="table-dialog-title"
      >
        <DialogTitleWithCloseButton onClose={() => setOpenTableDialog(false)}>
          <Typography variant="h5">
            Los siguientes numeros de orden fueron procesados...
          </Typography>
        </DialogTitleWithCloseButton>
        <DialogContent>
          <Box>
            <GeneralTableBox sx={{}}>
              <GeneralTableContainer sx={{ }}>
                <Table sx={{ minWidth: 800 }}>
                  <TableBody>
                    {tableData.map((row, index) => {
                      if (index === 0) return null;
                      return (
                        <TableRow key={index}>
                          {row.map((cell, index) => {
                            if (index > 3) return null; // solo queremos las primeras 4 celdas
                            return (
                              <RowTableCell key={index}>
                                {index === 0
                                  ? `Orden: ${cell}`
                                  : index === 3
                                  ? `Nuevo Seguro: ${cell}`
                                  : cell}
                              </RowTableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </GeneralTableContainer>
            </GeneralTableBox>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LifeInsuranceImportTable;
