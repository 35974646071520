const originalConstTypes = [
    {
      id: 1,
      id_plan: 1,
      name: "Tipo 1",
      surface: 1455.5,
      description: "Descripción del tipo 1",
    },
    {
        id: 2,
        id_plan: 1,
        name: "Tipo 2",
        surface: 585.5,
        description: "Descripción del tipo 2",
    },
    {
        id: 3,
        id_plan: 1,
        name: "Tipo 3",
        surface: 1582.5,
        description: "Descripción del tipo 3",
    },
    {
        id: 4,
        id_plan: 1,
        name: "Tipo 4",
        surface: 9985.65,
        description: "Descripción del tipo 4",
    },
    {
        id: 5,
        id_plan: 1,
        name: "Tipo 5",
        surface: 987.36,
        description: "Descripción del tipo 5",
    },
    {
        id: 6,
        id_plan: 1,
        name: "Tipo 6",
        surface: 2583.981,
        description: "Descripción del tipo 6",
    },
  ];
  
  export default originalConstTypes