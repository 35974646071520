import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  HeadTableCell,
  RowTableCell,
  EditButton,
  DeleteButton,
  HeaderBox,
  AddButton,
  PaginationBox,
} from "../../assets/styles/tables";

import originalPreAdjRs from "../../data/tableContentPreAdjudicationReasons";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from '@mui/icons-material/PostAdd';
import SearchBar from "../../common/SearchBar";


const PreAdjReasonsTab = (props) => {
  const ITEMS_PER_PAGE = 3;
  const {
    getCurrentPreAdjRPageItems, handleEditPreAdjR,
    handleDeletePreAdjR, pagePreAdjR, preAdjRs,
    setPagePreAdjR, setSelectedPreAdjR,
    setOpenPreAdjR, setFilteredPreAdjRs,
  } = props;

  const handleClickOpen = () => {
    setSelectedPreAdjR({});

    setOpenPreAdjR(true);
  };

  const handleSearch = (searchText) => {
    if (searchText === "") {
      setFilteredPreAdjRs(originalPreAdjRs);
    } else {
      const filteredGroups = originalPreAdjRs.filter((preAdjR) =>
        preAdjR.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredPreAdjRs(filteredGroups);
    }
    setPagePreAdjR(1);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <br />
      <HeaderBox sx={{ marginLeft: 0 }}>
        <AddButton variant="contained" onClick={handleClickOpen}>
          <PostAddIcon fontSize="large" />
        </AddButton>
        <SearchBar onSearch={handleSearch} />
      </HeaderBox>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>Motivo</HeadTableCell>
            <HeadTableCell sx={{ width: "65%", textAlign: "center" }}>Descripción</HeadTableCell>
            <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>Acciones</HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentPreAdjRPageItems().map((preAdjR) => (
            <TableRow key={preAdjR.id}>
              <RowTableCell sx={{ textAlign: "center" }}>
                {preAdjR.name}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                {preAdjR.description}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                <EditButton
                  variant="contained"
                  onClick={() => handleEditPreAdjR(preAdjR)}
                >
                  <IconButton size="small">
                    <EditIcon sx={{ color: "whitesmoke" }} />
                  </IconButton>
                </EditButton>
                <DeleteButton
                  variant="contained"
                  onClick={() => handleDeletePreAdjR(preAdjR.id, preAdjR.uuid)}
                >
                  <IconButton size="small">
                    <DeleteIcon sx={{ color: "whitesmoke" }} />
                  </IconButton>
                </DeleteButton>
              </RowTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PaginationBox>
        <Pagination
          count={Math.ceil(preAdjRs.length / ITEMS_PER_PAGE)}
          shape="rounded"
          page={pagePreAdjR}
          onChange={(event, value) => setPagePreAdjR(value)}
        />
      </PaginationBox>
    </Box>
  )

};

export default PreAdjReasonsTab;
