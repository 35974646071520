const originalPreAdjRs = [
    {
      id: 1,
      id_plan: 1,
      name: "Razón 1",
      description: "Descripción de la razón 1"
    },
    {
        id: 2,
        id_plan: 1,
        name: "Razón 2",
        description: "Descripción de la razón 2"
    },
    {
        id: 3,
        id_plan: 1,
        name: "Razón 3",
        description: "Descripción de la razón 3"
    },
    {
        id: 4,
        id_plan: 1,
        name: "Razón 4",
        description: "Descripción de la razón 4"
    },
    {
        id: 5,
        id_plan: 1,
        name: "Razón 5",
        description: "Descripción de la razón 5"
    },
    {
        id: 6,
        id_plan: 1,
        name: "Razón 6",
        description: "Descripción de la razón 6"
    }
  ];
  
  export default originalPreAdjRs