import React from "react";

import {
    DeleteButton,
    EditButton,
    GeneralTableBox,
    GeneralTableContainer,
    HeadTableCell,
    PaginationBox,
    RowTableCell
} from "../../assets/styles/tables";

import {
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableHead,
    TableRow
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";


const SubscribersTable = (props) => {

    const districts = useSelector((state) => state.districts);
    const { subscribers, handleEdit, handleDelete,
        ciudadesArray, getCurrentPageItems, ITEMS_PER_PAGE,
        page, setPage } = props;

    return (
        <GeneralTableBox>
            <GeneralTableContainer>
                <Table aria-label="subscriber table">
                    <TableHead>
                        <TableRow>
                            <HeadTableCell sx={{ width: "10%" }}>Nombre</HeadTableCell>
                            <HeadTableCell sx={{ width: "10%" }}>Apellido</HeadTableCell>
                            <HeadTableCell sx={{ width: "10%" }}>DNI</HeadTableCell>
                            <HeadTableCell sx={{ width: "20%", minWidth: "200px" }}>Dirección</HeadTableCell>
                            <HeadTableCell sx={{ width: "15%" }}>Ciudad</HeadTableCell>
                            <HeadTableCell sx={{ textAlign: "center", width: "18%" }}>
                                Acciones
                            </HeadTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getCurrentPageItems().map((subscriber) => (
                            <TableRow key={subscriber.id}>
                                <RowTableCell>{subscriber.first_name}</RowTableCell>
                                <RowTableCell>{subscriber.last_name}</RowTableCell>
                                <RowTableCell>{subscriber.dni}</RowTableCell>
                                <RowTableCell>{subscriber.address}</RowTableCell>
                                <RowTableCell>
                                    {districts.map((district) =>{
                                        if(district.uuid === subscriber.uuid_district)
                                            {return district.name}
                                    })}
                                </RowTableCell>
                                <RowTableCell sx={{ textAlign: "center" }}>
                                    <EditButton
                                        variant="contained"
                                        onClick={() => handleEdit(subscriber)}
                                    >
                                        <IconButton size="small">
                                            <EditIcon sx={{ color: "whitesmoke" }} />
                                        </IconButton>
                                    </EditButton>
                                    <DeleteButton
                                        variant="contained"
                                        onClick={() => handleDelete(subscriber.uuid)}
                                        color="tertiary"
                                    >
                                        <IconButton size="small">
                                            <DeleteIcon sx={{ color: "whitesmoke" }} />
                                        </IconButton>
                                    </DeleteButton>
                                </RowTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <PaginationBox>
                    <Pagination
                        count={Math.ceil(subscribers.length / ITEMS_PER_PAGE)}
                        shape="rounded"
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />
                </PaginationBox>
            </GeneralTableContainer>
        </GeneralTableBox>
    )
}

export default SubscribersTable;