const originalAdditionals = [
    {
      id: 1,
      id_plan: 1,
      name: "Adicional 1",
      amount: 1028123,
      prorate: 0,
      mandatory: 0,
      deducted: 1,
      created_at: "2014/08/19",
    },
    {
        id: 2,
        id_plan: 1,
        name: "Adicional 2",
        amount: 1009329,
        prorate: 1,
        mandatory: 1,
        deducted: 1,
        created_at: "2014/08/19",
    },
    {
        id: 3,
        id_plan: 1,
        name: "Adicional 3",
        amount: 874629,
        prorate: 1,
        mandatory: 0,
        deducted: 1,
        created_at: "2014/08/19",
    },
    {
        id: 4,
        id_plan: 1,
        name: "Adicional 4",
        amount: 981273817,
        prorate: 0,
        mandatory: 1,
        deducted: 1,
        created_at: "2014/08/19",
    },
    {
        id: 5,
        id_plan: 1,
        name: "Adicional 5",
        amount: 981273817,
        prorate: 0,
        mandatory: 1,
        deducted: 1,
        created_at: "2014/08/19",
    },
    {
        id: 6,
        id_plan: 1,
        name: "Adicional 6",
        amount: 981273817,
        prorate: 0,
        mandatory: 1,
        deducted: 1,
        created_at: "2014/08/19",
    }
  ];
  
  export default originalAdditionals