import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  HeadTableCell,
  RowTableCell,
  EditButton,
  DeleteButton,
  HeaderBox,
  AddButton,
  PaginationBox,
} from "../../assets/styles/tables";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import originalGroups from "../../data/tableContentGroups";
import SearchBar from "../../common/SearchBar";

const GroupTab = (props) => {
  const ITEMS_PER_PAGE = 3;
  const {
    getCurrentGroupPageItems,
    handleEditGroup,
    handleDeleteGroup,
    pageGroup,
    groups,
    setPageGroup,
    setSelectedGroup,
    setOpenGroup,
    setFilteredGroups,
  } = props;

  const handleClickOpen = () => {
    setSelectedGroup({});

    setOpenGroup(true);
  };

  const handleSearch = (searchText) => {
    if (searchText === "") {
      setFilteredGroups(originalGroups);
    } else {
      const filteredGroups = originalGroups.filter((group) =>
        group.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredGroups(filteredGroups);
    }
    setPageGroup(1);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <br />
      <HeaderBox sx={{ marginLeft: 0 }}>
        <AddButton variant="contained" onClick={handleClickOpen}>
          <PostAddIcon fontSize="large" />
        </AddButton>
        <SearchBar onSearch={handleSearch} />
      </HeaderBox>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <HeadTableCell sx={{ width: "10%", textAlign: "center" }}>
              Grupos
            </HeadTableCell>
            <HeadTableCell sx={{ width: "25%", textAlign: "center" }}>
              N° Orden Desde
            </HeadTableCell>
            <HeadTableCell sx={{ width: "25%", textAlign: "center" }}>
              N° Orden Hasta
            </HeadTableCell>
            <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>
              Fecha Creación
            </HeadTableCell>
            <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>
              Acciones
            </HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCurrentGroupPageItems().map((group) => (
            <TableRow key={group.id}>
              <RowTableCell sx={{ textAlign: "center" }}>
                {group.name}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                {group.number_from}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                {group.number_to}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                {group.created_at}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                <EditButton
                  variant="contained"
                  onClick={() => handleEditGroup(group)}
                >
                  <IconButton size="small">
                    <EditIcon sx={{ color: "whitesmoke" }} />
                  </IconButton>
                </EditButton>
                <DeleteButton
                  variant="contained"
                  onClick={() => handleDeleteGroup(group.id, group.uuid)}
                >
                  <IconButton size="small">
                    <DeleteIcon sx={{ color: "whitesmoke" }} />
                  </IconButton>
                </DeleteButton>
              </RowTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PaginationBox>
        <Pagination
          count={Math.ceil(groups.length / ITEMS_PER_PAGE)}
          shape="rounded"
          page={pageGroup}
          onChange={(event, value) => setPageGroup(value)}
        />
      </PaginationBox>
    </Box>
  );
};

export default GroupTab;
