import {
  AddButton,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  HeaderBox,
  NameViewBox,
  PaginationBox,
  RowTableCell,
  SelectFormControl,
} from "../assets/styles/tables";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@mui/material";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";

const exportData = [
  {
    plan: "Habitat 99",
    group: "GRUPO 1",
    order: "1",
    subscriber: "JORGE ROBERTO",
    installmentDebtBalance: "400",
    fireInsurance: "3040",
  },
  {
    plan: "Habitat 99",
    group: "GRUPO 1",
    order: "2",
    subscriber: "JORGE CALI",
    installmentDebtBalance: "401",
    fireInsurance: "3040",
  },
  {
    plan: "Habitat 99",
    group: "GRUPO 1",
    order: "3",
    subscriber: "MACARENA MARIA",
    installmentDebtBalance: "402",
    fireInsurance: "3040",
  },
  {
    plan: "Habitat 99",
    group: "GRUPO 1",
    order: "4",
    subscriber: "JORGE ROBERTO",
    installmentDebtBalance: "400",
    fireInsurance: "3040",
  },
  {
    plan: "Habitat 99",
    group: "GRUPO 1",
    order: "5",
    subscriber: "JORGE CALI",
    installmentDebtBalance: "401",
    fireInsurance: "3040",
  },
  {
    plan: "Habitat 99",
    group: "GRUPO 1",
    order: "6",
    subscriber: "MACARENA MARIA",
    installmentDebtBalance: "402",
    fireInsurance: "3040",
  },
];

const plans = ["Habitat 99", "Habitat 99 Premium"];

const downloadExcel = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // Agregar los títulos de las columnas al principio de los datos
  const titles = [
    "Plan",
    "Grupo",
    "Orden",
    "Suscriptor",
    "Saldo deuda cuota",
    "Seguro de incendio",
  ];

  const dataToDownload = exportData.map((item) => [
    item.plan,
    item.group,
    item.order,
    item.subscriber,
    item.installmentDebtBalance,
    item.fireInsurance,
  ]);

  const ws = utils.aoa_to_sheet([titles, ...dataToDownload]);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = write(wb, { bookType: "xlsx", type: "binary" });
  const data = new Blob([s2ab(excelBuffer)], { type: fileType });
  saveAs(data, "Seguro de incendio" + fileExtension);
};

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

const FireInsuranceExportsHeader = (props) => {
  return (
    <Box
      sx={{
        mt: 3,
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        padding: "15px",
        boxShadow: "none",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Box
        sx={{
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Contrato | Seguro de Incendio | Exportar
      </Box>
      <HeaderBox sx={{ ml: 0, mt: 2 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#5e894f",
            borderRadius: "15px",
            height: "50px",
            width: "100px",
            fontWeight: "bold",
            fontSize: "100%",
            boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
          }}
          onClick={downloadExcel}
        >
          <CloudDownloadIcon />
          <Box
            sx={{
              fontSize: "15px",
              ml: 1,
            }}
          >
            Excel
          </Box>
        </Button>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "30%" }}>
          <InputLabel margin="dense" id="feeType-label">
            Plan
          </InputLabel>
          <Select
            labelId="plan-label"
            name="plan"
            // onChange={handleSelectChange}
            // value={selectedFee}
          >
            {plans.map((plan, index) => (
              <MenuItem key={index} value={plan}>
                {plan}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
      </HeaderBox>
    </Box>
  );
};

const FireInsuranceExportTable = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mr: 15,
        ml: 25,
        width: "max-content",
      }}
    >
      <FireInsuranceExportsHeader />
      <GeneralTableBox
        sx={{
          mt: 0,
          ml: 0,
          mr: 0,
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          "*::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "*::-webkit-scrollbar-track": {
            background: "inherit",
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "20px",
          },
          "*::-webkit-scrollbar-corner": {
            background: "inherit",
          },
        }}
      >
        <GeneralTableContainer
          sx={{
            maxHeight: "30em",
            overflow: "auto",
          }}
        >
          <Table aria-label="plan table">
            <TableHead>
              <TableRow>
                <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                  Plan
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                  Grupo
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "5%" }}>
                  Orden
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                  Suscriptor
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                  Saldo deuda cuota
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                  Seguro de incendio
                </HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exportData.map((data, key) => (
                <TableRow key={key}>
                  <RowTableCell>{data.plan}</RowTableCell>
                  <RowTableCell>{data.group}</RowTableCell>
                  <RowTableCell>{data.order}</RowTableCell>
                  <RowTableCell>{data.subscriber}</RowTableCell>
                  <RowTableCell>{data.installmentDebtBalance}</RowTableCell>
                  <RowTableCell>{data.fireInsurance}</RowTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GeneralTableContainer>
      </GeneralTableBox>
    </Box>
  );
};

export default FireInsuranceExportTable;
