import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
} from "../../assets/styles/tables";
import Actions from "./Actions";

const PaymentMethodDialog = (props) => {
  const {
    openPaymentMethod,
    handleClosePaymentMethod,
    editModePaymentMethod,
    selectedPaymentMethod,
    handleInputChangePaymentMethod,
    handleSelectChangePaymentMethod,
    handleSavePaymentMethod,
  } = props;

  const [nameError, setNameError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  useEffect(() => {
    if (openPaymentMethod) {
      setNameError(false);
      setDateError(false);
      setSaveAttempted(false);
    }
  }, [openPaymentMethod]);

  const handleSave = () => {
    setSaveAttempted(true);

    if (!selectedPaymentMethod.name) {
      setNameError(true);
      return;
    }

    if (!selectedPaymentMethod.low_date) {
      setDateError(true);
      return;
    }

    handleSavePaymentMethod();
  };

  const actionProps = {
    handleClose: handleClosePaymentMethod,
    handleSave: handleSave,
  };

  return (
    <Dialog
      open={openPaymentMethod}
      onClose={handleClosePaymentMethod}
      maxWidth={false}
      PaperProps={{
        style: { minWidth: '600px', maxWidth: '600px' },
      }}
    >
      <FormDialogTitle>
        {editModePaymentMethod ? "Editar Forma de Pago" : "Nueva Forma de Pago"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <Box>
            <FormBoxContainer sx={{ display: "block" }}>
              <TextField
                margin="dense"
                name="name"
                label="Nombre"
                type="text"
                fullWidth
                value={selectedPaymentMethod.name}
                onChange={handleInputChangePaymentMethod}
                error={
                  nameError || (saveAttempted && !selectedPaymentMethod.name)
                }
              />
              {nameError || (saveAttempted && !selectedPaymentMethod.name) ? (
                <FormHelperText>Debes ingresar un nombre.</FormHelperText>
              ) : null}
              <TextField
                margin="dense"
                name="low_date"
                label="Fecha Baja"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={
                  selectedPaymentMethod.low_date
                    ? new Date(selectedPaymentMethod.low_date)
                        .toISOString()
                        .split("T")[0]
                    : null
                }
                onChange={handleInputChangePaymentMethod}
                error={
                  dateError ||
                  (saveAttempted && !selectedPaymentMethod.low_date)
                }
              />
              {dateError ||
              (saveAttempted && !selectedPaymentMethod.low_date) ? (
                <FormHelperText>Debes ingresar una fecha.</FormHelperText>
              ) : null}
              <SelectFormControl>
                <InputLabel margin="dense" id="complete-label">
                  Destacar en contrato
                </InputLabel>
                <Select
                  labelId="outlined_in_contract-label"
                  name="outlined_in_contract"
                  value={selectedPaymentMethod.outlined_in_contract}
                  onChange={handleSelectChangePaymentMethod}
                >
                  <MenuItem value="1">Si</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
              </SelectFormControl>
            </FormBoxContainer>
          </Box>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default PaymentMethodDialog;
