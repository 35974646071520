import { createSlice } from '@reduxjs/toolkit';
import { getAdditionals } from '../../api/endpoints/additionals';

export const AdditionalSlice = createSlice({
  name: 'Additionals',
  initialState: [], // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addAdditionals: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeAdditional: (state, action) => {
      const index = state.findIndex((additional) => additional.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdditionals.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { addAdditionals, removeAdditional } = AdditionalSlice.actions;

export default AdditionalSlice.reducer;
