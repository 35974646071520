import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { getUsers, deleteUserById, updateUser, addUser } from "../api/endpoints/users";
import { getProfileById } from "../api/endpoints/profiles";
import Loading from "./Loading";
import UsersTableMobile from "../components/mobile/table/UsersTableMobile";
import ABMUserDialogMobile from "../components/mobile/dialog/ABMUserDialogMobile";
import TableHeaderMobile from "../components/mobile/tableHeader/TableHeaderMobile";
import TableHeader from "../components/table/TableHeader";
import UsersTable from "../components/table/UsersTable";
import UserDialog from "../components/dialog/UserDialog";

const ABMUserForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: null,
    names: "",
    email: "",
    password: "",
    telephone: "",
    is_active: true,
    is_administrator: false,
    uuid_rol: "",
    timezone: "",
    uuid_subscriber: null,
  });

  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const usersData = await dispatch(getUsers()).unwrap();

        // Primero, obtenemos todos los roles
        const roleIds = usersData.map((user) => user.uuid_rol);
        const uniqueRoleIds = [...new Set(roleIds)]; // Elimina duplicados
        const roles = await Promise.all(
          uniqueRoleIds.map((roleId) =>
            dispatch(getProfileById(roleId)).unwrap()
          )
        );
        // Crea un mapa de roles para un acceso rápido
        const roleMap = roles.reduce((acc, role, index) => {
          acc[uniqueRoleIds[index]] = role;
          return acc;
        }, {});

        // Luego, crea la lista de usuarios con roles
        const usersWithRoles = usersData.map((user, index) => ({
          ...user,
          id: index,
          role: roleMap[user.uuid_rol],
        }));

        setUsers(usersWithRoles);
        setFilteredUsers(usersWithRoles);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, []);

  const handleSearch = (searchText) => {
    if (searchText === "") {
      setFilteredUsers(users);
    } else {
      const filteredUsers = users.filter((user) =>
        user.names.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredUsers(filteredUsers);
    }
    setPage(1);
  };

  const getCurrentPageItems = () => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredUsers.slice(startIndex, endIndex);
  };

  const handleClickOpen = () => {
    setSelectedUser({
      id: null,
      names: "",
      email: "",
      password: "",
      telephone: "",
      is_active: true,
      is_administrator: false,
      uuid_rol: "",
      timezone: "",
      uuid_subscriber: null,
    });
    setEditMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedUser({ ...selectedUser, [name]: value });
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedUser({ ...selectedUser, [name]: value });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true); // Indica que el proceso de guardado ha comenzado

      if (editMode) {
        // Llama al thunk updateUser para actualizar un usuario existente
        const updatedUser = await dispatch(
          updateUser({ id: selectedUser.uuid, user: selectedUser })
        ).unwrap();
        // Actualiza el estado local con el usuario actualizado
        const updatedUsers = users.map((user) =>
          user.id === updatedUser.id ? updatedUser : user
        );
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
      } else {
        // Llama al thunk addUser para agregar un nuevo usuario
        const addedUser = await dispatch(addUser(selectedUser)).unwrap();
        // Actualiza el estado local con el usuario agregado
        setUsers((prevUsers) => [...prevUsers, addedUser]);
        setFilteredUsers((prevUsers) => [...prevUsers, addedUser]);
      }

      handleClose(); // Cierra el modal/formulario
    } catch (error) {
      console.error("Failed to save user:", error);
    } finally {
      setIsLoading(false); // Restablece el estado de carga
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setEditMode(true);
    setOpen(true);
  };

  const handleDelete = (id, uuid) => {
    const updatedUsers = users.filter((user) => user.id !== id);
    dispatch(deleteUserById(uuid)).unwrap();
    setFilteredUsers(updatedUsers);
    setUsers(updatedUsers);
  };

  const props = {
    users: filteredUsers,
    handleEdit: handleEdit,
    handleDelete: handleDelete,
    getCurrentPageItems,
    ITEMS_PER_PAGE,
    page,
    setPage,
  };

  const name = "ADMINISTRACIÓN DE USUARIOS";
  const tableHeaderProps = {
    handleClickOpen,
    handleSearch,
    name,
  };

  const dialogProps = {
    open,
    handleClose,
    editMode,
    selectedUser,
    handleInputChange,
    handleSelectChange,
    handleSave,
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isMobile ? (
        <TableHeaderMobile {...tableHeaderProps} />
      ) : (
        <TableHeader {...tableHeaderProps} />
      )}

      {isMobile ? <UsersTableMobile {...props} /> : <UsersTable {...props} />}

      {isMobile ? (
        <ABMUserDialogMobile {...dialogProps} />
      ) : (
        <UserDialog {...dialogProps} />
      )}
    </>
  );
};

export default ABMUserForm;
