import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  useTheme,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo_constructora.png";

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    navigate("/");
  };
  return (
    <AppBar position="fixed" sx={{ backgroundColor: theme.palette.background.menus }}>
      <Toolbar component={Box} sx={{ justifyContent: "space-between" }}>
        <Typography variant="h6">
          <a href="/"><img src={logo} alt="" style={{ height: "42px", marginTop: "10px" }} /></a>
        </Typography>
        <Box>
          <IconButton color="primary" onClick={handleClick}>
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            getContentAnchorEl={null}
          >
            <MenuItem onClick={handleClose}>Editar usuario</MenuItem>
            <MenuItem onClick={handleLogOut}>Cerrar sesión</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
