import React from "react";
import GenerationControlForm from "../common/GenerationControlForm";
import { Box } from "@mui/material";

const GenerationControl = () => {
  return (
    <Box mt={12}>
      <GenerationControlForm />
    </Box>
  );
};

export default GenerationControl;