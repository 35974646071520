import {    Box, 
            Button, 
            DialogTitle, 
            Divider, 
            FormControl, 
            TableCell, 
            TableContainer, 
            TextareaAutosize, 
            styled, 
            useMediaQuery} from "@mui/material";

//BUTTONS
export const AddButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: "15px",
    height: "56px",
    width: "60px",
    fontWeight: "bold",
    fontSize: "100%",
    boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
}));

export const DeleteButton = styled(Button)(({ theme }) => ({
    borderRadius: "15px",
    boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
    backgroundColor: theme.palette.primary.cancel
}));

export const EditButton = styled(Button)(({ theme }) => ({
    marginRight: "5px",
    borderRadius: "15px",
    boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
    backgroundColor: theme.palette.primary.main,
    '@media (min-width: 900px) and (max-width: 1536px)': {
        marginRight: "0px",
        marginBottom: "5px",
    }
}));

//BOXES
export const NameViewBox = styled(Box)(({ theme }) => ({
    marginLeft: "25px",
    marginTop: "15px",
    fontSize: "16px",
    fontWeight: "bold",
    boxShadow: 5,
    color: "#1818184d",
}));

export const GeneralTableBox = styled(Box)(({ theme }) => ({
    display: "flex",
    boxShadow: "10px 10px 50px rgba(1,2,10,0.3)",
    borderRadius: "20px",
    marginRight: "5%",
    marginLeft: "3%",
    marginBottom: "1%",
    marginTop: "1.5%",
    backgroundColor: theme.palette.background.formTables,
    '@media (min-width: 900px) and (max-width: 1536px)': {
        width: 'min-content'
    }
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
    marginLeft: "3%", 
    display: "flex"
}));

//TABLE
export const GeneralTableContainer = styled(TableContainer)(({ theme }) => ({
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: 5,
    backgroundColor: theme.palette.background.formTables,
    borderRadius: "20px",
    width: "100%",
}));

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
    color: "black",
    fontWeight: "bold",
    fontSize: "22px",
}));

export const RowTableCell = styled(TableCell)(({ theme }) => ({
    color: "black",
    fontSize: "110%",
}));

//FORM
export const FormDialogSubtitle = styled(DialogTitle)(({ theme }) => ({
    marginTop: "-30px", 
    fontSize: "90%",
    textAlign: "center",
}));

export const FormDivider = styled(Divider)(({ theme }) => ({
    bgcolor: 'black', 
    marginLeft: '15px', 
    marginRight: '15px',
    borderColor: '#acacace6'
}));

export const FormDialogTitle = styled(DialogTitle)(({ theme }) => ({
    fontWeight: "bold",
    fontSize: "140%",
    marginTop: "15px",
    marginBottom: "-15px",
    textAlign: "center"
}));

export const FormBoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
}));

export const FormBoxLeft = styled(Box)(({ theme }) => ({
    width: "180px", 

}));

export const SelectFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: "12px", 
    width: "100%"
}));

export const FormBoxCenter = styled(Box)(({ theme }) => ({
    width: "10px",
}));

export const FormBoxRight = styled(Box)(({ theme }) => ({
    width: "180px",
}));

export const FormBoxRightDescCheck = styled(Box)(({ theme }) => ({
    width: "290px",
    marginTop: "10px",
}));

export const FormTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
    width: "100%",
    borderRadius: "5px",
    padding: "10px",
    fontSize: "100%",
    fontFamily: "roboto",
    marginTop: "-10px",
}));

export const SeparatorHrBox = styled(Box)(({ theme }) => ({
    // marginTop: "20px", 
    marginBottom: "10px"
}));

export const AceptButton = styled(Button)(({ theme }) => ({
    borderRadius: "15px", 
    color: theme.palette.background.formTables,
    backgroundColor: theme.palette.primary.main,
}));

export const CancelButton = styled(Button)(({ theme }) => ({
    borderRadius: "15px", 
    color: theme.palette.background.formTables,
    backgroundColor: theme.palette.primary.cancel,
}));

export const AccessButton = styled(Button)(({ theme }) => ({
    borderRadius: "15px", 
    border: "1px solid",
    borderColor: "red",
    backgroundColor: theme.palette.background.formTables,
    borderRadius: "15px",
    boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
}));

export const ContractButton = styled(Button)(({ theme }) => ({
    borderRadius: "15px", 
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.formTables,
    borderRadius: "15px",
    boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
}));

//PAGINATION
export const PaginationBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "10px",
}));



// '@media (max-width: 700px)': {
//     backgroundColor: "red"
//   }