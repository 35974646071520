import React from "react";
import FireInsuranceExportForm from "../common/FireInsuranceExportForm";
import { Box } from "@mui/material";

const FireInsuranceExport = () => {
  return (
    <Box mt={12}>
      <FireInsuranceExportForm />
    </Box>
  );
};

export default FireInsuranceExport;
