import { createSlice } from "@reduxjs/toolkit";
import { getBranches } from "../../api/endpoints/branches";

export const branchSlice = createSlice({
  name: "branch",
  initialState: {
    branches: [],
    branch: null,
  },
  reducers: {
    addBranch: (state, action) => {
      state.branch = action.payload;
    },
    clearBranch: (state, action) => {
      state.branch = null;
    },
    addBranches: (state, action) => {
      state.branches = action.payload;
    },
    clearBranches: (state, action) => {
      state.branches = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBranches.fulfilled, (state, action) => {
      state.branches = action.payload;
    });
  },
});

export const { addBranch, clearBranch, addBranches, clearBranches } =
  branchSlice.actions;

export default branchSlice.reducer;
