import React from "react";
import ABMUserForm from "../common/ABMUserForm";
import { Box } from "@mui/material";

const ABMUsers = () => {
  return (
    <Box mt={12} >
      <ABMUserForm />
    </Box>
  );
};

export default ABMUsers;