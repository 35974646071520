import React from "react";
import {
  Dialog,
  DialogContent,
  TextField,
} from "@mui/material";

import {
  FormDialogTitle,
  SeparatorHrBox,
} from "../../assets/styles/tables";
import Actions from "./Actions";

const ProfileDialog = (props) => {

  const { open, handleClose, editMode,
    selectedProfile, handleInputChange,
    handleSave } = props;

  const actionProps = { handleClose, handleSave, selected: selectedProfile };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormDialogTitle>
        {editMode ? "Editar Perfil" : "Agregar Perfil"}
      </FormDialogTitle>
      <DialogContent>
        <SeparatorHrBox>
          <hr />
        </SeparatorHrBox>
        <TextField
          margin="dense"
          name="name"
          label="Nombre"
          type="text"
          fullWidth
          value={selectedProfile.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="description"
          label="Descripción"
          type="text"
          fullWidth
          value={selectedProfile.description}
          onChange={handleInputChange}
        />
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  )
}

export default ProfileDialog;