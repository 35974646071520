import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Divider,
  DialogActions
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  FormBoxLeft,
  FormDivider,
  FormBoxRightDescCheck,
  AceptButton,
  CancelButton
} from "../../assets/styles/tables";
import Actions from "./Actions";

import LockIcon from '@mui/icons-material/Lock';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { getModules } from "../../api/endpoints/accesses";
import { useDispatch } from "react-redux";


const AccessDialog = (props) => {

  const dispatch = useDispatch();
  const [accesses, setAccesses] = useState([]);
  const [value, setValue] = React.useState(0);
  const [reload, setReload] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }
  useEffect(() => {
    const fetchAcceses = async () => {
      try {
        const accessesData = await dispatch(getModules()).unwrap();
        setAccesses(accessesData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAcceses();
  }, []);

  const { openAccess, handleClose,
    selectedProfile, selectedAccess,
    handleCheckBoxChange, handleSaveAccess } = props;
  const actionProps = { handleClose, handleSave: handleSaveAccess };

  const titulosExistentes = [];
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const handleChkBoxChange = async (uuid, event) => {
    await handleCheckBoxChange(uuid, event)
    setReload(!reload);
  }

  return (
    <Dialog open={openAccess} onClose={handleClose} maxWidth={false}>
      <FormDialogTitle>
        Accesos para usuario {selectedProfile.name}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: 'block', height: '500px', width: '1100px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {accesses.map((access, index) => {
              if (!titulosExistentes.includes(access.module_order)) {
                titulosExistentes.push(access.module_order);
                return <Tab key={index} label={access.module_order}
                  id={`simple-tab-${index}`}
                  aria-controls={`simple-tabpanel-${index}`} />
              }
            })}
          </Tabs>
        </Box>
        {titulosExistentes.map((tab, index) => {
          const matchingAccesses = accesses.filter(access => tab === access.module_order);
          const half = Math.ceil(matchingAccesses.length / 2);

          const accessesLeft = matchingAccesses.slice(0, half);
          const accessesRight = matchingAccesses.slice(half);

          return (
            <TabPanel value={value} index={index}>
              <Box sx={{ display: 'flex', marginTop: 2 }}>
                <Box sx={{ flex: 1 }}>
                  {accessesLeft.map((access, index2) => (
                    <Box>
                      <FormBoxContainer>
                        <FormBoxLeft sx={{ width: "50%" }}>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox checked={selectedProfile.modules
                                ? selectedProfile.modules.some(module =>
                                  module.uuid === access.uuid) : false}
                                onChange={(event) => handleChkBoxChange(selectedProfile.uuid, event)}
                                name={access.uuid} />}
                              label={access.module_name}
                            />
                          </FormGroup>
                        </FormBoxLeft>
                        <FormBoxRightDescCheck sx={{ width: "50%" }}>
                          <div> {access.module_description} </div>
                        </FormBoxRightDescCheck>
                      </FormBoxContainer>
                      <hr style={{ border: "0px solid" }} />
                    </Box>
                  ))}
                </Box>

                <FormDivider orientation="vertical" sx={{ height: "350px" }} />

                <Box sx={{ flex: 1 }}>
                  {accessesRight.map((access, index2) => (
                    <Box>
                      <FormBoxContainer>
                        <FormBoxLeft sx={{ width: "50%" }}>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox checked={selectedProfile.modules
                                ? selectedProfile.modules.some(module =>
                                  module.uuid === access.uuid) : false}
                                onChange={(event) => handleCheckBoxChange(selectedProfile.uuid, event)}
                                name={access.uuid} />}
                              label={access.module_name}
                            />
                          </FormGroup>
                        </FormBoxLeft>
                        <FormBoxRightDescCheck sx={{ width: "50%" }}>
                          <div> {access.module_description} </div>
                        </FormBoxRightDescCheck>
                      </FormBoxContainer>
                      <hr style={{ border: "0px solid" }} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </TabPanel>
          )
        })}
      </DialogContent>
      <DialogActions>
        <AceptButton onClick={(event) => {handleSaveAccess(selectedProfile.uuid)}} variant="contained">
          Guardar
        </AceptButton>
      </DialogActions>
    </Dialog>
  )
}

export default AccessDialog;