import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPlans } from "../api/endpoints/plans";
import { getGroups } from "../api/endpoints/groups";
import "../assets/styles/home.css";
import { getPaymentMethods } from "../api/endpoints/paymentMethods";
import { getPreAdjudicationReasons } from "../api/endpoints/preAdjudicationReasons";
import { getReleaseReasons } from "../api/endpoints/release_reasons";
import { getRecLegalEntities } from '../api/endpoints/legalEntities';
import { getSalePoints } from '../api/endpoints/salePoints';

const Home = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(getPlans());
  //   dispatch(getGroups());
  //   dispatch(getPaymentMethods());
  //   dispatch(getPreAdjudicationReasons());
  //   dispatch(getReleaseReasons());
  //   dispatch(getRecLegalEntities());
  //   dispatch(getSalePoints());
  // }, [dispatch]);

  const handleLogout = () => {
    props.onLogout();
    navigate("/");
  };

  return (
    <div className="home-container">
      <div className="box">
        <h1>Welcome to the Home</h1>
        <Button variant="contained" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Home;
