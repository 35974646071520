import React from "react";
import LegalEntitiesForm from '../common/LegalEntitiesForm';
import { Box } from "@mui/material";

const LegalEntities = () => {
  return (
    <Box mt={12}>
      <LegalEntitiesForm />
    </Box>
  );
};

export default LegalEntities;