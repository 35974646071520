import { createSlice } from '@reduxjs/toolkit';
import { afip } from '../../api/endpoints/afip';

export const afipSlice = createSlice({
    name: 'afip',
    initialState: {
      tokenAfip: null,
      expirationTime: null,
      sign: null,
    },
    reducers: {
      setTokenAfip: (state, action) => {
        state.tokenAfip = action.payload.token;
        state.expirationTime = action.payload.expirationTime;
        state.sign = action.payload.sign;
        state.genTime = new Date(); // Almacena el momento en que se establece el token
      },
      clearTokenAfip: (state) => {
        state.tokenAfip = null;
        state.expirationTime = null;
        state.sign = null;
      },
    },
    extraReducers: {
      [afip.fulfilled]: (state, action) => {
        if (action.payload.status !== 'existing') {
          state.tokenAfip = action.payload.data;
          state.expirationTime = action.payload.expirationTime;
          state.sign = action.payload.sign;
        }
      },
    },
  });
  
  export const { setTokenAfip, clearTokenAfip } = afipSlice.actions;
  
  export default afipSlice.reducer;