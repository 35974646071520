import React, { useState } from 'react';
import { Dialog, DialogContent, Box, TextField, FormHelperText } from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  FormTextareaAutosize,
  SeparatorHrBox,
} from "../../assets/styles/tables";
import Actions from "./Actions";

const PreAdjReasonsDialog = (props) => {
  const {
    openPreAdjR,
    handleClosePreAdjR,
    editModePreAdjR,
    selectedPreAdjR,
    handleInputChangePreAdjR,
    handleSavePreAdjR,
  } = props;

  const [errors, setErrors] = useState({
    name: false,
  });

  const handleInputChange = (event) => {
    handleInputChangePreAdjR(event);
    const { name, value } = event.target;
    setErrors({ ...errors, [name]: !value });
  };

  const handleSave = () => {
    const newErrors = {
      name: !selectedPreAdjR.name,
    };
    setErrors(newErrors);
    if (Object.values(newErrors).includes(true)) {
      return;
    }
    handleSavePreAdjR();
  };

  const actionProps = {
    handleClose: handleClosePreAdjR,
    handleSave: handleSave,
  };

  return (
    <Dialog open={openPreAdjR} onClose={handleClosePreAdjR} maxWidth={false} PaperProps={{style: {minWidth: '600px', maxWidth: '600px'}}}>
      <FormDialogTitle>
        {editModePreAdjR
          ? "Editar Razón de preadjudicación"
          : "Agregar Razón de preadjudicación"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "block" }}>
        <Box>
          <FormBoxContainer sx={{ display: "block" }}>
            <TextField
              error={errors.name}
              margin="dense"
              name="name"
              label="Motivo"
              type="text"
              fullWidth
              value={selectedPreAdjR ? selectedPreAdjR.name : ''}
              onChange={handleInputChange}
            />
            {errors.name && <FormHelperText>El motivo es obligatorio</FormHelperText>}
            <FormDialogSubtitle
              sx={{ marginTop: -1, textAlign: "left", marginLeft: -3 }}
            >
              Descripción:
            </FormDialogSubtitle>
            <FormTextareaAutosize
              rowsMin={3}
              placeholder="Descripción"
              aria-label="Descripción"
              margin="dense"
              name="description"
              value={selectedPreAdjR ? selectedPreAdjR.description : ''}
              onChange={handleInputChangePreAdjR}
              style={{ height: "169px", marginTop: -10 }}
            />
          </FormBoxContainer>
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default PreAdjReasonsDialog;
