import React from "react";
import { Box, Table, TableBody, Typography, IconButton } from "@mui/material";
import {
  CustomTableContainer,
  CustomTableRow,
  CustomTableCell,
  CustomButtonContainer,
  CustomButton,
} from "../../../assets/styles/tablesMobile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const branchTableMobile = (props) => {
  const { branches, handleEdit, handleDelete } = props;

  return (
    <CustomTableContainer>
      <Table>
        <TableBody>
          {branches.map((branch) => (
            <CustomTableRow key={branch.id}>
              <CustomTableCell sx={{ border: "transparent" }}>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {branch.description}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Empresa
                  </Typography>
                </CustomTableCell>
                <CustomTableCell>
                  <Typography variant="subtitle1" align="center">
                    {branch.adress}
                  </Typography>
                  <Typography variant="caption" align="center">
                    Direccion
                  </Typography>
                </CustomTableCell>
                <CustomTableCell sx={{ borderBottom: "transparent" }}>
                  <Typography variant="subtitle1" align="center">
                    {branch.city}
                  </Typography>
                  <Typography variant="caption" align="center">
                  Localidad
                  </Typography>
                </CustomTableCell>
                <CustomTableCell sx={{ borderBottom: "transparent" }}>
                  <Typography variant="subtitle1" align="center">
                    {branch.province}
                  </Typography>
                  <Typography variant="caption" align="center">
                  Provincia
                  </Typography>
                </CustomTableCell>
              </CustomTableCell>
              <CustomButtonContainer >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <CustomButton
                    variant="contained"
                    onClick={() => handleEdit(branch)}
                    sx={{ marginBottom: "10px" }}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    color="tertiary"
                    onClick={() => handleDelete(branch.id, branch.uuid)}
                  >
                    <IconButton size="small">
                      <DeleteIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </CustomButton>
                </Box>
              </CustomButtonContainer>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default branchTableMobile;