import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import {
  FormBoxContainer,
  FormBoxLeft,
  FormBoxRight,
  FormDialogSubtitle,
  FormDivider,
  SelectFormControl,
  SeparatorHrBox,
} from "../assets/styles/tables";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Importa useHistory de react-router-dom

import {
  addCoupon,
  clearExcludedContracts,
  setExcludedContracts,
} from "../features/coupons/couponSlice";
import {
  addCoupons,
  addCouponsByContract,
  getCheckCuota,
} from "../api/endpoints/coupon";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const Popup = ({ data, open, setOpen, onConfirm }) => {
  const [selectedUuids, setSelectedUuids] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Dialog state changed");
    setSelectedUuids([]);
    dispatch(clearExcludedContracts());
  }, [open, dispatch]);

  useEffect(() => {
    dispatch(setExcludedContracts(selectedUuids));
  }, [selectedUuids, dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
  };

  const handleNavigate = () => {
    navigate("/cupon/cupones/");
  };

  const handleCheckboxChange = (uuid, isChecked) => {
    setSelectedUuids((prevUuids) => {
      if (isChecked) {
        return [...prevUuids, uuid];
      } else {
        return prevUuids.filter((item) => item !== uuid);
      }
    });
    console.log(data);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Información de cupón generado"}</DialogTitle>
      <DialogContent>
        {data?.confirm ? <Typography>{data?.message}</Typography> : null}
        {data?.navigate && (
          <Typography>Para ver todos los cupones toque el boton</Typography>
        )}
        {/* modificar aca contract number */}
        {data?.contracts?.map((contract, key) =>
          contract.contrato &&
          contract.contrato.contract_number !== undefined ? (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  onChange={(e) =>
                    handleCheckboxChange(
                      contract.contrato.uuid,
                      e.target.checked
                    )
                  }
                  name="contratoTope"
                  value={
                    contract.contrato.contract_number
                      ? contract.contrato.contract_number
                      : ""
                  }
                />
              }
              label={`Contrato Num: ${contract.contrato.contract_number} | Cantidad de cupones emitidos: ${contract.cantidad_cupones_emitidos}`}
            />
          ) : (
            <Typography key={key}>{contract.message}</Typography>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cerrar</Button>
        {data?.confirm && <Button onClick={handleConfirm}>Confirmar</Button>}
        {data?.navigate && (
          <Button onClick={handleNavigate}>Ver Cupones</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const GenerateCouponsFormHeader = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [postData, setPostData] = useState(null);

  const coupon = useSelector((state) => state.coupons.coupon);

  const handleGenerateClick = async () => {
    setIsLoading(true);
    try {
      const contrats = await dispatch(getCheckCuota(coupon));
      const message =
        contrats.payload && contrats.payload.length > 0
          ? "Tildar el contrato para no generar cupon:"
          : "Toque confirmar para generar los cupones.";
      console.log(
        {
          message,
          contracts: contrats.payload,
          confirm: contrats.payload.contrats ? false : true,
          navigate: false,
        },
        contrats.payload
      );
      setPostData({
        message,
        contracts: contrats.payload,
        confirm: contrats.payload[0].contrato ? true : false,
        navigate: false,
      });
      dispatch(clearExcludedContracts());
      setShowPopup(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getErrorMessage = (payload) => {
    if (payload) {
      return (
        payload.message ||
        payload.message.message ||
        "Ocurrió un error inesperado. Por favor intente nuevamente."
      );
    }
    return "Ocurrió un error inesperado. Por favor intente nuevamente.";
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      console.log("Coupon Final, handleConfirm", coupon);

      let action;
      if (coupon.numberContract !== "") {
        if (coupon.excluded_contracts && coupon.excluded_contracts.length > 0) {
          // Si hay contratos excluidos, muestra un mensaje de error y retorna
          setPostData({
            message: "Generación de cupón cancelada con éxito",
            confirm: false,
            navigate: false,
          });
          setShowPopup(true);
          return;
        } else {
          console.log("addCouponsByContract");
          // Si no hay contratos excluidos, procede con el despacho
          action = await dispatch(addCouponsByContract(coupon));
        }
      } else {
        console.log("addCoupons");
        action = await dispatch(addCoupons(coupon));
      }

      const { meta, payload } = action;

      if (meta.requestStatus === "rejected") {
        const errorMessage = getErrorMessage(payload);
        setPostData({
          message: errorMessage.message,
          confirm: false,
          navigate: false,
        });
        setShowPopup(true);
        return;
      }

      if (payload.status === 201) {
        setPostData({
          message: "¡Cupón generado con éxito! Ya puede descargar el cupón.",
          confirm: false,
          navigate: true,
        });
        setShowPopup(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <Typography variant="h4" sx={{ ml: 2 }}>
        Generacion de Cupones
      </Typography>
      <Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: "10px" }}
          onClick={handleGenerateClick}
        >
          <IconButton size="small">
            <PublishIcon sx={{ color: "whitesmoke" }} />
          </IconButton>
          Generar
        </Button>
        <Button variant="contained" color="primary" sx={{ mr: "10px" }}>
          <IconButton size="small">
            <CancelIcon sx={{ color: "whitesmoke" }} />
          </IconButton>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" sx={{ mr: "10px" }}>
          <IconButton size="small">
            <CloseIcon sx={{ color: "whitesmoke" }} />
          </IconButton>
          Salir
        </Button>
      </Box>
      <Popup
        data={postData}
        open={showPopup}
        setOpen={setShowPopup}
        onConfirm={handleConfirm}
      />
    </Box>
  );
};

// Top Form Component
const TopForm = ({ setShowAdecuacion }) => {
  const dispatch = useDispatch();
  const { branch, uuid } = useSelector((state) => state.userLogged);
  const { branches } = useSelector((state) => state.branch);
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);
  const { paymentMethods } = useSelector((state) => state.paymentMethods);

  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState([]);

  const [selectedValues, setSelectedValues] = useState({
    branch: branch[0] ? branch[0].uuid_branch : branches[0].uuid,
    plan: plans[0] ? plans[0].uuid : "",
    group: groups[0] ? groups[0].uuid : "",
    paymentMethod: null,
    contract: "",
    defermentPercentage: 0,
  });

  useEffect(() => {
    console.log("PLANES", plans);
    if (selectedValues.plan) {
      const newFilteredGroups = groups.filter(
        (group) => group.uuid_plan === selectedValues.plan
      );
      setFilteredGroups(newFilteredGroups);
      const newFilteredPaymentMethods = paymentMethods.filter(
        (paymentMethod) => paymentMethod.uuid_plan === selectedValues.plan
      );
      setFilteredPaymentMethods(newFilteredPaymentMethods);
    } else {
      setFilteredGroups([]);
      setFilteredPaymentMethods([]);
    }
  }, [selectedValues.plan, groups, paymentMethods]);

  useEffect(() => {
    if (filteredGroups[0] && filteredPaymentMethods[0]) {
      setSelectedValues((prevValues) => ({
        ...prevValues,
        group: filteredGroups[0].uuid,
        paymentMethod: filteredPaymentMethods[0].uuid,
      }));
    }
  }, [filteredGroups, filteredPaymentMethods]);

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    setSelectedValues((prevValues) => ({ ...prevValues, [name]: value }));

    if (name === "paymentMethod") {
      const paymentMethodAdecuacion = paymentMethods.filter(
        (paymentMethod) => paymentMethod.uuid === value
      );
      setShowAdecuacion(paymentMethodAdecuacion[0].name);
    }
  };

  useEffect(() => {
    let couponPayload = {
      uuid_plan: selectedValues.plan,
      uuid_group: selectedValues.group,
      uuid_payment_method: selectedValues.paymentMethod,
      numberContract: selectedValues.contract,
      uuid_user_gen: uuid,
      defermentPercentage: selectedValues.defermentPercentage,
    };
    dispatch(addCoupon(couponPayload));
  }, [selectedValues]);

  return (
    <FormBoxContainer
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <FormBoxLeft sx={{ flex: 1 }}>
        <SelectFormControl>
          <InputLabel
            margin="dense"
            id="branch-label"
            sx={{
              zIndex: 1,
              padding: "0 10px",
            }}
          >
            Sucursal
          </InputLabel>
          <Select
            labelId="branch-label"
            name="branch"
            value={selectedValues.branch}
            onChange={handleSelectChange}
          >
            {branches.map((item, key) => (
              <MenuItem key={key} value={item.uuid}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl>
          <InputLabel
            margin="dense"
            id="plan-label"
            sx={{
              zIndex: 1,
              padding: "0 10px",
            }}
          >
            Plan
          </InputLabel>
          <Select
            labelId="plan-label"
            name="plan"
            value={selectedValues.plan}
            onChange={handleSelectChange}
          >
            {plans.map((plan, key) => (
              <MenuItem key={key} value={plan.uuid}>
                {plan.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <SelectFormControl>
          <InputLabel
            margin="dense"
            id="group-label"
            sx={{
              zIndex: 1,
              padding: "0 10px",
            }}
          >
            Grupo
          </InputLabel>
          <Select
            labelId="group-label"
            name="group"
            value={selectedValues.group || ""}
            onChange={handleSelectChange}
          >
            {filteredGroups.map((group, key) => (
              <MenuItem key={key} value={group.uuid}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
      </FormBoxLeft>

      <FormDivider orientation="vertical" sx={{ height: "185px", mt: 1.5 }} />
      <FormBoxRight sx={{ flex: 1 }}>
        <SelectFormControl>
          <InputLabel
            margin="dense"
            id="paymentMethod-label"
            sx={{
              zIndex: 1,
              padding: "0 10px",
            }}
          >
            Forma de Pago
          </InputLabel>
          <Select
            labelId="paymentMethod-label"
            name="paymentMethod"
            value={selectedValues.paymentMethod || ""}
            onChange={handleSelectChange}
          >
            {filteredPaymentMethods.map((paymentMethod, key) => (
              <MenuItem key={key} value={paymentMethod.uuid}>
                {paymentMethod.name}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <TextField
          name="contract"
          label="Solo un contrato"
          type="number"
          fullWidth
          sx={{ mt: "12px" }}
          value={selectedValues.contract || 0}
          onChange={handleSelectChange}
        />
        <TextField
          name="defermentPercentage"
          label="% de Diferimiento"
          type="number"
          fullWidth
          sx={{ mt: "12px" }}
          value={selectedValues.defermentPercentage}
          onChange={handleSelectChange}
        />
      </FormBoxRight>
    </FormBoxContainer>
  );
};

// Bottom Form Component
const BottomForm = ({ showAdecuacion }) => {
  const dateNow = new Date();
  const oneWeekLater = new Date(dateNow);
  oneWeekLater.setDate(oneWeekLater.getDate() + 7);
  const twoWeeksLater = new Date(dateNow);
  twoWeeksLater.setDate(twoWeeksLater.getDate() + 14);
  const dispatch = useDispatch();
  const [checkBoxValues, setCheckBoxValues] = useState({
    month2: false,
    month3: false,
  });

  const [dueDates, setDueDates] = useState({
    month1: { due1: 0, due2: 0, due3: 0 },
    month2: { due1: 0, due2: 0, due3: 0 },
    month3: { due1: 0, due2: 0, due3: 0 },
  });

  const [dueDatesCalendar, setDueDatesCalendar] = useState({
    month1: { due1: new Date(), due2: oneWeekLater, due3: twoWeeksLater },
    month2: { due1: new Date(), due2: oneWeekLater, due3: twoWeeksLater },
    month3: { due1: new Date(), due2: oneWeekLater, due3: twoWeeksLater },
  });

  const [diffValues, setDiffValues] = useState({
    month1: { diff1: "0.00", diff2: "0.00", diff3: "0.00" },
    month2: { diff1: "0.00", diff2: "0.00", diff3: "0.00" },
    month3: { diff1: "0.00", diff2: "0.00", diff3: "0.00" },
  });

  const [noCalculateInterest, setNoCalculateInterest] = useState({
    month1: false,
    month2: false,
    month3: false,
  });

  const coupon = useSelector((state) => state.coupons.coupon);

  // useEffect(() => {
  //   const dateNow = new Date();
  //   const oneWeekLater = new Date(dateNow);
  //   oneWeekLater.setDate(oneWeekLater.getDate() + 7);
  //   const twoWeeksLater = new Date(dateNow);
  //   twoWeeksLater.setDate(twoWeeksLater.getDate() + 14);

  //   setDueDatesCalendar({
  //     month1: { due1: dateNow, due2: oneWeekLater, due3: twoWeeksLater },
  //     month2: { due1: dateNow, due2: oneWeekLater, due3: twoWeeksLater },
  //     month3: { due1: dateNow, due2: oneWeekLater, due3: twoWeeksLater },
  //   });
  // }, []);

  const handleMonthCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckBoxValues((prevValues) => ({ ...prevValues, [name]: checked }));

    // Si el checkbox del segundo mes es seleccionado, se deshabilita el del tercer mes.
    if (name === "month2" && checked) {
      setCheckBoxValues((prevValues) => ({ ...prevValues, month3: true }));
    }
  };

  const handleInterestCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setNoCalculateInterest((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));

    setDueDates(() => ({
      month1: { due1: 0, due2: 0, due3: 0 },
      month2: { due1: 0, due2: 0, due3: 0 },
      month3: { due1: 0, due2: 0, due3: 0 },
    }));

    setDiffValues(() => ({
      month1: { diff1: "0.00", diff2: "0.00", diff3: "0.00" },
      month2: { diff1: "0.00", diff2: "0.00", diff3: "0.00" },
      month3: { diff1: "0.00", diff2: "0.00", diff3: "0.00" },
    }));
  };

  const calculateInterest = (principal, dueDate1, dueDate2) => {
    //console.log(principal, dueDate1, dueDate2);
    const diffTime = Math.abs(dueDate2 - dueDate1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const interest = principal * (1 + (0.09 * diffDays) / 30);
    return Math.ceil(interest).toFixed(2);
  };

  useEffect(() => {
    // Iteramos sobre los meses
    ["month1", "month2", "month3"].forEach((month) => {
      // Si se debe calcular interés y el mes está incluido
      if (!noCalculateInterest[month] && !checkBoxValues[month]) {
        const monthDues = dueDates[month];

        const interest2 = calculateInterest(
          monthDues.due1,
          dueDatesCalendar[month].due1,
          dueDatesCalendar[month].due2
        );
        const interest3 = calculateInterest(
          monthDues.due1,
          dueDatesCalendar[month].due1,
          dueDatesCalendar[month].due3
        );

        setDueDates((prevDueDates) => ({
          ...prevDueDates,
          [month]: { ...prevDueDates[month], due2: interest2, due3: interest3 },
        }));

        setDiffValues((prevDiffValues) => ({
          ...prevDiffValues,
          [month]: {
            ...prevDiffValues[month],
            diff1:
              monthDues.due1 -
              (monthDues.due1 * coupon.defermentPercentage) / 100,
            diff2: interest2 - (interest2 * coupon.defermentPercentage) / 100,
            diff3: interest3 - (interest3 * coupon.defermentPercentage) / 100,
          },
        }));
      } else {
        // No se calcula el interés o el mes no está incluido
        // Los valores del 2do y 3er vencimiento se igualan al valor del 1er vencimiento
        setDueDates((prevDueDates) => ({
          ...prevDueDates,
          [month]: {
            ...prevDueDates[month],
            due2: dueDates[month].due1,
            due3: dueDates[month].due1,
          },
        }));

        setDiffValues((prevDiffValues) => ({
          ...prevDiffValues,
          [month]: {
            ...prevDiffValues[month],
            diff1:
              dueDates[month].due1 -
              (dueDates[month].due1 * coupon.defermentPercentage) / 100,
            diff2:
              dueDates[month].due1 -
              (dueDates[month].due1 * coupon.defermentPercentage) / 100,
            diff3:
              dueDates[month].due1 -
              (dueDates[month].due1 * coupon.defermentPercentage) / 100,
          },
        }));
      }
    });
  }, [noCalculateInterest, checkBoxValues, dueDates, dueDatesCalendar]);

  const handleDueDateCalendarChange = (event, month, dueNumber) => {
    const { value } = event.target;
    let newDueDatesCalendar = { ...dueDatesCalendar };

    newDueDatesCalendar[month][dueNumber] = new Date(value);

    setDueDatesCalendar(newDueDatesCalendar);

    const interest2 = calculateInterest(
      dueDates[month].due1,
      newDueDatesCalendar[month].due1,
      newDueDatesCalendar[month].due2
    );
    const interest3 = calculateInterest(
      dueDates[month].due1,
      newDueDatesCalendar[month].due1,
      newDueDatesCalendar[month].due3
    );

    setDueDates((prevDueDates) => ({
      ...prevDueDates,
      [month]: { ...prevDueDates[month], due2: interest2, due3: interest3 },
    }));

    setDiffValues((prevDiffValues) => ({
      ...prevDiffValues,
      [month]: {
        ...prevDiffValues[month],
        diff1:
          dueDates[month].due1 -
          (dueDates[month].due1 * coupon.defermentPercentage) / 100,
        diff2: interest2 - (interest2 * coupon.defermentPercentage) / 100,
        diff3: interest3 - (interest3 * coupon.defermentPercentage) / 100,
      },
    }));

    let couponPayload = {
      first_due_date: newDueDatesCalendar[month].due1
        .toISOString()
        .split("T")[0],
      second_due_date: newDueDatesCalendar[month].due2
        .toISOString()
        .split("T")[0],
      third_due_date: newDueDatesCalendar[month].due3
        .toISOString()
        .split("T")[0],
      first_due_amount: dueDates[month].due1,
      second_due_amount: interest2,
      third_due_amount: interest3,
    };

    dispatch(addCoupon(couponPayload));
  };

  const handleDueDateChange = (event, month, dueNumber) => {
    const { value } = event.target;
    const interest2 = calculateInterest(
      value,
      dueDatesCalendar[month].due1,
      dueDatesCalendar[month].due2
    );
    const interest3 = calculateInterest(
      value,
      dueDatesCalendar[month].due1,
      dueDatesCalendar[month].due3
    );
    let newDueDates = { ...dueDates };
    let newDiffValues = { ...diffValues };

    if (dueNumber === "due1") {
      newDueDates[month] = { due1: value, due2: value, due3: value };

      if (!checkBoxValues[`month${month}`] && !noCalculateInterest[month]) {
        newDueDates[month] = { due1: value, due2: interest2, due3: interest3 };
        newDiffValues[month] = {
          diff1: value,
          diff2: interest2,
          diff3: interest3,
        };
        console.log("entra 1");
        let couponPayload = {
          first_due_date: dueDatesCalendar[month].due1
            .toISOString()
            .split("T")[0],
          second_due_date: dueDatesCalendar[month].due2
            .toISOString()
            .split("T")[0],
          third_due_date: dueDatesCalendar[month].due3
            .toISOString()
            .split("T")[0],
          first_due_amount: newDueDates[month].due1,
          second_due_amount: interest2,
          third_due_amount: interest3,
        };

        dispatch(addCoupon(couponPayload));
      } else {
        newDiffValues[month] = {
          diff1: value,
          diff2: value,
          diff3: value,
        };

        let couponPayload = {
          first_due_date: dueDatesCalendar[month].due1
            .toISOString()
            .split("T")[0],
          second_due_date: dueDatesCalendar[month].due2
            .toISOString()
            .split("T")[0],
          third_due_date: dueDatesCalendar[month].due3
            .toISOString()
            .split("T")[0],
          first_due_amount: newDueDates[month].due1,
          second_due_amount: newDueDates[month].due1,
          third_due_amount: newDueDates[month].due1,
        };

        dispatch(addCoupon(couponPayload));
      }
    } else {
      console.log("entro 3");
      if (dueNumber === "due2") {
        console.log("entro 4");
        newDueDates[month] = { due2: value };
        newDiffValues[month] = {
          diff2: value,
        };
        let couponPayload = {
          first_due_date: dueDatesCalendar[month].due1
            .toISOString()
            .split("T")[0],
          second_due_date: dueDatesCalendar[month].due2
            .toISOString()
            .split("T")[0],
          third_due_date: dueDatesCalendar[month].due3
            .toISOString()
            .split("T")[0],
          second_due_amount: value,
        };

        dispatch(addCoupon(couponPayload));
      }
      if (dueNumber === "due3") {
        console.log("entro 5");
        newDueDates[month] = { due3: value };
        newDiffValues[month] = {
          diff3: value,
        };
        let couponPayload = {
          first_due_date: dueDatesCalendar[month].due1
            .toISOString()
            .split("T")[0],
          second_due_date: dueDatesCalendar[month].due2
            .toISOString()
            .split("T")[0],
          third_due_date: dueDatesCalendar[month].due3
            .toISOString()
            .split("T")[0],
          third_due_amount: value,
        };

        dispatch(addCoupon(couponPayload));
      }
    }

    setDueDates(newDueDates);
    setDiffValues(newDiffValues);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: showAdecuacion === "Base Abril 2014" ? "red" : "transparent",
          }}
        >
          ADECUACION
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        {[1, 2, 3].map((i) => (
          <Box key={i} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">MES {i}</Typography>
                <FormControlLabel
                  sx={{ ml: 2 }}
                  control={
                    <Checkbox
                      checked={i !== 1 ? checkBoxValues[`month${i}`] : false}
                      onChange={handleMonthCheckboxChange}
                      name={`month${i}`}
                      disabled={i === 1}
                    />
                  }
                  label={i === 1 ? "" : "No incluir"}
                />
              </Box>
            </Box>
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={noCalculateInterest[`month${i}`]}
                  onChange={handleInterestCheckboxChange}
                  name={`month${i}`}
                />
              }
              label="No calcular Interes"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                mr: 2,
              }}
            >
              <TextField
                value={dueDates[`month${i}`].due1}
                type="number"
                label="1er vto"
                disabled={checkBoxValues[`month${i}`]}
                sx={{ mr: 0.5, ml: 0.5, flex: 1 }}
                onChange={(event) =>
                  handleDueDateChange(event, `month${i}`, "due1")
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Fecha"
                type="date"
                value={dueDatesCalendar[`month${i}`].due1
                  .toISOString()
                  .substring(0, 10)}
                InputLabelProps={{ shrink: true }}
                disabled={checkBoxValues[`month${i}`]}
                sx={{ mr: 0.5, ml: 0.5, flex: 1 }}
                onChange={(event) =>
                  handleDueDateCalendarChange(event, `month${i}`, "due1")
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                mr: 2,
              }}
            >
              <TextField
                value={dueDates[`month${i}`].due2}
                type="number"
                label="2do vto"
                disabled={checkBoxValues[`month${i}`]}
                sx={{ mr: 0.5, ml: 0.5, flex: 1 }}
                onChange={(event) =>
                  handleDueDateChange(event, `month${i}`, "due2")
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Fecha"
                type="date"
                value={dueDatesCalendar[`month${i}`].due2
                  .toISOString()
                  .substring(0, 10)}
                InputLabelProps={{ shrink: true }}
                disabled={checkBoxValues[`month${i}`]}
                sx={{ mr: 0.5, ml: 0.5, flex: 1 }}
                onChange={(event) =>
                  handleDueDateCalendarChange(event, `month${i}`, "due2")
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                mr: 2,
              }}
            >
              <TextField
                value={dueDates[`month${i}`].due3}
                type="number"
                label="3er vto"
                disabled={checkBoxValues[`month${i}`]}
                sx={{ mr: 0.5, ml: 0.5, flex: 1 }}
                onChange={(event) =>
                  handleDueDateChange(event, `month${i}`, "due3")
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Fecha"
                type="date"
                value={dueDatesCalendar[`month${i}`].due3
                  .toISOString()
                  .substring(0, 10)}
                InputLabelProps={{ shrink: true }}
                disabled={checkBoxValues[`month${i}`]}
                sx={{ mr: 0.5, ml: 0.5, flex: 1 }}
                onChange={(event) =>
                  handleDueDateCalendarChange(event, `month${i}`, "due3")
                }
              />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                mb: 2,
                mr: 2,
              }}
            >
              <TextField
                value={diffValues[`month${i}`].diff1}
                type="number"
                label="Valor Difer."
                disabled
                sx={{ mr: 0.5, ml: 0.5 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* <TextField
                value="2.00"
                type="number"
                label="Valor Difer."
                disabled
                sx={{ mr: 0.5, ml: 0.5 }}
              /> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                mr: 2,
              }}
            >
              <TextField
                value={diffValues[`month${i}`].diff2}
                type="number"
                label="Valor Difer."
                disabled
                sx={{ mr: 0.5, ml: 0.5 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* <TextField
                value="2.00"
                type="number"
                label="Valor Difer."
                disabled
                sx={{ mr: 0.5, ml: 0.5 }}
              /> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                mr: 2,
              }}
            >
              <TextField
                value={diffValues[`month${i}`].diff3}
                type="number"
                label="Valor Difer."
                disabled
                sx={{ mr: 0.5, ml: 0.5 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* <TextField
                value="2.00"
                type="number"
                label="Valor Difer."
                disabled
                sx={{ mr: 0.5, ml: 0.5 }}
              /> */}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

// Main Component
const GenerateCouponsForm = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [showAdecuacion, setShowAdecuacion] = useState(false);

  return (
    <Box
      sx={{
        ml: isSmallScreen ? 3 : 20,
        mr: isSmallScreen ? 3 : 20,
        boxShadow: "2px 2px 10px rgba(1,2,10,0.3)",
        bgcolor: "background.formTables",
        borderRadius: "15px",
      }}
    >
      <GenerateCouponsFormHeader />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          style={{
            padding: "25px 35px 25px 35px",
            width: "100%",
          }}
        >
          <TopForm setShowAdecuacion={setShowAdecuacion} />
          <BottomForm showAdecuacion={showAdecuacion} />
        </Box>
      </Box>
    </Box>
  );
};

export default GenerateCouponsForm;
