import React from "react";
import CouponsForm from "../common/CouponsForm";
import { Box } from "@mui/material";

const Coupons = () => {
  return (
    <Box mt={12}>
      <CouponsForm />
    </Box>
  );
};

export default Coupons;
