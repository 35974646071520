import React from "react";

import { Box } from "@mui/material";

import { AddButton, NameViewBox } from "../../../assets/styles/tables";

import PersonAddIcon from "@mui/icons-material/PersonAdd";

import SearchBar from "../../../common/SearchBar";


const TableHeaderMobile = (props) => {
  const { handleClickOpen, handleSearch, name } = props;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 2,
        }}
      >
        <AddButton
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          <PersonAddIcon />
        </AddButton>
        <NameViewBox>{name}</NameViewBox>
      </Box>
      <SearchBar onSearch={handleSearch} />
    </>
  )
}

export default TableHeaderMobile;