import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import "../assets/styles/sideMenu.css";
import menuItems from "../data/menuItems";

const SideMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [menuStates, setMenuStates] = useState({});

  const handleItemClick = (path) => () => navigate(path);

  const handleSubmenuClick = (index) => () => {
    setMenuStates({
      ...menuStates,
      [index]: !menuStates[index],
    });
  };

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          mt: 8,
          backgroundColor: theme.palette.background.menus,
          color: theme.palette.primary.main,
        },
      }}
    >
      <List>
        {menuItems.map((item, index) => (
          <Fragment key={item.label} >
            <ListItem
              button
              onClick={
                item.children
                  ? handleSubmenuClick(index)
                  : handleItemClick(item.path)
              }
            >
              <ListItemIcon sx={{ color: theme.palette.primary.main }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
              {item.children ? (
                menuStates[index] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItem>
            {item.children ? (
              <Collapse in={menuStates[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((child, subIndex) => (
                    <Fragment key={child.label}>
                      <ListItem
                        button
                        sx={{ pl: 4 }}
                        onClick={
                          child.children
                            ? handleSubmenuClick(`${index}-${subIndex}`)
                            : handleItemClick(child.path)
                        }
                      >
                        <ListItemText primary={child.label} />
                        {child.children ? (
                          menuStates[`${index}-${subIndex}`] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        ) : null}
                      </ListItem>
                      {child.children ? (
                        <Collapse
                          in={menuStates[`${index}-${subIndex}`]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {child.children.map((grandchild, subsubIndex) => (
                              <ListItem
                                key={grandchild.label}
                                button
                                sx={{ pl: 8 }}
                                onClick={handleItemClick(grandchild.path)}
                              >
                                <ListItemText primary={grandchild.label} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      ) : null}
                    </Fragment>
                  ))}
                </List>
              </Collapse>
            ) : null}
          </Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default SideMenu;
