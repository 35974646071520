import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getGroups = createAsyncThunk("groups", async () => {
  const response = await apiClient.get("groups");
  return response.data;
});

export const getGroupById = createAsyncThunk("group/id", async (id) => {
  const response = await apiClient.get(`group/${id}`);
  return response.data;
});

export const deleteGroupById = createAsyncThunk("group/delete", async (id) => {
  const response = await apiClient.delete(`group/${id}`);
  return response.data;
});

export const addGroup = createAsyncThunk("groups/add", async (group) => {
  const response = await apiClient.post("groups", group);
  return response.data;
});

export const updateGroupById = createAsyncThunk(
  "group/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`group/${id}`, data);
    return response.data;
  }
);