import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getFees = createAsyncThunk("fees", async () => {
  const response = await apiClient.get("fees");
  return response.data;
});

export const getFeeById = createAsyncThunk("fee/id", async ( id ) => {
  const response = await apiClient.get(`fee/${id}`);
  return response.data;
});

export const deleteFeeById = createAsyncThunk("fee/delete", async ( id ) => {
  const response = await apiClient.delete(`fee/${id}`);
  return response.data;
});

export const addFee = createAsyncThunk("fees/add", async (fee) => {
  const response = await apiClient.post("fees", fee);
  return response.data;
});

export const updateFeeById = createAsyncThunk(
  "fee/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`fee/${id}`, data);
    return response.data;
  }
);