import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../api/apiClient";

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }) => {
    const response = await apiClient.post("auth/login", { email, password });
    return {
      data: response.data,
      status: response.status,
    };
  }
);