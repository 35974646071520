import React from "react";
import PlansTable from './table/PlansTable';
import { Box } from "@mui/material";

const Plans = () => {
  return (
    <Box mt={12}>
      <PlansTable />
    </Box>
  );
};

export default Plans;