import React from "react";
import ABMProfileForm from "../common/ABMProfileForm";
import { Box } from "@mui/material";

const ABMProfiles = () => {
  return (
    <Box mt={12}>
      <ABMProfileForm />
    </Box>
  );
};

export default ABMProfiles;