import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.push(action.payload);
    },
    editItem: (state, action) => {
      const { id, user, name } = action.payload;
      const foundItem = state.find((item) => item.id === id);
      if (foundItem) {
        foundItem.user = user;
        foundItem.name = name;
      }
    },
    deleteItem: (state, action) => {
      const foundItem = state.find((item) => item.id === action.payload);
      if (foundItem) {
        state.splice(state.indexOf(foundItem), 1);
      }
    },
  },
});

export const { addItem, editItem, deleteItem } = tableSlice.actions;
export default tableSlice.reducer;