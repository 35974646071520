import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import {
  FormBoxContainer,
  FormDialogSubtitle,
  FormDialogTitle,
  SeparatorHrBox,
  SelectFormControl,
  FormTextareaAutosize,
  FormBoxLeft,
  FormBoxCenter,
  FormBoxRight,
  FormDivider
} from "../../assets/styles/tables";
import Actions from "./Actions";
import { useSelector } from "react-redux";


const SubscriberDialog = (props) => {

  const nationalities = useSelector((state) => state.nationalities);
  const maritalStatuses = useSelector((state) => state.maritalStatuses);
  const ivaConditions = useSelector((state) => state.ivaConditions);
  const countries = useSelector((state) => state.countries);
  const provinces = useSelector((state) => state.provinces);
  const departments = useSelector((state) => state.departments);
  const districts = useSelector((state) => state.districts);

  const [district, setDistrict] = useState();
  const [department, setDepartment] = useState();
  const [province, setProvince] = useState();
  const [country, setCountry] = useState();

  const { open, handleClose, editMode,
    selectedSubscriber, handleInputChange,
    handleSelectChange, handleSave } = props;

  const actionProps = { handleClose, handleSave };

  if(!editMode){
    selectedSubscriber.gender = "h";
    selectedSubscriber.uuid_nationality = nationalities[3]?.uuid
    selectedSubscriber.uuid_marital_status = maritalStatuses[0]?.uuid
    selectedSubscriber.uuid_iva_condition = ivaConditions[0]?.uuid
  }

  useEffect(() => {

    const selectedDistrict = districts.find(dist => dist.uuid === selectedSubscriber.uuid_district);
    const selectedDepartment = departments.find(dep => dep.uuid === selectedDistrict?.uuid_department);
    const selectedProvince = provinces.find(prov => prov.uuid === selectedDepartment?.uuid_province);
    const selectedCountry = countries.find(country => country.uuid === selectedProvince?.uuid_country);

    setDistrict(selectedDistrict);
    setDepartment(selectedDepartment);
    setProvince(selectedProvince);
    setCountry(selectedCountry);
  }, [selectedSubscriber]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <FormDialogTitle>
        {editMode ? "Editar Suscriptor" : "Agregar Suscriptor"}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <DialogContent sx={{ display: "flex" }}>
        <Box>
          <Box>
            <FormDialogSubtitle> Datos personales </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
                <TextField
                  margin="dense"
                  name="first_name"
                  label="Nombre"
                  type="text"
                  fullWidth
                  value={selectedSubscriber.first_name}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="dni"
                  label="DNI"
                  type="text"
                  fullWidth
                  value={selectedSubscriber.dni}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="birth_date"
                  label="Fecha de nacimiento"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  value={selectedSubscriber.birth_date? selectedSubscriber.birth_date.substr(0, 10) : ""}
                  onChange={handleInputChange}
                />
                <SelectFormControl sx={{ marginTop: 1 }}>
                  <InputLabel margin="dense" id="sexo-label">
                    Sexo
                  </InputLabel>
                  <Select
                    labelId="sexo-label"
                    name="gender"
                    value={selectedSubscriber.gender}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="h">Masculino</MenuItem>
                    <MenuItem value="m">Femenino</MenuItem>
                    <MenuItem value="O">Otro</MenuItem>
                  </Select>
                </SelectFormControl>
              </FormBoxLeft>
              <FormBoxCenter />
              <FormBoxRight>
                <TextField
                  margin="dense"
                  name="last_name"
                  label="Apellido"
                  type="text"
                  fullWidth
                  value={selectedSubscriber.last_name}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="cuit"
                  label="CUIT"
                  type="text"
                  fullWidth
                  value={selectedSubscriber.cuit}
                  onChange={handleInputChange}
                />
                <SelectFormControl sx={{ marginTop: 1 }}>
                  <InputLabel margin="dense" id="nationality-label">
                    Nacionalidad
                  </InputLabel>
                  <Select
                    labelId="nationality-label"
                    name="uuid_nationality"
                    value={selectedSubscriber.uuid_nationality}
                    onChange={handleSelectChange}
                  >
                    {nationalities.map((nat) => (
                      <MenuItem
                        key={nat.uuid}
                        value={nat.uuid}>
                        {nat.description}
                      </MenuItem>
                    ))}
                  </Select>
                </SelectFormControl>
                <SelectFormControl>
                  <InputLabel margin="dense" id="uuid_marital_status-label">
                    Estado Civil
                  </InputLabel>
                  <Select
                    labelId="uuid_marital_status-label"
                    name="uuid_marital_status"
                    value={selectedSubscriber.uuid_marital_status}
                    onChange={handleSelectChange}
                  >
                    {maritalStatuses.map((ms) => (
                      <MenuItem
                        key={ms.uuid}
                        value={ms.uuid}>
                        {ms.description}
                      </MenuItem>
                    ))}
                  </Select>
                </SelectFormControl>
              </FormBoxRight>
            </FormBoxContainer>
          </Box>
        </Box>

        <FormDivider orientation="vertical" sx={{ height: "300px" }} />
        <Box>
          <Box>
            <FormDialogSubtitle> Datos de contacto </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
                <TextField
                  margin="dense"
                  name="address"
                  label="Dirección"
                  type="text"
                  fullWidth
                  value={selectedSubscriber.address}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="postal_code"
                  label="CP"
                  type="text"
                  fullWidth
                  value={selectedSubscriber.postal_code}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="phone"
                  label="Teléfono"
                  type="text"
                  fullWidth
                  value={selectedSubscriber.phone}
                  onChange={handleInputChange}
                />
                <TextField
                  margin="dense"
                  name="email"
                  label="Correo Electrónico"
                  type="text"
                  fullWidth
                  value={selectedSubscriber.email}
                  onChange={handleInputChange}
                />
              </FormBoxLeft>
              <FormBoxCenter />
              {!editMode ?
                <FormBoxRight>
                  <SelectFormControl sx={{ marginTop: 1 }}>
                    <InputLabel margin="dense" id="uuid_country-label">
                      País
                    </InputLabel>
                    <Select
                      labelId="uuid_country-label"
                      name="uuid_country"
                      value={selectedSubscriber.uuid_country || countries[0]?.uuid}
                      onChange={handleSelectChange}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.uuid} value={country.uuid}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel margin="dense" id="uuid_province-label">
                      Provincia
                    </InputLabel>
                    <Select
                      labelId="uuid_province-label"
                      name="uuid_province"
                      value={selectedSubscriber.uuid_province || provinces[0]?.uuid}
                      onChange={handleSelectChange}
                      disabled={editMode ? false : !selectedSubscriber.uuid_country}
                    >
                      {provinces
                        .filter((prov) => prov.uuid_country === selectedSubscriber.uuid_country)
                        .map((prov) => (
                          <MenuItem key={prov.uuid} value={prov.uuid}>
                            {prov.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel margin="dense" id="uuid_department-label">
                      Departamento
                    </InputLabel>
                    <Select
                      labelId="uuid_department-label"
                      name="uuid_department"
                      value={selectedSubscriber.uuid_department || departments[0]?.uuid}
                      onChange={handleSelectChange}
                      disabled={editMode ? false : !selectedSubscriber.uuid_province}
                    >
                      {departments
                        .filter((dep) => dep.uuid_province === selectedSubscriber.uuid_province)
                        .map((dep) => (
                          <MenuItem key={dep.uuid} value={dep.uuid}>
                            {dep.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel margin="dense" id="uuid_district-label">
                      Distrito
                    </InputLabel>
                    <Select
                      labelId="uuid_district-label"
                      name="uuid_district"
                      value={selectedSubscriber.uuid_district || districts[0]?.uuid}
                      onChange={handleSelectChange}
                      disabled={editMode ? false : !selectedSubscriber.uuid_department}
                    >
                      {districts
                        .filter((dist) => dist.uuid_department === selectedSubscriber.uuid_department)
                        .map((dist) => (
                          <MenuItem key={dist.uuid} value={dist.uuid}>
                            {dist.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl>
                </FormBoxRight>
                :
                <FormBoxRight>
                  <SelectFormControl sx={{ marginTop: 1 }}>
                    <InputLabel margin="dense" id="uuid_country-label">
                      País
                    </InputLabel>
                    <Select
                      labelId="uuid_country-label"
                      name="uuid_country"
                      value={country? country.uuid: ""}
                      onChange={handleSelectChange}
                      disabled={true}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.uuid} value={country.uuid}>
                          {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel margin="dense" id="uuid_province-label">
                      Provincia
                    </InputLabel>
                    <Select
                      labelId="uuid_province-label"
                      name="uuid_province"
                      value={province? province.uuid: ""}
                      onChange={handleSelectChange}
                      disabled={true}
                    >
                      {provinces
                        .map((prov) => (
                          <MenuItem key={prov.uuid} value={prov.uuid}>
                            {prov.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel margin="dense" id="uuid_department-label">
                      Departamento
                    </InputLabel>
                    <Select
                      labelId="uuid_department-label"
                      name="uuid_department"
                      value={department? department.uuid : ""}
                      onChange={handleSelectChange}
                      disabled={true}
                    >
                      {departments
                        .map((dep) => (
                          <MenuItem key={dep.uuid} value={dep.uuid}>
                            {dep.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl><SelectFormControl>
                    <InputLabel margin="dense" id="uuid_district-label">
                      Distrito
                    </InputLabel>
                    <Select
                      labelId="uuid_district-label"
                      name="uuid_district"
                      value={district? district.uuid : ""}
                      onChange={handleSelectChange}
                    >
                      {districts
                        .map((dist) => (
                          <MenuItem key={dist.uuid} value={dist.uuid}>
                            {dist.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </SelectFormControl>
                </FormBoxRight>
              }
            </FormBoxContainer>
          </Box>
        </Box>
        <FormDivider orientation="vertical" sx={{ height: "300px" }} />
        <Box>
          <Box>
            <FormDialogSubtitle> Datos adicionales: </FormDialogSubtitle>
          </Box>
          <Box>
            <FormBoxContainer>
              <FormBoxLeft>
                <SelectFormControl sx={{ mt: 1 }}>
                  <InputLabel margin="dense" id="uuid_iva_condition-label">
                    Condicion IVA
                  </InputLabel>
                  <Select
                    labelId="uuid_iva_condition-label"
                    name="uuid_iva_condition"
                    value={selectedSubscriber.uuid_iva_condition}
                    onChange={handleSelectChange}
                    disabled
                  >
                    {ivaConditions.map((iva, index) => (
                      <MenuItem key={iva.uuid} value={iva.uuid} selected={index === 1}>
                        {iva.description}
                      </MenuItem>
                    ))}
                  </Select>
                </SelectFormControl>
              </FormBoxLeft>
              <FormBoxCenter />
              <FormBoxRight></FormBoxRight>
            </FormBoxContainer>
          </Box>

          <SeparatorHrBox />

          <FormDialogSubtitle sx={{ marginTop: "-10px" }}>
            {" "}
            Observaciones:{" "}
          </FormDialogSubtitle>

          <FormTextareaAutosize
            rowsMin={3}
            placeholder="Introduzca una observacion aqui"
            aria-label="Observaciones"
            margin="dense"
            name="observations"
            value={selectedSubscriber.observations}
            onChange={handleInputChange}
            style={{ height: "155px" }}
          />
        </Box>
      </DialogContent>
      <Actions {...actionProps} />
    </Dialog>
  )
}

export default SubscriberDialog;