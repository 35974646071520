import React from "react";
import GenerateCouponsForm from "../common/GenerateCouponsForm";
import { Box } from "@mui/material";

const GenerateCoupons = () => {
  return (
    <Box mt={12}>
      <GenerateCouponsForm />
    </Box>
  );
};

export default GenerateCoupons;
