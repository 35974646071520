import {
  AddButton,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  HeaderBox,
  NameViewBox,
  PaginationBox,
  RowTableCell,
  SelectFormControl,
} from "../assets/styles/tables";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const exportData = [
  {
    paymentDate: {
      pay: "31/03/2023",
      invoiced: "31/03/2023",
    },
    coupons: 5,
    collectingEntity: "BANCO",
    invoicesFromTo: ["B-0009-00046078", "B-0009-00046084"],
    totalCoupon: 287426.0,
    totalInvoiced: 270677.0,
  },
  {
    paymentDate: {
      pay: "31/03/2023",
      invoiced: "31/03/2023",
    },
    coupons: 4,
    collectingEntity: "Camara de Comercio de Mendoza S.A.",
    invoicesFromTo: ["B-0009-00046078", "B-0009-00046084"],
    totalCoupon: 1392610.0,
    totalInvoiced: 270677.0,
  },
  {
    paymentDate: {
      pay: "31/03/2023",
      invoiced: "31/03/2023",
    },
    coupons: 5,
    collectingEntity: "DEBITO",
    invoicesFromTo: ["B-0009-00046078", "B-0009-00046084"],
    totalCoupon: 287426.0,
    totalInvoiced: 270677.0,
  },
];

const PaymentsHeader = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/cupon/registrar/registrar-cobro");
  };

  const entidadesRec = useSelector(
    (state) => state.legalEntity.recLegalEntities
  );

  const [entidadSeleccionada, setEntidadSeleccionada] = useState(null);

  useEffect(() => {
    if (entidadesRec && entidadesRec.length > 0) {
      setEntidadSeleccionada(entidadesRec[0].uuid);
    }
  }, [entidadesRec]);

  return (
    <Box
      sx={{
        mt: 3,
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
        borderRadius: "20px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        padding: "15px",
        boxShadow: "none",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Box
        sx={{
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        Cupon | Registrar | Ver cobros
      </Box>
      <Box sx={{ ml: 0, mt: 2 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#5e894f",
            borderRadius: "15px",
            height: "60px",
            width: "150px",
            fontSize: "100%",
            boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
          }}
          onClick={handleNavigate}
        >
          Registrar cobro
          <AddIcon />
        </Button>
        <TextField
          label="Fecha Desde"
          type="date"
          sx={{ ml: 2, mt: 0, width: "15%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Fecha Hasta"
          type="date"
          sx={{ ml: 2, mt: 0, width: "15%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "20%" }}>
          <InputLabel id="entidad-rec-label" shrink>
            Entidad Rec.
          </InputLabel>
          <Select
            labelId="entidad-rec-label"
            name="entidadRec"
            value={entidadSeleccionada}
            onChange={(e) => setEntidadSeleccionada(e.target.value)}
          >
            {entidadesRec.map((entidad) => (
              <MenuItem key={entidad.uuid} value={entidad.uuid}>
                {entidad.company}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
      </Box>
    </Box>
  );
};

const PaymentsForm = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mr: 15,
        ml: 5,
      }}
    >
      <PaymentsHeader />
      <GeneralTableBox
        sx={{
          mt: 0,
          ml: 0,
          mr: 0,
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          boxShadow: "none",
          "*::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "*::-webkit-scrollbar-track": {
            background: "inherit",
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "gray",
            borderRadius: "20px",
          },
          "*::-webkit-scrollbar-corner": {
            background: "inherit",
          },
          "@media (min-width: 900px) and (max-width: 1536px)": {
            width: "100%",
          },
        }}
      >
        <GeneralTableContainer
          sx={{
            maxHeight: "45em",
            overflow: "auto",
          }}
        >
          <Table aria-label="plan table">
            <TableHead>
              <TableRow>
                <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                  Fecha Pago/Fact.
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "3%" }}>
                  Cupones
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                  Entidad Rec.
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "15%" }}>
                  Facturas desde-hasta
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "5%" }}>
                  Total Cupon
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "5%" }}>
                  Total Facturado
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "3%" }}>
                  Confirmar Facturación
                </HeadTableCell>
                <HeadTableCell sx={{ textAlign: "center", width: "3%" }}>
                  Facturado
                </HeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exportData.map((data, key) => (
                <TableRow key={key}>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    Pago: {data.paymentDate.pay} <br />
                    Fact.: {data.paymentDate.invoiced}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {data.coupons}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {data.collectingEntity}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {data.invoicesFromTo[0]} <br />
                    {data.invoicesFromTo[1]}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {data.totalCoupon.toFixed(2)}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {data.totalInvoiced.toFixed(2)}
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    <IconButton
                      size="small"
                      color="primary"
                      // onClick={() => handleOpenDialog(row.uuid, row)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </RowTableCell>
                  <RowTableCell sx={{ textAlign: "center" }}>
                    {true ? (
                      <IconButton
                        size="small"
                        color="primary"
                        // onClick={() => handleOpenDialog(row.uuid, row)}
                      >
                        <CheckIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        color="primary"
                        // onClick={() => handleOpenDialog(row.uuid, row)}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </RowTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GeneralTableContainer>
      </GeneralTableBox>
    </Box>
  );
};

export default PaymentsForm;
