import { createSlice } from "@reduxjs/toolkit";

export const CouponSlice = createSlice({
  name: "Coupons",
  initialState: {
    coupon: {
      numberContract: "",
      uuid_plan: "",
      uuid_group: "",
      uuid_payment_method: "",
      uuid_user_gen: "",
      first_due_date: "",
      second_due_date: "",
      third_due_date: "",
      first_due_amount: "",
      second_due_amount: "",
      third_due_amount: "",
      defermentPercentage: 0,
      excluded_contracts: [],
    },
    editCoupon: {
      contrato: "",
      plan: "",
      cuotas: "",
      first_interest: 0,
      second_interest: 0,
      third_interest: 0,
      items: {
        pei: "",
        social_fee: "",
        life_insurance: "",
        fire_insurance: "",
        property_tax: "",
      },
      fee_mount: 0,
    },
  },

  reducers: {
    addCoupon: (state, action) => {
      state.coupon = { ...state.coupon, ...action.payload };
    },
    clearCoupon: (state) => {
      state.coupon = {
        numberContract: "",
        uuid_plan: "",
        uuid_group: "",
        uuid_payment_method: "",
        uuid_user_gen: "",
        first_due_date: "",
        second_due_date: "",
        third_due_date: "",
        first_due_amount: "",
        second_due_amount: "",
        third_due_amount: "",
        excluded_contracts: [],
      };
    },
    addEditCoupon: (state, action) => {
      state.editCoupon = { ...state.coupon, ...action.payload };
    },
    setEditCouponField: (state, action) => {
      const { key, value, nestedKey } = action.payload;
      if (nestedKey) {
        state.editCoupon[nestedKey][key] = value;
      } else {
        state.editCoupon[key] = value;
      }
    },
    clearEditCoupon: (state) => {
      state.editCoupon = {
        contrato: "",
        plan: "",
        cuotas: "",
        first_interest: 0,
        second_interest: 0,
        third_interest: 0,
        items: {
          pei: "",
          social_fee: "",
          life_insurance: "",
          fire_insurance: "",
          property_tax: "",
        },
        fee_mount: 0,
      };
    },
    setExcludedContracts: (state, action) => {
      state.coupon.excluded_contracts = action.payload;
    },
    clearExcludedContracts: (state, action) => {
      state.coupon.excluded_contracts = [];
    },
  },
});

export const {
  addCoupon,
  clearCoupon,
  addEditCoupon,
  setEditCouponField,
  clearEditCoupon,
  setExcludedContracts,
  clearExcludedContracts
} = CouponSlice.actions;

export default CouponSlice.reducer;
