import React from "react";
import LifeInsuranceExportForm from "../common/LifeInsuranceExportForm";
import { Box } from "@mui/material";

const LifeInsuranceExport = () => {
  return (
    <Box mt={12}>
      <LifeInsuranceExportForm />
    </Box>
  );
};

export default LifeInsuranceExport;
