import { createSlice } from '@reduxjs/toolkit';
import { addInvoices, getContractFilter } from '../../api/endpoints/registerPayment';

const RegisterPaymentSlice = createSlice({
  name: "registerPayment",
  initialState: {
    cupones: [],
    status: "idle",
    error: null,
    addInvoiceStatus: "idle", // Nuevo estado para addInvoices
  },
  reducers: {
    resetAddInvoiceStatus: (state) => {
      state.addInvoiceStatus = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractFilter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getContractFilter.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cupones = action.payload.coupons;
      })
      .addCase(getContractFilter.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addInvoices.pending, (state) => {
        state.addInvoiceStatus = "loading";
      })
      .addCase(addInvoices.fulfilled, (state) => {
        state.addInvoiceStatus = "succeeded";
      })
      .addCase(addInvoices.rejected, (state, action) => {
        state.addInvoiceStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetAddInvoiceStatus } = RegisterPaymentSlice.actions;

export default RegisterPaymentSlice.reducer;