import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  HeadTableCell,
  RowTableCell,
  EditButton,
  DeleteButton,
  HeaderBox,
  AddButton,
  SelectFormControl,
} from "../../assets/styles/tables";
import { setSelectedFee } from "../../features/fees/feeSlice";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const FeeStructureTab = (props) => {
  const dispatch = useDispatch();
  const { fees, selectedFee } = useSelector((state) => state.fees);
  const {
    handleEditFeeStructure,
    handleDeleteFeeStructure,
    feeStructures,
    setSelectedFeeStructure,
    setOpenFeeStructure,
    selectedPlan,
  } = props;

  const [filteredFeeStructures, setFilteredFeeStructures] = useState([]);

  useEffect(() => {
    if (fees.length > 0 && selectedPlan) {
      const feesWithSelectedPlan = fees.filter(
        (fee) => fee.uuid_plan === selectedPlan.uuid
      );
      setFilteredFeeStructures(feesWithSelectedPlan);
      if (feesWithSelectedPlan.length > 0) {
        dispatch(setSelectedFee(feesWithSelectedPlan[0]));
        setFilteredFeeStructures(
          feeStructures.filter(
            (feeStructure) =>
              feeStructure.uuid_fee === feesWithSelectedPlan[0].uuid
          )
        );
      }
    }
  }, [fees, feeStructures, selectedPlan, dispatch]);

  const handleClickOpen = () => {
    setSelectedFeeStructure({
      name: "",
      fixed_amount: "",
      rate: "",
      iva: "",
    });
    setOpenFeeStructure(true);
  };

  const handleSelectChange = (event) => {
    dispatch(setSelectedFee(event.target.value));
    setFilteredFeeStructures(
      feeStructures.filter(
        (feeStructure) => feeStructure.uuid_fee === event.target.value.uuid
      )
    );
  };

  useEffect(() => {
    setFilteredFeeStructures(
      feeStructures.filter(
        (feeStructure) => feeStructure.uuid_fee === selectedFee.uuid
      )
    );
  }, [selectedFee, feeStructures]);

  return (
    <Box sx={{ mt: 2 }}>
      <br />
      <HeaderBox sx={{ marginLeft: 0 }}>
        <AddButton variant="contained" onClick={handleClickOpen}>
          <PostAddIcon fontSize="large" />
        </AddButton>
        <SelectFormControl sx={{ ml: 2, mt: 0, width: "30%" }}>
          <InputLabel margin="dense" id="feeType-label">
            Tipo Cuota
          </InputLabel>
          <Select
            labelId="feeType-label"
            name="feeType"
            onChange={handleSelectChange}
            value={selectedFee}
          >
            {fees
              .filter((fee) => fee.uuid_plan === selectedPlan.uuid)
              .map((fee, index) => (
                <MenuItem key={index} value={fee}>
                  {fee.name}
                </MenuItem>
              ))}
          </Select>
        </SelectFormControl>
      </HeaderBox>
      <br />
      {filteredFeeStructures.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <HeadTableCell sx={{ width: "25%", textAlign: "center" }}>
                Item
              </HeadTableCell>
              <HeadTableCell sx={{ width: "20%", textAlign: "center" }}>
                Monto Fijo($)
              </HeadTableCell>
              <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>
                Porcentaje(%)
              </HeadTableCell>
              <HeadTableCell sx={{ width: "15%", textAlign: "center" }}>
                Iva(%)
              </HeadTableCell>
              <HeadTableCell sx={{ width: "25%", textAlign: "center" }}>
                Acciones
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredFeeStructures.map((feeStructure, index) => (
              <TableRow key={index}>
                <RowTableCell sx={{ textAlign: "center" }}>
                  {feeStructure.name}
                </RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  {feeStructure.fixed_amount}
                </RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  {feeStructure.rate}
                </RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  {feeStructure.iva}
                </RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  <EditButton
                    variant="contained"
                    onClick={() => handleEditFeeStructure(feeStructure)}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </EditButton>
                  <DeleteButton
                    variant="contained"
                    onClick={() => handleDeleteFeeStructure(feeStructure.uuid)}
                  >
                    <IconButton size="small">
                      <DeleteIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </DeleteButton>
                </RowTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>
          No se encontraron estructuras de cuotas para el plan seleccionado.
        </p>
      )}
    </Box>
  );
};

export default FeeStructureTab;
