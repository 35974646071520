import CheckIcon from "@mui/icons-material/Check";
import CloudIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCouponById,
  getCouponById,
  getCouponsDeferred,
  getPdfByCoupon,
  updateCouponById,
} from "../api/endpoints/coupon";
import {
  FormDialogTitle,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  HeaderBox,
  PaginationBox,
  RowTableCell,
  SelectFormControl,
  SeparatorHrBox,
} from "../assets/styles/tables";
import Actions from "../components/dialog/Actions";
import {
  addEditCoupon,
  setEditCouponField,
} from "../features/coupons/couponSlice";
import Loading from "./Loading";

const CouponsForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isScreenSizeLarge = useMediaQuery(theme.breakpoints.up("xl")); // Para pantallas más grandes
  const isScreenSizeMedium = useMediaQuery(
    theme.breakpoints.between("sm", "lg")
  ); // Para pantallas medianas
  const [isLoading, setIsLoading] = useState(false);
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);
  const selectedData = useSelector((state) => state.coupons.editCoupon);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 12;
  const [filter, setFilter] = useState({
    quarter: "",
    year: new Date().getFullYear(),
    plan: plans.length > 0 ? plans[0].uuid : "",
    group: "",
    order: "",
  });
  const [totalPages, setTotalPages] = useState(0); // Agregado para almacenar el número total de páginas
  const [totalCount, setTotalCount] = useState(0);
  const [coeficienteActual, setCoeficienteActual] = useState(0);
  const [open, setOpen] = useState(false);
  const [openAdelantoDialog, setOpenAdelantoDialog] = useState(false);

  const dueMapping = [
    {
      label: "1er Vto.:",
      amount: "first_due_amount",
      date: "first_due_date",
      interest: "first_interest",
    },
    {
      label: "2do Vto.:",
      amount: "second_due_amount",
      date: "second_due_date",
      interest: "second_interest",
    },
    {
      label: "3er Vto.:",
      amount: "third_due_amount",
      date: "third_due_date",
      interest: "third_interest",
    },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date;
  };

  const calculateInterest = (principal, dueDate1, dueDate2) => {
    let date1 = formatDate(dueDate1);
    let date2 = formatDate(dueDate2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const total = principal * (1 + (0.09 * diffDays) / 30);
    const interest = total - principal;

    return {
      interest: Math.ceil(interest).toFixed(2),
      total: Math.ceil(total).toFixed(2),
    };
    // return Math.ceil(interest).toFixed(2);
  };

  const fetchDataWithFilters = async () => {
    setIsLoading(true); // Establecer isLoading en true al inicio
    try {
      const response = await dispatch(
        getCouponsDeferred({
          year: filter.year,
          quarter: filter.quarter,
          plan: filter.plan,
          group: filter.group,
          contract_number: filter.order,
          page: page,
          per_page: ITEMS_PER_PAGE,
        })
      );
      console.log(response.payload);
      // Transforma los cupones a la estructura esperada
      const transformedCoupons = response.payload.coupons.map((coupon) => {
        // Obtiene el valor de fee_type_name
        const feeTypeName = coupon.fee_type_name;

        // Determina el valor de cuotas en función de feeTypeName
        let cuotas;
        switch (feeTypeName) {
          case "Adecuacion":
            cuotas = "Adecuacion";
            break;
          case "Adelanto":
            cuotas = "Cupon de Puntaje";
            break;
          case "Adjudicacion":
            cuotas = "Cupon de Adjudicacion";
            break;
          default:
            cuotas = `${coupon.fee_number1} ${
              coupon.fee_number2 === 0 ? "" : ` - ${coupon.fee_number2}`
            }`;
            break;
        }

        let importes;
        if (feeTypeName === "Adjudicacion" || feeTypeName === "Adelanto") {
          // Formatea la fecha en el formato deseado
          const formattedDate = new Date(
            coupon.first_due_date
          ).toLocaleDateString("es-AR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          importes = [
            `Venc: ${formattedDate} $${Number(coupon.first_due_amount)}`,
          ];
        } else {
          importes = [
            `1 - Venc: ${new Date(coupon.first_due_date).toLocaleDateString(
              "es-AR",
              { day: "2-digit", month: "2-digit", year: "numeric" }
            )} $${Number(coupon.first_due_amount)}`,
            `2 - Venc: ${new Date(coupon.second_due_date).toLocaleDateString(
              "es-AR",
              { day: "2-digit", month: "2-digit", year: "numeric" }
            )} $${Number(coupon.second_due_amount)}`,
            `3 - Venc: ${new Date(coupon.third_due_date).toLocaleDateString(
              "es-AR",
              { day: "2-digit", month: "2-digit", year: "numeric" }
            )} $${Number(coupon.third_due_amount)}`,
          ];
        }

        return {
          uuid: coupon.uuid,
          orden: coupon.contract_number, // Aquí, puedes asignar cualquier valor que necesites
          suscriptores: `- ${coupon.subscribers[0].subscriber.last_name}, ${coupon.subscribers[0].subscriber.first_name}`,
          mes: new Date(coupon.first_due_date).toLocaleString("default", {
            month: "long",
          }),
          año: coupon.year,
          cuotas: cuotas,
          importes: importes,
          cancelado:
            (coupon.cancellation_date === "0000-00-00 00:00:00") |
            (coupon.cancellation_date === "None") ? (
              "No"
            ) : (
              <>
                Sí
                <CheckIcon sx={{ ml: 1, mt: 1, color: "green" }} />
                {new Date(coupon.cancellation_date).toLocaleDateString()}
              </>
            ),
          can_delete: coupon.can_delete,
        };
      });

      setData(transformedCoupons);
      setTotalPages(response.payload.pages);
      setTotalCount(response.payload.total);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false); // Establecer isLoading en false al final
  };

  // Actualiza los datos cuando cambian los filtros o el número de página
  useEffect(() => {
    fetchDataWithFilters();
  }, [filter, page]);

  const handleDownload = async (uuid) => {
    try {
      const pdfData = await dispatch(getPdfByCoupon(uuid));
      // console.log(pdfData);

      // Decodifica la cadena en base64 en bytes
      let pdfBytes = atob(pdfData.payload.base64_pdf);

      // Crear un nuevo array de bytes
      let bytes = new Uint8Array(pdfBytes.length);
      for (let i = 0; i < pdfBytes.length; i++) {
        bytes[i] = pdfBytes.charCodeAt(i);
      }

      // Crea un blob de los bytes y luego una URL de ese blob
      let blob = new Blob([bytes.buffer], { type: "application/pdf" });
      let url = URL.createObjectURL(blob);

      // Abre la URL en una nueva ventana
      window.open(url);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilter = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  const generateMenuItems = (start, end) => {
    let items = [];
    for (let i = start; i >= end; i--) {
      items.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return items;
  };

  const updateAmounts = (name, value) => {
    const difference = parseFloat(value) - parseFloat(selectedData.items[name]);

    const amountsToUpdate = [
      "first_due_amount",
      "second_due_amount",
      "third_due_amount",
    ];

    amountsToUpdate.forEach((amount) => {
      const updatedAmount = parseFloat(selectedData[amount]) + difference;
      dispatch(
        setEditCouponField({ key: amount, value: updatedAmount.toString() })
      );
    });
  };

  const normalizeValue = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const inputValue = e.target.value;
    const value = normalizeValue(inputValue).toString();
    console.log("modifica", name, inputValue, value, selectedData);

    if (name in selectedData.items) {
      dispatch(
        setEditCouponField({ key: name, value: value, nestedKey: "items" })
      );
      updateAmounts(name, value); // Llamada a la función de actualización
    } else {
      dispatch(setEditCouponField({ key: name, value: value }));
    }

    if (name === "second_interest") {
      const originalAmount = parseFloat(selectedData.second_due_amount);
      const secondAmount =
        originalAmount + (parseFloat(value) - selectedData.second_interest);
      dispatch(
        setEditCouponField({
          key: "second_due_amount",
          value: secondAmount.toString(),
        })
      );
    } else if (name === "third_interest") {
      const originalAmount = parseFloat(selectedData.third_due_amount);
      const thirdAmount =
        originalAmount + (parseFloat(value) - selectedData.third_interest);
      dispatch(
        setEditCouponField({
          key: "third_due_amount",
          value: thirdAmount.toString(),
        })
      );
    } else if (name === "fee_mount") {
      const originalFirstAmount = parseFloat(selectedData.third_due_amount);
      const firstAmount =
        originalFirstAmount + (parseFloat(value) - selectedData.fee_mount);

      const originalSecondAmount = parseFloat(selectedData.third_due_amount);
      const secondAmount =
        originalSecondAmount + (parseFloat(value) - selectedData.fee_mount);

      const originalThirdAmount = parseFloat(selectedData.third_due_amount);
      const thirdAmount =
        originalThirdAmount + (parseFloat(value) - selectedData.fee_mount);

      dispatch(
        setEditCouponField({
          key: "first_due_amount",
          value: firstAmount.toString(),
        })
      );
      dispatch(
        setEditCouponField({
          key: "second_due_amount",
          value: secondAmount.toString(),
        })
      );
      dispatch(
        setEditCouponField({
          key: "third_due_amount",
          value: thirdAmount.toString(),
        })
      );
    }
  };

  const handleInputAdelantoChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    console.log("modifica", name, value);

    // Permitir solo números y puntos como entrada válida
    if (/^\d*\.?\d*$/.test(value) || value === "." || value === "0.") {
      dispatch(
        setEditCouponField({
          key: name,
          value: value,
        })
      );
    }
  };

  const handleDateChange = (e, dueDate) => {
    const name = e.target.name;
    const value = e.target.value;

    // Dispatching the action to update the specific date field
    dispatch(setEditCouponField({ key: name, value: value }));

    if (dueDate === "first_due_date") {
      // dispatch(
      //   setEditCouponField({ key: "first_interest", value: newInterestValue })
      // );
    } else if (dueDate === "second_due_date") {
      const secondInterest = calculateInterest(
        selectedData.first_due_amount,
        selectedData.first_due_date,
        value
      );
      dispatch(
        setEditCouponField({
          key: "second_interest",
          value: secondInterest.interest,
        })
      );
      dispatch(
        setEditCouponField({
          key: "second_due_amount",
          value: secondInterest.total,
        })
      );
    } else if (dueDate === "third_due_date") {
      const secondInterest = calculateInterest(
        selectedData.first_due_amount,
        selectedData.first_due_date,
        selectedData.second_due_date
      );
      dispatch(
        setEditCouponField({
          key: "second_interest",
          value: secondInterest.interest,
        })
      );
      dispatch(
        setEditCouponField({
          key: "second_due_amount",
          value: secondInterest.total,
        })
      );

      const thirdInterest = calculateInterest(
        selectedData.first_due_amount,
        selectedData.first_due_date,
        value
      );
      dispatch(
        setEditCouponField({
          key: "third_interest",
          value: thirdInterest.interest,
        })
      );
      dispatch(
        setEditCouponField({
          key: "third_due_amount",
          value: thirdInterest.total,
        })
      );
    }
  };

  const handleDateAdelantoChange = (e) => {
    const value = e.target.value;

    // Dispatching the action to update all three date fields
    dispatch(setEditCouponField({ key: "first_due_date", value: value }));
    dispatch(setEditCouponField({ key: "second_due_date", value: value }));
    dispatch(setEditCouponField({ key: "third_due_date", value: value }));
  };

  const handleOpenDialog = async (uuid, row) => {
    setOpen(true);
    const response = await dispatch(getCouponById(uuid));
    console.log(response.payload);
    const plan = plans.find((plan) => plan.uuid === response.payload.uuid_plan);

    const finalData = {
      ...response.payload,
      orden: row.orden,
      cuotas: row.cuotas,
      plan,
      first_interest: 0,
      //second_interest:
      //response.payload.second_due_amount - response.payload.first_due_amount,
      second_interest: calculateInterest(
        response.payload.first_due_amount,
        response.payload.first_due_date,
        response.payload.second_due_date
      ).interest,
      //third_interest:
      //response.payload.third_due_amount - response.payload.first_due_amount,
      third_interest: calculateInterest(
        response.payload.first_due_amount,
        response.payload.first_due_date,
        response.payload.third_due_date
      ).interest,
      fee_mount:
        response.payload.first_due_amount -
        response.payload.items.fire_insurance -
        response.payload.items.life_insurance -
        response.payload.items.pei -
        response.payload.items.property_tax -
        response.payload.items.social_fee,
    };

    console.log(finalData);
    dispatch(addEditCoupon(finalData));
    setCoeficienteActual(finalData.score_coefficent);
    // setOpen(true);
    if (response.payload.fee_type_name === "Adelanto") {
      setOpenAdelantoDialog(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    //console.log(selectedData);
    setOpen(false);
    setOpenAdelantoDialog(false);
  };

  const handleSave = () => {
    const {
      uuid,
      first_due_amount,
      second_due_amount,
      third_due_amount,
      first_interest,
      second_interest,
      third_interest,
      fee_mount,
      score_coefficent,
      items: {
        pei,
        social_fee,
        fire_insurance,
        life_insurance,
        property_tax,
        uuid_pei,
        uuid_social_fee,
        uuid_fire_insurance,
        uuid_life_insurance,
        uuid_property_tax,
      },
      first_due_date,
      second_due_date,
      third_due_date,
    } = selectedData;

    const data = {
      first_due_amount: parseFloat(first_due_amount),
      second_due_amount: parseFloat(second_due_amount),
      third_due_amount: parseFloat(third_due_amount),
      first_interest: parseFloat(first_interest),
      second_interest: parseFloat(second_interest),
      third_interest: parseFloat(third_interest),
      fee_mount: parseFloat(fee_mount),
      score_coefficent: parseFloat(score_coefficent),
      items: {
        pei: parseFloat(pei),
        social_fee: parseFloat(social_fee),
        fire_insurance: parseFloat(fire_insurance),
        life_insurance: parseFloat(life_insurance),
        property_tax: parseFloat(property_tax),
        uuid_pei,
        uuid_social_fee,
        uuid_fire_insurance,
        uuid_life_insurance,
        uuid_property_tax,
      },
      first_due_date,
      second_due_date,
      third_due_date,
    };
    console.log(data, selectedData);
    dispatch(updateCouponById({ id: uuid, data }));
    setOpenAdelantoDialog(false);
    setOpen(false);
  };

  const handleDeleteCoupon = async (uuid) => {
    try {
      await dispatch(deleteCouponById(uuid)).unwrap(); // Desempaquetar la promesa
      fetchDataWithFilters(); // Refrescar los datos
    } catch (error) {
      // Manejar cualquier error que pueda ocurrir durante la eliminación
      console.error(error);
    }
  };

  const actionProps = { handleClose, handleSave };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // mr: 15,
          ml: isScreenSizeLarge ? "3%" : isScreenSizeMedium ? "5%" : 5, // Ajustar de acuerdo a tus necesidades
          // mr: isScreenSizeLarge ? 12 : isScreenSizeMedium ? 12 : 12,
          // width: isScreenSizeLarge
          //   ? "100%"
          //   : isScreenSizeMedium
          //   ? "100%"
          //   : "100%", // Ajustar de acuerdo a tus necesidades
          // ml: 5,
          width: "100%",
        }}
      >
        <Box
          sx={{
            mt: 3,
            justifyContent: "center",
            backgroundColor: "#f2f2f2",
            borderRadius: "20px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            padding: "15px",
            boxShadow: "none",
            borderBottom: "1px solid #ccc",
            overflowX: "auto", // permite el desplazamiento horizontal
            maxWidth: "90%", // Ajusta este valor a tu necesidad
          }}
        >
          <Box
            sx={{
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            Estadísticas | Ver cupones diferidos
          </Box>
          <HeaderBox sx={{ ml: 0, mt: 2 }}>
            <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
              <InputLabel margin="dense" id="quarter-label">
                Trimestre
              </InputLabel>
              <Select
                labelId="quarter-label"
                name="quarter"
                value={filter.quarter}
                onChange={handleFilter}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value={1}>1 (Ene, Feb, Mar)</MenuItem>
                <MenuItem value={2}>2 (Abr, May, Jun)</MenuItem>
                <MenuItem value={3}>3 (Jul, Ago, Sep)</MenuItem>
                <MenuItem value={4}>4 (Oct, Nov, Dic)</MenuItem>
              </Select>
            </SelectFormControl>
            <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
              <InputLabel margin="dense" id="year-label">
                Año
              </InputLabel>
              <Select
                labelId="year-label"
                id="year-select"
                value={filter.year}
                onChange={handleFilter}
                name="year"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "300px", // Ajusta este valor a tu necesidad
                      overflow: "auto",
                    },
                  },
                }}
              >
                {generateMenuItems(2024, 1990)}
              </Select>
            </SelectFormControl>
            <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
              <InputLabel margin="dense" id="trimestre-label">
                Plan
              </InputLabel>
              <Select
                labelId="plan-label"
                id="plan-select"
                value={filter.plan}
                onChange={handleFilter}
                name="plan"
              >
                {plans.map((plan) => (
                  <MenuItem key={plan.uuid} value={plan.uuid}>
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
            </SelectFormControl>
            <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
              <InputLabel margin="dense" id="group-label">
                Grupo
              </InputLabel>
              <Select
                labelId="group-label"
                id="group-select"
                value={filter.group}
                onChange={handleFilter}
                name="group"
              >
                <MenuItem value="">Todos</MenuItem>
                {groups.map((group) => (
                  <MenuItem key={group.uuid} value={group.uuid}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
            </SelectFormControl>
            <SelectFormControl sx={{ ml: 2, mt: 0, width: "15%" }}>
              <TextField
                label="Orden"
                id="order-input"
                value={filter.order}
                onChange={handleFilter}
                name="order"
              />
            </SelectFormControl>
          </HeaderBox>
        </Box>
        <GeneralTableBox
          sx={{
            overflowX: "auto", // permite el desplazamiento horizontal
            maxWidth: "90%", // Ajusta este valor a tu necesidad
            mt: 0,
            ml: 0,
            mr: 0,
            mb: 0,
            borderRadius: "0px",
            "*::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "*::-webkit-scrollbar-track": {
              background: "inherit",
              boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            },
            "*::-webkit-scrollbar-thumb": {
              backgroundColor: "gray",
              borderRadius: "20px",
            },
            "*::-webkit-scrollbar-corner": {
              background: "inherit",
            },
          }}
        >
          <GeneralTableContainer
            sx={{
              minHeight: "50em",
              overflow: "auto",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    Orden
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", minWidth: "10%" }}>
                    Suscriptores
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    Mes
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    Año
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    Cuotas
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", minWidth: "10%" }}>
                    Importes
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    Cancelado
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    Editar
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    Eliminar
                  </HeadTableCell>
                  <HeadTableCell sx={{ textAlign: "center", width: "10%" }}>
                    Descargar
                  </HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <Loading />
                ) : (
                  data.map((row, index) => (
                    <TableRow key={index}>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.orden}
                      </RowTableCell>
                      <RowTableCell
                        sx={{
                          textAlign: "center",
                          padding: 0,
                          whiteSpace: "nowrap",
                          overflow: "visible",
                        }}
                      >
                        {row.suscriptores}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.mes}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.año}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center", padding: 0 }}>
                        {row.cuotas}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.importes.map((importe, index) => (
                          <Chip
                            label={importe}
                            key={index}
                            sx={{ margin: 0.3 }}
                          />
                        ))}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.cancelado}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.cancelado === "No" && ( // Esta es la condición de renderizado
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => handleOpenDialog(row.uuid, row)}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        {row.can_delete === true && ( // Esta es la condición de renderizado
                          <IconButton
                            size="small"
                            color="tertiary"
                            onClick={() => handleDeleteCoupon(row.uuid)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </RowTableCell>
                      <RowTableCell sx={{ textAlign: "center" }}>
                        <IconButton
                          size="small"
                          color="info"
                          onClick={() => handleDownload(row.uuid)}
                        >
                          <CloudIcon />
                        </IconButton>
                      </RowTableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </GeneralTableContainer>
        </GeneralTableBox>
        <PaginationBox
          sx={{
            backgroundColor: theme.palette.background.formTables,
            mt: 0,
            borderBottomRightRadius: "20px",
            borderBottomLeftRadius: "20px",
            maxWidth: "90%", // Ajusta este valor a tu necesidad
          }}
        >
          <Pagination
            sx={{ mr: 10 }}
            count={totalPages} // Usamos totalPages directamente
            shape="rounded"
            page={page}
            onChange={(event, value) => setPage(value)}
          />
          <Box
            sx={{
              ml: 10,
              fontSize: "15px",
            }}
          >
            Registros: {totalCount}
          </Box>
        </PaginationBox>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <FormDialogTitle>Editar Vencimiento</FormDialogTitle>
        <SeparatorHrBox>
          <hr />
        </SeparatorHrBox>
        <DialogContent>
          <Box>
            Contrato: {selectedData.orden}
            <br />
            Plan: {selectedData.plan.name}
            <br />
            Cuota/s: {selectedData.cuotas}
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Interes</TableCell>
                  <TableCell>Cuota</TableCell>
                  {selectedData.items.uuid_pei && <TableCell>PEI</TableCell>}
                  {selectedData.items.uuid_social_fee && (
                    <TableCell>Cta Social</TableCell>
                  )}
                  {selectedData.items.uuid_life_insurance && (
                    <TableCell>Seg. Vida</TableCell>
                  )}
                  {selectedData.items.uuid_fire_insurance && (
                    <TableCell>Seguro de Incendio</TableCell>
                  )}
                  {selectedData.items.uuid_property_tax && (
                    <TableCell>Impuesto Inmobiliario</TableCell>
                  )}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dueMapping.map((due, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        padding: 0,
                        whiteSpace: "nowrap",
                        overflow: "visible",
                      }}
                    >
                      {due.label}
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        name={due.amount}
                        type="text"
                        disabled
                        value={selectedData[due.amount]}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        name={due.interest}
                        type="text"
                        value={selectedData[due.interest]}
                        disabled={rowIndex === 0}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        name={`fee_mount`}
                        type="text"
                        value={selectedData.fee_mount}
                        disabled={rowIndex !== 0}
                        onChange={handleInputChange}
                      />
                    </TableCell>
                    {selectedData.items.uuid_pei && (
                      <TableCell>
                        <TextField
                          size="small"
                          fullWidth
                          name={`pei`}
                          type="text"
                          value={selectedData.items.pei}
                          disabled={rowIndex !== 0}
                          onChange={handleInputChange}
                        />
                      </TableCell>
                    )}
                    {selectedData.items.uuid_social_fee && (
                      <TableCell>
                        <TextField
                          size="small"
                          fullWidth
                          name={`social_fee`}
                          type="text"
                          value={selectedData.items.social_fee}
                          disabled={rowIndex !== 0}
                          onChange={handleInputChange}
                        />
                      </TableCell>
                    )}
                    {selectedData.items.uuid_life_insurance && (
                      <TableCell>
                        <TextField
                          size="small"
                          fullWidth
                          name={`life_insurance`}
                          type="text"
                          value={selectedData.items.life_insurance}
                          disabled={rowIndex !== 0}
                          onChange={handleInputChange}
                        />
                      </TableCell>
                    )}
                    {selectedData.items.uuid_fire_insurance && (
                      <TableCell>
                        <TextField
                          size="small"
                          fullWidth
                          name={`fire_insurance`}
                          type="text"
                          value={selectedData.items.fire_insurance}
                          disabled={rowIndex !== 0}
                          onChange={handleInputChange}
                        />
                      </TableCell>
                    )}
                    {selectedData.items.uuid_property_tax && (
                      <TableCell>
                        <TextField
                          size="small"
                          fullWidth
                          name={`property_tax`}
                          type="text"
                          value={selectedData.items.property_tax}
                          disabled={rowIndex !== 0}
                          onChange={handleInputChange}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextField
                        name={due.date}
                        type="date"
                        value={
                          selectedData[due.date]
                            ? selectedData[due.date].substr(0, 10)
                            : ""
                        }
                        disabled={rowIndex === 0}
                        onChange={(e) => handleDateChange(e, due.date)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Actions {...actionProps} />
      </Dialog>
      <Dialog
        open={openAdelantoDialog}
        onClose={handleClose}
        maxWidth={false}
        sx={{
          "& .MuiDialog-paper": {
            width: "50%",
          },
        }}
      >
        <FormDialogTitle>Editar Vencimiento</FormDialogTitle>
        <SeparatorHrBox>
          <hr />
        </SeparatorHrBox>
        <DialogContent>
          <Box>
            Contrato: {selectedData.orden}
            <br />
            Plan: {selectedData.plan.name}
            <br />
            Cuota/s: {selectedData.cuotas}
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <Box sx={{ mt: 3, mb: 2 }}>
                  Coeficiente Actual: {coeficienteActual}
                </Box>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      padding: 0,
                      whiteSpace: "nowrap",
                      overflow: "visible",
                    }}
                  >
                    Coef.de Punt.:
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="score_coefficent"
                      type="text"
                      value={
                        selectedData.score_coefficent
                          ? selectedData.score_coefficent
                          : ""
                      }
                      onChange={handleInputAdelantoChange}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="first_due_date"
                      type="date"
                      value={
                        selectedData.first_due_date
                          ? selectedData.first_due_date.substr(0, 10)
                          : ""
                      }
                      onChange={handleDateAdelantoChange}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Actions {...actionProps} />
      </Dialog>
    </>
  );
};

export default CouponsForm;
