// ArchivedContractsTable.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    ContractButton,
    GeneralTableBox,
    GeneralTableContainer,
    HeadTableCell,
    RowTableCell
} from "../../assets/styles/tables";

import {
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import { getContracts } from "../../api/endpoints/contracts";
import {
    setLoadingContractsFalse,
    setLoadingContractsTrue,
} from "../../features/contracts/contractSlice";

const ArchivedContractsTable = ({ style, ...props }) => {
  const dispatch = useDispatch();
  const { handleEdit, setOpen } = props;
  // Cambia los selectores de Redux para que apunten a la parte de contratos archivados (si existe)
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);
  // Aquí usarías selectores para los contratos archivados
  const { contracts } = useSelector((state) => state.contracts);
  const archivedCounts = useSelector((state) => state.contracts.archivedContractCount );

  return (
    <GeneralTableBox style={style.generalTableBox}>
      <GeneralTableContainer style={style.generalTableContainer}>
        <Table aria-label="archived contracts table">
          <TableHead>
            <TableRow>
              <HeadTableCell sx={{ width: "15%", minWidth: "200px" }}>
                Nombre del plan
              </HeadTableCell>
              <HeadTableCell sx={{ width: "20%", minWidth: "200px" }}>
                Contratos Archivados sin Suscripción
              </HeadTableCell>
              <HeadTableCell sx={{ width: "5%", textAlign: "center" }}>
                Editar
              </HeadTableCell>
              <HeadTableCell sx={{ width: "20%", minWidth: "200px" }}>
                Contratos Archivados con Suscripción
              </HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "5%" }}>
                Editar
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map((plan, key) => (
              <TableRow key={key}>
                <RowTableCell>{plan.name}</RowTableCell>
                <RowTableCell>
                  {groups.map((group, key) => {
                    const numContratos =
                    archivedCounts.no_subscribers[group.uuid]?.count || 0;

                    if (group.uuid_plan === plan.uuid && numContratos > 0) {
                      return (
                        <React.Fragment key={key}>
                          <strong>{group.name}:</strong> {numContratos}
                          <br />
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}

                  {archivedCounts.no_group[plan.uuid] > 0 && (
                    <React.Fragment>
                      <strong>SIN GRUPO:</strong> {archivedCounts.no_group[plan.uuid]}
                      <br />
                    </React.Fragment>
                  )}
                </RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  <ContractButton
                    variant="contained"
                    onClick={async () => {
                      props.onOpen();
                      const filters = {
                        archived: true,
                        uuid_plan: plan.uuid,
                        has_subscribers: false,
                      };
                      setOpen(true);
                      dispatch(setLoadingContractsTrue());
                      try {
                        const contratos = await dispatch(getContracts(filters));
                        handleEdit(contratos.payload);
                      } catch (error) {
                        console.error("Error fetching contracts:", error);
                      } finally {
                        dispatch(setLoadingContractsFalse());
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#e3e3e3",
                      },
                    }}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "#5e894f" }} />
                    </IconButton>
                  </ContractButton>
                </RowTableCell>
                <RowTableCell>
                  {groups.map((group, key) => {
                    const numContratos =
                    archivedCounts.with_subscribers[group.uuid]?.count || 0;
                    if (group.uuid_plan === plan.uuid && numContratos > 0) {
                      return (
                        <React.Fragment key={key}>
                          <strong>{group.name}</strong>: {numContratos}
                          <br />
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  <ContractButton
                    variant="contained"
                    onClick={async () => {
                      props.onOpen();
                      const filters = {
                        archived: true,
                        uuid_plan: plan.uuid,
                        has_subscribers: true,
                      };
                      setOpen(true);
                      dispatch(setLoadingContractsTrue());
                      try {
                        const contratos = await dispatch(getContracts(filters));
                        handleEdit(contratos.payload);
                      } catch (error) {
                        console.error("Error fetching contracts:", error);
                      } finally {
                        dispatch(setLoadingContractsFalse());
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#e3e3e3",
                      },
                    }}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "#5e894f" }} />
                    </IconButton>
                  </ContractButton>
                </RowTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </GeneralTableContainer>
    </GeneralTableBox>
  );
};

export default ArchivedContractsTable;
