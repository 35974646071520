import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
    AddButton,
    DeleteButton,
    EditButton,
    FormBoxCenter,
    FormBoxContainer,
    FormBoxLeft,
    FormBoxRight,
    FormDialogSubtitle,
    FormDialogTitle,
    FormDivider,
    FormTextareaAutosize,
    GeneralTableContainer,
    HeadTableCell,
    RowTableCell,
    SelectFormControl,
    SeparatorHrBox
} from "../../assets/styles/tables";
import Actions from "./Actions";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PostAddIcon from "@mui/icons-material/PostAdd";

import {
    addContractAdditional,
    addContractSubscriber,
    archiveContract,
    deleteContractAdditional,
    deleteContractSubscriber,
    getContractById,
    getContractsCount,
    releaseContract,
    updateContractAdditional,
    updateContractSubscriber,
} from "../../api/endpoints/contracts";

import {
    setContractAdditionals,
    setContractSubscribers,
    setContractToEdit
} from "../../features/contracts/contractSlice";

const ContractEditDialog = (props) => {
  const {
    openEditContract,
    handleCloseEditContract,
    handleClose,
    //setSelectedContract,
    //selectedContract,
    handleInputChangeEditContract: handleInputChange,
    handleSelectChangeEditContract: handleSelectChange,
    handleSaveEditContract,
    save,
    setSave,
  } = props;

  const dispatch = useDispatch();
  const selectedContract = useSelector(
    (state) => state.contracts.contractToEdit
  );
  const [activeTab, setActiveTab] = useState(0);
  const [openSubscriber, setOpenSubscriber] = useState(false);
  // const [selectedSubscriber, setSelectedSubscriber] = useState({});
  const selectedSubscriber = useSelector(
    (state) => state.contracts.contractSubscribers
  );
  const additionals = useSelector((state) => state.additionals);
  const subscribers = useSelector((state) => state.subscribers);
  const [editModeSubscriber, setEditModeSubscriber] = useState(false);
  const [searchInput, setSearchInput] = useState(""); // Estado para la cadena ingresada en el input
  const [matchingSubscribers, setMatchingSubscribers] = useState([]); // Estado para los suscriptores que coinciden con la cadena ingresada
  const [subscribersData, setSubscribersData] = useState(subscribers); // Estado para los suscriptores que coinciden con la cadena ingresada

  const [openAdditional, setOpenAdditional] = useState(false);
  //const [selectedAdditional, setSelectedAdditional] = useState({});
  const selectedAdditional = useSelector(
    (state) => state.contracts.contractAdditionals
  );
  const [editModeAdditional, setEditModeAdditional] = useState(false);
  const [additionalsData, setAdditionalsData] = useState(additionals); // Estado para los suscriptores que coinciden con la cadena ingresada
  const [editDescription, setEditDescription] = useState(false);

  const releaseReasons = useSelector((state) => state.releaseReasons);
  const { plans } = useSelector((state) => state.plans);
  const { groups } = useSelector((state) => state.groups);
  const paymentMethods = useSelector(
    (state) => state.paymentMethods.paymentMethods
  );
  let legalEntities = useSelector((state) => state.legalEntity.legalEntities);
  const constTypes = useSelector((state) => state.constructionTypes);
  const preAdjReasons = useSelector(
    (state) => state.preAdjudicationReasons.preAdjudicationReasons
  );

  const isArchiveDialog = useSelector(
    (state) => state.contracts.isArchiveDialog
  );

  const handleEditSubscriber = (subscriber) => {
    dispatch(setContractSubscribers(subscriber));
    setOpenSubscriber(true);
    setEditModeSubscriber(true);
  };

  const handleEditAdditional = (additional) => {
    dispatch(setContractAdditionals(additional));
    setOpenAdditional(true);
    setEditModeAdditional(true);
  };

  const handleCloseSubscriber = () => {
    setOpenSubscriber(false);
  };

  const handleCloseAdditional = () => {
    setOpenAdditional(false);
  };

  const handleClickOpenSubscriber = () => {
    dispatch(setContractSubscribers({}));
    setOpenSubscriber(true);
    setEditModeSubscriber(false);
  };

  const handleClickOpenAdditional = () => {
    dispatch(setContractAdditionals({}));
    setOpenAdditional(true);
    setEditModeAdditional(false);
  };

  const handleSaveSubscriber = async () => {
    const subscriberCopy = { ...selectedSubscriber };
    subscriberCopy.uuid_subscriber = selectedSubscriber.subscriber.uuid;
    subscriberCopy.uuid_contract = selectedContract.uuid;
    if (editModeSubscriber) {
      await dispatch(updateContractSubscriber(subscriberCopy));
      dispatch(getContractsCount(false)).unwrap();
      dispatch(getContractsCount(true)).unwrap();
    } else {
      if (subscriberCopy.subscriber) {
        await dispatch(addContractSubscriber(subscriberCopy));
        dispatch(getContractsCount(false)).unwrap();
      dispatch(getContractsCount(true)).unwrap();
      }
    }
    const newContract = await dispatch(getContractById(selectedContract.uuid));
    dispatch(setContractToEdit(newContract.payload));
    handleCloseSubscriber();
    setSave(!save);
  };

  const handleSaveAdditional = async () => {
    const additionalCopy = { ...selectedAdditional };

    if (editModeAdditional) {
      additionalCopy.uuid = selectedAdditional.uuid;
      additionalCopy.uuid_additional = selectedAdditional.additional.uuid;
      additionalCopy.uuid_contract = selectedContract.uuid;
      await dispatch(updateContractAdditional(additionalCopy));
      setEditModeAdditional(false);
    } else {
      additionalCopy.current_fee = 0;
      additionalCopy.final_fee = 0;
      additionalCopy.initial_fee = 0;

      additionalCopy.uuid_additional = selectedAdditional.additional
        ? selectedAdditional.additional.uuid
        : "";
      additionalCopy.uuid_contract = selectedContract.uuid;

      if (additionalCopy.additional) {
        await dispatch(addContractAdditional(additionalCopy));
      }
      setEditModeAdditional(false);
    }

    const newContract = await dispatch(getContractById(selectedContract.uuid));
    dispatch(setContractToEdit(newContract.payload));
    handleCloseAdditional();
    setSave(!save);
  };

  const handleDeleteSubscriber = async (uuid_subscriber, uuid_contract) => {
    const contractSubscriber = { uuid_subscriber, uuid_contract };
    dispatch(deleteContractSubscriber(contractSubscriber));

    const newContract = await dispatch(getContractById(selectedContract.uuid));

    dispatch(setContractToEdit(newContract.payload));
    setSave(!save);
  };

  const handleDeleteAdditional = async (uuid) => {
    const contractAdditional = { uuid };
    dispatch(deleteContractAdditional(contractAdditional));

    const newContract = await dispatch(getContractById(selectedContract.uuid));

    dispatch(setContractToEdit(newContract.payload));
    setSave(!save);
  };

  const handleReleaseContract = async () => {
    // dispatch(deleteContractAdditional(selectedContract));
    await dispatch(releaseContract(selectedContract));
    dispatch(getContractsCount(false)).unwrap();
    dispatch(getContractsCount(true)).unwrap();
    handleCloseEditContract();
    handleClose();
    // const newContract = await dispatch(getContractById(selectedContract.uuid));

    // setContractToEdit(newContract.payload)
    // setSave(!save)
  };

  const confirmAction = (action) => {
    const confirmation = window.confirm(
      `¿Confirma nuevamente para ${action} este contrato?`
    );
    if (confirmation) {
      // Aquí asumimos que handleArchiveContract puede manejar ambos casos
      // posiblemente pasando un argumento adicional para especificar la acción
      handleArchiveContract(action);
    }
  };

  const handleArchiveContract = async () => {
    if (isArchiveDialog) {
      await dispatch(
        archiveContract({ uuid: selectedContract.uuid, action: "unarchive" })
      );
    } else {
      await dispatch(
        archiveContract({ uuid: selectedContract.uuid, action: "archive" })
      );
    }
    dispatch(getContractsCount(false)).unwrap();
    dispatch(getContractsCount(true)).unwrap();
    handleCloseEditContract();
    handleClose();
  };

  const actionProps = {
    handleClose: handleCloseEditContract,
    handleSave: handleSaveEditContract,
  };
  const actionSubscriberProps = {
    handleClose: handleCloseSubscriber,
    handleSave: handleSaveSubscriber,
  };
  const actionAdditionalProps = {
    handleClose: handleCloseAdditional,
    handleSave: handleSaveAdditional,
  };

  legalEntities = legalEntities.filter((le) => le.is_intermediate_entity === 1);

  const handleInputChangeSubscriber = (event) => {
    const { name, value } = event.target;
    dispatch(setContractSubscribers({ ...selectedSubscriber, [name]: value }));
  };

  const handleInputChangeAdditional = async (event) => {
    const { name, value } = event.target;
    dispatch(setContractAdditionals({ ...selectedAdditional, [name]: value }));
    if (name === "uuid") {
      const additional = additionals.find(
        (additional) => additional.uuid === value
      );
      if (additional && additional.name === "Descuentos") {
        setEditDescription(true);
      } else {
        setEditDescription(false);
      }
      const selectedAdditionalForm = additionalsData.find(
        (additional) => additional.uuid === value
      );
      dispatch(
        setContractAdditionals({
          ...selectedAdditional,
          additional: selectedAdditionalForm,
        })
      );
    }
  };

  const handleInputChangeDNISubscriber = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);

    const filteredSubscribers = subscribersData.filter((subscriber) =>
      subscriber.dni.includes(inputValue)
    );
    setMatchingSubscribers(filteredSubscribers);
  };

  const handleSubscriberSelection = (subscriber) => {
    // Actualizar el valor del campo 'dni' en selectedSubscriber
    if (subscriber) {
      dispatch(
        setContractSubscribers({
          ...selectedSubscriber,
          subscriber: subscriber,
        })
      );
    }
  };

  // useEffect(() => {
  //   const fetchContracts = async () => {
  //     try {
  //       const subscribers = await dispatch(getSubscribers()).unwrap();
  //       setSubscribersData(subscribers);

  //       const additionals = await dispatch(getAdditionals()).unwrap();
  //       await dispatch(addAdditionals(additionals));

  //       setAdditionalsData(additionals);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchContracts();
  // }, [save]);

  return (
    <Dialog
      open={openEditContract}
      onClose={handleCloseEditContract}
      maxWidth={false}
    >
      <FormDialogTitle>
        CONTRATO N° {selectedContract ? selectedContract.contract_number : ""}
      </FormDialogTitle>
      <SeparatorHrBox>
        <hr />
      </SeparatorHrBox>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            scrollButtons="auto"
            variant="scrollable"
            sx={{
              "& .MuiTab-root": {
                fontSize: "0.9rem", // Tamaño de la fuente
                fontWeight: "bold", // Peso de la fuente (negrita)
              },
            }}
          >
            <Tab label="DATOS GENERALES DEL CONTRATO" />
            <Tab label="SUSCRIPTORES DEL CONTRATO" />
            <Tab label="SUB-PLANES/ADICIONALES DEL CONTRATO" />
            <Tab label="LIBERAR CONTRATO" />
            <Tab
              label={
                isArchiveDialog ? "DESARCHIVAR CONTRATO" : "ARCHIVAR CONTRATO"
              }
            />
          </Tabs>
        </Box>
        {activeTab === 0 && (
          <>
            <DialogContent sx={{ display: "flex" }}>
              <Box>
                <Box>
                  <FormBoxContainer>
                    <FormBoxLeft sx={{ width: "250px" }}>
                      <TextField
                        margin="dense"
                        name="contract_number"
                        label="Contrato N°"
                        type="text"
                        fullWidth
                        disabled
                        value={
                          selectedContract
                            ? selectedContract.contract_number
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                      <TextField
                        margin="dense"
                        name="plan_name"
                        label="Plan"
                        type="text"
                        fullWidth
                        disabled
                        value={
                          plans.find(
                            (plan) => plan.uuid === selectedContract.uuid_plan
                          )?.name || "-"
                        }
                        onChange={handleInputChange}
                      />
                      <TextField
                        margin="dense"
                        name="group_name"
                        label="Grupo"
                        type="text"
                        fullWidth
                        disabled
                        value={
                          groups.find(
                            (group) =>
                              group.uuid === selectedContract.uuid_group
                          )?.name || "-"
                        }
                        onChange={handleInputChange}
                      />
                      <SelectFormControl sx={{ marginTop: 1 }}>
                        <InputLabel
                          margin="dense"
                          id="uuid_payment_method-label"
                        >
                          Forma Pago
                        </InputLabel>
                        <Select
                          labelId="uuid_payment_method-label"
                          name="uuid_payment_method"
                          value={selectedContract.uuid_payment_method}
                          onChange={handleSelectChange}
                        >
                          {paymentMethods.map((pm, index) => (
                            <MenuItem key={index} value={pm.uuid}>
                              {pm.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectFormControl>
                    </FormBoxLeft>
                    <FormBoxCenter />
                    <FormBoxRight sx={{ width: "250px" }}>
                      <TextField
                        margin="dense"
                        name="first_fee"
                        label="Cuota cominezo"
                        type="text"
                        fullWidth
                        value={selectedContract.first_fee}
                        onChange={handleInputChange}
                      />
                      <SelectFormControl sx={{ marginTop: 1 }}>
                        <InputLabel
                          margin="dense"
                          id="uuid_payment_method-label"
                        >
                          Entidad Intermedia
                        </InputLabel>
                        <Select
                          labelId="uuid_legal_entity-label"
                          name="uuid_legal_entity"
                          value={selectedContract.uuid_legal_entity}
                          onChange={handleSelectChange}
                        >
                          {legalEntities.map((le, index) => (
                            <MenuItem key={index} value={le.uuid}>
                              {le.legal_business_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectFormControl>
                      <TextField
                        margin="dense"
                        name="request_number"
                        label="Número solicitud"
                        type="text"
                        sx={{ marginTop: "12px" }}
                        fullWidth
                        value={selectedContract.request_number}
                        onChange={handleInputChange}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedContract.by_email === "True"
                                ? true
                                : selectedContract.by_email === "False"
                                ? false
                                : selectedContract.by_email === true
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleInputChange({
                                target: {
                                  name: "by_email",
                                  value: !(selectedContract.by_email === "True"
                                    ? true
                                    : selectedContract.by_email === "False"
                                    ? false
                                    : selectedContract.by_email === true
                                    ? true
                                    : false),
                                },
                              })
                            }
                            sx={{ ml: "-10px" }}
                          />
                        }
                        label="Cupón por mail"
                        sx={{
                          mt: "8px",
                          ml: "0px",
                          border: "1px solid #c6c7c5",
                          borderRadius: "4px",
                          width: "100%",
                          padding: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                    </FormBoxRight>
                  </FormBoxContainer>
                </Box>
                <SelectFormControl sx={{ marginTop: 1.5 }}>
                  <InputLabel margin="dense" id="uuid_construction_type-label">
                    Tipo de construcción
                  </InputLabel>
                  <Select
                    labelId="uuid_construction_type-label"
                    name="uuid_construction_type"
                    value={selectedContract.uuid_construction_type}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value={"None"}>Ninguno</MenuItem>
                    {constTypes.map((ct, index) => (
                      <MenuItem key={index} value={ct.uuid}>
                        {ct.name}
                      </MenuItem>
                    ))}
                  </Select>
                </SelectFormControl>
                <FormBoxContainer sx={{ marginTop: 2 }}>
                  <FormBoxLeft sx={{ width: "250px" }}>
                    <div style={{ marginTop: 15, color: "grey" }}>
                      Adelanto C/Punt.: $0.000
                    </div>
                    <div style={{ marginTop: 15, color: "grey" }}>
                      Adelanto S/Punt.: $0.000
                    </div>
                  </FormBoxLeft>
                  <FormBoxCenter />
                  <FormBoxRight sx={{ width: "250px" }}>
                    <div style={{ marginTop: 15, color: "grey" }}>
                      Puntos: 0.00
                    </div>
                  </FormBoxRight>
                </FormBoxContainer>
              </Box>

              <FormDivider orientation="vertical" sx={{ height: "440px" }} />
              <Box>
                <Box>
                  <FormBoxContainer>
                    <FormBoxLeft sx={{ width: "250px" }}>
                      <TextField
                        margin="dense"
                        name="initial_subscription_date"
                        label="Fecha Suscripcion"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        value={
                          selectedContract.initial_subscription_date
                            ? selectedContract.initial_subscription_date.substr(
                                0,
                                10
                              )
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                      <TextField
                        margin="dense"
                        name="current_possessor_date"
                        label="Fecha Poseedor"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        value={
                          selectedContract.current_possessor_date
                            ? selectedContract.current_possessor_date.substr(
                                0,
                                10
                              )
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                      <TextField
                        margin="dense"
                        name="adjudication_date"
                        label="Fecha adjudicación"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        value={
                          selectedContract.adjudication_date
                            ? selectedContract.adjudication_date.substr(0, 10)
                            : ""
                        }
                        onChange={handleInputChange}
                      />
                      <TextField
                        margin="dense"
                        name="life_insurance"
                        label="Seguro de vida ($)"
                        type="text"
                        fullWidth
                        value={selectedContract.life_insurance}
                        onChange={handleInputChange}
                      />
                    </FormBoxLeft>
                    <FormBoxCenter />
                    <FormBoxRight sx={{ width: "250px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedContract.private_land === "True"
                                ? true
                                : selectedContract.private_land === "False"
                                ? false
                                : selectedContract.private_land === true
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleInputChange({
                                target: {
                                  name: "private_land",
                                  value: !(selectedContract.private_land ===
                                  "True"
                                    ? true
                                    : selectedContract.private_land === "False"
                                    ? false
                                    : selectedContract.private_land === true
                                    ? true
                                    : false),
                                },
                              })
                            }
                            sx={{ ml: "-10px" }}
                          />
                        }
                        label="Lote propio"
                        sx={{
                          mt: "8px",
                          ml: "0px",
                          border: "1px solid #c6c7c5",
                          borderRadius: "4px",
                          width: "100%",
                          padding: "6px",
                          display: "flex",
                          paddingLeft: "50px",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedContract.is_premium === "True"
                                ? true
                                : selectedContract.is_premium === "False"
                                ? false
                                : selectedContract.is_premium === true
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleInputChange({
                                target: {
                                  name: "is_premium",
                                  value: !(selectedContract.is_premium ===
                                  "True"
                                    ? true
                                    : selectedContract.is_premium === "False"
                                    ? false
                                    : selectedContract.is_premium === true
                                    ? true
                                    : false),
                                },
                              })
                            }
                            sx={{ ml: "-10px" }}
                          />
                        }
                        label="Es plan premium"
                        sx={{
                          mt: "12px",
                          ml: "0px",
                          border: "1px solid #c6c7c5",
                          borderRadius: "4px",
                          width: "100%",
                          padding: "6px",
                          display: "flex",
                          paddingLeft: "50px",
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedContract.not_generate === "True"
                                ? true
                                : selectedContract.not_generate === "False"
                                ? false
                                : selectedContract.not_generate === true
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleInputChange({
                                target: {
                                  name: "not_generate",
                                  value: !(selectedContract.not_generate ===
                                  "True"
                                    ? true
                                    : selectedContract.not_generate === "False"
                                    ? false
                                    : selectedContract.not_generate === true
                                    ? true
                                    : false),
                                },
                              })
                            }
                            sx={{ ml: "-10px" }}
                          />
                        }
                        label="No generar"
                        sx={{
                          mt: "12px",
                          ml: "0px",
                          border: "1px solid #c6c7c5",
                          borderRadius: "4px",
                          width: "100%",
                          padding: "6px",
                          display: "flex",
                          paddingLeft: "50px",
                        }}
                      />
                      <SelectFormControl sx={{ marginTop: 1.5 }}>
                        <InputLabel
                          margin="dense"
                          id="uuid_pre_adjudication_reason-label"
                        >
                          Motivo
                        </InputLabel>
                        <Select
                          disabled
                          labelId="uuid_pre_adjudication_reason-label"
                          name="uuid_pre_adjudication_reason"
                          value={selectedContract.uuid_pre_adjudication_reason}
                          onChange={handleSelectChange}
                        >
                          {preAdjReasons.map((par, index) => (
                            <MenuItem key={index} value={par.uuid}>
                              {par.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectFormControl>
                    </FormBoxRight>
                  </FormBoxContainer>
                </Box>
                <FormDialogSubtitle
                  sx={{
                    marginTop: "-5px",
                    textAlign: "left",
                    marginLeft: "-20px",
                  }}
                >
                  Direccion Construccion:
                </FormDialogSubtitle>
                <FormTextareaAutosize
                  rowsMin={3}
                  placeholder=""
                  aria-label="construction_site_location"
                  margin="dense"
                  name="construction_site_location"
                  value={selectedContract.construction_site_location}
                  onChange={handleInputChange}
                  style={{ height: "100px", borderColor: "lightgray" }}
                />
              </Box>
            </DialogContent>
          </>
        )}
        {activeTab === 1 && selectedContract.subscribers && (
          <>
            <Box sx={{ minHeight: "480px", minWidth: "1099px" }}>
              <DialogContent sx={{ display: "flex" }}>
                <FormBoxContainer>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 4 }}
                  >
                    <Box>
                      <AddButton
                        variant="contained"
                        onClick={handleClickOpenSubscriber}
                        sx={{ marginBottom: "10px" }}
                      >
                        <PersonAddIcon />
                      </AddButton>
                      <GeneralTableContainer
                        sx={{ boxShadow: "5px 5px 20px rgba(1,2,10,0.3)" }}
                      >
                        <Table aria-label="contract table">
                          <TableHead>
                            <TableRow>
                              <HeadTableCell sx={{ minWidth: "100px" }}>
                                DNI
                              </HeadTableCell>
                              <HeadTableCell sx={{ minWidth: "402px" }}>
                                Nombre
                              </HeadTableCell>
                              <HeadTableCell
                                sx={{ textAlign: "center", width: "100px" }}
                              >
                                Cuota Social
                              </HeadTableCell>
                              <HeadTableCell
                                sx={{ textAlign: "center", width: "120px" }}
                              >
                                Seg. Vida (%)
                              </HeadTableCell>
                              <HeadTableCell
                                sx={{ textAlign: "center", width: "120px" }}
                              >
                                {" "}
                                Postal/Mail{" "}
                              </HeadTableCell>
                              <HeadTableCell
                                sx={{ textAlign: "center", width: "100px" }}
                              >
                                Acciones
                              </HeadTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedContract.subscribers.map(
                              (subscriber, key) => (
                                <TableRow key={key}>
                                  <RowTableCell>
                                    {subscriber
                                      ? subscriber.subscriber.dni
                                      : ""}
                                  </RowTableCell>
                                  <RowTableCell>
                                    {subscriber
                                      ? subscriber.subscriber.last_name
                                      : ""}
                                    ,{" "}
                                    {subscriber
                                      ? subscriber.subscriber.first_name
                                      : ""}
                                  </RowTableCell>
                                  <RowTableCell sx={{ textAlign: "center" }}>
                                    {subscriber
                                      ? subscriber.social_fee_payment
                                        ? "SI"
                                        : "NO"
                                      : ""}
                                  </RowTableCell>
                                  <RowTableCell sx={{ textAlign: "center" }}>
                                    {subscriber.life_insurance_percentage}
                                  </RowTableCell>
                                  <RowTableCell sx={{ textAlign: "center" }}>
                                    {subscriber
                                      ? subscriber.postage_address
                                        ? "SI"
                                        : "NO"
                                      : ""}{" "}
                                    /{" "}
                                    {subscriber
                                      ? subscriber.mail_coupon
                                        ? "SI"
                                        : "NO"
                                      : ""}
                                  </RowTableCell>
                                  <RowTableCell
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <EditButton
                                      variant="contained"
                                      sx={{
                                        width: "80%",
                                        height: "80%",
                                        marginX: 0,
                                      }}
                                      onClick={() =>
                                        handleEditSubscriber(subscriber)
                                      }
                                    >
                                      <IconButton size="small">
                                        <EditIcon
                                          sx={{ color: "whitesmoke" }}
                                        />
                                      </IconButton>
                                    </EditButton>
                                    <DeleteButton
                                      variant="contained"
                                      sx={{
                                        width: "80%",
                                        height: "80%",
                                        marginTop: 0.5,
                                        marginX: 0,
                                        "&:hover": {
                                          backgroundColor: "#A93226",
                                        },
                                      }}
                                      onClick={() =>
                                        handleDeleteSubscriber(
                                          subscriber.subscriber.uuid,
                                          selectedContract.uuid
                                        )
                                      }
                                    >
                                      <IconButton size="small">
                                        <DeleteIcon
                                          sx={{ color: "whitesmoke" }}
                                        />
                                      </IconButton>
                                    </DeleteButton>
                                  </RowTableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </GeneralTableContainer>
                    </Box>
                  </Grid>
                </FormBoxContainer>
              </DialogContent>
            </Box>
            <Dialog
              open={openSubscriber}
              onClose={handleCloseSubscriber}
              maxWidth={false}
            >
              <FormDialogTitle>
                {editModeSubscriber
                  ? "EDITAR SUSCRIPTOR"
                  : "AGREGAR SUSCRIPTOR"}
              </FormDialogTitle>
              <SeparatorHrBox>
                <hr />
              </SeparatorHrBox>
              <DialogContent sx={{ display: "flex" }}>
                <Box>
                  <Box>
                    <FormBoxContainer>
                      <FormBoxLeft sx={{ width: "300px" }}>
                        {!editModeSubscriber ? (
                          <Autocomplete
                            options={matchingSubscribers}
                            getOptionLabel={(subscriber) =>
                              `(${subscriber.dni}) ${subscriber.last_name}, ${subscriber.first_name}`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                disabled={editModeSubscriber}
                                margin="dense"
                                name="dni"
                                label="DNI"
                                type="text"
                                fullWidth
                                value={
                                  selectedSubscriber.subscriber
                                    ? selectedSubscriber.subscriber.dni
                                    : ""
                                }
                                onChange={handleInputChangeDNISubscriber}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // Para evitar el autocompletado del navegador
                                }}
                              />
                            )}
                            onChange={(event, value) =>
                              handleSubscriberSelection(value)
                            }
                          />
                        ) : (
                          <TextField
                            disabled={editModeSubscriber}
                            margin="dense"
                            name="dni"
                            label="DNI"
                            type="text"
                            fullWidth
                            value={
                              selectedSubscriber.subscriber
                                ? selectedSubscriber.subscriber.dni
                                : ""
                            }
                            onChange={handleInputChangeSubscriber}
                          />
                        )}
                        <TextField
                          margin="dense"
                          name="name"
                          label="Nombre"
                          type="text"
                          fullWidth
                          disabled
                          value={
                            selectedSubscriber.subscriber
                              ? selectedSubscriber.subscriber.last_name +
                                ", " +
                                selectedSubscriber.subscriber.first_name
                              : ""
                          }
                          onChange={handleInputChangeSubscriber}
                        />
                        <TextField
                          margin="dense"
                          name="life_insurance_percentage"
                          label="Seguro de Vida (%)"
                          type="number"
                          fullWidth
                          value={
                            selectedSubscriber
                              ? selectedSubscriber.life_insurance_percentage
                              : ""
                          }
                          onChange={handleInputChangeSubscriber}
                        />
                      </FormBoxLeft>
                      <FormBoxCenter />
                      <FormBoxRight sx={{ width: "300px" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                selectedSubscriber
                                  ? selectedSubscriber.social_fee_payment
                                  : false
                              }
                              onChange={() =>
                                handleInputChangeSubscriber({
                                  target: {
                                    name: "social_fee_payment",
                                    value:
                                      !(selectedSubscriber.social_fee_payment ===
                                      "True"
                                        ? true
                                        : selectedSubscriber.social_fee_payment ===
                                          "False"
                                        ? false
                                        : selectedSubscriber.social_fee_payment ===
                                          true
                                        ? true
                                        : false),
                                  },
                                })
                              }
                            />
                          }
                          label="Paga Cuota Social"
                          sx={{
                            mt: "8px",
                            ml: "0px",
                            border: "1px solid #c6c7c5",
                            borderRadius: "4px",
                            width: "100%",
                            padding: "6px",
                            display: "flex",
                            paddingLeft: "60px",
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                selectedSubscriber
                                  ? selectedSubscriber.postage_address
                                  : false
                              }
                              onChange={() =>
                                handleInputChangeSubscriber({
                                  target: {
                                    name: "postage_address",
                                    value:
                                      !(selectedSubscriber.postage_address ===
                                      "True"
                                        ? true
                                        : selectedSubscriber.postage_address ===
                                          "False"
                                        ? false
                                        : selectedSubscriber.postage_address ===
                                          true
                                        ? true
                                        : false),
                                  },
                                })
                              }
                            />
                          }
                          label="Dirección Franqueo"
                          sx={{
                            mt: "8px",
                            ml: "0px",
                            border: "1px solid #c6c7c5",
                            borderRadius: "4px",
                            width: "100%",
                            padding: "6px",
                            display: "flex",
                            paddingLeft: "60px",
                            mt: 1.5,
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                selectedSubscriber
                                  ? selectedSubscriber.mail_coupon
                                  : false
                              }
                              onChange={() =>
                                handleInputChangeSubscriber({
                                  target: {
                                    name: "mail_coupon",
                                    value: !(selectedSubscriber.mail_coupon ===
                                    "True"
                                      ? true
                                      : selectedSubscriber.mail_coupon ===
                                        "False"
                                      ? false
                                      : selectedSubscriber.mail_coupon === true
                                      ? true
                                      : false),
                                  },
                                })
                              }
                            />
                          }
                          label="Cupón por mail"
                          sx={{
                            mt: "8px",
                            ml: "0px",
                            border: "1px solid #c6c7c5",
                            borderRadius: "4px",
                            width: "100%",
                            padding: "6px",
                            display: "flex",
                            paddingLeft: "60px",
                            mt: 1.5,
                          }}
                        />
                      </FormBoxRight>
                    </FormBoxContainer>
                  </Box>
                </Box>
              </DialogContent>
              <Actions {...actionSubscriberProps} />
            </Dialog>
          </>
        )}
        {activeTab === 2 && selectedContract.additionals && (
          <>
            <Box sx={{ minHeight: "480px", minWidth: "1099px" }}>
              <DialogContent sx={{ display: "flex" }}>
                <FormBoxContainer>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ marginTop: 4 }}
                  >
                    <Box>
                      <AddButton
                        variant="contained"
                        onClick={handleClickOpenAdditional}
                        sx={{ marginBottom: "10px" }}
                      >
                        <PostAddIcon />
                      </AddButton>
                      <GeneralTableContainer
                        sx={{ boxShadow: "5px 5px 20px rgba(1,2,10,0.3)" }}
                      >
                        <Table aria-label="contract table">
                          <TableHead>
                            <TableRow>
                              <HeadTableCell sx={{ minWidth: "250px" }}>
                                Descripción
                              </HeadTableCell>
                              <HeadTableCell sx={{ minWidth: "250px" }}>
                                Detalle
                              </HeadTableCell>
                              <HeadTableCell
                                sx={{ textAlign: "center", minWidth: "150px" }}
                              >
                                Importe
                              </HeadTableCell>
                              <HeadTableCell
                                sx={{ textAlign: "center", width: "90px" }}
                              >
                                Cuota Actual
                              </HeadTableCell>
                              <HeadTableCell
                                sx={{ textAlign: "center", width: "90px" }}
                              >
                                Cuota Final
                              </HeadTableCell>
                              <HeadTableCell
                                sx={{ textAlign: "center", width: "100px" }}
                              >
                                Acciones
                              </HeadTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedContract.additionals.map(
                              (additional, key) => (
                                <TableRow key={key}>
                                  <RowTableCell>
                                    {additional
                                      ? additional.additional.name
                                      : ""}
                                  </RowTableCell>
                                  <RowTableCell>
                                    {additional ? additional.description : ""}
                                  </RowTableCell>
                                  <RowTableCell sx={{ textAlign: "center" }}>
                                    {additional ? additional.amount : ""}
                                  </RowTableCell>
                                  <RowTableCell sx={{ textAlign: "center" }}>
                                    {additional ? additional.current_fee : ""}
                                  </RowTableCell>
                                  <RowTableCell sx={{ textAlign: "center" }}>
                                    {additional ? additional.final_fee : ""}
                                  </RowTableCell>
                                  <RowTableCell
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <EditButton
                                      variant="contained"
                                      sx={{
                                        width: "80%",
                                        height: "80%",
                                        marginX: 0,
                                      }}
                                      onClick={() =>
                                        handleEditAdditional(additional)
                                      }
                                    >
                                      <IconButton size="small">
                                        <EditIcon
                                          sx={{ color: "whitesmoke" }}
                                        />
                                      </IconButton>
                                    </EditButton>
                                    <DeleteButton
                                      variant="contained"
                                      sx={{
                                        width: "80%",
                                        height: "80%",
                                        marginTop: 0.5,
                                        marginX: 0,
                                        "&:hover": {
                                          backgroundColor: "#A93226",
                                        },
                                      }}
                                      onClick={() =>
                                        handleDeleteAdditional(additional.uuid)
                                      }
                                    >
                                      <IconButton size="small">
                                        <DeleteIcon
                                          sx={{ color: "whitesmoke" }}
                                        />
                                      </IconButton>
                                    </DeleteButton>
                                  </RowTableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </GeneralTableContainer>
                    </Box>
                  </Grid>
                </FormBoxContainer>
              </DialogContent>
            </Box>
            <Dialog
              open={openAdditional}
              onClose={handleCloseAdditional}
              maxWidth={false}
            >
              <FormDialogTitle>
                {editModeAdditional ? "EDITAR ADICIONAL" : "AGREGAR ADICIONAL"}
              </FormDialogTitle>
              <SeparatorHrBox>
                <hr />
              </SeparatorHrBox>
              <DialogContent sx={{ display: "flex" }}>
                <Box>
                  <Box>
                    <FormBoxContainer>
                      <FormBoxLeft sx={{ width: "300px" }}>
                        <FormControl
                          fullWidth
                          sx={{ marginTop: 1, marginBottom: 0.5 }}
                        >
                          <InputLabel id="descripcion-label">
                            Descripción
                          </InputLabel>
                          <Select
                            fullWidth
                            name="uuid"
                            variant="outlined"
                            value={
                              selectedAdditional.additional
                                ? selectedAdditional.additional.uuid
                                : 0
                            }
                            onChange={handleInputChangeAdditional}
                            // sx={{ marginRight: 5, width: 150 }}
                          >
                            <MenuItem value={0}>Seleccionar</MenuItem>
                            {additionalsData.map((additional) => (
                              <MenuItem
                                key={additional.uuid}
                                value={additional.uuid}
                              >
                                {additional.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          margin="dense"
                          name="amount"
                          label="Importe"
                          type="number"
                          fullWidth
                          value={
                            selectedAdditional ? selectedAdditional.amount : ""
                          }
                          onChange={handleInputChangeAdditional}
                        />
                      </FormBoxLeft>
                      <FormBoxCenter />
                      <FormBoxRight sx={{ width: "300px" }}>
                        <TextField
                          disabled
                          margin="dense"
                          name="current_fee"
                          label="Cuota Actual"
                          type="text"
                          fullWidth
                          value={
                            selectedAdditional
                              ? selectedAdditional.current_fee
                              : ""
                          }
                          onChange={handleInputChangeAdditional}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TextField
                            disabled
                            margin="dense"
                            name="initial_fee"
                            label="Cuota Inicial"
                            type="text"
                            fullWidth
                            value={
                              selectedAdditional
                                ? selectedAdditional.initial_fee
                                : ""
                            }
                            onChange={handleInputChangeAdditional}
                          />
                          <Box sx={{ fontSize: "30px", marginX: 1 }}>/</Box>
                          <TextField
                            disabled
                            margin="dense"
                            name="final_fee"
                            label="Cuota Final"
                            type="text"
                            fullWidth
                            value={
                              selectedAdditional
                                ? selectedAdditional.final_fee
                                : ""
                            }
                            onChange={handleInputChangeAdditional}
                          />
                        </Box>
                      </FormBoxRight>
                    </FormBoxContainer>
                    <TextField
                      disabled={!editDescription}
                      margin="dense"
                      name="description"
                      label="Detalle"
                      type="text"
                      fullWidth
                      value={
                        selectedAdditional ? selectedAdditional.description : ""
                      }
                      onChange={handleInputChangeAdditional}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <Actions {...actionAdditionalProps} />
            </Dialog>
          </>
        )}
        {activeTab === 3 && (
          <Box sx={{ height: "480px", width: "1099px" }}>
            <DialogContent sx={{ display: "flex" }}>
              <FormBoxContainer>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ marginTop: 8 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px",
                      padding: 4,
                      boxShadow: "5px 5px 20px rgba(1,2,10,0.3)",
                      borderRadius: 3,
                      backgroundColor: "#f2f2f2",
                    }}
                  >
                    <FormBoxLeft sx={{ width: "100%", marginRight: "20px" }}>
                      <FormTextareaAutosize
                        rowsMin={3}
                        placeholder="Observación"
                        aria-label="release_reason_obs"
                        margin="dense"
                        name="release_reason_obs"
                        value={selectedContract.release_reason_obs}
                        onChange={handleInputChange}
                        style={{
                          height: "105px",
                          width: "400px",
                          borderColor: "lightgray",
                          marginTop: "15px",
                          backgroundColor: "#fcfcfc",
                        }}
                      />
                    </FormBoxLeft>
                    <FormBoxRight sx={{ width: "100%" }}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <SelectFormControl>
                          <InputLabel
                            margin="dense"
                            id="uuid_release_reason-label"
                          >
                            Motivo
                          </InputLabel>
                          <Select
                            labelId="uuid_release_reason-label"
                            name="uuid_release_reason"
                            value={
                              selectedContract.uuid_release_reason != "None"
                                ? selectedContract.uuid_release_reason
                                : 0
                            }
                            onChange={handleSelectChange}
                            sx={{ backgroundColor: "#fcfcfc" }}
                          >
                            <MenuItem key={0} value={0}>
                              Seleccionar
                            </MenuItem>
                            {releaseReasons.map((rr, index) => (
                              <MenuItem key={index} value={rr.uuid}>
                                {rr.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </SelectFormControl>
                        <Button
                          variant="contained"
                          color="tertiary"
                          sx={{
                            marginLeft: "140px",
                            marginTop: 2,
                            color: "whitesmoke",
                          }}
                          onClick={() => handleReleaseContract()}
                        >
                          Liberar
                        </Button>
                      </Box>
                    </FormBoxRight>
                  </Box>
                </Grid>
              </FormBoxContainer>
            </DialogContent>
          </Box>
        )}
        {activeTab === 4 && (
          <Box
            sx={{
              height: "480px",
              width: "1099px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DialogContent>
              {isArchiveDialog ? (
                // Contenido para desarchivar
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    ¿Desarchivar este contrato con todos sus datos y activarlo para proximos movimientos?
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => confirmAction("desarchivar")}
                  >
                    Desarchivar
                  </Button>
                </Box>
              ) : (
                // Contenido para archivar
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  ¿Archivar este contrato con todos sus datos y desactivarlo para proximos movimientos?
                  </Typography>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => confirmAction("archivar")}
                  >
                    Archivar
                  </Button>
                </Box>
              )}
            </DialogContent>
          </Box>
        )}
      </Box>
      <Actions {...actionProps} />
    </Dialog>
  );
};

export default ContractEditDialog;
