import React from "react";
import { DialogActions } from "@mui/material";

import { AceptButton, CancelButton } from "../../assets/styles/tables";

const Actions = (props) => {

  const { handleClose, handleSave } = props;

  return (
    <DialogActions>
      <AceptButton onClick={handleSave} variant="contained">
        Guardar
      </AceptButton>
      <CancelButton
        onClick={handleClose}
        variant="contained"
        color="tertiary"
      >
        Cancelar
      </CancelButton>
    </DialogActions>
  )
}

export default Actions;