import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient";

export const getPaymentMethodDetails = createAsyncThunk(
  "paymentMethodDetails",
  async () => {
    const response = await apiClient.get("payment_method_details");
    return response.data;
  }
);

export const getPaymentMethodDetailById = createAsyncThunk(
  "paymentMethodDetail/id",
  async (id) => {
    const response = await apiClient.get(`payment_method_detail/${id}`);
    return response.data;
  }
);

export const deletePaymentMethodDetailById = createAsyncThunk(
  "paymentMethodDetail/delete",
  async (id) => {
    const response = await apiClient.delete(`payment_method_detail/${id}`);
    return response.data;
  }
);

export const addPaymentMethodDetail = createAsyncThunk(
  "paymentMethodDetail/add",
  async (paymentMethodDetail) => {
    console.log(paymentMethodDetail)
    const response = await apiClient.post(
      "payment_method_details",
      paymentMethodDetail
    );
    return response.data;
  }
);

export const updatePaymentMethodDetailById = createAsyncThunk(
  "paymentMethodDetail/id",
  async ({ id, data }) => {
    const response = await apiClient.put(`payment_method_detail/${id}`, data);
    return response.data;
  }
);
