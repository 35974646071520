import React from "react";

import {
  AccessButton,
  DeleteButton,
  EditButton,
  GeneralTableBox,
  GeneralTableContainer,
  HeadTableCell,
  PaginationBox,
  RowTableCell
} from "../../assets/styles/tables";

import {
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableHead,
  TableRow
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VpnKeyIcon from "@mui/icons-material/VpnKey";


const ProfilesTable = (props) => {

  const { profiles, handleEdit, handleDelete,
    getCurrentPageItems, ITEMS_PER_PAGE,
    handleAccessEdit, page, setPage } = props;

  return (
    <GeneralTableBox>
      <GeneralTableContainer>
        <Table aria-label="profile table">
          <TableHead>
            <TableRow>
              <HeadTableCell sx={{ width: "15%" }}>
                Nombre
              </HeadTableCell>
              <HeadTableCell sx={{ width: "30%", minWidth: "300px" }}>
                Descripción
              </HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "18%" }}>
                Acciones
              </HeadTableCell>
              <HeadTableCell sx={{ textAlign: "center", width: "12%" }}>
                Accesos
              </HeadTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCurrentPageItems().map((profile) => (
              <TableRow key={profile.id}>
                <RowTableCell>
                  {profile.name}
                </RowTableCell>
                <RowTableCell>
                  {profile.description}
                </RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  <EditButton
                    variant="contained"
                    onClick={() => handleEdit(profile)}
                  >
                    <IconButton size="small">
                      <EditIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </EditButton>
                  <DeleteButton
                    variant="contained"
                    onClick={() => handleDelete(profile.uuid)}
                    color="tertiary"
                  >
                    <IconButton size="small">
                      <DeleteIcon sx={{ color: "whitesmoke" }} />
                    </IconButton>
                  </DeleteButton>
                </RowTableCell>
                <RowTableCell sx={{ textAlign: "center" }}>
                  <AccessButton onClick={() => handleAccessEdit(profile)}>
                    <IconButton size="small">
                      <VpnKeyIcon sx={{ color: "red" }} />
                    </IconButton>
                  </AccessButton>
                </RowTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <PaginationBox>
          <Pagination
            count={Math.ceil(profiles.length / ITEMS_PER_PAGE)}
            shape="rounded"
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </PaginationBox>
      </GeneralTableContainer>
    </GeneralTableBox>
  )
}

export default ProfilesTable;