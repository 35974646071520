import React from "react";
import CouponsDeferredForm from "../common/CouponsDeferredForm";
import { Box } from "@mui/material";

const CouponsDeferred = () => {
  return (
    <Box mt={12}>
      <CouponsDeferredForm />
    </Box>
  );
};

export default CouponsDeferred;
