import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { apiSlice } from "./api/apiClient";
import { ApiProvider } from "@reduxjs/toolkit/query/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./app/store";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./assets/styles/index.css";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#5e894f",
      cancel: "#d44242",
    },
    secondary: {
      main: "#f5f5f5",
    },
    tertiary: {
      main: "#d44242",
    },
    background: {
      paper: "#ffffff",
      default: "#d6d8d5",
      menus: "#ededed",
      formTables: "#f2f2f2",
    },
    divider: "rgba(0,0,0,0.12)",
  },
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
