import React from "react";
import ContractsForm from '../common/ContractsForm';
import { Box } from "@mui/material";

const Contracts = () => {
  return (
    <Box mt={12}>
      <ContractsForm />
    </Box>
  );
};

export default Contracts;