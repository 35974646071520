import { createSlice } from '@reduxjs/toolkit';
import { getSubscribers } from '../../api/endpoints/subscribers';

export const SubscriberSlice = createSlice({
  name: 'Subscribers',
  initialState: [], // Cambiar el estado inicial a un arreglo vacío
  reducers: {
    addSubscribers: (state, action) => {
      state = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    removeSubscriber: (state, action) => {
      const index = state.findIndex((Subscriber) => Subscriber.uuid === action.payload);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscribers.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { addSubscribers, removeSubscriber } = SubscriberSlice.actions;

export default SubscriberSlice.reducer;
