import { createSlice } from "@reduxjs/toolkit";
import { getPreAdjudicationReasons } from "../../api/endpoints/preAdjudicationReasons";

export const preAdjudicationReasonSlice = createSlice({
  name: "preAdjudicationReason",
  initialState: {
    preAdjudicationReasons: [],
    preAdjudicationReason: null,
  },
  reducers: {
    addPreAdjudicationReasons: (state, action) => {
      state.preAdjudicationReasons = action.payload; // Asignar el payload directamente al estado
      return state; // Retornar el nuevo estado
    },
    addPreAdjudicationReason: (state, action) => {
      state.preAdjudicationReason = action.payload;
    },
    clearPreAdjudicationReason: (state, action) => {
      state.preAdjudicationReason = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreAdjudicationReasons.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPreAdjudicationReasons.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.preAdjudicationReasons = action.payload;
      })
      .addCase(getPreAdjudicationReasons.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  addPreAdjudicationReasons,
  addPreAdjudicationReason,
  clearPreAdjudicationReason,
} = preAdjudicationReasonSlice.actions;

export default preAdjudicationReasonSlice.reducer;
