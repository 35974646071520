import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Pagination,
} from "@mui/material";
import {
  HeadTableCell,
  RowTableCell,
  EditButton,
  DeleteButton,
  PaginationBox,
  HeaderBox,
  AddButton,
} from "../../assets/styles/tables";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";

const PaymentMethodTab = (props) => {
  const {
    handleEditPaymentMethod,
    handleDeletePaymentMethod,
    paymentMethods,
    setSelectedPaymentMethod,
    setOpenPaymentMethod,
  } = props;

  const handleClickOpen = () => {
    setSelectedPaymentMethod({});
    setOpenPaymentMethod(true);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {/* <br />
      <HeaderBox sx={{ marginLeft: 0 }}>
        <AddButton variant="contained" onClick={handleClickOpen}>
          <PostAddIcon fontSize="large" />
        </AddButton>
      </HeaderBox>
      <br /> */}
      <Table>
        <TableHead>
          <TableRow>
            <HeadTableCell sx={{ width: "50%", textAlign: "center" }}>
              Nombre
            </HeadTableCell>
            <HeadTableCell sx={{ width: "50%", textAlign: "center" }}>
              Fecha baja
            </HeadTableCell>
            {/* <HeadTableCell sx={{ width: "20%", textAlign: "center" }}>
              Acciones
            </HeadTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentMethods.map((paymentMethod) => (
            <TableRow key={paymentMethod.id}>
              <RowTableCell sx={{ textAlign: "center" }}>
                {paymentMethod.name}
              </RowTableCell>
              <RowTableCell sx={{ textAlign: "center" }}>
                {paymentMethod.low_date}
              </RowTableCell>
              {/* <RowTableCell sx={{ textAlign: "center" }}>
                <EditButton
                  variant="contained"
                  onClick={() => handleEditPaymentMethod(paymentMethod)}
                >
                  <IconButton size="small">
                    <EditIcon sx={{ color: "whitesmoke" }} />
                  </IconButton>
                </EditButton>
                <DeleteButton
                  variant="contained"
                  onClick={() =>
                    handleDeletePaymentMethod(
                      paymentMethod.id,
                      paymentMethod.uuid
                    )
                  }
                >
                  <IconButton size="small">
                    <DeleteIcon sx={{ color: "whitesmoke" }} />
                  </IconButton>
                </DeleteButton>
              </RowTableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PaymentMethodTab;